import React, { useState, useEffect } from 'react'
import { withData } from '../context'

export default withData(({ data, fulcrum }) => {
  const [thumbnail, setThumbnail] = useState(null)

  useEffect(() => {
    fulcrum.getPhoto(data.photo_id).then(resp => {
      setThumbnail(resp.thumbnail)
    })
    return () => setThumbnail(null)
  }, [data, fulcrum])

  return (
    <a href={`https://web.fulcrumapp.com/api/v2/photos/${data.photo_id}`} target='_blank' rel='noopener noreferrer'>
      <img src={thumbnail} className='photo' alt='img' />
    </a>
  )
})
