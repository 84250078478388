import React from 'react'
import { withData } from './context'
import Select from 'react-select'
import Button from 'antd/lib/button'

export default withData(
  ({ selectedJob, loading, setSelectedJob, data, startBlank }) =>
    !selectedJob && (
      <div className='enq-action'>
        <Select
          options={data}
          value={selectedJob}
          classNamePrefix='job_select'
          onChange={setSelectedJob}
          placeholder='Select or search for a name, phone number or address...'
          isLoading={loading}
          isClearable
          getOptionLabel={r => {
            let str = ''
            if (r.form_values['6612']) str += r.form_values['6612']
            if (r.form_values['6612'] && r.form_values['ba02']) str += ' | '
            if (r.form_values['ba02']) str += r.form_values['ba02']
            if ((r.form_values['ba02'] && r.form_values['c0bc']) || (r.form_values['6612'] && r.form_values['c0bc']))
              str += ' | '
            if (r.form_values['c0bc']) str += r.form_values['c0bc']
            return str
          }}
          getOptionValue={r => r.id}
          theme={theme => ({
            ...theme,
            borderWidth: 1,
            colors: {
              ...theme.colors,
              primary: '#40a9ff'
            }
          })}
        />
        <Button type='primary' loading={loading} onClick={startBlank}>
          Create New
        </Button>
      </div>
    )
)
