import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from './Auth'

import ReactGA from 'react-ga'

import { Loading } from '../components/layout/Loading'
import PrintHeader from '../components/layout/PrintHeader'

const PrivateRoute = ({ component: RouteComponent, restricted, adminOnly, dev, ...rest }) => {
  const { currentUser, user } = useContext(AuthContext)

  ReactGA.pageview(window.location.pathname)

  const MyRoute = ({ props }) => (
    <div id='content-container'>
      <div id='content'>
        <RouteComponent {...props} />
      </div>
    </div>
  )
  if (currentUser === false) return <Loading />
  if (user === false) return <Loading />

  return (
    <div>
      <PrintHeader page={RouteComponent.name} />
      <Route
        {...rest}
        render={(routeProps) => {
          if (!!currentUser) {
            if (!adminOnly) {
              return <MyRoute props={routeProps} />
            } else {
              if (user.role === 'admin') {
                return <MyRoute props={routeProps} />
              } else {
                return <Redirect to={'/'} />
              }
            }
          } else {
            return <Redirect to={'/login'} />
          }
        }}
      />
    </div>
  )
}

export default PrivateRoute
