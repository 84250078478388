import React from 'react'
import moment from 'moment'

export default (timesheet) => {
  let weekEnd = timesheet ? moment(timesheet.form_values['b4cf']) : moment()
  var columns = [
    {
      title: '',
      dataIndex: 0,
      key: 'name',
      className: 'timesheet-type',
      width: 50
    }
  ]
  let days = []
  for (let i = 0; i < 7; i++) {
    let day = moment(weekEnd).subtract(i, 'days')
    days.push({
      title: day.format('ddd'),
      className: 'timesheet-subheader',
      children: [
        {
          title: day.format('D-MMM'),
          dataIndex: day.format('D-MMM'),
          className: 'timesheet-subheader',
          key: day.format('D-MMM'),
          width: 50,
          render: (v) => (
            <div className='timesheet-value' suppressContentEditableWarning contentEditable>
              {v}
            </div>
          )
        }
      ]
    })
  }

  days.reverse()

  return [...columns, ...days]
}
