export default [
  [
    { lat: -38.2165841844191, lng: 146.500861661171 },
    { lat: -38.2179824507004, lng: 146.501676782864 },
    { lat: -38.2192647436355, lng: 146.502671458408 },
    { lat: -38.2202845742479, lng: 146.504275884544 },
    { lat: -38.2213028057511, lng: 146.506095010336 },
    { lat: -38.2219773819685, lng: 146.507880265955 },
    { lat: -38.2223605627666, lng: 146.510311221791 },
    { lat: -38.2226330810946, lng: 146.512206093109 },
    { lat: -38.2232860938016, lng: 146.520754082745 },
    { lat: -38.2236613011101, lng: 146.524258518113 },
    { lat: -38.2238594946872, lng: 146.528443775854 },
    { lat: -38.2242267551726, lng: 146.532761870173 },
    { lat: -38.2246608414394, lng: 146.538734638574 },
    { lat: -38.22440542469, lng: 146.544038563907 },
    { lat: -38.2252274676764, lng: 146.543534874313 },
    { lat: -38.2286458789166, lng: 146.541440067243 },
    { lat: -38.2287283461962, lng: 146.541415559269 },
    { lat: -38.2307140993279, lng: 146.540825431185 },
    { lat: -38.2315006719674, lng: 146.540591658783 },
    { lat: -38.2325357513446, lng: 146.540956814942 },
    { lat: -38.233605816019, lng: 146.541334335208 },
    { lat: -38.2381657008345, lng: 146.543481424737 },
    { lat: -38.2441697518085, lng: 146.546308941796 },
    { lat: -38.2443586476864, lng: 146.546394147726 },
    { lat: -38.2464595159919, lng: 146.54738955992 },
    { lat: -38.246627238177, lng: 146.547492607758 },
    { lat: -38.2484153236289, lng: 146.548591342973 },
    { lat: -38.2492622300406, lng: 146.547372983095 },
    { lat: -38.2500416204138, lng: 146.543770966507 },
    { lat: -38.2502468191045, lng: 146.542822481266 },
    { lat: -38.25030226444, lng: 146.542840300991 },
    { lat: -38.2503936308257, lng: 146.542889558579 },
    { lat: -38.2504993537257, lng: 146.542929708766 },
    { lat: -38.2505627808387, lng: 146.5429546127 },
    { lat: -38.2506411682878, lng: 146.54296024547 },
    { lat: -38.2507757727526, lng: 146.542941578095 },
    { lat: -38.250868636445, lng: 146.542906674841 },
    { lat: -38.2509558293, lng: 146.542858406666 },
    { lat: -38.2511945010594, lng: 146.542747406557 },
    { lat: -38.2513240917721, lng: 146.542705121983 },
    { lat: -38.2515518662049, lng: 146.542723657031 },
    { lat: -38.2520569873311, lng: 146.542914858939 },
    { lat: -38.2521400827043, lng: 146.542940038556 },
    { lat: -38.2522562435925, lng: 146.542975050522 },
    { lat: -38.2523752870663, lng: 146.543001351956 },
    { lat: -38.2524433892888, lng: 146.543020315656 },
    { lat: -38.252504062114, lng: 146.543020414631 },
    { lat: -38.2525572796362, lng: 146.543040820448 },
    { lat: -38.2526087808535, lng: 146.543064118128 },
    { lat: -38.2526831559069, lng: 146.543100516544 },
    { lat: -38.2527249169657, lng: 146.543119458748 },
    { lat: -38.2527999130444, lng: 146.54312609153 },
    { lat: -38.2528582911895, lng: 146.543126895193 },
    { lat: -38.2529247009948, lng: 146.543119745245 },
    { lat: -38.2529899383631, lng: 146.543130733789 },
    { lat: -38.2530591961274, lng: 146.54313809296 },
    { lat: -38.2531135629949, lng: 146.543157035079 },
    { lat: -38.253175383144, lng: 146.543146244394 },
    { lat: -38.2532320694493, lng: 146.543134704911 },
    { lat: -38.2532761680937, lng: 146.54310863539 },
    { lat: -38.2533156931369, lng: 146.543081827436 },
    { lat: -38.2533626643851, lng: 146.54303690357 },
    { lat: -38.253407356628, lng: 146.542986875989 },
    { lat: -38.2534411690421, lng: 146.542939751802 },
    { lat: -38.2534698488325, lng: 146.54287881177 },
    { lat: -38.2534990972562, lng: 146.542822228404 },
    { lat: -38.2535306235558, lng: 146.542783805422 },
    { lat: -38.2535758778893, lng: 146.542741037323 },
    { lat: -38.2536199667631, lng: 146.542716429688 },
    { lat: -38.2536595010327, lng: 146.542676554502 },
    { lat: -38.2537334328073, lng: 146.542585211451 },
    { lat: -38.2538389267438, lng: 146.54238718861 },
    { lat: -38.2539209611631, lng: 146.542202201672 },
    { lat: -38.2539553772383, lng: 146.542107162264 },
    { lat: -38.2539771967501, lng: 146.542036783781 },
    { lat: -38.2539944177353, lng: 146.541982356527 },
    { lat: -38.2539984719422, lng: 146.541934462561 },
    { lat: -38.2539986586239, lng: 146.541915685437 },
    { lat: -38.2555548558624, lng: 146.541625680762 },
    { lat: -38.2564162205643, lng: 146.548444514978 },
    { lat: -38.256494819783, lng: 146.548912080901 },
    { lat: -38.2568321174005, lng: 146.548691267349 },
    { lat: -38.257010701848, lng: 146.54857436987 },
    { lat: -38.2589845379905, lng: 146.548188941515 },
    { lat: -38.2594872109199, lng: 146.547436892709 },
    { lat: -38.2601990858864, lng: 146.546989324905 },
    { lat: -38.2605038533151, lng: 146.546928164924 },
    { lat: -38.261006637165, lng: 146.546826579671 },
    { lat: -38.2635290380097, lng: 146.546316939534 },
    { lat: -38.2639486228221, lng: 146.546232151559 },
    { lat: -38.2650951278023, lng: 146.546153709654 },
    { lat: -38.265864268258, lng: 146.546332944005 },
    { lat: -38.2677340638157, lng: 146.546772030434 },
    { lat: -38.2700730426556, lng: 146.546346396514 },
    { lat: -38.2734728991018, lng: 146.546911532987 },
    { lat: -38.2759283189687, lng: 146.5485521716 },
    { lat: -38.2760829427102, lng: 146.548525915302 },
    { lat: -38.2761378780445, lng: 146.548516575687 },
    { lat: -38.2763847687839, lng: 146.548550159024 },
    { lat: -38.2776502412608, lng: 146.548722232496 },
    { lat: -38.2782579822494, lng: 146.548973912569 },
    { lat: -38.2784724543696, lng: 146.549062736725 },
    { lat: -38.2792782032391, lng: 146.549617642471 },
    { lat: -38.2818550802411, lng: 146.551876162836 },
    { lat: -38.2826307314417, lng: 146.553159323803 },
    { lat: -38.2831786582116, lng: 146.554864103566 },
    { lat: -38.2834263387526, lng: 146.555691523882 },
    { lat: -38.2831377733268, lng: 146.553113905049 },
    { lat: -38.282429637993, lng: 146.546790972703 },
    { lat: -38.2822954824175, lng: 146.545593336868 },
    { lat: -38.2823134338412, lng: 146.545600112966 },
    { lat: -38.282331837196, lng: 146.545605084492 },
    { lat: -38.282350501809, lng: 146.545608648696 },
    { lat: -38.2823692539519, lng: 146.545610793999 },
    { lat: -38.2823876980709, lng: 146.545611805993 },
    { lat: -38.2824922402681, lng: 146.545560215999 },
    { lat: -38.2825776140603, lng: 146.545518097142 },
    { lat: -38.282693519714, lng: 146.54529378548 },
    { lat: -38.2828173139486, lng: 146.544982528499 },
    { lat: -38.2829211925498, lng: 146.544807354466 },
    { lat: -38.2829453326539, lng: 146.544502918488 },
    { lat: -38.2828904569406, lng: 146.543908114771 },
    { lat: -38.2828177901459, lng: 146.543368125313 },
    { lat: -38.2828620240263, lng: 146.543107633864 },
    { lat: -38.2829757875751, lng: 146.542967493749 },
    { lat: -38.2831056507317, lng: 146.542920546523 },
    { lat: -38.2831871316747, lng: 146.542803033488 },
    { lat: -38.283350578591, lng: 146.542621697838 },
    { lat: -38.2834326965727, lng: 146.542350306187 },
    { lat: -38.2834784533672, lng: 146.542155154483 },
    { lat: -38.2836890233807, lng: 146.542056022841 },
    { lat: -38.2839125480695, lng: 146.54192516691 },
    { lat: -38.2840799809713, lng: 146.541697467514 },
    { lat: -38.28418890777, lng: 146.541580459546 },
    { lat: -38.2841490183902, lng: 146.541467919361 },
    { lat: -38.2838701657079, lng: 146.541239119287 },
    { lat: -38.2835274213512, lng: 146.541093358529 },
    { lat: -38.2832876189899, lng: 146.540949499519 },
    { lat: -38.283226054248, lng: 146.540733482171 },
    { lat: -38.2832012932128, lng: 146.540500733265 },
    { lat: -38.2831047841895, lng: 146.540336326925 },
    { lat: -38.2827688637225, lng: 146.540094865774 },
    { lat: -38.2825868540659, lng: 146.539804025479 },
    { lat: -38.2825793823676, lng: 146.539658704943 },
    { lat: -38.2826301612588, lng: 146.539523185035 },
    { lat: -38.2826507738885, lng: 146.539324678808 },
    { lat: -38.2827516001987, lng: 146.539116053296 },
    { lat: -38.282806536086, lng: 146.538918185473 },
    { lat: -38.2828261708134, lng: 146.538705137127 },
    { lat: -38.2828605029254, lng: 146.53850833564 },
    { lat: -38.2829465907402, lng: 146.538287818848 },
    { lat: -38.2828727166203, lng: 146.538046897386 },
    { lat: -38.282708470651, lng: 146.537802839518 },
    { lat: -38.2825741968498, lng: 146.53763774047 },
    { lat: -38.2826722192055, lng: 146.537375335976 },
    { lat: -38.2827303162401, lng: 146.537200754766 },
    { lat: -38.2827683028605, lng: 146.536786253075 },
    { lat: -38.2829273891427, lng: 146.536289801562 },
    { lat: -38.283137729579, lng: 146.535814633465 },
    { lat: -38.2832562292682, lng: 146.53559445846 },
    { lat: -38.2826393332119, lng: 146.53507511555 },
    { lat: -38.2782808300315, lng: 146.53592695557 },
    { lat: -38.2782273483004, lng: 146.535937415584 },
    { lat: -38.2780606030659, lng: 146.534569092933 },
    { lat: -38.2780324256775, lng: 146.534340249182 },
    { lat: -38.2771516988789, lng: 146.534417842806 },
    { lat: -38.2769721276594, lng: 146.534433968988 },
    { lat: -38.2754820609131, lng: 146.522003155102 },
    { lat: -38.2752003538059, lng: 146.519675367648 },
    { lat: -38.2753823297799, lng: 146.519638715889 },
    { lat: -38.2789540809482, lng: 146.518972027936 },
    { lat: -38.278815463439, lng: 146.517838664593 },
    { lat: -38.2781063332057, lng: 146.512042897278 },
    { lat: -38.2785979900547, lng: 146.511947724952 },
    { lat: -38.2784945706519, lng: 146.511085434997 },
    { lat: -38.2770903884506, lng: 146.512635070573 },
    { lat: -38.2748703920097, lng: 146.51539136318 },
    { lat: -38.274786378108, lng: 146.515494300852 },
    { lat: -38.2747062742499, lng: 146.515593708168 },
    { lat: -38.2719310487512, lng: 146.519037257583 },
    { lat: -38.2660994035653, lng: 146.518452784404 },
    { lat: -38.2653548197798, lng: 146.511001185627 },
    { lat: -38.2668490605921, lng: 146.510128864095 },
    { lat: -38.267644653049, lng: 146.509664366866 },
    { lat: -38.2735692383123, lng: 146.506205141612 },
    { lat: -38.2736570596165, lng: 146.506153848642 },
    { lat: -38.2737494967071, lng: 146.506096164934 },
    { lat: -38.2743047512009, lng: 146.50576333843 },
    { lat: -38.2783795065842, lng: 146.510126004555 },
    { lat: -38.278100757891, lng: 146.507802111422 },
    { lat: -38.2785262932306, lng: 146.507509126317 },
    { lat: -38.2774818383602, lng: 146.506888483507 },
    { lat: -38.2759486088275, lng: 146.505977419811 },
    { lat: -38.2756929372129, lng: 146.505831670531 },
    { lat: -38.2747050590179, lng: 146.498131647948 },
    { lat: -38.2734458165618, lng: 146.498389916777 },
    { lat: -38.2711717194194, lng: 146.498856349784 },
    { lat: -38.2704673918176, lng: 146.49900080146 },
    { lat: -38.2697880983087, lng: 146.499140106405 },
    { lat: -38.2682982090903, lng: 146.499445664148 },
    { lat: -38.2681955461207, lng: 146.499466915731 },
    { lat: -38.268108241628, lng: 146.499484857353 },
    { lat: -38.2669034863373, lng: 146.499732488873 },
    { lat: -38.265930040978, lng: 146.499931908187 },
    { lat: -38.2658517314122, lng: 146.499948660684 },
    { lat: -38.2657643590062, lng: 146.499967339161 },
    { lat: -38.264519967592, lng: 146.500221944652 },
    { lat: -38.2632727875947, lng: 146.500477100733 },
    { lat: -38.2633065917716, lng: 146.500793504551 },
    { lat: -38.2617396506724, lng: 146.501106333716 },
    { lat: -38.2616561560509, lng: 146.501125033683 },
    { lat: -38.2615634808243, lng: 146.501145780425 },
    { lat: -38.2593571190768, lng: 146.501597704336 },
    { lat: -38.2571361360338, lng: 146.502052597499 },
    { lat: -38.2569602967247, lng: 146.502100965104 },
    { lat: -38.2567718520993, lng: 146.500628670166 },
    { lat: -38.2557620517847, lng: 146.492742198171 },
    { lat: -38.2554306797409, lng: 146.49015512974 },
    { lat: -38.255356245169, lng: 146.489573713923 },
    { lat: -38.2557397990801, lng: 146.489173666105 },
    { lat: -38.2561611438339, lng: 146.488723974786 },
    { lat: -38.2569220811601, lng: 146.487911790086 },
    { lat: -38.2573616933299, lng: 146.487016060244 },
    { lat: -38.2577164320246, lng: 146.485211212537 },
    { lat: -38.2580588714338, lng: 146.483468735958 },
    { lat: -38.2600367191338, lng: 146.481662733794 },
    { lat: -38.2602322362906, lng: 146.481484193 },
    { lat: -38.2630670372195, lng: 146.478934964815 },
    { lat: -38.2644671398052, lng: 146.477675816487 },
    { lat: -38.266384195856, lng: 146.475951666471 },
    { lat: -38.266551169909, lng: 146.475804145441 },
    { lat: -38.2684154843391, lng: 146.474111432448 },
    { lat: -38.2687189758382, lng: 146.473400754843 },
    { lat: -38.2696078978492, lng: 146.471319169634 },
    { lat: -38.269931093036, lng: 146.470997926398 },
    { lat: -38.2711035662659, lng: 146.469832442704 },
    { lat: -38.2713259595607, lng: 146.470182924699 },
    { lat: -38.2713458669023, lng: 146.47034045544 },
    { lat: -38.2714653596175, lng: 146.471285433551 },
    { lat: -38.2718110377291, lng: 146.474019471452 },
    { lat: -38.2718334191244, lng: 146.474196505528 },
    { lat: -38.2724760187008, lng: 146.479269947719 },
    { lat: -38.2735447827465, lng: 146.47905285226 },
    { lat: -38.2746451497848, lng: 146.478829342713 },
    { lat: -38.2747273797925, lng: 146.478810114443 },
    { lat: -38.2748117082588, lng: 146.478790424636 },
    { lat: -38.2759057507972, lng: 146.47857998399 },
    { lat: -38.2769539287376, lng: 146.47837837546 },
    { lat: -38.2770445811611, lng: 146.478357453731 },
    { lat: -38.2771429855967, lng: 146.478334716645 },
    { lat: -38.2783288381731, lng: 146.478090714894 },
    { lat: -38.278746163136, lng: 146.479522596436 },
    { lat: -38.2788829789293, lng: 146.479495953541 },
    { lat: -38.2795962986702, lng: 146.479356980097 },
    { lat: -38.2801774266727, lng: 146.479243767599 },
    { lat: -38.2798292332242, lng: 146.47649297558 },
    { lat: -38.2790055743685, lng: 146.469986376718 },
    { lat: -38.2813374381684, lng: 146.46946359112 },
    { lat: -38.2839325911179, lng: 146.468995816282 },
    { lat: -38.2890010900688, lng: 146.467976709751 },
    { lat: -38.2925955606082, lng: 146.467253889354 },
    { lat: -38.2913796698837, lng: 146.457776069493 },
    { lat: -38.291230511796, lng: 146.457206049504 },
    { lat: -38.2909047073804, lng: 146.454272425754 },
    { lat: -38.2916439519141, lng: 146.454125091633 },
    { lat: -38.2938366457794, lng: 146.453644743624 },
    { lat: -38.2940083719332, lng: 146.453607993078 },
    { lat: -38.2938597317296, lng: 146.452411644286 },
    { lat: -38.2936970762499, lng: 146.451108210459 },
    { lat: -38.2930959296404, lng: 146.446292355277 },
    { lat: -38.2929972972542, lng: 146.445502335127 },
    { lat: -38.2924280677319, lng: 146.440943913382 },
    { lat: -38.2921836086892, lng: 146.438986726619 },
    { lat: -38.2905691686621, lng: 146.439314526774 },
    { lat: -38.2902297308661, lng: 146.437433274545 },
    { lat: -38.2893344170364, lng: 146.432472141759 },
    { lat: -38.2888963692196, lng: 146.430045288524 },
    { lat: -38.2891582043971, lng: 146.429776921721 },
    { lat: -38.2894625878946, lng: 146.429251814696 },
    { lat: -38.2902044259795, lng: 146.427971965054 },
    { lat: -38.2897504239923, lng: 146.424171981608 },
    { lat: -38.2902472581301, lng: 146.423306997036 },
    { lat: -38.2897294802546, lng: 146.422845370441 },
    { lat: -38.2897284094134, lng: 146.422844425401 },
    { lat: -38.2895928255939, lng: 146.422723600714 },
    { lat: -38.2904885466314, lng: 146.421361140918 },
    { lat: -38.2905361051184, lng: 146.408914784315 },
    { lat: -38.2902833568857, lng: 146.406879310593 },
    { lat: -38.2901188906618, lng: 146.405554922482 },
    { lat: -38.2897794686928, lng: 146.402822246644 },
    { lat: -38.290631237038, lng: 146.402650976681 },
    { lat: -38.2925103316079, lng: 146.402273137471 },
    { lat: -38.2918668726912, lng: 146.397177387899 },
    { lat: -38.2917514511287, lng: 146.396263507784 },
    { lat: -38.2919241117031, lng: 146.39622628343 },
    { lat: -38.2918952038926, lng: 146.396001586835 },
    { lat: -38.2915731814046, lng: 146.393501122001 },
    { lat: -38.2963036524073, lng: 146.392523684435 },
    { lat: -38.296303669288, lng: 146.392523783059 },
    { lat: -38.3026491486588, lng: 146.391212538544 },
    { lat: -38.3043111958275, lng: 146.390869063929 },
    { lat: -38.3045451495556, lng: 146.390820708415 },
    { lat: -38.3048811044075, lng: 146.390798124643 },
    { lat: -38.3050896085672, lng: 146.38941930853 },
    { lat: -38.305245218321, lng: 146.388705815167 },
    { lat: -38.307830128098, lng: 146.388177154801 },
    { lat: -38.3085479525909, lng: 146.388082159126 },
    { lat: -38.3121391606587, lng: 146.388430584066 },
    { lat: -38.3147697919289, lng: 146.387894191084 },
    { lat: -38.3172997913073, lng: 146.387378280146 },
    { lat: -38.3174428460195, lng: 146.387364530289 },
    { lat: -38.3192316197216, lng: 146.387012948543 },
    { lat: -38.3200815443634, lng: 146.386845891224 },
    { lat: -38.320201053791, lng: 146.387814957544 },
    { lat: -38.3203550821715, lng: 146.389064305086 },
    { lat: -38.3233048971219, lng: 146.391102879651 },
    { lat: -38.3234461918117, lng: 146.395962734481 },
    { lat: -38.3257971810107, lng: 146.395704136471 },
    { lat: -38.326622768963, lng: 146.395715586943 },
    { lat: -38.3269861867705, lng: 146.395934519706 },
    { lat: -38.3273239603081, lng: 146.39642022473 },
    { lat: -38.3275847999967, lng: 146.397372220555 },
    { lat: -38.3278439823451, lng: 146.399373724683 },
    { lat: -38.3280876771577, lng: 146.401239588525 },
    { lat: -38.330418181123, lng: 146.403535684835 },
    { lat: -38.3310945426041, lng: 146.403381971298 },
    { lat: -38.3314565657927, lng: 146.404836622322 },
    { lat: -38.3345896406132, lng: 146.40914718116 },
    { lat: -38.3347768283332, lng: 146.408379073276 },
    { lat: -38.3353894494193, lng: 146.408670836714 },
    { lat: -38.3370481392834, lng: 146.408420301459 },
    { lat: -38.3378717712625, lng: 146.408629003471 },
    { lat: -38.3391406260607, lng: 146.410633468408 },
    { lat: -38.3410403416858, lng: 146.408723956376 },
    { lat: -38.3427417793657, lng: 146.407649057702 },
    { lat: -38.343096882701, lng: 146.407442544017 },
    { lat: -38.3430653231324, lng: 146.407373882405 },
    { lat: -38.3435215936009, lng: 146.406289535096 },
    { lat: -38.3437894287294, lng: 146.405812399386 },
    { lat: -38.3440990332072, lng: 146.405225460911 },
    { lat: -38.3442588424027, lng: 146.40502827455 },
    { lat: -38.3443752320645, lng: 146.404884670024 },
    { lat: -38.3448852492231, lng: 146.404255348702 },
    { lat: -38.3452631340036, lng: 146.403738139548 },
    { lat: -38.3454260695579, lng: 146.403538292202 },
    { lat: -38.346606414162, lng: 146.402090571021 },
    { lat: -38.3466068473161, lng: 146.402072255838 },
    { lat: -38.3468897701104, lng: 146.401652573113 },
    { lat: -38.3475149712166, lng: 146.400662496146 },
    { lat: -38.3538689066574, lng: 146.386444390664 },
    { lat: -38.3533790780448, lng: 146.382588802773 },
    { lat: -38.3533185242482, lng: 146.382112184248 },
    { lat: -38.3531211804138, lng: 146.380559270208 },
    { lat: -38.3528942639316, lng: 146.378773783369 },
    { lat: -38.3542535241521, lng: 146.378492689412 },
    { lat: -38.3539175098129, lng: 146.375881013663 },
    { lat: -38.3535790489002, lng: 146.373250815312 },
    { lat: -38.3587192711511, lng: 146.372185476306 },
    { lat: -38.357238078249, lng: 146.360562435955 },
    { lat: -38.3598927129482, lng: 146.360010962274 },
    { lat: -38.3600520031937, lng: 146.359977863248 },
    { lat: -38.3603352569228, lng: 146.359919023512 },
    { lat: -38.3622396555577, lng: 146.35952337581 },
    { lat: -38.3622457172432, lng: 146.359522264954 },
    { lat: -38.3626637466244, lng: 146.359445199481 },
    { lat: -38.3628283411972, lng: 146.35941164848 },
    { lat: -38.3640924444377, lng: 146.359148099644 },
    { lat: -38.36536515718, lng: 146.358910687339 },
    { lat: -38.3671018435662, lng: 146.358537852788 },
    { lat: -38.3666316919116, lng: 146.35496319444 },
    { lat: -38.366466416912, lng: 146.353706851336 },
    { lat: -38.3664955808831, lng: 146.352926224736 },
    { lat: -38.3669654267612, lng: 146.350752394418 },
    { lat: -38.3656661764174, lng: 146.348825887482 },
    { lat: -38.3656533330119, lng: 146.348806835397 },
    { lat: -38.3655920570541, lng: 146.348322758294 },
    { lat: -38.364585732598, lng: 146.34037466367 },
    { lat: -38.3645579719294, lng: 146.340143806823 },
    { lat: -38.3645398500906, lng: 146.339989906195 },
    { lat: -38.3644267490766, lng: 146.339107288064 },
    { lat: -38.3697619606906, lng: 146.338011347547 },
    { lat: -38.3695999340502, lng: 146.336803953751 },
    { lat: -38.369112101552, lng: 146.333169719125 },
    { lat: -38.3685982013668, lng: 146.333136070084 },
    { lat: -38.3660560885782, lng: 146.328909286501 },
    { lat: -38.3693618910036, lng: 146.327840779539 },
    { lat: -38.3693015844533, lng: 146.327389262536 },
    { lat: -38.368384501702, lng: 146.320463245062 },
    { lat: -38.368349540922, lng: 146.320199343772 },
    { lat: -38.3716189390838, lng: 146.307624342294 },
    { lat: -38.3735647424334, lng: 146.303541493604 },
    { lat: -38.3742921807798, lng: 146.301995157982 },
    { lat: -38.3742628221463, lng: 146.301766269558 },
    { lat: -38.374169959107, lng: 146.30104270145 },
    { lat: -38.3738778389523, lng: 146.298766977257 },
    { lat: -38.3737992989206, lng: 146.298137347825 },
    { lat: -38.3736236808548, lng: 146.296771060529 },
    { lat: -38.3728282506981, lng: 146.290592492555 },
    { lat: -38.3726394998865, lng: 146.291071993329 },
    { lat: -38.3723663438187, lng: 146.291587057215 },
    { lat: -38.3719224228253, lng: 146.291888445991 },
    { lat: -38.3715309558063, lng: 146.29205897867 },
    { lat: -38.3711106993416, lng: 146.292251027836 },
    { lat: -38.3707270172475, lng: 146.29210051451 },
    { lat: -38.3702344081804, lng: 146.291948527706 },
    { lat: -38.3697989533057, lng: 146.291819211675 },
    { lat: -38.3693743531618, lng: 146.291785023564 },
    { lat: -38.3689337894331, lng: 146.291568071517 },
    { lat: -38.368469544872, lng: 146.291460270892 },
    { lat: -38.3678941536086, lng: 146.291672406231 },
    { lat: -38.367366644064, lng: 146.291578433345 },
    { lat: -38.3668628664307, lng: 146.291367937182 },
    { lat: -38.3666930960957, lng: 146.290979087265 },
    { lat: -38.3662936807917, lng: 146.290609200675 },
    { lat: -38.3658806568603, lng: 146.29058245969 },
    { lat: -38.3659421370745, lng: 146.290560020046 },
    { lat: -38.3660023430837, lng: 146.290474824478 },
    { lat: -38.3660614519788, lng: 146.290388431362 },
    { lat: -38.3661194729105, lng: 146.290300827128 },
    { lat: -38.36617638107, lng: 146.290212057116 },
    { lat: -38.3662321580871, lng: 146.290122154193 },
    { lat: -38.3662868128567, lng: 146.290031128813 },
    { lat: -38.3663403027883, lng: 146.289939004272 },
    { lat: -38.3663926205764, lng: 146.2898457896 },
    { lat: -38.3664437566019, lng: 146.289751541843 },
    { lat: -38.3664937111065, lng: 146.289656238124 },
    { lat: -38.3665424499454, lng: 146.28955995452 },
    { lat: -38.3665899739928, lng: 146.289462693336 },
    { lat: -38.3666362560188, lng: 146.289364473559 },
    { lat: -38.3666813056602, lng: 146.289265320531 },
    { lat: -38.3667250981576, lng: 146.289165275019 },
    { lat: -38.3667676315733, lng: 146.289064349581 },
    { lat: -38.3672588723317, lng: 146.287919965221 },
    { lat: -38.3674104359851, lng: 146.287557537274 },
    { lat: -38.3674068493373, lng: 146.2875541929 },
    { lat: -38.367403320723, lng: 146.287550728235 },
    { lat: -38.3674004828333, lng: 146.287547791479 },
    { lat: -38.3673976857546, lng: 146.287544744436 },
    { lat: -38.3673958070478, lng: 146.287542588495 },
    { lat: -38.3673939632852, lng: 146.287540365646 },
    { lat: -38.3673912085654, lng: 146.287536978354 },
    { lat: -38.3673884968957, lng: 146.287533524293 },
    { lat: -38.3673850459177, lng: 146.287529113553 },
    { lat: -38.3673815867238, lng: 146.287524712971 },
    { lat: -38.3673800478719, lng: 146.28752278828 },
    { lat: -38.367378501583, lng: 146.287520885202 },
    { lat: -38.3673753479416, lng: 146.287517145505 },
    { lat: -38.3673721688497, lng: 146.287513427111 },
    { lat: -38.3673681996935, lng: 146.287508950261 },
    { lat: -38.3673641956051, lng: 146.287504539175 },
    { lat: -38.3673597665855, lng: 146.287499764939 },
    { lat: -38.3673553142206, lng: 146.287495068109 },
    { lat: -38.3673504257632, lng: 146.28749004112 },
    { lat: -38.3673455220787, lng: 146.287485090531 },
    { lat: -38.3673400473114, lng: 146.287479712495 },
    { lat: -38.3673345404478, lng: 146.287474387686 },
    { lat: -38.3673289296292, lng: 146.287469096562 },
    { lat: -38.3673232602172, lng: 146.287463893682 },
    { lat: -38.3673172496958, lng: 146.287458492715 },
    { lat: -38.3673111976937, lng: 146.287453180284 },
    { lat: -38.3673047900618, lng: 146.287447679815 },
    { lat: -38.3672983301412, lng: 146.287442267698 },
    { lat: -38.3672917419475, lng: 146.287436888849 },
    { lat: -38.3672851116161, lng: 146.287431575641 },
    { lat: -38.3672786182087, lng: 146.287426516513 },
    { lat: -38.3672720824445, lng: 146.287421543619 },
    { lat: -38.3672653068464, lng: 146.287416505963 },
    { lat: -38.3672584904492, lng: 146.287411577452 },
    { lat: -38.3672522679058, lng: 146.287407222095 },
    { lat: -38.3672460117688, lng: 146.287402976007 },
    { lat: -38.3672413026832, lng: 146.287399884689 },
    { lat: -38.367236568914, lng: 146.287396827274 },
    { lat: -38.3672289268427, lng: 146.287392031042 },
    { lat: -38.3672212768594, lng: 146.28738730104 },
    { lat: -38.367212718261, lng: 146.287382032526 },
    { lat: -38.3672041835671, lng: 146.287376718654 },
    { lat: -38.3672010389864, lng: 146.28737475037 },
    { lat: -38.367197928096, lng: 146.287372748338 },
    { lat: -38.3671916289196, lng: 146.287368567881 },
    { lat: -38.3671853898091, lng: 146.28736424543 },
    { lat: -38.3671805028922, lng: 146.287360680791 },
    { lat: -38.3671756831371, lng: 146.287356984577 },
    { lat: -38.3671710083296, lng: 146.287353211903 },
    { lat: -38.3671664086804, lng: 146.28734931809 },
    { lat: -38.367160340936, lng: 146.287343960784 },
    { lat: -38.3671543312247, lng: 146.28733848319 },
    { lat: -38.367152851615, lng: 146.287337085864 },
    { lat: -38.3671513810121, lng: 146.287335688693 },
    { lat: -38.367142678059, lng: 146.287327394701 },
    { lat: -38.3671339914392, lng: 146.287319089549 },
    { lat: -38.3671280071416, lng: 146.287313424745 },
    { lat: -38.367121988581, lng: 146.287307847457 },
    { lat: -38.3671184266323, lng: 146.28730463626 },
    { lat: -38.367114823106, lng: 146.287301522751 },
    { lat: -38.3671140239963, lng: 146.287300852248 },
    { lat: -38.3671132166711, lng: 146.287300191901 },
    { lat: -38.3671050913249, lng: 146.287293658483 },
    { lat: -38.367096989883, lng: 146.287287079707 },
    { lat: -38.3670920086166, lng: 146.28728290016 },
    { lat: -38.3670871222506, lng: 146.287278522006 },
    { lat: -38.3670838062331, lng: 146.287275310459 },
    { lat: -38.3670806190005, lng: 146.287271932925 },
    { lat: -38.3670765478298, lng: 146.287267137406 },
    { lat: -38.3670727386104, lng: 146.287262021431 },
    { lat: -38.3670691526866, lng: 146.287256577471 },
    { lat: -38.3670658373676, lng: 146.287250846385 },
    { lat: -38.3670628632694, lng: 146.287245049075 },
    { lat: -38.3670601171514, lng: 146.28723907604 },
    { lat: -38.3670562671238, lng: 146.287229923734 },
    { lat: -38.3670526793016, lng: 146.287220596292 },
    { lat: -38.3670506230822, lng: 146.287214974938 },
    { lat: -38.3670486419836, lng: 146.287209320549 },
    { lat: -38.3670446208433, lng: 146.287197540488 },
    { lat: -38.3670406766365, lng: 146.287185726279 },
    { lat: -38.3670368271065, lng: 146.287174242086 },
    { lat: -38.367032916865, lng: 146.287162791178 },
    { lat: -38.3670311490123, lng: 146.287157752606 },
    { lat: -38.3670293132316, lng: 146.287152748339 },
    { lat: -38.367026737851, lng: 146.287146137484 },
    { lat: -38.3670240256283, lng: 146.28713960323 },
    { lat: -38.3670210769074, lng: 146.287132860102 },
    { lat: -38.3670179912226, lng: 146.287126205014 },
    { lat: -38.3670160530259, lng: 146.287122189876 },
    { lat: -38.3670140731547, lng: 146.287118196906 },
    { lat: -38.3670101118524, lng: 146.287110442071 },
    { lat: -38.3670061082182, lng: 146.287102686509 },
    { lat: -38.3670018997925, lng: 146.287094536111 },
    { lat: -38.3669977187516, lng: 146.287086351859 },
    { lat: -38.3669953378655, lng: 146.287081610556 },
    { lat: -38.3669930095713, lng: 146.287076836975 },
    { lat: -38.3672034607739, lng: 146.286593716578 },
    { lat: -38.3673224527999, lng: 146.286310015659 },
    { lat: -38.3674112770045, lng: 146.286125402499 },
    { lat: -38.3674888909522, lng: 146.285973735382 },
    { lat: -38.3675694882886, lng: 146.285824607814 },
    { lat: -38.367653042612, lng: 146.285678131468 },
    { lat: -38.3677394802595, lng: 146.285534372578 },
    { lat: -38.3678287630238, lng: 146.285393451771 },
    { lat: -38.3679208271498, lng: 146.285255435456 },
    { lat: -38.3680156020187, lng: 146.285120442561 },
    { lat: -38.3681130377143, lng: 146.284988508843 },
    { lat: -38.3664628298881, lng: 146.283859766022 },
    { lat: -38.3659861756663, lng: 146.283771644589 },
    { lat: -38.3659871962203, lng: 146.28376418705 },
    { lat: -38.3659880719842, lng: 146.283756706435 },
    { lat: -38.3659888883118, lng: 146.283747951314 },
    { lat: -38.3659895591793, lng: 146.283739151366 },
    { lat: -38.3659900443283, lng: 146.283731043907 },
    { lat: -38.3659904169778, lng: 146.283722926512 },
    { lat: -38.3659907662003, lng: 146.283712773194 },
    { lat: -38.3659910292854, lng: 146.283702587507 },
    { lat: -38.3659911737198, lng: 146.283693819733 },
    { lat: -38.3659912416935, lng: 146.283685041495 },
    { lat: -38.3659912593747, lng: 146.283674636496 },
    { lat: -38.3659912083732, lng: 146.283664252061 },
    { lat: -38.3659911228374, lng: 146.283655847634 },
    { lat: -38.3659910038425, lng: 146.283647455221 },
    { lat: -38.3659908172758, lng: 146.283637621109 },
    { lat: -38.3659905958128, lng: 146.28362776466 },
    { lat: -38.3659904426189, lng: 146.283621660049 },
    { lat: -38.3659902732126, lng: 146.283615555161 },
    { lat: -38.3659900006642, lng: 146.283606182976 },
    { lat: -38.3659897120122, lng: 146.283596800217 },
    { lat: -38.365989440245, lng: 146.283588627161 },
    { lat: -38.3659891341301, lng: 146.283580464958 },
    { lat: -38.3659889734417, lng: 146.283577187523 },
    { lat: -38.3659887773963, lng: 146.283573931222 },
    { lat: -38.3659883688373, lng: 146.283568969864 },
    { lat: -38.365987816804, lng: 146.283564031222 },
    { lat: -38.3659873490992, lng: 146.283560907571 },
    { lat: -38.365986736801, lng: 146.283557827212 },
    { lat: -38.3659858700942, lng: 146.283554527388 },
    { lat: -38.365984782421, lng: 146.283551337055 },
    { lat: -38.365983601442, lng: 146.283548619964 },
    { lat: -38.365982207129, lng: 146.283546057118 },
    { lat: -38.3659806513811, lng: 146.283543681445 },
    { lat: -38.3659789343197, lng: 146.283541481503 },
    { lat: -38.3659764606449, lng: 146.283538764013 },
    { lat: -38.3659738603093, lng: 146.283536267473 },
    { lat: -38.3659711900794, lng: 146.283533990565 },
    { lat: -38.3659684373802, lng: 146.283531844972 },
    { lat: -38.3659645178935, lng: 146.283529039792 },
    { lat: -38.3659605229, lng: 146.28352638893 },
    { lat: -38.3659565959486, lng: 146.283523947476 },
    { lat: -38.3659526524107, lng: 146.283521625879 },
    { lat: -38.3659472629375, lng: 146.283518634195 },
    { lat: -38.3659418229921, lng: 146.28351572975 },
    { lat: -38.3659366722292, lng: 146.283513078548 },
    { lat: -38.3659315044284, lng: 146.283510504859 },
    { lat: -38.3659245682373, lng: 146.283507172035 },
    { lat: -38.3659176061229, lng: 146.283503905131 },
    { lat: -38.3659112996817, lng: 146.283500990426 },
    { lat: -38.3659049674387, lng: 146.2834981302 },
    { lat: -38.3658963735606, lng: 146.28349430216 },
    { lat: -38.3658877628873, lng: 146.283490528754 },
    { lat: -38.3658725136812, lng: 146.283483895602 },
    { lat: -38.3658572569169, lng: 146.283477295504 },
    { lat: -38.3658549021704, lng: 146.283476273462 },
    { lat: -38.3658525475575, lng: 146.283475238837 },
    { lat: -38.365841676414, lng: 146.283470387799 },
    { lat: -38.3658308386295, lng: 146.283465449231 },
    { lat: -38.3658225093999, lng: 146.28346155479 },
    { lat: -38.365814212738, lng: 146.283457562507 },
    { lat: -38.3658035708154, lng: 146.28345224857 },
    { lat: -38.3657929876142, lng: 146.283446749136 },
    { lat: -38.3657841571864, lng: 146.283441952504 },
    { lat: -38.3657754017043, lng: 146.283436969508 },
    { lat: -38.3657652867037, lng: 146.283430897992 },
    { lat: -38.3657552990556, lng: 146.283424540322 },
    { lat: -38.3657469855675, lng: 146.283418908134 },
    { lat: -38.3657388013425, lng: 146.283412979527 },
    { lat: -38.3657305899165, lng: 146.283406643124 },
    { lat: -38.3657225324238, lng: 146.283399977544 },
    { lat: -38.3657144742016, lng: 146.283392871439 },
    { lat: -38.3657065940122, lng: 146.283385457165 },
    { lat: -38.3656995481835, lng: 146.283378449636 },
    { lat: -38.3656926713344, lng: 146.28337122303 },
    { lat: -38.3656857263145, lng: 146.283363544447 },
    { lat: -38.3656789523062, lng: 146.283355625082 },
    { lat: -38.3656730189818, lng: 146.283348365436 },
    { lat: -38.3656672143153, lng: 146.28334095124 },
    { lat: -38.365661322673, lng: 146.283333074448 },
    { lat: -38.365655568671, lng: 146.283325045548 },
    { lat: -38.3656506901701, lng: 146.283317960722 },
    { lat: -38.3656459039237, lng: 146.283310756193 },
    { lat: -38.3656409830751, lng: 146.283303077954 },
    { lat: -38.3656361633784, lng: 146.283295290461 },
    { lat: -38.3656315387022, lng: 146.283287546356 },
    { lat: -38.3656270431108, lng: 146.283279692331 },
    { lat: -38.3656237019701, lng: 146.283273575501 },
    { lat: -38.3656204808955, lng: 146.283267349741 },
    { lat: -38.3656170300034, lng: 146.283260200119 },
    { lat: -38.3656137574275, lng: 146.283252885355 },
    { lat: -38.3656111560829, lng: 146.283246494003 },
    { lat: -38.3656087499367, lng: 146.283239959537 },
    { lat: -38.3656064303727, lng: 146.283232821278 },
    { lat: -38.3656043815662, lng: 146.283225550353 },
    { lat: -38.3656026733273, lng: 146.283218444302 },
    { lat: -38.3656012616133, lng: 146.283211239207 },
    { lat: -38.3656000802171, lng: 146.283203637589 },
    { lat: -38.3655992139078, lng: 146.283195970427 },
    { lat: -38.3655986010719, lng: 146.283187677157 },
    { lat: -38.3655983038836, lng: 146.283179350389 },
    { lat: -38.3655982872576, lng: 146.283171155429 },
    { lat: -38.3655985162112, lng: 146.283162993279 },
    { lat: -38.3655991124162, lng: 146.283152906027 },
    { lat: -38.3655999612844, lng: 146.283142863147 },
    { lat: -38.3656009313163, lng: 146.283133711375 },
    { lat: -38.3656020687876, lng: 146.283124570479 },
    { lat: -38.3656036759268, lng: 146.283113360922 },
    { lat: -38.3656054610703, lng: 146.283102185304 },
    { lat: -38.3656071612468, lng: 146.283092394988 },
    { lat: -38.3656089631014, lng: 146.283082615566 },
    { lat: -38.3656113426434, lng: 146.283070350558 },
    { lat: -38.3656138344283, lng: 146.283058119509 },
    { lat: -38.3656158822874, lng: 146.283048417524 },
    { lat: -38.3656179896023, lng: 146.283038715413 },
    { lat: -38.3656210076666, lng: 146.283025107758 },
    { lat: -38.3656240931706, lng: 146.283011511555 },
    { lat: -38.3656272983801, lng: 146.282997585585 },
    { lat: -38.3656305697765, lng: 146.282983704226 },
    { lat: -38.3656329924309, lng: 146.282973572717 },
    { lat: -38.3656354834264, lng: 146.282963452676 },
    { lat: -38.3656387976481, lng: 146.282950374123 },
    { lat: -38.3656422321215, lng: 146.282937338819 },
    { lat: -38.3656451476073, lng: 146.282926690561 },
    { lat: -38.3656481824326, lng: 146.282916086679 },
    { lat: -38.3656518368365, lng: 146.282903888106 },
    { lat: -38.3656556788244, lng: 146.282891754528 },
    { lat: -38.3656590279258, lng: 146.282881755579 },
    { lat: -38.365662573254, lng: 146.282871856099 },
    { lat: -38.3656667116942, lng: 146.282860988488 },
    { lat: -38.3656711231515, lng: 146.282850284593 },
    { lat: -38.3656749741824, lng: 146.282841628355 },
    { lat: -38.365679053855, lng: 146.282833157956 },
    { lat: -38.3656835082676, lng: 146.282824688249 },
    { lat: -38.3656882597511, lng: 146.282816492511 },
    { lat: -38.3656930111936, lng: 146.282808979852 },
    { lat: -38.3656980181429, lng: 146.282801753036 },
    { lat: -38.3657030578735, lng: 146.282795087433 },
    { lat: -38.3657083203349, lng: 146.282788740293 },
    { lat: -38.365713963698, lng: 146.282782492351 },
    { lat: -38.3657198118876, lng: 146.282776552269 },
    { lat: -38.3657254640178, lng: 146.282771261018 },
    { lat: -38.3657312690754, lng: 146.282766244701 },
    { lat: -38.3657379496371, lng: 146.282760898967 },
    { lat: -38.3657447678148, lng: 146.282755827904 },
    { lat: -38.3657510322595, lng: 146.28275149452 },
    { lat: -38.3657573813238, lng: 146.2827473365 },
    { lat: -38.3657652610591, lng: 146.282742484981 },
    { lat: -38.3657732170652, lng: 146.282737831567 },
    { lat: -38.3657808326175, lng: 146.28273359682 },
    { lat: -38.365788490555, lng: 146.282729527562 },
    { lat: -38.3657967958037, lng: 146.282725325213 },
    { lat: -38.3658051428888, lng: 146.282721255161 },
    { lat: -38.3658135583693, lng: 146.28271736134 },
    { lat: -38.3658220319105, lng: 146.282713598949 },
    { lat: -38.3658305578657, lng: 146.282709990777 },
    { lat: -38.3658391263273, lng: 146.282706536653 },
    { lat: -38.3658478981007, lng: 146.282703193562 },
    { lat: -38.365856730297, lng: 146.282700013979 },
    { lat: -38.3658650425728, lng: 146.282697187062 },
    { lat: -38.3658733806699, lng: 146.282694558532 },
    { lat: -38.3658820602821, lng: 146.282692005642 },
    { lat: -38.3658907626494, lng: 146.282689685411 },
    { lat: -38.3658984313758, lng: 146.282687825762 },
    { lat: -38.3659061401036, lng: 146.282686186483 },
    { lat: -38.3659131183479, lng: 146.282684877959 },
    { lat: -38.3659201306409, lng: 146.282683756521 },
    { lat: -38.3659280359883, lng: 146.28268268927 },
    { lat: -38.3659359673997, lng: 146.282681797526 },
    { lat: -38.365940896264, lng: 146.2826813361 },
    { lat: -38.3659458272599, lng: 146.282680928488 },
    { lat: -38.3659543600855, lng: 146.282680323931 },
    { lat: -38.3659629030273, lng: 146.282679784767 },
    { lat: -38.3659714370716, lng: 146.282679235152 },
    { lat: -38.3659799705672, lng: 146.282678652346 },
    { lat: -38.3659836518354, lng: 146.282678354927 },
    { lat: -38.3659873244366, lng: 146.282678025323 },
    { lat: -38.3659937238092, lng: 146.282677276699 },
    { lat: -38.3660000910436, lng: 146.282676330723 },
    { lat: -38.3660048755928, lng: 146.282675461782 },
    { lat: -38.3660096362313, lng: 146.282674384188 },
    { lat: -38.3660151193901, lng: 146.282672877301 },
    { lat: -38.3660205498504, lng: 146.282671073165 },
    { lat: -38.3660254031469, lng: 146.282669158462 },
    { lat: -38.3660301812038, lng: 146.282666948413 },
    { lat: -38.36603516236, lng: 146.282664275009 },
    { lat: -38.3660400228154, lng: 146.282661260856 },
    { lat: -38.3660446482482, lng: 146.282657982949 },
    { lat: -38.3660491176233, lng: 146.282654385429 },
    { lat: -38.3660536488742, lng: 146.282650304973 },
    { lat: -38.3660579844257, lng: 146.282645905369 },
    { lat: -38.366062403879, lng: 146.282640899633 },
    { lat: -38.3660666029505, lng: 146.282635608653 },
    { lat: -38.3660707340672, lng: 146.282629845101 },
    { lat: -38.3660746273496, lng: 146.282623828043 },
    { lat: -38.3660788011823, lng: 146.282616755117 },
    { lat: -38.3660827188151, lng: 146.282609461553 },
    { lat: -38.3660863820035, lng: 146.282602036628 },
    { lat: -38.3660898334432, lng: 146.282594446747 },
    { lat: -38.3660938033195, lng: 146.282585030448 },
    { lat: -38.3660975429601, lng: 146.2825754935 },
    { lat: -38.3661008922918, lng: 146.282566319446 },
    { lat: -38.3661040713932, lng: 146.282557057809 },
    { lat: -38.3661074797943, lng: 146.28254647626 },
    { lat: -38.3661107259616, lng: 146.282535817561 },
    { lat: -38.3661136590716, lng: 146.282525542531 },
    { lat: -38.3661164640398, lng: 146.28251520352 },
    { lat: -38.3661192440239, lng: 146.28250424736 },
    { lat: -38.3661218786564, lng: 146.282493236078 },
    { lat: -38.3661242932651, lng: 146.282482500215 },
    { lat: -38.3661265616214, lng: 146.282471709215 },
    { lat: -38.3661286357674, lng: 146.28246113801 },
    { lat: -38.3661305741173, lng: 146.282450545029 },
    { lat: -38.3661319086799, lng: 146.282442691219 },
    { lat: -38.3661331669033, lng: 146.282434815508 },
    { lat: -38.3661350371294, lng: 146.282422406665 },
    { lat: -38.3661368380995, lng: 146.282409987483 },
    { lat: -38.3661389129117, lng: 146.282395445925 },
    { lat: -38.3661410199279, lng: 146.282380925513 },
    { lat: -38.3661417181093, lng: 146.282376250831 },
    { lat: -38.3661424398292, lng: 146.282371565111 },
    { lat: -38.3661442080832, lng: 146.28236112597 },
    { lat: -38.3661461369986, lng: 146.282350742211 },
    { lat: -38.3661477776806, lng: 146.282342733448 },
    { lat: -38.3661495879097, lng: 146.282334791661 },
    { lat: -38.3661520277389, lng: 146.282325243896 },
    { lat: -38.3661547482523, lng: 146.282315816497 },
    { lat: -38.3661574771725, lng: 146.282307380115 },
    { lat: -38.3661604937521, lng: 146.282299085958 },
    { lat: -38.3661638765713, lng: 146.282290659618 },
    { lat: -38.3661675321539, lng: 146.282282421016 },
    { lat: -38.3661720029188, lng: 146.282273170015 },
    { lat: -38.3661767292249, lng: 146.282264116755 },
    { lat: -38.3661813357458, lng: 146.282255910439 },
    { lat: -38.3661861297938, lng: 146.282247859507 },
    { lat: -38.3661917905834, lng: 146.282238861134 },
    { lat: -38.366197622227, lng: 146.28223006134 },
    { lat: -38.3662030959198, lng: 146.28222214103 },
    { lat: -38.3662087058014, lng: 146.282214375227 },
    { lat: -38.3662152079495, lng: 146.282205718483 },
    { lat: -38.366221821859, lng: 146.282197226125 },
    { lat: -38.3662278387062, lng: 146.282189735023 },
    { lat: -38.3662339508936, lng: 146.282182342807 },
    { lat: -38.366240963881, lng: 146.282174104419 },
    { lat: -38.3662480441785, lng: 146.282165974736 },
    { lat: -38.3662546566743, lng: 146.282158549884 },
    { lat: -38.3662613458267, lng: 146.282151201859 },
    { lat: -38.3662681627771, lng: 146.282143865173 },
    { lat: -38.3662750397112, lng: 146.282136648508 },
    { lat: -38.3662817638336, lng: 146.28212974045 },
    { lat: -38.3662885383599, lng: 146.282122921356 },
    { lat: -38.3662952103394, lng: 146.282116343076 },
    { lat: -38.3663019426423, lng: 146.282109852787 },
    { lat: -38.366308571595, lng: 146.282103594144 },
    { lat: -38.3663152624419, lng: 146.282097445259 },
    { lat: -38.3663215783814, lng: 146.282091736196 },
    { lat: -38.3663279605973, lng: 146.282086148407 },
    { lat: -38.3663343023372, lng: 146.28208072469 },
    { lat: -38.3663407025873, lng: 146.282075390076 },
    { lat: -38.3663464570308, lng: 146.282070726356 },
    { lat: -38.3663522796735, lng: 146.282066172501 },
    { lat: -38.3663582471302, lng: 146.28206163943 },
    { lat: -38.3663642732942, lng: 146.28205726183 },
    { lat: -38.3663693556446, lng: 146.28205370907 },
    { lat: -38.3663744986371, lng: 146.282050299228 },
    { lat: -38.3663799389121, lng: 146.28204688875 },
    { lat: -38.3663854377853, lng: 146.282043644039 },
    { lat: -38.366390530337, lng: 146.282040828317 },
    { lat: -38.3663956892534, lng: 146.282038210532 },
    { lat: -38.3664009927079, lng: 146.282035724513 },
    { lat: -38.3664063393402, lng: 146.282033414296 },
    { lat: -38.3664117968339, lng: 146.282031268451 },
    { lat: -38.3664173132323, lng: 146.282029344444 },
    { lat: -38.3664228205694, lng: 146.282027595345 },
    { lat: -38.3664283627477, lng: 146.282026043643 },
    { lat: -38.3664345678086, lng: 146.282024504425 },
    { lat: -38.3664407978033, lng: 146.282023162436 },
    { lat: -38.3664470044351, lng: 146.282021984812 },
    { lat: -38.3664532174142, lng: 146.282020973205 },
    { lat: -38.3664602118613, lng: 146.282020004714 },
    { lat: -38.3664672161492, lng: 146.282019212599 },
    { lat: -38.3664741530383, lng: 146.282018574943 },
    { lat: -38.3664810796349, lng: 146.282018058395 },
    { lat: -38.3664884840912, lng: 146.282017672451 },
    { lat: -38.3664958870192, lng: 146.28201743065 },
    { lat: -38.3665032911329, lng: 146.282017331895 },
    { lat: -38.3665106938517, lng: 146.282017364699 },
    { lat: -38.3665182076504, lng: 146.282017541284 },
    { lat: -38.3665257300828, lng: 146.282017838158 },
    { lat: -38.3665333292604, lng: 146.282018288523 },
    { lat: -38.366540928711, lng: 146.282018883063 },
    { lat: -38.3665483070755, lng: 146.28201959854 },
    { lat: -38.3665556678328, lng: 146.282020445298 },
    { lat: -38.3665628496051, lng: 146.282021435453 },
    { lat: -38.3665700239082, lng: 146.282022535324 },
    { lat: -38.3665774186152, lng: 146.28202383348 },
    { lat: -38.3665848228566, lng: 146.28202525194 },
    { lat: -38.3665917330809, lng: 146.282026704286 },
    { lat: -38.3665986260498, lng: 146.282028254738 },
    { lat: -38.3666058771284, lng: 146.282030014984 },
    { lat: -38.3666131018237, lng: 146.282031884623 },
    { lat: -38.3666197494306, lng: 146.282033721507 },
    { lat: -38.366626388228, lng: 146.282035624604 },
    { lat: -38.3666335098917, lng: 146.282037780821 },
    { lat: -38.3666406162107, lng: 146.28204002488 },
    { lat: -38.3666469998933, lng: 146.282042114696 },
    { lat: -38.3666533656382, lng: 146.282044282012 },
    { lat: -38.3666604389931, lng: 146.282046746339 },
    { lat: -38.3666675016276, lng: 146.282049287145 },
    { lat: -38.3666743105759, lng: 146.282051795007 },
    { lat: -38.3666811105932, lng: 146.282054380522 },
    { lat: -38.3666874685715, lng: 146.282056855498 },
    { lat: -38.3666938178613, lng: 146.282059385247 },
    { lat: -38.3667004560182, lng: 146.282062113309 },
    { lat: -38.3667070782813, lng: 146.282064896021 },
    { lat: -38.3667133166973, lng: 146.282067602372 },
    { lat: -38.3667195391104, lng: 146.282070373668 },
    { lat: -38.3667259910432, lng: 146.282073333108 },
    { lat: -38.3667324160436, lng: 146.28207636875 },
    { lat: -38.3667385023518, lng: 146.28207931736 },
    { lat: -38.3667445462949, lng: 146.282082353349 },
    { lat: -38.3667508098625, lng: 146.282085576169 },
    { lat: -38.366757040851, lng: 146.282088897978 },
    { lat: -38.3667629402436, lng: 146.282092143174 },
    { lat: -38.3667688136045, lng: 146.282095464588 },
    { lat: -38.3667747455635, lng: 146.282098951769 },
    { lat: -38.3667806631874, lng: 146.282102516512 },
    { lat: -38.3667864506414, lng: 146.282106123653 },
    { lat: -38.3667921966067, lng: 146.282109820477 },
    { lat: -38.3667979172104, lng: 146.28211361527 },
    { lat: -38.3668036037856, lng: 146.282117475845 },
    { lat: -38.3668091376497, lng: 146.28212138072 },
    { lat: -38.366814653576, lng: 146.282125363095 },
    { lat: -38.3668201106825, lng: 146.282129455453 },
    { lat: -38.3668255426579, lng: 146.282133624043 },
    { lat: -38.3668308035904, lng: 146.282137781698 },
    { lat: -38.3668360229249, lng: 146.282142039333 },
    { lat: -38.3668412078912, lng: 146.282146394782 },
    { lat: -38.3668463513808, lng: 146.28215083877 },
    { lat: -38.3668513229269, lng: 146.282155271807 },
    { lat: -38.3668562271073, lng: 146.282159771201 },
    { lat: -38.3668608168499, lng: 146.282164105027 },
    { lat: -38.3668653572404, lng: 146.282168505518 },
    { lat: -38.3668702870785, lng: 146.282173388662 },
    { lat: -38.3668751834247, lng: 146.282178371924 },
    { lat: -38.3668793390792, lng: 146.282182694893 },
    { lat: -38.3668834697117, lng: 146.282187083799 },
    { lat: -38.3668881030125, lng: 146.28219206714 },
    { lat: -38.3668927006046, lng: 146.282197104792 },
    { lat: -38.3668962539639, lng: 146.282201053601 },
    { lat: -38.3668997819708, lng: 146.282205014563 },
    { lat: -38.3669042190971, lng: 146.282210063202 },
    { lat: -38.3669086639774, lng: 146.282215145156 },
    { lat: -38.3669100238872, lng: 146.282216707382 },
    { lat: -38.3669113838092, lng: 146.282218268463 },
    { lat: -38.3669171388192, lng: 146.282224934677 },
    { lat: -38.3669229020562, lng: 146.28223158959 },
    { lat: -38.3669264716808, lng: 146.282235703447 },
    { lat: -38.366930049751, lng: 146.282239785411 },
    { lat: -38.3669327699338, lng: 146.282242875544 },
    { lat: -38.3669354984411, lng: 146.282245945223 },
    { lat: -38.3669386089811, lng: 146.28224943221 },
    { lat: -38.3669417369857, lng: 146.282252886314 },
    { lat: -38.3669445591867, lng: 146.28225595531 },
    { lat: -38.3669473813877, lng: 146.282259024305 },
    { lat: -38.3669504072312, lng: 146.282262258118 },
    { lat: -38.3669534497599, lng: 146.282265447593 },
    { lat: -38.366956569834, lng: 146.282268715057 },
    { lat: -38.366959697708, lng: 146.282271926588 },
    { lat: -38.3669629373651, lng: 146.28227521555 },
    { lat: -38.3669661917846, lng: 146.282278471582 },
    { lat: -38.3669698975887, lng: 146.282282146109 },
    { lat: -38.3669736117413, lng: 146.282285797895 },
    { lat: -38.3669780995698, lng: 146.282290142578 },
    { lat: -38.3669826138694, lng: 146.282294454533 },
    { lat: -38.3669862100978, lng: 146.282297843423 },
    { lat: -38.3669898482422, lng: 146.282301187261 },
    { lat: -38.3669946069025, lng: 146.282305477082 },
    { lat: -38.3669994097292, lng: 146.282309679554 },
    { lat: -38.3670034141677, lng: 146.282313055978 },
    { lat: -38.3670074508333, lng: 146.28231636659 },
    { lat: -38.3670122709399, lng: 146.282320128838 },
    { lat: -38.3670171584455, lng: 146.282323736628 },
    { lat: -38.3670210598566, lng: 146.282326464813 },
    { lat: -38.367025020791, lng: 146.282329016663 },
    { lat: -38.3670295766721, lng: 146.282331701124 },
    { lat: -38.3670342255564, lng: 146.282334110278 },
    { lat: -38.3670376687885, lng: 146.282335616617 },
    { lat: -38.3670411788802, lng: 146.28233684949 },
    { lat: -38.3670448768127, lng: 146.282337783506 },
    { lat: -38.3670486079951, lng: 146.282338300454 },
    { lat: -38.3670517792486, lng: 146.282338355858 },
    { lat: -38.3670549411899, lng: 146.282338015207 },
    { lat: -38.3670579498087, lng: 146.282337266881 },
    { lat: -38.3670608824534, lng: 146.282336122504 },
    { lat: -38.3670639166143, lng: 146.282334494718 },
    { lat: -38.3670668242422, lng: 146.282332481456 },
    { lat: -38.3670694329626, lng: 146.282330282295 },
    { lat: -38.3670718894365, lng: 146.282327828802 },
    { lat: -38.367074898828, lng: 146.282324374432 },
    { lat: -38.367077762693, lng: 146.282320711613 },
    { lat: -38.3670802029809, lng: 146.282317236063 },
    { lat: -38.3670825229399, lng: 146.282313639456 },
    { lat: -38.367085667993, lng: 146.282308435619 },
    { lat: -38.3670887023818, lng: 146.282303133775 },
    { lat: -38.3670914572159, lng: 146.282298029672 },
    { lat: -38.3670941422463, lng: 146.282292882039 },
    { lat: -38.3670972786697, lng: 146.282286623091 },
    { lat: -38.3671003300991, lng: 146.28228030891 },
    { lat: -38.3671032798493, lng: 146.282273983835 },
    { lat: -38.3671061608177, lng: 146.282267603805 },
    { lat: -38.3671093058991, lng: 146.282260443364 },
    { lat: -38.3671123922394, lng: 146.282253215554 },
    { lat: -38.3671154180129, lng: 146.282245922631 },
    { lat: -38.3671183928965, lng: 146.282238586501 },
    { lat: -38.3671214364224, lng: 146.282230808735 },
    { lat: -38.367124419151, lng: 146.282222987592 },
    { lat: -38.3671273856004, lng: 146.282215002548 },
    { lat: -38.3671303011841, lng: 146.282206972009 },
    { lat: -38.3671331410109, lng: 146.282198864655 },
    { lat: -38.3671359199189, lng: 146.282190725365 },
    { lat: -38.3671385811113, lng: 146.282182728231 },
    { lat: -38.3671411562874, lng: 146.282174687289 },
    { lat: -38.3671438591435, lng: 146.282166074137 },
    { lat: -38.3671465030156, lng: 146.282157416495 },
    { lat: -38.3671489077296, lng: 146.282149309703 },
    { lat: -38.3671512625756, lng: 146.28214114828 },
    { lat: -38.3671537106453, lng: 146.282132435797 },
    { lat: -38.3671561157005, lng: 146.282123701982 },
    { lat: -38.3671583006119, lng: 146.282115594861 },
    { lat: -38.3671604434095, lng: 146.282107466423 },
    { lat: -38.3671626688532, lng: 146.282098765004 },
    { lat: -38.3671648618599, lng: 146.282090064174 },
    { lat: -38.3671668178953, lng: 146.282082132777 },
    { lat: -38.3671687390223, lng: 146.282074180187 },
    { lat: -38.3671707791738, lng: 146.282065622055 },
    { lat: -38.3671727764321, lng: 146.282057031152 },
    { lat: -38.3671748503044, lng: 146.282048011335 },
    { lat: -38.3671768559555, lng: 146.282038968611 },
    { lat: -38.3671784030282, lng: 146.282031884942 },
    { lat: -38.3671798902165, lng: 146.282024756768 },
    { lat: -38.3671816328517, lng: 146.282016221006 },
    { lat: -38.367183291272, lng: 146.282007641467 },
    { lat: -38.3671847181254, lng: 146.281999831454 },
    { lat: -38.3671860703318, lng: 146.281992009866 },
    { lat: -38.3671874979659, lng: 146.281983276487 },
    { lat: -38.3671888239327, lng: 146.281974531071 },
    { lat: -38.367190031183, lng: 146.281965852276 },
    { lat: -38.3671911529781, lng: 146.281957161721 },
    { lat: -38.3671922497956, lng: 146.281947768193 },
    { lat: -38.3671932266895, lng: 146.281938385199 },
    { lat: -38.3671941369991, lng: 146.281928485026 },
    { lat: -38.3671949437064, lng: 146.28191858537 },
    { lat: -38.3671955726244, lng: 146.281909905835 },
    { lat: -38.3671961089788, lng: 146.281901205266 },
    { lat: -38.367196830652, lng: 146.281888280001 },
    { lat: -38.3671974677578, lng: 146.281875344137 },
    { lat: -38.3671977071185, lng: 146.281870185545 },
    { lat: -38.3671979278074, lng: 146.28186500375 },
    { lat: -38.3671987091815, lng: 146.281847041539 },
    { lat: -38.3671995093701, lng: 146.281829088804 },
    { lat: -38.3672001630675, lng: 146.281815097114 },
    { lat: -38.36720087689, lng: 146.281801127048 },
    { lat: -38.3672014119639, lng: 146.281791611776 },
    { lat: -38.3672020081737, lng: 146.281782107848 },
    { lat: -38.3672029085651, lng: 146.281769403038 },
    { lat: -38.3672039366444, lng: 146.281756719864 },
    { lat: -38.3672048724215, lng: 146.281746710279 },
    { lat: -38.36720592621, lng: 146.281736700424 },
    { lat: -38.367207192649, lng: 146.281726260549 },
    { lat: -38.3672086200891, lng: 146.281715844023 },
    { lat: -38.3672102437799, lng: 146.281705184847 },
    { lat: -38.3672120121506, lng: 146.281694559038 },
    { lat: -38.3672137287642, lng: 146.281685076556 },
    { lat: -38.3672155738337, lng: 146.281675628309 },
    { lat: -38.3672177324088, lng: 146.281665243736 },
    { lat: -38.3672200023268, lng: 146.281654893104 },
    { lat: -38.3672219818941, lng: 146.281646268699 },
    { lat: -38.3672240313737, lng: 146.281637677528 },
    { lat: -38.3672266663754, lng: 146.281626963557 },
    { lat: -38.3672293858211, lng: 146.281616271625 },
    { lat: -38.3672309839775, lng: 146.281610145378 },
    { lat: -38.3672325911525, lng: 146.281604018141 },
    { lat: -38.3672361270459, lng: 146.281590663855 },
    { lat: -38.3672396971634, lng: 146.281577310153 },
    { lat: -38.3672434107967, lng: 146.281563504651 },
    { lat: -38.3672471344448, lng: 146.281549689021 },
    { lat: -38.367248809091, lng: 146.281543485129 },
    { lat: -38.3672505079457, lng: 146.281537291948 },
    { lat: -38.3672536110977, lng: 146.281526093963 },
    { lat: -38.3672567555703, lng: 146.281514906982 },
    { lat: -38.3672591362192, lng: 146.281506601798 },
    { lat: -38.3672615840784, lng: 146.281498329801 },
    { lat: -38.3672646101203, lng: 146.281488287294 },
    { lat: -38.3672677291522, lng: 146.281478310452 },
    { lat: -38.3672701864352, lng: 146.281470764046 },
    { lat: -38.3672727279203, lng: 146.281463262559 },
    { lat: -38.3672757277116, lng: 146.281454846671 },
    { lat: -38.3672788896026, lng: 146.281446520515 },
    { lat: -38.3672812703719, lng: 146.281440668523 },
    { lat: -38.3672837861239, lng: 146.281434914952 },
    { lat: -38.3672864043566, lng: 146.2814294366 },
    { lat: -38.3672891838891, lng: 146.281424123484 },
    { lat: -38.3672918271325, lng: 146.281419515161 },
    { lat: -38.3672946319183, lng: 146.281415049194 },
    { lat: -38.3672969699312, lng: 146.28141161648 },
    { lat: -38.3672994183542, lng: 146.281408305796 },
    { lat: -38.3673023071796, lng: 146.281404665099 },
    { lat: -38.3673052911158, lng: 146.281401145026 },
    { lat: -38.3673158896528, lng: 146.281389649532 },
    { lat: -38.3673190517155, lng: 146.281386151955 },
    { lat: -38.3673221211388, lng: 146.281382555536 },
    { lat: -38.3673243564295, lng: 146.281379805304 },
    { lat: -38.3673256737354, lng: 146.281378078323 },
    { lat: -38.3673268723051, lng: 146.281376504925 },
    { lat: -38.3673273744361, lng: 146.281375790366 },
    { lat: -38.3673307483665, lng: 146.281370916488 },
    { lat: -38.3673348539687, lng: 146.281364834242 },
    { lat: -38.3673408809485, lng: 146.281355616516 },
    { lat: -38.3673468221542, lng: 146.281346332102 },
    { lat: -38.3673665421637, lng: 146.281315235386 },
    { lat: -38.3673722958055, lng: 146.281306478674 },
    { lat: -38.3673781697643, lng: 146.281297844161 },
    { lat: -38.3673827005745, lng: 146.281291420191 },
    { lat: -38.3673873329215, lng: 146.281285105513 },
    { lat: -38.367393316776, lng: 146.281277406555 },
    { lat: -38.3673994802867, lng: 146.281269925779 },
    { lat: -38.3674050805844, lng: 146.281263568147 },
    { lat: -38.3674108520687, lng: 146.281257462879 },
    { lat: -38.3674171078801, lng: 146.281251302958 },
    { lat: -38.3674235433031, lng: 146.281245450468 },
    { lat: -38.3674308616606, lng: 146.281239246923 },
    { lat: -38.3674383422743, lng: 146.281233373396 },
    { lat: -38.3674455073871, lng: 146.281228114639 },
    { lat: -38.3674527997599, lng: 146.281223088044 },
    { lat: -38.3674611637075, lng: 146.281217675858 },
    { lat: -38.3674696220302, lng: 146.281212538753 },
    { lat: -38.3674775443694, lng: 146.281207995486 },
    { lat: -38.3674855330624, lng: 146.281203651301 },
    { lat: -38.3674947821888, lng: 146.281198866399 },
    { lat: -38.367504097572, lng: 146.281194289732 },
    { lat: -38.367512563235, lng: 146.281190330146 },
    { lat: -38.3675210644434, lng: 146.281186501607 },
    { lat: -38.3675311187515, lng: 146.281182157258 },
    { lat: -38.3675412165898, lng: 146.281177955535 },
    { lat: -38.3675511192114, lng: 146.281173983894 },
    { lat: -38.3675610465461, lng: 146.281170145404 },
    { lat: -38.3675706776556, lng: 146.281166547856 },
    { lat: -38.3675803513825, lng: 146.281163094062 },
    { lat: -38.3675906529102, lng: 146.281159562895 },
    { lat: -38.3676009808434, lng: 146.281156175206 },
    { lat: -38.3676107717605, lng: 146.281153138765 },
    { lat: -38.3676205899838, lng: 146.281150245816 },
    { lat: -38.3676310541097, lng: 146.281147352467 },
    { lat: -38.3676415342611, lng: 146.281144647042 },
    { lat: -38.367651267152, lng: 146.281142325887 },
    { lat: -38.3676610330721, lng: 146.281140203245 },
    { lat: -38.3676713856482, lng: 146.281138145554 },
    { lat: -38.367681772582, lng: 146.281136331024 },
    { lat: -38.3676912167733, lng: 146.281134900856 },
    { lat: -38.3677006944338, lng: 146.281133712689 },
    { lat: -38.3677096873501, lng: 146.28113278856 },
    { lat: -38.3677186968409, lng: 146.281132085549 },
    { lat: -38.367728829073, lng: 146.281131524161 },
    { lat: -38.3677389527822, lng: 146.28113117202 },
    { lat: -38.3677470023525, lng: 146.281131029287 },
    { lat: -38.3677550596567, lng: 146.281131006829 },
    { lat: -38.3677656758455, lng: 146.281131105919 },
    { lat: -38.3677763096756, lng: 146.281131325455 },
    { lat: -38.3677773470796, lng: 146.281131359207 },
    { lat: -38.367778367383, lng: 146.281131391524 },
    { lat: -38.3677959877218, lng: 146.281131985649 },
    { lat: -38.3678135991628, lng: 146.281132569325 },
    { lat: -38.3678248371894, lng: 146.281132898736 },
    { lat: -38.3678360823327, lng: 146.281133151606 },
    { lat: -38.367843851098, lng: 146.281133239782 },
    { lat: -38.3678516277717, lng: 146.281133261729 },
    { lat: -38.3678606730843, lng: 146.281133173776 },
    { lat: -38.3678696989541, lng: 146.281132965348 },
    { lat: -38.3678802388454, lng: 146.281132613455 },
    { lat: -38.3678907709057, lng: 146.281132150439 },
    { lat: -38.3678996366386, lng: 146.281131666948 },
    { lat: -38.3679085020527, lng: 146.281131128528 },
    { lat: -38.3679190341987, lng: 146.281130402342 },
    { lat: -38.3679295561183, lng: 146.281129621058 },
    { lat: -38.3679371223359, lng: 146.281129015804 },
    { lat: -38.3679446697484, lng: 146.281128399932 },
    { lat: -38.3679553968803, lng: 146.281127465396 },
    { lat: -38.3679661225744, lng: 146.281126496509 },
    { lat: -38.3679815587584, lng: 146.281125100108 },
    { lat: -38.3679970210738, lng: 146.281123703009 },
    { lat: -38.3679977686759, lng: 146.281123626539 },
    { lat: -38.3679985170696, lng: 146.281123560381 },
    { lat: -38.3680085730591, lng: 146.281122712775 },
    { lat: -38.3680186111442, lng: 146.281121854565 },
    { lat: -38.3680220201104, lng: 146.281121579868 },
    { lat: -38.3680254114147, lng: 146.281121271688 },
    { lat: -38.3680321943628, lng: 146.281120623294 },
    { lat: -38.3680389601216, lng: 146.281119896798 },
    { lat: -38.3680432274409, lng: 146.281119380468 },
    { lat: -38.3680475027898, lng: 146.281118786468 },
    { lat: -38.3680527467494, lng: 146.281117917248 },
    { lat: -38.3680579922241, lng: 146.281116905025 },
    { lat: -38.3680620213112, lng: 146.281116002451 },
    { lat: -38.3680660414447, lng: 146.281114924658 },
    { lat: -38.3680703934142, lng: 146.281113560759 },
    { lat: -38.3680747107834, lng: 146.281111976577 },
    { lat: -38.3680785276337, lng: 146.28111035981 },
    { lat: -38.3680901126656, lng: 146.281103869845 },
    { lat: -38.3680936826909, lng: 146.281101317458 },
    { lat: -38.3680971596692, lng: 146.281098534636 },
    { lat: -38.3681007133798, lng: 146.281095311454 },
    { lat: -38.3681041212432, lng: 146.281091824895 },
    { lat: -38.3681072829004, lng: 146.281088194532 },
    { lat: -38.3681102578165, lng: 146.281084334419 },
    { lat: -38.368113232698, lng: 146.281079967414 },
    { lat: -38.3681159701704, lng: 146.281075391533 },
    { lat: -38.3681185372794, lng: 146.281070485491 },
    { lat: -38.3681208830823, lng: 146.281065381148 },
    { lat: -38.3681230925823, lng: 146.281059792755 },
    { lat: -38.3681250227573, lng: 146.28105404054 },
    { lat: -38.3681268080188, lng: 146.281047681696 },
    { lat: -38.3681283025655, lng: 146.281041213758 },
    { lat: -38.3681295188649, lng: 146.281034735343 },
    { lat: -38.3681304800399, lng: 146.281028189634 },
    { lat: -38.3681313980159, lng: 146.281019939433 },
    { lat: -38.3681320685975, lng: 146.281011657542 },
    { lat: -38.3681324770082, lng: 146.281004143523 },
    { lat: -38.3681327236006, lng: 146.280996598132 },
    { lat: -38.3681328431098, lng: 146.280987358258 },
    { lat: -38.3681327825688, lng: 146.280978107297 },
    { lat: -38.3681326130507, lng: 146.280970044469 },
    { lat: -38.3681323327374, lng: 146.280961980891 },
    { lat: -38.3681318731096, lng: 146.280952136121 },
    { lat: -38.3681313213702, lng: 146.280942312661 },
    { lat: -38.3681308032733, lng: 146.28093441551 },
    { lat: -38.3681302157151, lng: 146.280926527469 },
    { lat: -38.3681294091856, lng: 146.280916264849 },
    { lat: -38.368128542298, lng: 146.280906002341 },
    { lat: -38.3681276663087, lng: 146.28089634383 },
    { lat: -38.368126747975, lng: 146.28088668574 },
    { lat: -38.3681259411269, lng: 146.280878578838 },
    { lat: -38.3681250900241, lng: 146.280870482622 },
    { lat: -38.3681240624113, lng: 146.280861275778 },
    { lat: -38.3681229743196, lng: 146.280852080487 },
    { lat: -38.3681219119697, lng: 146.280843686595 },
    { lat: -38.3681207809137, lng: 146.280835315558 },
    { lat: -38.3681194809843, lng: 146.280826218484 },
    { lat: -38.3681180862293, lng: 146.280817143819 },
    { lat: -38.3681166665976, lng: 146.280808376525 },
    { lat: -38.3681151709333, lng: 146.280799643404 },
    { lat: -38.3681134792013, lng: 146.28079037029 },
    { lat: -38.3681117033425, lng: 146.280781130066 },
    { lat: -38.3681098163572, lng: 146.280771835308 },
    { lat: -38.3681078443446, lng: 146.280762573425 },
    { lat: -38.3681060673516, lng: 146.280754630735 },
    { lat: -38.3681042397872, lng: 146.280746699768 },
    { lat: -38.3681014692303, lng: 146.280735094596 },
    { lat: -38.3680986380727, lng: 146.280723512418 },
    { lat: -38.3680963780796, lng: 146.280714393221 },
    { lat: -38.3680940999513, lng: 146.280705285158 },
    { lat: -38.3680913117644, lng: 146.280694153397 },
    { lat: -38.368088532046, lng: 146.280682987455 },
    { lat: -38.3680855821322, lng: 146.280670965014 },
    { lat: -38.3680827011529, lng: 146.280658897982 },
    { lat: -38.3680807122006, lng: 146.280650384139 },
    { lat: -38.3680787741577, lng: 146.280641826542 },
    { lat: -38.368076342857, lng: 146.280630594472 },
    { lat: -38.368074039693, lng: 146.280619341707 },
    { lat: -38.3680722627993, lng: 146.280610112916 },
    { lat: -38.3680706144061, lng: 146.280600829111 },
    { lat: -38.3680690418099, lng: 146.280591193035 },
    { lat: -38.3680676136958, lng: 146.280581523957 },
    { lat: -38.3680662108479, lng: 146.280571085248 },
    { lat: -38.3680649533827, lng: 146.280560613553 },
    { lat: -38.3680639588531, lng: 146.280551427882 },
    { lat: -38.3680630825531, lng: 146.280542221348 },
    { lat: -38.3680621480912, lng: 146.280531616721 },
    { lat: -38.3680613316285, lng: 146.280521012966 },
    { lat: -38.368060762666, lng: 146.280512642391 },
    { lat: -38.36806022704, lng: 146.280504271241 },
    { lat: -38.3680595727474, lng: 146.280492984867 },
    { lat: -38.3680589692427, lng: 146.280481666178 },
    { lat: -38.3680587907593, lng: 146.280477849426 },
    { lat: -38.3680586041696, lng: 146.280474032535 },
    { lat: -38.3680578473483, lng: 146.280458136615 },
    { lat: -38.3680570731577, lng: 146.280442264427 },
    { lat: -38.3680567673352, lng: 146.280436181078 },
    { lat: -38.368056470289, lng: 146.28043011962 },
    { lat: -38.3680557557367, lng: 146.280416271441 },
    { lat: -38.368055068106, lng: 146.280402432877 },
    { lat: -38.3680544888736, lng: 146.280390607712 },
    { lat: -38.3680539536638, lng: 146.280378793598 },
    { lat: -38.3680535969351, lng: 146.280370456401 },
    { lat: -38.3680532743344, lng: 146.280362128941 },
    { lat: -38.3680529000896, lng: 146.280351107092 },
    { lat: -38.3680525943068, lng: 146.280340085269 },
    { lat: -38.3680523810704, lng: 146.280330702414 },
    { lat: -38.3680522372091, lng: 146.280321318455 },
    { lat: -38.3680521596171, lng: 146.280310440126 },
    { lat: -38.3680521862961, lng: 146.28029958303 },
    { lat: -38.3680522957177, lng: 146.280289759438 },
    { lat: -38.3680525086068, lng: 146.280279947913 },
    { lat: -38.3680528408944, lng: 146.280269409555 },
    { lat: -38.3680533001879, lng: 146.280258872223 },
    { lat: -38.3680538692456, lng: 146.28024843288 },
    { lat: -38.3680545581039, lng: 146.28023799444 },
    { lat: -38.3680553908498, lng: 146.280227323891 },
    { lat: -38.3680563342683, lng: 146.280216665531 },
    { lat: -38.3680573961385, lng: 146.280206050386 },
    { lat: -38.3680585865979, lng: 146.280195456888 },
    { lat: -38.3680599810544, lng: 146.280184314215 },
    { lat: -38.3678081764152, lng: 146.278150271098 },
    { lat: -38.3678474383732, lng: 146.2780878895 },
    { lat: -38.3678823728571, lng: 146.27803239549 },
    { lat: -38.3675810570181, lng: 146.275584653067 },
    { lat: -38.3674997123918, lng: 146.275666900458 },
    { lat: -38.3668386327781, lng: 146.275723726173 },
    { lat: -38.3664885598671, lng: 146.275753822244 },
    { lat: -38.3658855361587, lng: 146.275926643086 },
    { lat: -38.3651308036233, lng: 146.276026269626 },
    { lat: -38.3641639800324, lng: 146.27635918466 },
    { lat: -38.3641228907939, lng: 146.276353410558 },
    { lat: -38.3635429355811, lng: 146.276271954759 },
    { lat: -38.3630669359838, lng: 146.275847432008 },
    { lat: -38.3616602710159, lng: 146.276128591776 },
    { lat: -38.3614689190406, lng: 146.276149899322 },
    { lat: -38.3613380533967, lng: 146.276174912727 },
    { lat: -38.3605314616263, lng: 146.275995305309 },
    { lat: -38.3601531188391, lng: 146.277149017845 },
    { lat: -38.3594995288315, lng: 146.277658461245 },
    { lat: -38.3591969033118, lng: 146.278363264078 },
    { lat: -38.3573752166513, lng: 146.279287462142 },
    { lat: -38.3559955732215, lng: 146.279551428461 },
    { lat: -38.3516525240852, lng: 146.280382325123 },
    { lat: -38.3519635479571, lng: 146.282909167875 },
    { lat: -38.3485267851015, lng: 146.285381308363 },
    { lat: -38.3446024199324, lng: 146.290620805695 },
    { lat: -38.3429419958584, lng: 146.291551526786 },
    { lat: -38.3395591406447, lng: 146.291396493116 },
    { lat: -38.3381419441568, lng: 146.293081693143 },
    { lat: -38.334874247351, lng: 146.296046654702 },
    { lat: -38.3349055687474, lng: 146.296304396267 },
    { lat: -38.3354899689407, lng: 146.300903748558 },
    { lat: -38.3344653881306, lng: 146.302773352932 },
    { lat: -38.3343255211543, lng: 146.303028575242 },
    { lat: -38.334327153402, lng: 146.303081474081 },
    { lat: -38.3343744380073, lng: 146.304617624566 },
    { lat: -38.3343373023467, lng: 146.30573389355 },
    { lat: -38.3346642786556, lng: 146.30817130549 },
    { lat: -38.3346852242638, lng: 146.308292163021 },
    { lat: -38.3347170130086, lng: 146.3085003385 },
    { lat: -38.3352600023112, lng: 146.310304062862 },
    { lat: -38.3351074096796, lng: 146.311496418593 },
    { lat: -38.338671025838, lng: 146.313482117427 },
    { lat: -38.3403936956705, lng: 146.31787465914 },
    { lat: -38.3426489252283, lng: 146.318428123672 },
    { lat: -38.3428177512212, lng: 146.319760938624 },
    { lat: -38.3390907221335, lng: 146.334124706515 },
    { lat: -38.3384958666986, lng: 146.336416434591 },
    { lat: -38.3305620692258, lng: 146.341304560425 },
    { lat: -38.3287289417279, lng: 146.339382408884 },
    { lat: -38.3269457024391, lng: 146.339797614646 },
    { lat: -38.3269115494032, lng: 146.339561422509 },
    { lat: -38.3267258829585, lng: 146.339603211865 },
    { lat: -38.3258211177791, lng: 146.339806788909 },
    { lat: -38.3231859979879, lng: 146.339644373456 },
    { lat: -38.3220076177551, lng: 146.339906130326 },
    { lat: -38.3173753391405, lng: 146.340387797805 },
    { lat: -38.3167278012008, lng: 146.340476028792 },
    { lat: -38.3142671272538, lng: 146.341183428565 },
    { lat: -38.3143365465577, lng: 146.340737883706 },
    { lat: -38.3132091828156, lng: 146.338986574516 },
    { lat: -38.3101792132621, lng: 146.336516105951 },
    { lat: -38.3101792130626, lng: 146.336516205419 },
    { lat: -38.3101749041818, lng: 146.336518405052 },
    { lat: -38.3101705006619, lng: 146.336520527537 },
    { lat: -38.3101660049183, lng: 146.336522354537 },
    { lat: -38.3101615077314, lng: 146.336523904821 },
    { lat: -38.3101569180076, lng: 146.336525269375 },
    { lat: -38.3101044562454, lng: 146.336536785642 },
    { lat: -38.3100519683931, lng: 146.336544145351 },
    { lat: -38.3099994556012, lng: 146.33654830323 },
    { lat: -38.3099469343468, lng: 146.336550294307 },
    { lat: -38.3098944043196, lng: 146.336551042407 },
    { lat: -38.3098008528941, lng: 146.336547653505 },
    { lat: -38.3097073696014, lng: 146.336538512517 },
    { lat: -38.3096138700309, lng: 146.336527666538 },
    { lat: -38.3095203787893, lng: 146.336518998799 },
    { lat: -38.3094269288844, lng: 146.336516447545 },
    { lat: -38.3093142445985, lng: 146.336523410285 },
    { lat: -38.3092021553299, lng: 146.336538964348 },
    { lat: -38.3090903721377, lng: 146.336561866316 },
    { lat: -38.3089788011827, lng: 146.336591070621 },
    { lat: -38.3088673582141, lng: 146.336625479278 },
    { lat: -38.3087752765998, lng: 146.336658764827 },
    { lat: -38.3086859330568, lng: 146.336698045542 },
    { lat: -38.308599071808, lng: 146.336744861484 },
    { lat: -38.3085145989623, lng: 146.336800532642 },
    { lat: -38.3084324291708, lng: 146.336866257964 },
    { lat: -38.3083716542359, lng: 146.336922941208 },
    { lat: -38.3083148754079, lng: 146.336983055817 },
    { lat: -38.3082621662045, lng: 146.337047284523 },
    { lat: -38.3082136221587, lng: 146.337116111514 },
    { lat: -38.3081695328561, lng: 146.337189987848 },
    { lat: -38.3081696273683, lng: 146.337190076426 },
    { lat: -38.3084335088353, lng: 146.337216817221 },
    { lat: -38.3085350841183, lng: 146.337254008335 },
    { lat: -38.3086707699283, lng: 146.337346144398 },
    { lat: -38.3091391449644, lng: 146.337666882202 },
    { lat: -38.3097357258975, lng: 146.338177380735 },
    { lat: -38.3109500874165, lng: 146.351468978089 },
    { lat: -38.3100721991148, lng: 146.364837805641 },
    { lat: -38.3100739071733, lng: 146.364837486855 },
    { lat: -38.3100723935729, lng: 146.364839412162 },
    { lat: -38.309286398653, lng: 146.364996447798 },
    { lat: -38.3094770711873, lng: 146.366549273659 },
    { lat: -38.3019426705557, lng: 146.368123231456 },
    { lat: -38.3017701289471, lng: 146.368148486969 },
    { lat: -38.301049457146, lng: 146.362409621641 },
    { lat: -38.3001644960753, lng: 146.355590854077 },
    { lat: -38.2999929834432, lng: 146.35277604239 },
    { lat: -38.2997877680539, lng: 146.353002356157 },
    { lat: -38.2996221202901, lng: 146.353185033413 },
    { lat: -38.298441376766, lng: 146.354487103444 },
    { lat: -38.2972177683702, lng: 146.355836374108 },
    { lat: -38.294311014212, lng: 146.358907398412 },
    { lat: -38.2923962531088, lng: 146.360623552436 },
    { lat: -38.2915688209531, lng: 146.361395015387 },
    { lat: -38.2888474694256, lng: 146.364506078445 },
    { lat: -38.2887765617325, lng: 146.364577986228 },
    { lat: -38.2867581013762, lng: 146.366670713985 },
    { lat: -38.2852023463207, lng: 146.368563769675 },
    { lat: -38.283123160992, lng: 146.37068989383 },
    { lat: -38.2824540334543, lng: 146.371522692595 },
    { lat: -38.2808523689276, lng: 146.373359802823 },
    { lat: -38.2809183381273, lng: 146.373725233443 },
    { lat: -38.280673427367, lng: 146.374139032454 },
    { lat: -38.2805779632133, lng: 146.374274749628 },
    { lat: -38.2797822605601, lng: 146.374969906299 },
    { lat: -38.2797219291981, lng: 146.374985922486 },
    { lat: -38.2789030465812, lng: 146.375202599984 },
    { lat: -38.277459288369, lng: 146.37485206257 },
    { lat: -38.2774473969068, lng: 146.374847838975 },
    { lat: -38.2770613609548, lng: 146.374732317365 },
    { lat: -38.2770958790567, lng: 146.374961369688 },
    { lat: -38.2772260989866, lng: 146.375964911401 },
    { lat: -38.2773131986689, lng: 146.376213049171 },
    { lat: -38.2776586221977, lng: 146.379000855804 },
    { lat: -38.2777421083487, lng: 146.379902360536 },
    { lat: -38.2777946473637, lng: 146.380469686301 },
    { lat: -38.2768752020929, lng: 146.382196091526 },
    { lat: -38.2765551425774, lng: 146.382977158453 },
    { lat: -38.2760147811063, lng: 146.385198244639 },
    { lat: -38.2758786016357, lng: 146.386368986008 },
    { lat: -38.2756343473658, lng: 146.388468511899 },
    { lat: -38.275651821834, lng: 146.389179947972 },
    { lat: -38.2757430192598, lng: 146.389694945592 },
    { lat: -38.2760576724094, lng: 146.391180012324 },
    { lat: -38.2760519087489, lng: 146.391287900515 },
    { lat: -38.2757507536834, lng: 146.392197435286 },
    { lat: -38.2745529336983, lng: 146.393930649639 },
    { lat: -38.2744354304224, lng: 146.394943145558 },
    { lat: -38.2744805573386, lng: 146.395240167342 },
    { lat: -38.2745650473449, lng: 146.395515442271 },
    { lat: -38.2746822793148, lng: 146.395769487529 },
    { lat: -38.2748308673227, lng: 146.39598870654 },
    { lat: -38.2775570208387, lng: 146.399270962631 },
    { lat: -38.2778844832517, lng: 146.399781626775 },
    { lat: -38.2781277790205, lng: 146.400363065287 },
    { lat: -38.2782818926164, lng: 146.400986324494 },
    { lat: -38.27836329685, lng: 146.40278436293 },
    { lat: -38.2783768117805, lng: 146.404509492499 },
    { lat: -38.2783961498167, lng: 146.407987253391 },
    { lat: -38.2784896073855, lng: 146.413396986457 },
    { lat: -38.2784864535917, lng: 146.414435882284 },
    { lat: -38.2782186779534, lng: 146.415440875122 },
    { lat: -38.278080901701, lng: 146.415981337889 },
    { lat: -38.2777787688655, lng: 146.417166444999 },
    { lat: -38.277463189904, lng: 146.41829694758 },
    { lat: -38.2769496616767, lng: 146.419283648184 },
    { lat: -38.2762702397431, lng: 146.420042582254 },
    { lat: -38.2751785770335, lng: 146.420687137434 },
    { lat: -38.2703985756381, lng: 146.423097766203 },
    { lat: -38.2699778604188, lng: 146.423276405703 },
    { lat: -38.269792781169, lng: 146.423355826248 },
    { lat: -38.2691220121824, lng: 146.423641738082 },
    { lat: -38.2682096468734, lng: 146.423777488868 },
    { lat: -38.2681325529822, lng: 146.42447439428 },
    { lat: -38.2644839778424, lng: 146.425237067733 },
    { lat: -38.2648642260425, lng: 146.428172957075 },
    { lat: -38.2652117739048, lng: 146.430902254518 },
    { lat: -38.2653993184293, lng: 146.432375364332 },
    { lat: -38.2655040131648, lng: 146.433197624885 },
    { lat: -38.2656644075995, lng: 146.434457619847 },
    { lat: -38.2656947358684, lng: 146.434691227348 },
    { lat: -38.2667882608861, lng: 146.43446903783 },
    { lat: -38.2669103457046, lng: 146.435473062528 },
    { lat: -38.2675237055813, lng: 146.440518091923 },
    { lat: -38.2653313184361, lng: 146.440950458573 },
    { lat: -38.2588996572781, lng: 146.44221872495 },
    { lat: -38.256915052179, lng: 146.44261003902 },
    { lat: -38.2578999803029, lng: 146.450542425317 },
    { lat: -38.2546510419069, lng: 146.451229319727 },
    { lat: -38.2508921642802, lng: 146.452023937452 },
    { lat: -38.2505301657433, lng: 146.452098583689 },
    { lat: -38.2503148035822, lng: 146.450440704665 },
    { lat: -38.25031465762, lng: 146.450439581092 },
    { lat: -38.2503113398765, lng: 146.450414059556 },
    { lat: -38.2501684098116, lng: 146.449314819811 },
    { lat: -38.2500689312556, lng: 146.448549763868 },
    { lat: -38.2489926269189, lng: 146.448770303333 },
    { lat: -38.2490926041885, lng: 146.449532944278 },
    { lat: -38.2492375282398, lng: 146.450638586659 },
    { lat: -38.2481574763932, lng: 146.450864405649 },
    { lat: -38.248393997261, lng: 146.452691296844 },
    { lat: -38.2484349236241, lng: 146.453007678709 },
    { lat: -38.2480821324477, lng: 146.453080059208 },
    { lat: -38.246838428765, lng: 146.453338449442 },
    { lat: -38.2455906116259, lng: 146.453597674543 },
    { lat: -38.2458116042861, lng: 146.455227643644 },
    { lat: -38.2445710030992, lng: 146.455486848134 },
    { lat: -38.2443383837714, lng: 146.455535445826 },
    { lat: -38.2433113879764, lng: 146.45575003347 },
    { lat: -38.2440827209826, lng: 146.46177696705 },
    { lat: -38.244000116753, lng: 146.461793962062 },
    { lat: -38.243909848229, lng: 146.461812530486 },
    { lat: -38.2428020594172, lng: 146.462039031248 },
    { lat: -38.2427782001226, lng: 146.461853384121 },
    { lat: -38.2315415996317, lng: 146.464222069398 },
    { lat: -38.2324981297451, lng: 146.471499097427 },
    { lat: -38.2323994618317, lng: 146.471519700335 },
    { lat: -38.2323054427963, lng: 146.47153933549 },
    { lat: -38.2289144960971, lng: 146.472241442821 },
    { lat: -38.2291071913308, lng: 146.473724144287 },
    { lat: -38.2256004312149, lng: 146.474445479462 },
    { lat: -38.2242882427012, lng: 146.476376067953 },
    { lat: -38.2250822535514, lng: 146.482543328254 },
    { lat: -38.219370406598, lng: 146.483721505488 },
    { lat: -38.2173884884473, lng: 146.486681769887 },
    { lat: -38.2175780712331, lng: 146.488162315573 },
    { lat: -38.2150250026134, lng: 146.497797093491 },
    { lat: -38.215053375894, lng: 146.498012924993 },
    { lat: -38.2150794877259, lng: 146.498211826935 },
    { lat: -38.2159013785587, lng: 146.500185656507 },
    { lat: -38.2165841844191, lng: 146.500861661171 }
  ],
  [
    { lng: 146.698807881564, lat: -38.1815570768019 },
    { lng: 146.698474142187, lat: -38.1816141639431 },
    { lng: 146.698254637441, lat: -38.1816090129845 },
    { lng: 146.698226026289, lat: -38.1816083410066 },
    { lng: 146.698156912612, lat: -38.1816034794264 },
    { lng: 146.697637862557, lat: -38.181566951353 },
    { lng: 146.69747775133, lat: -38.1816068476198 },
    { lng: 146.69720951594, lat: -38.1818118906441 },
    { lng: 146.696874874385, lat: -38.1820303920879 },
    { lng: 146.696711739058, lat: -38.1821996858944 },
    { lng: 146.696540596334, lat: -38.1826048946469 },
    { lng: 146.696446302431, lat: -38.1830208286101 },
    { lng: 146.696422693059, lat: -38.1831249736295 },
    { lng: 146.696440066364, lat: -38.1834624290794 },
    { lng: 146.69641208171, lat: -38.1836388796366 },
    { lng: 146.696378756018, lat: -38.1838489581322 },
    { lng: 146.696378754508, lat: -38.183848992391 },
    { lng: 146.696417615055, lat: -38.1841735388322 },
    { lng: 146.696409276672, lat: -38.184359247228 },
    { lng: 146.696383690748, lat: -38.1844853284059 },
    { lng: 146.696265595287, lat: -38.1845910672075 },
    { lng: 146.696069091103, lat: -38.1846920900201 },
    { lng: 146.695802989755, lat: -38.1847265656499 },
    { lng: 146.695640629605, lat: -38.1847287079697 },
    { lng: 146.695610707512, lat: -38.1847841087907 },
    { lng: 146.695570867602, lat: -38.1848578571165 },
    { lng: 146.695552673913, lat: -38.1848708961301 },
    { lng: 146.695478049681, lat: -38.184924309807 },
    { lng: 146.695268840974, lat: -38.1849065019228 },
    { lng: 146.694999791532, lat: -38.1848711680773 },
    { lng: 146.694896270483, lat: -38.1849126331641 },
    { lng: 146.694888214603, lat: -38.1850111460176 },
    { lng: 146.694888208528, lat: -38.1850111803405 },
    { lng: 146.69497541578, lat: -38.1851553913633 },
    { lng: 146.695051636604, lat: -38.1853283576995 },
    { lng: 146.695017017903, lat: -38.1854718626097 },
    { lng: 146.694885908837, lat: -38.185499360855 },
    { lng: 146.69486078502, lat: -38.1855046216113 },
    { lng: 146.694844164334, lat: -38.1855005682838 },
    { lng: 146.694664699527, lat: -38.1854567776042 },
    { lng: 146.694612955313, lat: -38.1854473568561 },
    { lng: 146.694344599209, lat: -38.1854578720841 },
    { lng: 146.694309046923, lat: -38.1854592572238 },
    { lng: 146.694266510653, lat: -38.1855492219903 },
    { lng: 146.694266498669, lat: -38.1855492473863 },
    { lng: 146.694403437929, lat: -38.1858445882814 },
    { lng: 146.694781596085, lat: -38.1864289631678 },
    { lng: 146.695228261648, lat: -38.1870259183911 },
    { lng: 146.695172395222, lat: -38.1875957410594 },
    { lng: 146.695166331701, lat: -38.1876086103908 },
    { lng: 146.695070791714, lat: -38.1877296952867 },
    { lng: 146.69487962424, lat: -38.1878396489169 },
    { lng: 146.694818369283, lat: -38.1878719307335 },
    { lng: 146.694813397529, lat: -38.1878745503917 },
    { lng: 146.694437405807, lat: -38.1880093421426 },
    { lng: 146.694433698434, lat: -38.1880108790546 },
    { lng: 146.694412007016, lat: -38.1880198612042 },
    { lng: 146.694306379787, lat: -38.1881282489994 },
    { lng: 146.694073977229, lat: -38.1882664156299 },
    { lng: 146.693935861034, lat: -38.1883547908039 },
    { lng: 146.693850676291, lat: -38.1884231980879 },
    { lng: 146.693832351393, lat: -38.1884379117755 },
    { lng: 146.69382083374, lat: -38.1884471608804 },
    { lng: 146.693818842491, lat: -38.1884475501412 },
    { lng: 146.693687514058, lat: -38.1884731111192 },
    { lng: 146.693438129129, lat: -38.1885837051046 },
    { lng: 146.693438100006, lat: -38.1885837298402 },
    { lng: 146.693565018033, lat: -38.1887750822625 },
    { lng: 146.693624761508, lat: -38.1891666994096 },
    { lng: 146.693302008332, lat: -38.1894639389937 },
    { lng: 146.693260042566, lat: -38.1894845516781 },
    { lng: 146.693205395109, lat: -38.1895113519589 },
    { lng: 146.692599075247, lat: -38.1898086913937 },
    { lng: 146.691984021455, lat: -38.190240127612 },
    { lng: 146.691554342101, lat: -38.1905528859853 },
    { lng: 146.691554319783, lat: -38.1905529088229 },
    { lng: 146.691462135522, lat: -38.1907225083659 },
    { lng: 146.691438168208, lat: -38.1907665981151 },
    { lng: 146.691361245367, lat: -38.1909819283518 },
    { lng: 146.69132077626, lat: -38.1912513794403 },
    { lng: 146.691277623021, lat: -38.1913278321552 },
    { lng: 146.690995407348, lat: -38.1914057672142 },
    { lng: 146.690857016364, lat: -38.191362387861 },
    { lng: 146.690854254706, lat: -38.1913615210869 },
    { lng: 146.690849745714, lat: -38.1913603202166 },
    { lng: 146.690595171699, lat: -38.1912924713442 },
    { lng: 146.69028379554, lat: -38.1910611567847 },
    { lng: 146.690189646338, lat: -38.19101590338 },
    { lng: 146.690090899109, lat: -38.1910119677162 },
    { lng: 146.690040926165, lat: -38.1910741700681 },
    { lng: 146.690040903907, lat: -38.1910741956076 },
    { lng: 146.690018100909, lat: -38.191102589805 },
    { lng: 146.689958601779, lat: -38.1911517784325 },
    { lng: 146.689859491457, lat: -38.1913848110824 },
    { lng: 146.689859484056, lat: -38.1913848373146 },
    { lng: 146.68984925554, lat: -38.1914453997342 },
    { lng: 146.689833498693, lat: -38.1915387210097 },
    { lng: 146.689804752028, lat: -38.1915976093527 },
    { lng: 146.689764704647, lat: -38.1916178298929 },
    { lng: 146.689670335636, lat: -38.1916149488567 },
    { lng: 146.689632232071, lat: -38.1916268818974 },
    { lng: 146.689592709184, lat: -38.1916392591719 },
    { lng: 146.689554627519, lat: -38.1916765834181 },
    { lng: 146.689546200998, lat: -38.1917351005496 },
    { lng: 146.689498622246, lat: -38.1917451183371 },
    { lng: 146.689454549217, lat: -38.1917576160627 },
    { lng: 146.689255405375, lat: -38.1918592991652 },
    { lng: 146.689279923574, lat: -38.1919630581723 },
    { lng: 146.689535003007, lat: -38.1921202398015 },
    { lng: 146.689665682948, lat: -38.1922897547191 },
    { lng: 146.68944800275, lat: -38.1924009914146 },
    { lng: 146.689410978216, lat: -38.1924199072018 },
    { lng: 146.689314650399, lat: -38.1924665290346 },
    { lng: 146.689242006883, lat: -38.1925016856278 },
    { lng: 146.689238537269, lat: -38.1925600125524 },
    { lng: 146.689231974872, lat: -38.1926701217785 },
    { lng: 146.68922249001, lat: -38.1927061101614 },
    { lng: 146.689197734543, lat: -38.1928000240121 },
    { lng: 146.689283279114, lat: -38.1928441328019 },
    { lng: 146.689285951839, lat: -38.1928455108141 },
    { lng: 146.689297657198, lat: -38.1928480579325 },
    { lng: 146.689549996519, lat: -38.1929029630793 },
    { lng: 146.689609703523, lat: -38.1930210105869 },
    { lng: 146.689497184901, lat: -38.1933170569572 },
    { lng: 146.689442834008, lat: -38.1933495607984 },
    { lng: 146.689221634842, lat: -38.1934818472836 },
    { lng: 146.688933292291, lat: -38.1934923533202 },
    { lng: 146.688820542511, lat: -38.1935617751862 },
    { lng: 146.688691698666, lat: -38.1936033106931 },
    { lng: 146.688565990793, lat: -38.1936438308978 },
    { lng: 146.688286470097, lat: -38.1938773943723 },
    { lng: 146.688202406514, lat: -38.1942072792644 },
    { lng: 146.688202400415, lat: -38.1942073126861 },
    { lng: 146.688174108563, lat: -38.1944356491809 },
    { lng: 146.688164171473, lat: -38.1945158630918 },
    { lng: 146.688157736638, lat: -38.1945238700876 },
    { lng: 146.688053258949, lat: -38.194653886297 },
    { lng: 146.687644542309, lat: -38.1948630591465 },
    { lng: 146.687602489592, lat: -38.1948845764429 },
    { lng: 146.687558509984, lat: -38.1949028528201 },
    { lng: 146.687183587591, lat: -38.1950586077128 },
    { lng: 146.686858769225, lat: -38.1951166429601 },
    { lng: 146.686743772226, lat: -38.1952101359959 },
    { lng: 146.686768183726, lat: -38.1953104694508 },
    { lng: 146.686849365756, lat: -38.1954728182323 },
    { lng: 146.686804109694, lat: -38.1956258017552 },
    { lng: 146.68685683352, lat: -38.195754287746 },
    { lng: 146.686945734554, lat: -38.1958798540223 },
    { lng: 146.686831099491, lat: -38.1959321104013 },
    { lng: 146.686580656198, lat: -38.1959213337458 },
    { lng: 146.686562450457, lat: -38.1959205476738 },
    { lng: 146.686539185441, lat: -38.1959724226378 },
    { lng: 146.686539174615, lat: -38.1959724489176 },
    { lng: 146.686635634514, lat: -38.1961093575862 },
    { lng: 146.686686532516, lat: -38.1963124740223 },
    { lng: 146.68669076626, lat: -38.1963293721929 },
    { lng: 146.68652200963, lat: -38.1965278719169 },
    { lng: 146.686432353845, lat: -38.1964835709299 },
    { lng: 146.686262822002, lat: -38.1962174017664 },
    { lng: 146.686236817688, lat: -38.1962110358704 },
    { lng: 146.686157694781, lat: -38.1961916716577 },
    { lng: 146.68568770555, lat: -38.1964626778447 },
    { lng: 146.685524208848, lat: -38.1965878906835 },
    { lng: 146.685505912532, lat: -38.1967736331338 },
    { lng: 146.685575402679, lat: -38.1969327357918 },
    { lng: 146.685568601331, lat: -38.1971686616965 },
    { lng: 146.685693550229, lat: -38.1973405922799 },
    { lng: 146.68586303798, lat: -38.1976056391836 },
    { lng: 146.685899843465, lat: -38.1979185660348 },
    { lng: 146.685900514929, lat: -38.1979243715787 },
    { lng: 146.685900504061, lat: -38.1979243960571 },
    { lng: 146.685873913166, lat: -38.1979706177032 },
    { lng: 146.685738286484, lat: -38.1982063509586 },
    { lng: 146.685425831769, lat: -38.1986276016242 },
    { lng: 146.685402170649, lat: -38.1986595025708 },
    { lng: 146.68538849805, lat: -38.198669505281 },
    { lng: 146.685274108924, lat: -38.1987531638562 },
    { lng: 146.685134694914, lat: -38.1988316669283 },
    { lng: 146.685121176372, lat: -38.1988392771543 },
    { lng: 146.685004685722, lat: -38.1989316304417 },
    { lng: 146.684854315878, lat: -38.1989732631487 },
    { lng: 146.684818751848, lat: -38.1989924858561 },
    { lng: 146.684645227839, lat: -38.1990862615529 },
    { lng: 146.684292238322, lat: -38.1993272880814 },
    { lng: 146.684291995658, lat: -38.1993312323546 },
    { lng: 146.684260639393, lat: -38.199839994704 },
    { lng: 146.684139249309, lat: -38.2002884680545 },
    { lng: 146.684139240945, lat: -38.2002885024085 },
    { lng: 146.68407891556, lat: -38.2006672368594 },
    { lng: 146.683845219936, lat: -38.2009556329124 },
    { lng: 146.683808391853, lat: -38.2010010833024 },
    { lng: 146.683662939552, lat: -38.2010291752363 },
    { lng: 146.683055310492, lat: -38.2011465261719 },
    { lng: 146.682313964511, lat: -38.2011318301099 },
    { lng: 146.681856837534, lat: -38.2013508316979 },
    { lng: 146.681408863086, lat: -38.2012854381077 },
    { lng: 146.68137401459, lat: -38.2012803503927 },
    { lng: 146.681357239634, lat: -38.2012823807576 },
    { lng: 146.681075155674, lat: -38.2013165255792 },
    { lng: 146.680694720359, lat: -38.2015188174162 },
    { lng: 146.68067579019, lat: -38.2015288820012 },
    { lng: 146.680711356378, lat: -38.2018345036201 },
    { lng: 146.680613430798, lat: -38.2021548474065 },
    { lng: 146.680249498797, lat: -38.2021005408928 },
    { lng: 146.680156194103, lat: -38.2023132873041 },
    { lng: 146.680151101838, lat: -38.2023203849529 },
    { lng: 146.680039792364, lat: -38.2024756203502 },
    { lng: 146.679804364503, lat: -38.2026698800035 },
    { lng: 146.679852912956, lat: -38.2030053062866 },
    { lng: 146.679812948134, lat: -38.2030754038225 },
    { lng: 146.679697090705, lat: -38.2032786043879 },
    { lng: 146.679697076471, lat: -38.2032786316155 },
    { lng: 146.679771568402, lat: -38.2036804697716 },
    { lng: 146.679854673178, lat: -38.2041212370965 },
    { lng: 146.679824721144, lat: -38.2041560958124 },
    { lng: 146.679588517176, lat: -38.2044310020814 },
    { lng: 146.679583874623, lat: -38.2044371311913 },
    { lng: 146.679519999521, lat: -38.204521535603 },
    { lng: 146.679456121053, lat: -38.2046059490317 },
    { lng: 146.679460026546, lat: -38.2046142953628 },
    { lng: 146.679568694756, lat: -38.2048464740609 },
    { lng: 146.679640822392, lat: -38.2050159642455 },
    { lng: 146.679542822532, lat: -38.2053717744444 },
    { lng: 146.679318577331, lat: -38.2056367531725 },
    { lng: 146.678921769147, lat: -38.2061056471137 },
    { lng: 146.678645345902, lat: -38.2065932409541 },
    { lng: 146.678614445316, lat: -38.2066477510555 },
    { lng: 146.678609550323, lat: -38.2068766479166 },
    { lng: 146.678744311072, lat: -38.2069375076224 },
    { lng: 146.679057315656, lat: -38.2070672860704 },
    { lng: 146.679446286945, lat: -38.2072368753124 },
    { lng: 146.679476086326, lat: -38.2072498696753 },
    { lng: 146.679956048641, lat: -38.2073196283609 },
    { lng: 146.680016945198, lat: -38.2073284820331 },
    { lng: 146.680213745906, lat: -38.2075404814248 },
    { lng: 146.680245096059, lat: -38.2077763218588 },
    { lng: 146.680353269844, lat: -38.2080397288176 },
    { lng: 146.680479395523, lat: -38.2080662229622 },
    { lng: 146.680688462413, lat: -38.2079887026319 },
    { lng: 146.680894536351, lat: -38.207942064002 },
    { lng: 146.680994217849, lat: -38.2081275931346 },
    { lng: 146.680994206958, lat: -38.2081276167117 },
    { lng: 146.680892247697, lat: -38.2083071672752 },
    { lng: 146.681045643337, lat: -38.2084664791738 },
    { lng: 146.681239429693, lat: -38.2084710613275 },
    { lng: 146.68125160734, lat: -38.2084713502169 },
    { lng: 146.681308366977, lat: -38.208387880357 },
    { lng: 146.681478218025, lat: -38.2083274624101 },
    { lng: 146.681676855655, lat: -38.2083643689882 },
    { lng: 146.68168685028, lat: -38.2084375969464 },
    { lng: 146.68168684646, lat: -38.2084376303357 },
    { lng: 146.681658914505, lat: -38.2086035512755 },
    { lng: 146.68164790989, lat: -38.2089754756243 },
    { lng: 146.681647903827, lat: -38.2089755108468 },
    { lng: 146.681739182065, lat: -38.2090179933974 },
    { lng: 146.681892951405, lat: -38.209061113829 },
    { lng: 146.681907339295, lat: -38.2090651523293 },
    { lng: 146.681916523429, lat: -38.2090778496624 },
    { lng: 146.681919490561, lat: -38.2090819527793 },
    { lng: 146.68199266591, lat: -38.2091831319303 },
    { lng: 146.681992643463, lat: -38.2091831493617 },
    { lng: 146.681885152533, lat: -38.2092608082189 },
    { lng: 146.681744429028, lat: -38.2092651773759 },
    { lng: 146.681665999251, lat: -38.2093062973405 },
    { lng: 146.681584532821, lat: -38.2094114620904 },
    { lng: 146.681462521457, lat: -38.2094811357908 },
    { lng: 146.681454776799, lat: -38.2096159772159 },
    { lng: 146.681452964365, lat: -38.2096476401842 },
    { lng: 146.681451950304, lat: -38.2096653872908 },
    { lng: 146.681523834689, lat: -38.209939035627 },
    { lng: 146.681684468438, lat: -38.210109800745 },
    { lng: 146.681934068978, lat: -38.2100632303611 },
    { lng: 146.681954804365, lat: -38.2100525620598 },
    { lng: 146.682131584731, lat: -38.2099616252967 },
    { lng: 146.682293857256, lat: -38.2100397015878 },
    { lng: 146.68229702531, lat: -38.2100452272333 },
    { lng: 146.682366093871, lat: -38.210165689277 },
    { lng: 146.682226742014, lat: -38.2102124081468 },
    { lng: 146.682078730331, lat: -38.2102362556372 },
    { lng: 146.681986912889, lat: -38.2104283899693 },
    { lng: 146.6818822483, lat: -38.2105301013822 },
    { lng: 146.681738674544, lat: -38.2105138689219 },
    { lng: 146.681648570502, lat: -38.2105847016999 },
    { lng: 146.681532000129, lat: -38.210814600789 },
    { lng: 146.681531985934, lat: -38.210814629818 },
    { lng: 146.681583664366, lat: -38.2110561921202 },
    { lng: 146.681528003968, lat: -38.2112941581772 },
    { lng: 146.681294100322, lat: -38.2114426441141 },
    { lng: 146.681289061855, lat: -38.2114417106446 },
    { lng: 146.681094020876, lat: -38.2114057028884 },
    { lng: 146.681066557282, lat: -38.2112801231966 },
    { lng: 146.681060977535, lat: -38.2112545979858 },
    { lng: 146.681055875409, lat: -38.2112526563035 },
    { lng: 146.680949404282, lat: -38.2112120733612 },
    { lng: 146.68078966688, lat: -38.2112896787933 },
    { lng: 146.680789640007, lat: -38.211289702593 },
    { lng: 146.680848754156, lat: -38.2114488828575 },
    { lng: 146.681050042552, lat: -38.2116002429481 },
    { lng: 146.681271571598, lat: -38.2117722322256 },
    { lng: 146.681184485346, lat: -38.2118449235804 },
    { lng: 146.681177114785, lat: -38.2118510691133 },
    { lng: 146.681161341205, lat: -38.2118571803749 },
    { lng: 146.680970964061, lat: -38.2119308711623 },
    { lng: 146.680969511975, lat: -38.21193436014 },
    { lng: 146.680966695573, lat: -38.2119411269458 },
    { lng: 146.680951593325, lat: -38.2119776429692 },
    { lng: 146.680934488085, lat: -38.2120189530436 },
    { lng: 146.680934479517, lat: -38.2120189783901 },
    { lng: 146.68091685549, lat: -38.2121139486236 },
    { lng: 146.680917322169, lat: -38.2121235753234 },
    { lng: 146.680932079309, lat: -38.2124298599382 },
    { lng: 146.680320512353, lat: -38.2128009700588 },
    { lng: 146.680215956489, lat: -38.2128189221095 },
    { lng: 146.67996351808, lat: -38.2128622631195 },
    { lng: 146.679947128221, lat: -38.212881116238 },
    { lng: 146.679844255836, lat: -38.2129994110315 },
    { lng: 146.679765573341, lat: -38.2131572468671 },
    { lng: 146.679421635661, lat: -38.2132105495758 },
    { lng: 146.679234624435, lat: -38.2131633495719 },
    { lng: 146.679201177766, lat: -38.2132010756896 },
    { lng: 146.67920116459, lat: -38.2132010992983 },
    { lng: 146.679173322288, lat: -38.2133189510318 },
    { lng: 146.679144997515, lat: -38.2133371322357 },
    { lng: 146.6790701679, lat: -38.2133851782656 },
    { lng: 146.678893085896, lat: -38.2134272826341 },
    { lng: 146.678873441448, lat: -38.2135046422455 },
    { lng: 146.678855036696, lat: -38.2135771301632 },
    { lng: 146.678799838773, lat: -38.2136034029452 },
    { lng: 146.678677904315, lat: -38.2136466893157 },
    { lng: 146.67867127251, lat: -38.2136626062556 },
    { lng: 146.67862975664, lat: -38.2137622177908 },
    { lng: 146.67849040913, lat: -38.2138043686475 },
    { lng: 146.678376856039, lat: -38.2138117812399 },
    { lng: 146.67836996988, lat: -38.2138122320165 },
    { lng: 146.678329258935, lat: -38.2138516617511 },
    { lng: 146.678288547827, lat: -38.2138910860669 },
    { lng: 146.67828853692, lat: -38.2138927953617 },
    { lng: 146.678288526536, lat: -38.2138945280744 },
    { lng: 146.678289177363, lat: -38.2138963128556 },
    { lng: 146.678304387634, lat: -38.2139380396979 },
    { lng: 146.678305565112, lat: -38.2139999290718 },
    { lng: 146.67830568585, lat: -38.2140067117078 },
    { lng: 146.678304674367, lat: -38.2140177113235 },
    { lng: 146.678298250885, lat: -38.2140868006768 },
    { lng: 146.678263313627, lat: -38.2141416702002 },
    { lng: 146.678205541444, lat: -38.2141926407919 },
    { lng: 146.678162256758, lat: -38.2142214126284 },
    { lng: 146.678151114559, lat: -38.2142288168231 },
    { lng: 146.678081274644, lat: -38.2142926426804 },
    { lng: 146.67799439705, lat: -38.2143749906638 },
    { lng: 146.677960439441, lat: -38.2144909989821 },
    { lng: 146.677960428789, lat: -38.2144910333672 },
    { lng: 146.677995629013, lat: -38.2145833087093 },
    { lng: 146.67795107859, lat: -38.2146777022764 },
    { lng: 146.677951067718, lat: -38.2146777267538 },
    { lng: 146.678031962088, lat: -38.2147328240956 },
    { lng: 146.67803998053, lat: -38.2148448879784 },
    { lng: 146.677954357395, lat: -38.2149169167492 },
    { lng: 146.677761328749, lat: -38.2149135510985 },
    { lng: 146.677663924334, lat: -38.214903308246 },
    { lng: 146.677554473758, lat: -38.2149435943788 },
    { lng: 146.677552771436, lat: -38.2150054192463 },
    { lng: 146.677506250218, lat: -38.2150735298677 },
    { lng: 146.677433209767, lat: -38.2150963263436 },
    { lng: 146.677429811022, lat: -38.2150973889906 },
    { lng: 146.677398494438, lat: -38.2151275383984 },
    { lng: 146.677396892564, lat: -38.2151325628588 },
    { lng: 146.677372555123, lat: -38.2152091134303 },
    { lng: 146.677372545391, lat: -38.2152091378918 },
    { lng: 146.677502267984, lat: -38.2155326307267 },
    { lng: 146.677621750574, lat: -38.2156864562435 },
    { lng: 146.677627315802, lat: -38.215693629873 },
    { lng: 146.677627503008, lat: -38.2156950066559 },
    { lng: 146.677644311062, lat: -38.2158181770795 },
    { lng: 146.677523233914, lat: -38.2158833159035 },
    { lng: 146.677517965245, lat: -38.2158861470275 },
    { lng: 146.677507988043, lat: -38.2158880002927 },
    { lng: 146.677325707177, lat: -38.2159216882516 },
    { lng: 146.677174111824, lat: -38.2160323121052 },
    { lng: 146.67706069499, lat: -38.216167386292 },
    { lng: 146.677058286556, lat: -38.2161702506171 },
    { lng: 146.677016749521, lat: -38.2162197209139 },
    { lng: 146.677006332677, lat: -38.2162263337661 },
    { lng: 146.676880635522, lat: -38.216306072381 },
    { lng: 146.676699553538, lat: -38.2163197573872 },
    { lng: 146.676586407581, lat: -38.2162490565722 },
    { lng: 146.676473525957, lat: -38.2161897780817 },
    { lng: 146.676331449521, lat: -38.2162046251917 },
    { lng: 146.676324387984, lat: -38.216205363892 },
    { lng: 146.676315191548, lat: -38.2162139067242 },
    { lng: 146.676223246423, lat: -38.2162993320336 },
    { lng: 146.676203835625, lat: -38.2164185783948 },
    { lng: 146.676203828377, lat: -38.2164186118314 },
    { lng: 146.676246112529, lat: -38.2165417518885 },
    { lng: 146.676250005698, lat: -38.2165531157928 },
    { lng: 146.676253404486, lat: -38.2165554282138 },
    { lng: 146.676370609567, lat: -38.2166351997594 },
    { lng: 146.676476357831, lat: -38.2166991372653 },
    { lng: 146.676469752716, lat: -38.2168056543043 },
    { lng: 146.676453187123, lat: -38.216816047417 },
    { lng: 146.676332165494, lat: -38.2168920787289 },
    { lng: 146.676143632558, lat: -38.2169687919222 },
    { lng: 146.676045197441, lat: -38.2170547270558 },
    { lng: 146.675965557094, lat: -38.2172090783414 },
    { lng: 146.675822567512, lat: -38.2172417433604 },
    { lng: 146.675672450616, lat: -38.2172813707776 },
    { lng: 146.675594020806, lat: -38.2174243023046 },
    { lng: 146.675590929473, lat: -38.2174269670559 },
    { lng: 146.675498452618, lat: -38.217506731111 },
    { lng: 146.675402995433, lat: -38.2175324611976 },
    { lng: 146.675395954795, lat: -38.2175343473859 },
    { lng: 146.675388838261, lat: -38.2175336506476 },
    { lng: 146.675292763953, lat: -38.2175241432968 },
    { lng: 146.675188538996, lat: -38.2174636361448 },
    { lng: 146.675096028584, lat: -38.2174075188662 },
    { lng: 146.675024408475, lat: -38.2173809227791 },
    { lng: 146.674969066528, lat: -38.2173713263069 },
    { lng: 146.674937734794, lat: -38.2174003192788 },
    { lng: 146.674944184937, lat: -38.2174334529806 },
    { lng: 146.674963237369, lat: -38.2175167777186 },
    { lng: 146.674941210006, lat: -38.2175765672918 },
    { lng: 146.674897270448, lat: -38.2176286036679 },
    { lng: 146.674845863247, lat: -38.2176704501126 },
    { lng: 146.674784900535, lat: -38.2176689105597 },
    { lng: 146.674738066582, lat: -38.2176488770848 },
    { lng: 146.674720994957, lat: -38.2175930169995 },
    { lng: 146.674714581556, lat: -38.2175621928649 },
    { lng: 146.67471310746, lat: -38.217561844829 },
    { lng: 146.674669955517, lat: -38.2175516997132 },
    { lng: 146.674663948613, lat: -38.2175503190119 },
    { lng: 146.674663564151, lat: -38.2175502342512 },
    { lng: 146.674657250362, lat: -38.2175504552273 },
    { lng: 146.674563530228, lat: -38.2175537191956 },
    { lng: 146.674455212755, lat: -38.2175790999438 },
    { lng: 146.674351460935, lat: -38.2176147243223 },
    { lng: 146.674256267091, lat: -38.2176445032984 },
    { lng: 146.674161325835, lat: -38.2176868967641 },
    { lng: 146.674114400174, lat: -38.21773441177 },
    { lng: 146.674050489527, lat: -38.2178038613507 },
    { lng: 146.674002606645, lat: -38.217862490427 },
    { lng: 146.673988141657, lat: -38.2178802041476 },
    { lng: 146.673984786503, lat: -38.2178813345623 },
    { lng: 146.673931942602, lat: -38.2178991798041 },
    { lng: 146.673861978148, lat: -38.2178828725534 },
    { lng: 146.673816152612, lat: -38.2178399311158 },
    { lng: 146.673787432557, lat: -38.2177819440447 },
    { lng: 146.673797599909, lat: -38.2177085208902 },
    { lng: 146.673785037503, lat: -38.2176605978335 },
    { lng: 146.673737363992, lat: -38.2176286044459 },
    { lng: 146.67373650602, lat: -38.2176280262129 },
    { lng: 146.673732337499, lat: -38.2176280173664 },
    { lng: 146.673661067571, lat: -38.2176277797734 },
    { lng: 146.673573078934, lat: -38.2176540480932 },
    { lng: 146.673512612374, lat: -38.2176776921071 },
    { lng: 146.673454554212, lat: -38.2176848311731 },
    { lng: 146.673450351763, lat: -38.2176853498542 },
    { lng: 146.673448240132, lat: -38.2176842709498 },
    { lng: 146.673369755158, lat: -38.2176439635546 },
    { lng: 146.673302280808, lat: -38.2176070081796 },
    { lng: 146.673239075504, lat: -38.217547199952 },
    { lng: 146.673234245714, lat: -38.2175426269404 },
    { lng: 146.673195371822, lat: -38.2174835768429 },
    { lng: 146.673195966215, lat: -38.2174754004156 },
    { lng: 146.673198806217, lat: -38.2174365914363 },
    { lng: 146.673239701802, lat: -38.2173777181019 },
    { lng: 146.673290454982, lat: -38.2173026951206 },
    { lng: 146.673290470463, lat: -38.2173026723817 },
    { lng: 146.673337238745, lat: -38.2172293759376 },
    { lng: 146.673338327902, lat: -38.2172276706144 },
    { lng: 146.673345026977, lat: -38.2171268610709 },
    { lng: 146.673312181524, lat: -38.2170791760076 },
    { lng: 146.673221298752, lat: -38.2170333913738 },
    { lng: 146.673155453222, lat: -38.2170001136054 },
    { lng: 146.673052921801, lat: -38.2169793742471 },
    { lng: 146.672923771569, lat: -38.2169624762179 },
    { lng: 146.672886821598, lat: -38.2169610613612 },
    { lng: 146.672836815669, lat: -38.2169602833996 },
    { lng: 146.672715254624, lat: -38.2169755057909 },
    { lng: 146.672634481472, lat: -38.2170005390891 },
    { lng: 146.672539529801, lat: -38.2170316999563 },
    { lng: 146.672507629496, lat: -38.2170421717445 },
    { lng: 146.672507027811, lat: -38.2170428450001 },
    { lng: 146.67246647884, lat: -38.2170884293996 },
    { lng: 146.67241276612, lat: -38.2171611982879 },
    { lng: 146.67230680769, lat: -38.2171590810762 },
    { lng: 146.672210442443, lat: -38.217128208835 },
    { lng: 146.672112741191, lat: -38.2171030833932 },
    { lng: 146.672064297566, lat: -38.2171168268997 },
    { lng: 146.672053583536, lat: -38.2171198620803 },
    { lng: 146.6720347513, lat: -38.217146042131 },
    { lng: 146.67202533004, lat: -38.2171591322264 },
    { lng: 146.672011959534, lat: -38.21719308943 },
    { lng: 146.671997703317, lat: -38.2172292743877 },
    { lng: 146.671939567747, lat: -38.2172986878681 },
    { lng: 146.671861402242, lat: -38.2173076607712 },
    { lng: 146.671783225182, lat: -38.2173166283778 },
    { lng: 146.671779275743, lat: -38.2173152766879 },
    { lng: 146.671676612501, lat: -38.2172801513197 },
    { lng: 146.671601010287, lat: -38.2171986492912 },
    { lng: 146.671542281498, lat: -38.2170889897259 },
    { lng: 146.671540730122, lat: -38.2170860920793 },
    { lng: 146.671469438736, lat: -38.2170022561975 },
    { lng: 146.671458604405, lat: -38.2169980906464 },
    { lng: 146.671353949446, lat: -38.2169578552725 },
    { lng: 146.67115443159, lat: -38.2169202482704 },
    { lng: 146.670953769715, lat: -38.2168975281761 },
    { lng: 146.670781993794, lat: -38.2169061299653 },
    { lng: 146.670771114679, lat: -38.2169066734362 },
    { lng: 146.670762457792, lat: -38.2169080853011 },
    { lng: 146.670642422285, lat: -38.2169276766982 },
    { lng: 146.670584560077, lat: -38.217010810195 },
    { lng: 146.670584544775, lat: -38.2170108410398 },
    { lng: 146.670565184029, lat: -38.2170928062463 },
    { lng: 146.670563115751, lat: -38.2171015283301 },
    { lng: 146.670658596096, lat: -38.2172342893618 },
    { lng: 146.670779981016, lat: -38.2173564340761 },
    { lng: 146.670905349191, lat: -38.2174749142237 },
    { lng: 146.670917481186, lat: -38.2174863822228 },
    { lng: 146.670918163686, lat: -38.2174906748961 },
    { lng: 146.670934031341, lat: -38.2175903293166 },
    { lng: 146.670880718827, lat: -38.2176825537554 },
    { lng: 146.670735420049, lat: -38.2177461496918 },
    { lng: 146.670674326348, lat: -38.2177377435961 },
    { lng: 146.670607170808, lat: -38.2177168078439 },
    { lng: 146.670547550633, lat: -38.2176766879162 },
    { lng: 146.670545405794, lat: -38.2176752436281 },
    { lng: 146.670544471077, lat: -38.2176750646634 },
    { lng: 146.670466755941, lat: -38.2176601556562 },
    { lng: 146.670416222158, lat: -38.2176722209915 },
    { lng: 146.67031824473, lat: -38.2177077979679 },
    { lng: 146.670194637625, lat: -38.2176932799225 },
    { lng: 146.670038382779, lat: -38.217641371168 },
    { lng: 146.669962295554, lat: -38.2175968479005 },
    { lng: 146.669950295515, lat: -38.2175898188546 },
    { lng: 146.66991603872, lat: -38.2175318841029 },
    { lng: 146.669905271475, lat: -38.2175136840016 },
    { lng: 146.66987677052, lat: -38.2174659216951 },
    { lng: 146.66980224593, lat: -38.2174426152279 },
    { lng: 146.669799374519, lat: -38.2174417233649 },
    { lng: 146.669795305077, lat: -38.2174399038669 },
    { lng: 146.669707183938, lat: -38.2174004725728 },
    { lng: 146.66959127256, lat: -38.2173355238267 },
    { lng: 146.669540542929, lat: -38.2173090841182 },
    { lng: 146.669443194755, lat: -38.2172583524247 },
    { lng: 146.669438124575, lat: -38.2172553863139 },
    { lng: 146.669349273496, lat: -38.2172033918451 },
    { lng: 146.669250375524, lat: -38.2171773049071 },
    { lng: 146.669245711626, lat: -38.2171760729376 },
    { lng: 146.669237802585, lat: -38.2171768121339 },
    { lng: 146.669147240299, lat: -38.2171852610361 },
    { lng: 146.669082405835, lat: -38.2171929791667 },
    { lng: 146.669071955577, lat: -38.2171942202074 },
    { lng: 146.669067786917, lat: -38.2171928678581 },
    { lng: 146.669031080132, lat: -38.2171809683918 },
    { lng: 146.668947465229, lat: -38.2171350773367 },
    { lng: 146.668859787961, lat: -38.2171029416377 },
    { lng: 146.668793008503, lat: -38.2170877289399 },
    { lng: 146.668788364444, lat: -38.2170866696642 },
    { lng: 146.668730604516, lat: -38.2171011118916 },
    { lng: 146.668716172241, lat: -38.2171047225627 },
    { lng: 146.668692126513, lat: -38.2171248259721 },
    { lng: 146.668663360592, lat: -38.2171488733253 },
    { lng: 146.668624136773, lat: -38.2172191516325 },
    { lng: 146.668624123614, lat: -38.2172191761406 },
    { lng: 146.668592831473, lat: -38.217294054103 },
    { lng: 146.668587909641, lat: -38.2173058329542 },
    { lng: 146.668577010799, lat: -38.2173319108407 },
    { lng: 146.668508612463, lat: -38.2173957298817 },
    { lng: 146.668429379499, lat: -38.2173906530039 },
    { lng: 146.668418544727, lat: -38.2173899577179 },
    { lng: 146.668414035833, lat: -38.2173887632156 },
    { lng: 146.668253754072, lat: -38.2173461662001 },
    { lng: 146.668156533626, lat: -38.2173473662247 },
    { lng: 146.668121148753, lat: -38.2173924569682 },
    { lng: 146.668117866477, lat: -38.2174462793334 },
    { lng: 146.668117863792, lat: -38.2174463127063 },
    { lng: 146.668107266521, lat: -38.2175723505881 },
    { lng: 146.668112437019, lat: -38.2177611697531 },
    { lng: 146.668105832487, lat: -38.2178676851981 },
    { lng: 146.66812904646, lat: -38.2179406561959 },
    { lng: 146.668199865122, lat: -38.2180004617815 },
    { lng: 146.668463402468, lat: -38.2181208477868 },
    { lng: 146.668603499495, lat: -38.2182359038093 },
    { lng: 146.668594589006, lat: -38.2183001299927 },
    { lng: 146.668588022158, lat: -38.218314664221 },
    { lng: 146.668560169919, lat: -38.2183763097909 },
    { lng: 146.668555590843, lat: -38.2183864540423 },
    { lng: 146.668554505533, lat: -38.2183888521105 },
    { lng: 146.668457561484, lat: -38.2184883963684 },
    { lng: 146.66834724856, lat: -38.2185595584119 },
    { lng: 146.668271365557, lat: -38.2186108473809 },
    { lng: 146.668207796403, lat: -38.2186253570355 },
    { lng: 146.668135460373, lat: -38.2185621505698 },
    { lng: 146.668118896637, lat: -38.2184947015839 },
    { lng: 146.668118091563, lat: -38.2184914133708 },
    { lng: 146.668139695319, lat: -38.218409881649 },
    { lng: 146.66816836123, lat: -38.2183167811598 },
    { lng: 146.668126802895, lat: -38.2182692071061 },
    { lng: 146.66806291483, lat: -38.2182677018937 },
    { lng: 146.667924402556, lat: -38.2183060293199 },
    { lng: 146.667816701827, lat: -38.2183634384412 },
    { lng: 146.667779939685, lat: -38.2184131367158 },
    { lng: 146.667765913895, lat: -38.2184910651665 },
    { lng: 146.667760325406, lat: -38.2185220987558 },
    { lng: 146.667760315871, lat: -38.2185221322235 },
    { lng: 146.667776761287, lat: -38.2186203671464 },
    { lng: 146.667786045007, lat: -38.2187232761671 },
    { lng: 146.667728938923, lat: -38.2187708770592 },
    { lng: 146.667641790528, lat: -38.2187971275749 },
    { lng: 146.667633749595, lat: -38.218799546988 },
    { lng: 146.667622331482, lat: -38.2188023680578 },
    { lng: 146.667516797965, lat: -38.2188284302059 },
    { lng: 146.667439797497, lat: -38.2188782393715 },
    { lng: 146.667419359807, lat: -38.2188914569954 },
    { lng: 146.667280749256, lat: -38.2189263496193 },
    { lng: 146.667233515532, lat: -38.2189589821245 },
    { lng: 146.667215622637, lat: -38.2190072500147 },
    { lng: 146.667215607274, lat: -38.219007278157 },
    { lng: 146.6672352862, lat: -38.2190493868369 },
    { lng: 146.667288867105, lat: -38.2190441596416 },
    { lng: 146.667402937069, lat: -38.2190152939519 },
    { lng: 146.667481201932, lat: -38.2190109004186 },
    { lng: 146.667508274157, lat: -38.2190597830639 },
    { lng: 146.667443076736, lat: -38.2191497302672 },
    { lng: 146.667440469422, lat: -38.219153327822 },
    { lng: 146.667356054533, lat: -38.2192138879135 },
    { lng: 146.667323449479, lat: -38.2192873357253 },
    { lng: 146.667311604018, lat: -38.2193140175746 },
    { lng: 146.667312512348, lat: -38.2193186657257 },
    { lng: 146.667327718784, lat: -38.2193962468955 },
    { lng: 146.667439842502, lat: -38.2194166216017 },
    { lng: 146.667555407841, lat: -38.2193900163642 },
    { lng: 146.667643342462, lat: -38.2194347269438 },
    { lng: 146.667689992918, lat: -38.2195200236232 },
    { lng: 146.667676324052, lat: -38.2195818449969 },
    { lng: 146.667674009834, lat: -38.2195923155862 },
    { lng: 146.667675550408, lat: -38.219595502637 },
    { lng: 146.667698209438, lat: -38.2196423992278 },
    { lng: 146.66772754745, lat: -38.2197324562196 },
    { lng: 146.667656542249, lat: -38.2198100008775 },
    { lng: 146.66756882351, lat: -38.2197767296066 },
    { lng: 146.66749357709, lat: -38.2197135783269 },
    { lng: 146.667490464009, lat: -38.2197118860865 },
    { lng: 146.667417324625, lat: -38.2196721307803 },
    { lng: 146.66738614, lat: -38.2197102879079 },
    { lng: 146.667368864001, lat: -38.2198086158057 },
    { lng: 146.667363969088, lat: -38.2198364881847 },
    { lng: 146.667343186264, lat: -38.2198974410731 },
    { lng: 146.667311835356, lat: -38.219989377377 },
    { lng: 146.667300505321, lat: -38.2199932936713 },
    { lng: 146.667239785292, lat: -38.220014281911 },
    { lng: 146.667231424824, lat: -38.2200052472141 },
    { lng: 146.667194178985, lat: -38.2199648453502 },
    { lng: 146.667183431594, lat: -38.2199531839766 },
    { lng: 146.667187304176, lat: -38.2198546692158 },
    { lng: 146.667176425281, lat: -38.21981820424 },
    { lng: 146.667151707603, lat: -38.2198150419041 },
    { lng: 146.667011204657, lat: -38.2199014622854 },
    { lng: 146.666905605236, lat: -38.21991763666 },
    { lng: 146.666842189651, lat: -38.2199390232261 },
    { lng: 146.666803667091, lat: -38.2199727073524 },
    { lng: 146.666799236876, lat: -38.2200425709431 },
    { lng: 146.666799235332, lat: -38.2200426043002 },
    { lng: 146.666854422535, lat: -38.2200912923904 },
    { lng: 146.666856809364, lat: -38.2200934001145 },
    { lng: 146.666858570184, lat: -38.2200941109786 },
    { lng: 146.666950441482, lat: -38.2201311400853 },
    { lng: 146.666967348403, lat: -38.2201801758715 },
    { lng: 146.666952584901, lat: -38.2201912238667 },
    { lng: 146.666878699339, lat: -38.2202464881789 },
    { lng: 146.666750819505, lat: -38.2203110421688 },
    { lng: 146.666663670737, lat: -38.2203063932865 },
    { lng: 146.666653220908, lat: -38.2203098360653 },
    { lng: 146.666548357765, lat: -38.2203443958777 },
    { lng: 146.666476021669, lat: -38.2204288469616 },
    { lng: 146.666420286383, lat: -38.2205212819376 },
    { lng: 146.666408569584, lat: -38.2205407127212 },
    { lng: 146.666329138904, lat: -38.2206332523533 },
    { lng: 146.666245049928, lat: -38.2206377399533 },
    { lng: 146.666094156684, lat: -38.2205640360981 },
    { lng: 146.665997885325, lat: -38.2204644760703 },
    { lng: 146.665916232193, lat: -38.2203704659066 },
    { lng: 146.665825361858, lat: -38.2203240800813 },
    { lng: 146.665734490374, lat: -38.2202776887979 },
    { lng: 146.665660889411, lat: -38.2202980458853 },
    { lng: 146.665622643218, lat: -38.2203442735607 },
    { lng: 146.665622619882, lat: -38.2203443027138 },
    { lng: 146.665616872628, lat: -38.2204210533788 },
    { lng: 146.665640538511, lat: -38.2205180769945 },
    { lng: 146.665606721439, lat: -38.22056885286 },
    { lng: 146.665589806516, lat: -38.2205942368223 },
    { lng: 146.665374998491, lat: -38.220664383237 },
    { lng: 146.665226762563, lat: -38.2207257225761 },
    { lng: 146.665185222885, lat: -38.2207536969282 },
    { lng: 146.665165839837, lat: -38.2208008687112 },
    { lng: 146.66516582335, lat: -38.2208008977696 },
    { lng: 146.665200142465, lat: -38.2208485573598 },
    { lng: 146.665228340808, lat: -38.2208814111464 },
    { lng: 146.66521636744, lat: -38.220910300984 },
    { lng: 146.665199262992, lat: -38.2209515598163 },
    { lng: 146.665116644411, lat: -38.2210452480827 },
    { lng: 146.66510842506, lat: -38.2210545720818 },
    { lng: 146.665096831451, lat: -38.2210769507888 },
    { lng: 146.665059497341, lat: -38.2211489981615 },
    { lng: 146.665036247537, lat: -38.2212214286881 },
    { lng: 146.665038414033, lat: -38.2212269919879 },
    { lng: 146.665066801371, lat: -38.2213000433893 },
    { lng: 146.665121954926, lat: -38.2213474812586 },
    { lng: 146.665125849405, lat: -38.2213508313642 },
    { lng: 146.665177857455, lat: -38.2213765191553 },
    { lng: 146.665229864538, lat: -38.2214022132444 },
    { lng: 146.665315632502, lat: -38.2214449687566 },
    { lng: 146.665344166116, lat: -38.221459196723 },
    { lng: 146.665363561272, lat: -38.2214860562137 },
    { lng: 146.665402344435, lat: -38.2215397599673 },
    { lng: 146.665435873564, lat: -38.2216217598756 },
    { lng: 146.665420550541, lat: -38.2216539997952 },
    { lng: 146.665418899754, lat: -38.2216544406277 },
    { lng: 146.665339743441, lat: -38.2216755679034 },
    { lng: 146.66522740127, lat: -38.2216448998142 },
    { lng: 146.665064849059, lat: -38.2215690428196 },
    { lng: 146.664899431735, lat: -38.2214943702673 },
    { lng: 146.664825009641, lat: -38.221473527975 },
    { lng: 146.66478183542, lat: -38.2214923979398 },
    { lng: 146.664773827089, lat: -38.2215279875538 },
    { lng: 146.664822534578, lat: -38.2215697483095 },
    { lng: 146.664913713898, lat: -38.2216315767609 },
    { lng: 146.664951698942, lat: -38.2216676318387 },
    { lng: 146.664956581328, lat: -38.221672270213 },
    { lng: 146.664945394531, lat: -38.2216910601428 },
    { lng: 146.664925704101, lat: -38.2217241278803 },
    { lng: 146.664841641032, lat: -38.2217596172475 },
    { lng: 146.664830707451, lat: -38.2217642278808 },
    { lng: 146.664809862569, lat: -38.2217669643058 },
    { lng: 146.664561527426, lat: -38.2217995537066 },
    { lng: 146.664329570537, lat: -38.221810399832 },
    { lng: 146.664249995478, lat: -38.2218216710948 },
    { lng: 146.664218736795, lat: -38.22185525865 },
    { lng: 146.664234463651, lat: -38.2219180516513 },
    { lng: 146.664306029356, lat: -38.2220360658699 },
    { lng: 146.66431093594, lat: -38.2220441672458 },
    { lng: 146.664342383918, lat: -38.2220935793843 },
    { lng: 146.664373822723, lat: -38.2221429880365 },
    { lng: 146.664467807043, lat: -38.2222002192356 },
    { lng: 146.664651187951, lat: -38.222302142005 },
    { lng: 146.664695343507, lat: -38.2223329269722 },
    { lng: 146.664701205179, lat: -38.2223370211767 },
    { lng: 146.664697778545, lat: -38.2223839880133 },
    { lng: 146.66455954708, lat: -38.222438336559 },
    { lng: 146.664441110145, lat: -38.2224661073238 },
    { lng: 146.664341284642, lat: -38.2224757488197 },
    { lng: 146.66433248544, lat: -38.2224765963653 },
    { lng: 146.664319991193, lat: -38.2224588223753 },
    { lng: 146.664286219439, lat: -38.2224107721145 },
    { lng: 146.664303752837, lat: -38.2223430191655 },
    { lng: 146.664316251512, lat: -38.2222885420017 },
    { lng: 146.664321138968, lat: -38.2222672316333 },
    { lng: 146.664280081535, lat: -38.2222460080881 },
    { lng: 146.664142603218, lat: -38.2222636882045 },
    { lng: 146.663867536884, lat: -38.2222968376079 },
    { lng: 146.663550802813, lat: -38.2223487522262 },
    { lng: 146.66346173644, lat: -38.222356312094 },
    { lng: 146.663424764966, lat: -38.2223594489842 },
    { lng: 146.663338524538, lat: -38.2223273282472 },
    { lng: 146.663304138516, lat: -38.2222750777777 },
    { lng: 146.66329862795, lat: -38.2222167585938 },
    { lng: 146.663301890299, lat: -38.2221606339578 },
    { lng: 146.66327630479, lat: -38.2221140373516 },
    { lng: 146.663206345056, lat: -38.2220977004477 },
    { lng: 146.663072764999, lat: -38.2220947673947 },
    { lng: 146.662954498559, lat: -38.2220561260273 },
    { lng: 146.662853238513, lat: -38.2219990065429 },
    { lng: 146.662829140145, lat: -38.2219793768551 },
    { lng: 146.662785411668, lat: -38.221943748046 },
    { lng: 146.662780299191, lat: -38.2219397506402 },
    { lng: 146.662717640708, lat: -38.221890751064 },
    { lng: 146.662662497765, lat: -38.2218884106861 },
    { lng: 146.662655182761, lat: -38.2218880988395 },
    { lng: 146.662596894417, lat: -38.2219039093682 },
    { lng: 146.662584458877, lat: -38.2219072827433 },
    { lng: 146.662574245934, lat: -38.2219342361824 },
    { lng: 146.662567897876, lat: -38.2219509815912 },
    { lng: 146.662564961374, lat: -38.2220231379923 },
    { lng: 146.662616792609, lat: -38.2220762970271 },
    { lng: 146.662725660201, lat: -38.2221528231733 },
    { lng: 146.662742426248, lat: -38.222193795969 },
    { lng: 146.662707851096, lat: -38.2222056588003 },
    { lng: 146.66262800225, lat: -38.2222199131465 },
    { lng: 146.662618200897, lat: -38.2222216636283 },
    { lng: 146.662469756369, lat: -38.2221994279295 },
    { lng: 146.662385705155, lat: -38.2222050375939 },
    { lng: 146.662375804472, lat: -38.2222079246402 },
    { lng: 146.662307803104, lat: -38.2222277357777 },
    { lng: 146.662242407449, lat: -38.2222228119314 },
    { lng: 146.662179587324, lat: -38.2222006612615 },
    { lng: 146.662138006497, lat: -38.222151943155 },
    { lng: 146.662097394732, lat: -38.2220792036745 },
    { lng: 146.662064698046, lat: -38.22203950886 },
    { lng: 146.661990090556, lat: -38.2220083675521 },
    { lng: 146.661912441558, lat: -38.2219704036378 },
    { lng: 146.661851336462, lat: -38.2219335146185 },
    { lng: 146.661849290013, lat: -38.2219322823534 },
    { lng: 146.661841040681, lat: -38.2219161218384 },
    { lng: 146.661832790092, lat: -38.2218999559342 },
    { lng: 146.661819112895, lat: -38.2218731297694 },
    { lng: 146.661780309212, lat: -38.2218186476567 },
    { lng: 146.6617088205, lat: -38.2217977522436 },
    { lng: 146.661638068487, lat: -38.2218157746352 },
    { lng: 146.661596971567, lat: -38.2218654972109 },
    { lng: 146.661573389722, lat: -38.2219197780246 },
    { lng: 146.661559462365, lat: -38.2219518396365 },
    { lng: 146.661540673506, lat: -38.2219913993467 },
    { lng: 146.661516275639, lat: -38.2220427668493 },
    { lng: 146.661471879632, lat: -38.2220719189253 },
    { lng: 146.661409488147, lat: -38.2220726879048 },
    { lng: 146.661362408442, lat: -38.2220389228798 },
    { lng: 146.661342476438, lat: -38.2219853637654 },
    { lng: 146.661347128968, lat: -38.2219257792701 },
    { lng: 146.66132481788, lat: -38.221878664172 },
    { lng: 146.661322884499, lat: -38.2218745923021 },
    { lng: 146.661318033787, lat: -38.2218738022699 },
    { lng: 146.661244290504, lat: -38.2218617769905 },
    { lng: 146.661116030153, lat: -38.2219056970921 },
    { lng: 146.660977858999, lat: -38.2219319684359 },
    { lng: 146.660974339117, lat: -38.2219326385538 },
    { lng: 146.660947157717, lat: -38.2219276231393 },
    { lng: 146.660933573317, lat: -38.2219251162439 },
    { lng: 146.660932554321, lat: -38.2219231418412 },
    { lng: 146.660912475193, lat: -38.2218841379661 },
    { lng: 146.660910385254, lat: -38.2217777177436 },
    { lng: 146.660929898886, lat: -38.2216641834443 },
    { lng: 146.660917117394, lat: -38.2216036370498 },
    { lng: 146.660852833493, lat: -38.2215803760331 },
    { lng: 146.660727092481, lat: -38.2216059489435 },
    { lng: 146.660650004203, lat: -38.2216710171529 },
    { lng: 146.660643580535, lat: -38.2216857561484 },
    { lng: 146.660613907914, lat: -38.2217538485962 },
    { lng: 146.660536307533, lat: -38.2217925669495 },
    { lng: 146.660332544252, lat: -38.2217607369174 },
    { lng: 146.660125347933, lat: -38.2217014157892 },
    { lng: 146.659979619452, lat: -38.2216700138055 },
    { lng: 146.659914272234, lat: -38.2217406186112 },
    { lng: 146.659932957789, lat: -38.2218068131106 },
    { lng: 146.660032079139, lat: -38.2219040528611 },
    { lng: 146.660155115289, lat: -38.2219753409677 },
    { lng: 146.660159767146, lat: -38.2219780366197 },
    { lng: 146.660163979819, lat: -38.2219801355687 },
    { lng: 146.660307760953, lat: -38.222051792198 },
    { lng: 146.660343621263, lat: -38.2221039817669 },
    { lng: 146.660336856101, lat: -38.2221164000507 },
    { lng: 146.660325415517, lat: -38.2221374030889 },
    { lng: 146.660185711491, lat: -38.2221906100182 },
    { lng: 146.660132090517, lat: -38.2222430162717 },
    { lng: 146.660121629995, lat: -38.2222532329723 },
    { lng: 146.660099239934, lat: -38.2222946790524 },
    { lng: 146.660035781835, lat: -38.22231493724 },
    { lng: 146.65992278351, lat: -38.222324327441 },
    { lng: 146.659806465182, lat: -38.2223108291562 },
    { lng: 146.65966223682, lat: -38.2222084470964 },
    { lng: 146.659593849847, lat: -38.222168207316 },
    { lng: 146.659553611881, lat: -38.2221445175837 },
    { lng: 146.659469527585, lat: -38.2221478083896 },
    { lng: 146.659356219022, lat: -38.2222156118495 },
    { lng: 146.659332698663, lat: -38.2223486788714 },
    { lng: 146.659419796947, lat: -38.222498722365 },
    { lng: 146.659485665266, lat: -38.2226020895989 },
    { lng: 146.659557330347, lat: -38.2227053770685 },
    { lng: 146.65955820981, lat: -38.2227104959637 },
    { lng: 146.659558453682, lat: -38.2227119188358 },
    { lng: 146.65957207489, lat: -38.2227910199435 },
    { lng: 146.659502953347, lat: -38.2228170462189 },
    { lng: 146.659364995566, lat: -38.2228130178215 },
    { lng: 146.659237010455, lat: -38.2227974107933 },
    { lng: 146.659185771495, lat: -38.2227739686177 },
    { lng: 146.659188742335, lat: -38.2227029597296 },
    { lng: 146.659178688453, lat: -38.2226786070667 },
    { lng: 146.659176543493, lat: -38.2226733803359 },
    { lng: 146.659104119329, lat: -38.2226799837642 },
    { lng: 146.659089412185, lat: -38.22268952087 },
    { lng: 146.659026755708, lat: -38.2227301166663 },
    { lng: 146.658914203934, lat: -38.2228356470443 },
    { lng: 146.658825500424, lat: -38.2229008313463 },
    { lng: 146.658739183125, lat: -38.2229500122908 },
    { lng: 146.658720736214, lat: -38.2229605098304 },
    { lng: 146.658706854417, lat: -38.2229678110612 },
    { lng: 146.658615894819, lat: -38.2230155637318 },
    { lng: 146.658512351832, lat: -38.2230626027377 },
    { lng: 146.658452511442, lat: -38.2231194507396 },
    { lng: 146.658444841666, lat: -38.2231721679315 },
    { lng: 146.658444834429, lat: -38.2231722022676 },
    { lng: 146.658485896895, lat: -38.2232689996371 },
    { lng: 146.658538927774, lat: -38.2233839625912 },
    { lng: 146.658576220472, lat: -38.223505426963 },
    { lng: 146.658582339687, lat: -38.2235253811846 },
    { lng: 146.658586046287, lat: -38.2235369869034 },
    { lng: 146.65860538944, lat: -38.2235976703106 },
    { lng: 146.658608409846, lat: -38.2236071132373 },
    { lng: 146.658607732984, lat: -38.2236070279398 },
    { lng: 146.658598547859, lat: -38.2236058721211 },
    { lng: 146.658609835275, lat: -38.2236441403637 },
    { lng: 146.658622021476, lat: -38.2236854568235 },
    { lng: 146.658611366345, lat: -38.2236840887629 },
    { lng: 146.656901171003, lat: -38.2234645094084 },
    { lng: 146.656626258891, lat: -38.2235443739276 },
    { lng: 146.649650179594, lat: -38.2226469020638 },
    { lng: 146.649418894192, lat: -38.222617637268 },
    { lng: 146.638040130539, lat: -38.2211270339676 },
    { lng: 146.638030263571, lat: -38.2211257416927 },
    { lng: 146.638166729715, lat: -38.2204521681441 },
    { lng: 146.630937980851, lat: -38.2195392507242 },
    { lng: 146.623918342565, lat: -38.2186555991657 },
    { lng: 146.619375793359, lat: -38.2180949397337 },
    { lng: 146.602160188184, lat: -38.2087663676003 },
    { lng: 146.599541671497, lat: -38.2084187428943 },
    { lng: 146.597362505052, lat: -38.2081293611821 },
    { lng: 146.597609377675, lat: -38.2062245882349 },
    { lng: 146.602352864342, lat: -38.2072184839738 },
    { lng: 146.609062017144, lat: -38.2032945522538 },
    { lng: 146.611621331782, lat: -38.2013378438377 },
    { lng: 146.613205244424, lat: -38.1993404893443 },
    { lng: 146.614304990593, lat: -38.1979536802382 },
    { lng: 146.615905359165, lat: -38.1904935868729 },
    { lng: 146.614544098501, lat: -38.1880888182339 },
    { lng: 146.615352455014, lat: -38.1859868357645 },
    { lng: 146.618614318488, lat: -38.1816771323037 },
    { lng: 146.634565780884, lat: -38.178994445732 },
    { lng: 146.646080008955, lat: -38.1770562588761 },
    { lng: 146.64630808342, lat: -38.1770178484233 },
    { lng: 146.658852912194, lat: -38.1749047138047 },
    { lng: 146.65907396751, lat: -38.1748676540473 },
    { lng: 146.666827648436, lat: -38.1735674596814 },
    { lng: 146.668915140432, lat: -38.1732173031267 },
    { lng: 146.670049052878, lat: -38.1733750291585 },
    { lng: 146.676884959208, lat: -38.1743255408698 },
    { lng: 146.677317127071, lat: -38.1766580765952 },
    { lng: 146.677397448657, lat: -38.1770915596229 },
    { lng: 146.677481928946, lat: -38.1781587005565 },
    { lng: 146.684217327771, lat: -38.1790545500499 },
    { lng: 146.690621362447, lat: -38.1799059437755 },
    { lng: 146.690891479051, lat: -38.1799411001508 },
    { lng: 146.699561371243, lat: -38.1810925779527 },
    { lng: 146.699539360375, lat: -38.1811255238471 },
    { lng: 146.699528661185, lat: -38.1811548297956 },
    { lng: 146.699415159326, lat: -38.1812719705743 },
    { lng: 146.699333891032, lat: -38.1813018573764 },
    { lng: 146.6991115812, lat: -38.1813836183439 },
    { lng: 146.698807881564, lat: -38.1815570768019 }
  ],
  [
    { lng: 146.750848210551, lat: -38.1571619333176 },
    { lng: 146.75022302623, lat: -38.1601259171822 },
    { lng: 146.751650484794, lat: -38.1599860918946 },
    { lng: 146.752141294536, lat: -38.1629023999074 },
    { lng: 146.752258267688, lat: -38.1634451081845 },
    { lng: 146.752386693245, lat: -38.1642735846885 },
    { lng: 146.753798124583, lat: -38.1641387932156 },
    { lng: 146.752693900826, lat: -38.1693457308326 },
    { lng: 146.751895378194, lat: -38.173058326765 },
    { lng: 146.751131186506, lat: -38.1767528859502 },
    { lng: 146.771814727737, lat: -38.1794761920674 },
    { lng: 146.771826376928, lat: -38.1794777229057 },
    { lng: 146.777389362894, lat: -38.1802094959211 },
    { lng: 146.782956121678, lat: -38.1809415083898 },
    { lng: 146.782970741546, lat: -38.1809434276198 },
    { lng: 146.78339361446, lat: -38.1789621379504 },
    { lng: 146.790090282263, lat: -38.1798413613414 },
    { lng: 146.797417239208, lat: -38.1808028898348 },
    { lng: 146.798756115156, lat: -38.18097854194 },
    { lng: 146.80487384223, lat: -38.1817809339084 },
    { lng: 146.802937324809, lat: -38.1909597521659 },
    { lng: 146.792810295913, lat: -38.1896273941745 },
    { lng: 146.791203789577, lat: -38.1894159651235 },
    { lng: 146.791159823109, lat: -38.1895972529336 },
    { lng: 146.790391780808, lat: -38.1931797566251 },
    { lng: 146.789427608475, lat: -38.1976765202688 },
    { lng: 146.780372926204, lat: -38.1964662731638 },
    { lng: 146.780361112021, lat: -38.1964646923999 },
    { lng: 146.779672996277, lat: -38.1963726794059 },
    { lng: 146.778901049271, lat: -38.199994511718 },
    { lng: 146.777652131076, lat: -38.2058530608264 },
    { lng: 146.776713181442, lat: -38.2102569788127 },
    { lng: 146.775930894781, lat: -38.2139257401573 },
    { lng: 146.775462592374, lat: -38.2161217407387 },
    { lng: 146.769361826949, lat: -38.2153197399167 },
    { lng: 146.76848346564, lat: -38.2193298880394 },
    { lng: 146.766723246304, lat: -38.221488896427 },
    { lng: 146.766173763415, lat: -38.2227375711747 },
    { lng: 146.764564044709, lat: -38.2232677081896 },
    { lng: 146.76243731548, lat: -38.2232599902274 },
    { lng: 146.762493768414, lat: -38.223620348335 },
    { lng: 146.761689096364, lat: -38.227481438379 },
    { lng: 146.757189303511, lat: -38.2269013391155 },
    { lng: 146.756398183793, lat: -38.2305563975678 },
    { lng: 146.747307805866, lat: -38.2293144719627 },
    { lng: 146.747078610361, lat: -38.2292831316346 },
    { lng: 146.747002050245, lat: -38.2296355772619 },
    { lng: 146.746017770533, lat: -38.2341635611664 },
    { lng: 146.745978158639, lat: -38.234345792054 },
    { lng: 146.745174663481, lat: -38.2380416937594 },
    { lng: 146.744936536106, lat: -38.2380163733491 },
    { lng: 146.736465271359, lat: -38.2369149683171 },
    { lng: 146.736342180959, lat: -38.237475262282 },
    { lng: 146.733317159174, lat: -38.2370613121441 },
    { lng: 146.732395666435, lat: -38.2369315601076 },
    { lng: 146.730674266273, lat: -38.2366985414431 },
    { lng: 146.705764974494, lat: -38.2445592820242 },
    { lng: 146.705151174945, lat: -38.2472917766894 },
    { lng: 146.704016470175, lat: -38.2523425793855 },
    { lng: 146.695660705125, lat: -38.2512751057751 },
    { lng: 146.695388619543, lat: -38.2512407323501 },
    { lng: 146.692278589826, lat: -38.250836471985 },
    { lng: 146.692244732112, lat: -38.2509960941444 },
    { lng: 146.689836645555, lat: -38.2506792733017 },
    { lng: 146.689357176885, lat: -38.2506212183338 },
    { lng: 146.686518571658, lat: -38.2641638767175 },
    { lng: 146.68829837211, lat: -38.2643761981094 },
    { lng: 146.69179484299, lat: -38.2662257562651 },
    { lng: 146.692276554577, lat: -38.2664629911983 },
    { lng: 146.691857245959, lat: -38.26863516638 },
    { lng: 146.691844881741, lat: -38.2691605085951 },
    { lng: 146.696018392399, lat: -38.2655715030455 },
    { lng: 146.696853764703, lat: -38.2648887483549 },
    { lng: 146.698243362235, lat: -38.2645027473852 },
    { lng: 146.702379548517, lat: -38.2639615093219 },
    { lng: 146.706585014019, lat: -38.2621371791074 },
    { lng: 146.706712778614, lat: -38.2621017683994 },
    { lng: 146.707084458033, lat: -38.2620069678876 },
    { lng: 146.708259070683, lat: -38.2616676556671 },
    { lng: 146.71048049948, lat: -38.2592264144423 },
    { lng: 146.712944389261, lat: -38.258394408627 },
    { lng: 146.716733195963, lat: -38.2538673511325 },
    { lng: 146.716863952934, lat: -38.2536968825862 },
    { lng: 146.720013770034, lat: -38.2499183532923 },
    { lng: 146.743755279216, lat: -38.2478881790735 },
    { lng: 146.743875586021, lat: -38.2474685342208 },
    { lng: 146.74358508696, lat: -38.2450997367486 },
    { lng: 146.755509076202, lat: -38.2465719968362 },
    { lng: 146.757370682871, lat: -38.2383807423309 },
    { lng: 146.76410326589, lat: -38.2392637899724 },
    { lng: 146.765637699625, lat: -38.2394649944173 },
    { lng: 146.765708045446, lat: -38.2392827280581 },
    { lng: 146.76601638568, lat: -38.237764848933 },
    { lng: 146.769304572333, lat: -38.2398920755586 },
    { lng: 146.769460453031, lat: -38.2401126679358 },
    { lng: 146.769695335879, lat: -38.2401507229991 },
    { lng: 146.770149866579, lat: -38.2402047912309 },
    { lng: 146.774269092274, lat: -38.240732939112 },
    { lng: 146.774097194679, lat: -38.2416287958001 },
    { lng: 146.77617735058, lat: -38.2417115524179 },
    { lng: 146.777335407742, lat: -38.2422582040183 },
    { lng: 146.777378109439, lat: -38.2422741666465 },
    { lng: 146.777469200473, lat: -38.2423082443166 },
    { lng: 146.777614852009, lat: -38.2424177912711 },
    { lng: 146.777690894712, lat: -38.2425458611356 },
    { lng: 146.777865706433, lat: -38.2426828301189 },
    { lng: 146.778179514514, lat: -38.2427232218091 },
    { lng: 146.7784873277, lat: -38.2427223886356 },
    { lng: 146.778783315602, lat: -38.2426712113046 },
    { lng: 146.779114393564, lat: -38.2426748998241 },
    { lng: 146.779451202792, lat: -38.2426602627045 },
    { lng: 146.779816534885, lat: -38.2425264303245 },
    { lng: 146.779583665499, lat: -38.2431928976413 },
    { lng: 146.779518402083, lat: -38.2434795859108 },
    { lng: 146.779307609376, lat: -38.2444056181232 },
    { lng: 146.778840834584, lat: -38.2464559966962 },
    { lng: 146.778817053279, lat: -38.2465604879064 },
    { lng: 146.77809260406, lat: -38.2531583491196 },
    { lng: 146.781665018561, lat: -38.253624973809 },
    { lng: 146.782530818339, lat: -38.2537380414261 },
    { lng: 146.784671154204, lat: -38.2540186089624 },
    { lng: 146.78463881405, lat: -38.2541960123624 },
    { lng: 146.784089792477, lat: -38.2571264211479 },
    { lng: 146.781065100697, lat: -38.2575191134253 },
    { lng: 146.779919121154, lat: -38.2640603728102 },
    { lng: 146.779452764592, lat: -38.263998671252 },
    { lng: 146.778103615549, lat: -38.26382032385 },
    { lng: 146.775473646995, lat: -38.2757488667278 },
    { lng: 146.783987107549, lat: -38.2817276309553 },
    { lng: 146.771368017499, lat: -38.2929153135809 },
    { lng: 146.771136765361, lat: -38.2929192067127 },
    { lng: 146.755224979378, lat: -38.2950358602006 },
    { lng: 146.753964664472, lat: -38.301360931383 },
    { lng: 146.753260939474, lat: -38.3012193380846 },
    { lng: 146.748887428526, lat: -38.3003728571384 },
    { lng: 146.748382120527, lat: -38.3004606539497 },
    { lng: 146.745617205166, lat: -38.2999561867312 },
    { lng: 146.745614025682, lat: -38.2999556939165 },
    { lng: 146.742641364122, lat: -38.2994863831212 },
    { lng: 146.741351966305, lat: -38.3037860489689 },
    { lng: 146.742576772242, lat: -38.306942422125 },
    { lng: 146.742657655192, lat: -38.3078753400809 },
    { lng: 146.742661010208, lat: -38.3079138364042 },
    { lng: 146.742510584566, lat: -38.3083123672977 },
    { lng: 146.742408846086, lat: -38.3086749258864 },
    { lng: 146.741927859648, lat: -38.3110035428003 },
    { lng: 146.740224080597, lat: -38.3126049005912 },
    { lng: 146.738142650248, lat: -38.3127041814316 },
    { lng: 146.737849588193, lat: -38.3133038899022 },
    { lng: 146.738998306696, lat: -38.3151412158804 },
    { lng: 146.738946804615, lat: -38.3152418821217 },
    { lng: 146.738769682917, lat: -38.3152132536808 },
    { lng: 146.738506727702, lat: -38.3151707713076 },
    { lng: 146.730583879235, lat: -38.3138903467205 },
    { lng: 146.730542727621, lat: -38.3140532423477 },
    { lng: 146.724837347056, lat: -38.3131857398345 },
    { lng: 146.723820386004, lat: -38.3168942475493 },
    { lng: 146.720647315253, lat: -38.3163725941978 },
    { lng: 146.720364394433, lat: -38.3163260737863 },
    { lng: 146.719596364245, lat: -38.3151873878646 },
    { lng: 146.719189595297, lat: -38.3141859176409 },
    { lng: 146.717838750642, lat: -38.3127297322868 },
    { lng: 146.717631202905, lat: -38.3109995055594 },
    { lng: 146.717530420876, lat: -38.3107241061374 },
    { lng: 146.717349998973, lat: -38.3102310470107 },
    { lng: 146.716993566105, lat: -38.3092569887628 },
    { lng: 146.716319013155, lat: -38.3088587470808 },
    { lng: 146.715644460407, lat: -38.3084604971364 },
    { lng: 146.715224083431, lat: -38.3082123138559 },
    { lng: 146.715659001816, lat: -38.3061072288982 },
    { lng: 146.715249560321, lat: -38.3052642589799 },
    { lng: 146.715498412614, lat: -38.3011065008795 },
    { lng: 146.71416909643, lat: -38.2985004772487 },
    { lng: 146.714428905087, lat: -38.2957108370105 },
    { lng: 146.714603914689, lat: -38.2949782812096 },
    { lng: 146.713673579329, lat: -38.2948386257484 },
    { lng: 146.713814752949, lat: -38.2947568555535 },
    { lng: 146.716339571765, lat: -38.2910676257588 },
    { lng: 146.716503076089, lat: -38.2908286919959 },
    { lng: 146.716227185291, lat: -38.290458619053 },
    { lng: 146.705738134624, lat: -38.2832654600139 },
    { lng: 146.705299631166, lat: -38.2831143132343 },
    { lng: 146.700329973978, lat: -38.2813861181208 },
    { lng: 146.699917616735, lat: -38.2812206912889 },
    { lng: 146.69707693348, lat: -38.2800809600554 },
    { lng: 146.694209299424, lat: -38.2815613119627 },
    { lng: 146.693283539523, lat: -38.2820545071373 },
    { lng: 146.690206970452, lat: -38.2824759967363 },
    { lng: 146.687893549853, lat: -38.2821625588743 },
    { lng: 146.68639910115, lat: -38.2820395977261 },
    { lng: 146.683141176003, lat: -38.2816352190573 },
    { lng: 146.682464973105, lat: -38.2850649093501 },
    { lng: 146.682153849559, lat: -38.2866428241596 },
    { lng: 146.681083152726, lat: -38.2923915443004 },
    { lng: 146.679855036152, lat: -38.2982971994385 },
    { lng: 146.679834190978, lat: -38.2984201599081 },
    { lng: 146.679812454598, lat: -38.2985271160333 },
    { lng: 146.679612728221, lat: -38.2995100981013 },
    { lng: 146.679587263425, lat: -38.2996385669817 },
    { lng: 146.679558234103, lat: -38.3002019312776 },
    { lng: 146.678698770957, lat: -38.3009724131481 },
    { lng: 146.678381519544, lat: -38.3010463894184 },
    { lng: 146.678156118505, lat: -38.3010989531147 },
    { lng: 146.678001326827, lat: -38.3011393361258 },
    { lng: 146.677581105039, lat: -38.301200604141 },
    { lng: 146.677358189648, lat: -38.3013317512658 },
    { lng: 146.677322043592, lat: -38.3013530177576 },
    { lng: 146.677345507299, lat: -38.301649208844 },
    { lng: 146.677335364978, lat: -38.3018392940159 },
    { lng: 146.677426389699, lat: -38.3020527706038 },
    { lng: 146.694107835469, lat: -38.3038520681933 },
    { lng: 146.697366914527, lat: -38.3042033307243 },
    { lng: 146.697327733058, lat: -38.3043820103336 },
    { lng: 146.698376274646, lat: -38.3044964106588 },
    { lng: 146.698718155318, lat: -38.3045401691204 },
    { lng: 146.703684457567, lat: -38.3050971323128 },
    { lng: 146.704488325695, lat: -38.3051885906097 },
    { lng: 146.709921567434, lat: -38.3058065501993 },
    { lng: 146.71121851106, lat: -38.3058685324408 },
    { lng: 146.712514431647, lat: -38.3050017702468 },
    { lng: 146.71478484301, lat: -38.3053101920817 },
    { lng: 146.715173164825, lat: -38.3061314535814 },
    { lng: 146.715012070001, lat: -38.3069048010025 },
    { lng: 146.71471129688, lat: -38.308348781305 },
    { lng: 146.714840414688, lat: -38.308719295867 },
    { lng: 146.715491945284, lat: -38.3088251036476 },
    { lng: 146.716582550466, lat: -38.3095285391136 },
    { lng: 146.717184250684, lat: -38.3110880158569 },
    { lng: 146.717397596548, lat: -38.3128182165584 },
    { lng: 146.71820493036, lat: -38.3137496465748 },
    { lng: 146.71897277455, lat: -38.3148826892912 },
    { lng: 146.71955576319, lat: -38.3162040992398 },
    { lng: 146.719913295636, lat: -38.3164856870433 },
    { lng: 146.720250500723, lat: -38.3167512519188 },
    { lng: 146.720603524311, lat: -38.3174964467149 },
    { lng: 146.72085301491, lat: -38.3183065391934 },
    { lng: 146.72087598271, lat: -38.3186343330019 },
    { lng: 146.720945525544, lat: -38.3203481122265 },
    { lng: 146.721835667323, lat: -38.3225064397911 },
    { lng: 146.722062552446, lat: -38.3225423021062 },
    { lng: 146.722160915474, lat: -38.3226260438755 },
    { lng: 146.721830233278, lat: -38.3244299913203 },
    { lng: 146.720438139309, lat: -38.324229993719 },
    { lng: 146.717848178075, lat: -38.3238447062137 },
    { lng: 146.715956815501, lat: -38.3235860588295 },
    { lng: 146.716255785056, lat: -38.322447467762 },
    { lng: 146.707460107908, lat: -38.3210479758173 },
    { lng: 146.709857030053, lat: -38.3225008391393 },
    { lng: 146.710460358176, lat: -38.3239619038574 },
    { lng: 146.710540614042, lat: -38.3241562389241 },
    { lng: 146.710474900493, lat: -38.3248452667258 },
    { lng: 146.710252205427, lat: -38.3248075782945 },
    { lng: 146.703953736722, lat: -38.3238527299506 },
    { lng: 146.701829306425, lat: -38.3245899687592 },
    { lng: 146.69962477505, lat: -38.3253549614075 },
    { lng: 146.69922712502, lat: -38.3246602126696 },
    { lng: 146.697930731551, lat: -38.3235562075439 },
    { lng: 146.696535325485, lat: -38.3225484562541 },
    { lng: 146.696101827181, lat: -38.3224051802643 },
    { lng: 146.694150877551, lat: -38.3219744338393 },
    { lng: 146.691538234509, lat: -38.3216744606157 },
    { lng: 146.691310359526, lat: -38.321653720251 },
    { lng: 146.690388602612, lat: -38.3215227006143 },
    { lng: 146.690043895764, lat: -38.3214736988552 },
    { lng: 146.686647327543, lat: -38.320990847923 },
    { lng: 146.687084973437, lat: -38.3189183102234 },
    { lng: 146.68298241275, lat: -38.3183865326416 },
    { lng: 146.679950912527, lat: -38.3179934926334 },
    { lng: 146.679773580502, lat: -38.3187249180841 },
    { lng: 146.679396215173, lat: -38.3202813096916 },
    { lng: 146.673260800274, lat: -38.3195475815969 },
    { lng: 146.674017621551, lat: -38.3161090517176 },
    { lng: 146.670650225232, lat: -38.3156544121209 },
    { lng: 146.670033378234, lat: -38.3155711462407 },
    { lng: 146.670147547304, lat: -38.3171949768628 },
    { lng: 146.66804868085, lat: -38.3187341073627 },
    { lng: 146.66597030821, lat: -38.3180916341326 },
    { lng: 146.662562695221, lat: -38.3176308487429 },
    { lng: 146.662288949187, lat: -38.317593831618 },
    { lng: 146.662614175171, lat: -38.3161542289221 },
    { lng: 146.661842678875, lat: -38.3152818399553 },
    { lng: 146.661069258032, lat: -38.3147139637581 },
    { lng: 146.660567900105, lat: -38.3148386082454 },
    { lng: 146.65928007511, lat: -38.3151587692592 },
    { lng: 146.658876814452, lat: -38.314869096777 },
    { lng: 146.654062654411, lat: -38.3120162514099 },
    { lng: 146.652391170758, lat: -38.3103286427472 },
    { lng: 146.652100991248, lat: -38.3100356909179 },
    { lng: 146.649698767349, lat: -38.3108745556196 },
    { lng: 146.645479310093, lat: -38.314384716189 },
    { lng: 146.640117711757, lat: -38.3133213827497 },
    { lng: 146.638544480449, lat: -38.3121881459194 },
    { lng: 146.638471210036, lat: -38.3074872632927 },
    { lng: 146.637442412878, lat: -38.3051447911854 },
    { lng: 146.636246635546, lat: -38.3004241120458 },
    { lng: 146.636200259592, lat: -38.3002357007847 },
    { lng: 146.635336023489, lat: -38.2970083113401 },
    { lng: 146.636732781239, lat: -38.2965994103702 },
    { lng: 146.637118496079, lat: -38.2937369660507 },
    { lng: 146.638608017207, lat: -38.2911813310163 },
    { lng: 146.638702902566, lat: -38.2910202310286 },
    { lng: 146.638235777273, lat: -38.290953591072 },
    { lng: 146.628006535676, lat: -38.2896983612772 },
    { lng: 146.625619085138, lat: -38.2894052560755 },
    { lng: 146.624797792264, lat: -38.2892308872524 },
    { lng: 146.624824962081, lat: -38.2891175574216 },
    { lng: 146.624777904238, lat: -38.2889586068798 },
    { lng: 146.624810496829, lat: -38.2887434121189 },
    { lng: 146.625057358937, lat: -38.2883549189902 },
    { lng: 146.625242400522, lat: -38.2881405160195 },
    { lng: 146.625244611979, lat: -38.2881270426448 },
    { lng: 146.625270439675, lat: -38.2876646515883 },
    { lng: 146.625019453045, lat: -38.2871351697604 },
    { lng: 146.62469940762, lat: -38.2865660692341 },
    { lng: 146.624605611057, lat: -38.2863374190311 },
    { lng: 146.624600650256, lat: -38.2861658042668 },
    { lng: 146.624552129066, lat: -38.2860022468289 },
    { lng: 146.624562942066, lat: -38.2857905547353 },
    { lng: 146.624555759035, lat: -38.2854175324254 },
    { lng: 146.624721352717, lat: -38.2850406416078 },
    { lng: 146.62469588853, lat: -38.2848301896864 },
    { lng: 146.624818329205, lat: -38.2845746971085 },
    { lng: 146.624958611464, lat: -38.284426763906 },
    { lng: 146.624983944838, lat: -38.2842173321233 },
    { lng: 146.624811640623, lat: -38.2839396456363 },
    { lng: 146.624806658449, lat: -38.2837737085802 },
    { lng: 146.624725994818, lat: -38.2835439030546 },
    { lng: 146.624668982355, lat: -38.2834422002844 },
    { lng: 146.624672887125, lat: -38.2833255036812 },
    { lng: 146.624720516648, lat: -38.283246419995 },
    { lng: 146.624728898517, lat: -38.283160553273 },
    { lng: 146.624653570491, lat: -38.2830821570448 },
    { lng: 146.624635641175, lat: -38.2830635075776 },
    { lng: 146.624542591956, lat: -38.2830271113075 },
    { lng: 146.624376480618, lat: -38.2828604180338 },
    { lng: 146.624088170573, lat: -38.2826242108077 },
    { lng: 146.624019002993, lat: -38.2825835045581 },
    { lng: 146.623905372758, lat: -38.2825166268649 },
    { lng: 146.623824820138, lat: -38.2824692139255 },
    { lng: 146.623679334177, lat: -38.2823825808481 },
    { lng: 146.62363534501, lat: -38.2823058689957 },
    { lng: 146.623628205471, lat: -38.2822934339677 },
    { lng: 146.623606910504, lat: -38.2820314383185 },
    { lng: 146.623654286533, lat: -38.2818894447501 },
    { lng: 146.623709044624, lat: -38.2817806105855 },
    { lng: 146.623769853381, lat: -38.2817312437286 },
    { lng: 146.623826304721, lat: -38.2816899419846 },
    { lng: 146.623847964159, lat: -38.2816578380444 },
    { lng: 146.623856499928, lat: -38.2816074577405 },
    { lng: 146.623864827237, lat: -38.2813635003201 },
    { lng: 146.623866972014, lat: -38.2813007857348 },
    { lng: 146.623866421924, lat: -38.2811508893328 },
    { lng: 146.623796165336, lat: -38.2809908172556 },
    { lng: 146.623725996004, lat: -38.280854833903 },
    { lng: 146.62365654221, lat: -38.2807238059039 },
    { lng: 146.623629305439, lat: -38.2807025137568 },
    { lng: 146.623663339532, lat: -38.2805387356864 },
    { lng: 146.623879027634, lat: -38.2804966009724 },
    { lng: 146.62390119284, lat: -38.2804922667535 },
    { lng: 146.623921290216, lat: -38.2804802645971 },
    { lng: 146.624122645224, lat: -38.2803599719378 },
    { lng: 146.624255195051, lat: -38.2801558791437 },
    { lng: 146.624300613631, lat: -38.2800696123542 },
    { lng: 146.624341489554, lat: -38.2799919816949 },
    { lng: 146.624492003446, lat: -38.279898829813 },
    { lng: 146.624639535396, lat: -38.2797873776186 },
    { lng: 146.624727358737, lat: -38.2796417823914 },
    { lng: 146.624878806778, lat: -38.2794467843853 },
    { lng: 146.624914424628, lat: -38.2793116421842 },
    { lng: 146.624841626813, lat: -38.2792706978153 },
    { lng: 146.624781918709, lat: -38.2792285213965 },
    { lng: 146.624798969036, lat: -38.2791861392625 },
    { lng: 146.624807725539, lat: -38.2791643630805 },
    { lng: 146.624964177847, lat: -38.2790963711025 },
    { lng: 146.625110081693, lat: -38.2789563419239 },
    { lng: 146.625078820174, lat: -38.2787950627042 },
    { lng: 146.62517833655, lat: -38.2786665940401 },
    { lng: 146.625242433981, lat: -38.2785639559645 },
    { lng: 146.625302185611, lat: -38.2784682720611 },
    { lng: 146.625340377563, lat: -38.2782656062673 },
    { lng: 146.625416475672, lat: -38.2780983097992 },
    { lng: 146.625395565074, lat: -38.2779759096779 },
    { lng: 146.625256250539, lat: -38.2779866706396 },
    { lng: 146.625090853444, lat: -38.2780043421239 },
    { lng: 146.624964551473, lat: -38.2779989793523 },
    { lng: 146.624898914819, lat: -38.2779397001948 },
    { lng: 146.624970976294, lat: -38.2778341810885 },
    { lng: 146.6250022055, lat: -38.277691065981 },
    { lng: 146.624984076763, lat: -38.2775492087648 },
    { lng: 146.624998300387, lat: -38.2774896838354 },
    { lng: 146.62506623598, lat: -38.2774299460245 },
    { lng: 146.625186081537, lat: -38.2773014171747 },
    { lng: 146.625277325468, lat: -38.2772611103539 },
    { lng: 146.62537180518, lat: -38.2772813907902 },
    { lng: 146.62546343512, lat: -38.2773223273275 },
    { lng: 146.62560585235, lat: -38.2773516527235 },
    { lng: 146.625625244924, lat: -38.2774579961694 },
    { lng: 146.625747521454, lat: -38.2775274326152 },
    { lng: 146.625925919407, lat: -38.2774959567741 },
    { lng: 146.626092338334, lat: -38.2773924872772 },
    { lng: 146.626081426018, lat: -38.2772426057035 },
    { lng: 146.626175168083, lat: -38.277119883373 },
    { lng: 146.626216142922, lat: -38.2768920409345 },
    { lng: 146.626243972581, lat: -38.2766493569448 },
    { lng: 146.626250507034, lat: -38.2765063108746 },
    { lng: 146.626164542423, lat: -38.2764413536622 },
    { lng: 146.625951142143, lat: -38.2764374179967 },
    { lng: 146.625734606992, lat: -38.2763911776579 },
    { lng: 146.62571542334, lat: -38.2763236966576 },
    { lng: 146.625839888123, lat: -38.2762512509343 },
    { lng: 146.625965981287, lat: -38.2762096607939 },
    { lng: 146.6259828221, lat: -38.2760940102208 },
    { lng: 146.62599387721, lat: -38.2760099359324 },
    { lng: 146.626006615327, lat: -38.2759131304272 },
    { lng: 146.626116240478, lat: -38.2757732016742 },
    { lng: 146.626242256881, lat: -38.2757236308957 },
    { lng: 146.626376038675, lat: -38.2757689614122 },
    { lng: 146.626470562541, lat: -38.2758030039329 },
    { lng: 146.626539905935, lat: -38.2757375793737 },
    { lng: 146.626523593033, lat: -38.2756666894131 },
    { lng: 146.626465094572, lat: -38.2755810518098 },
    { lng: 146.626470462522, lat: -38.2754917763845 },
    { lng: 146.626420907729, lat: -38.2754495567526 },
    { lng: 146.626323711706, lat: -38.2754613121453 },
    { lng: 146.626236702281, lat: -38.2754764502165 },
    { lng: 146.626175498107, lat: -38.2754285790596 },
    { lng: 146.626166225106, lat: -38.2753164720756 },
    { lng: 146.626183098985, lat: -38.2752100176841 },
    { lng: 146.626277291605, lat: -38.2751742326318 },
    { lng: 146.626433920812, lat: -38.2751474321314 },
    { lng: 146.626541259005, lat: -38.2751333913041 },
    { lng: 146.626605147375, lat: -38.2751389066165 },
    { lng: 146.626654031424, lat: -38.2750426357329 },
    { lng: 146.626740710519, lat: -38.2749576696001 },
    { lng: 146.626834431021, lat: -38.2748338170683 },
    { lng: 146.62682077943, lat: -38.2747148336936 },
    { lng: 146.626679573496, lat: -38.2746340492596 },
    { lng: 146.62655030131, lat: -38.274618417947 },
    { lng: 146.626451169236, lat: -38.274530622128 },
    { lng: 146.626404011634, lat: -38.274390006323 },
    { lng: 146.626411910347, lat: -38.2742251913543 },
    { lng: 146.626527728792, lat: -38.2741653508747 },
    { lng: 146.626720911219, lat: -38.27419221898 },
    { lng: 146.626890608162, lat: -38.274159629886 },
    { lng: 146.627038304479, lat: -38.2740905428801 },
    { lng: 146.627132564058, lat: -38.2740707892206 },
    { lng: 146.627144587418, lat: -38.2741519805787 },
    { lng: 146.62725327786, lat: -38.2741196292195 },
    { lng: 146.627368898878, lat: -38.2740174169809 },
    { lng: 146.627498996333, lat: -38.2739117373175 },
    { lng: 146.627633625269, lat: -38.273836954299 },
    { lng: 146.627729116194, lat: -38.2738447476278 },
    { lng: 146.627822033212, lat: -38.2738584839015 },
    { lng: 146.627910605445, lat: -38.2738859314249 },
    { lng: 146.628064516642, lat: -38.2739316427888 },
    { lng: 146.628153055604, lat: -38.2739362158822 },
    { lng: 146.628306912726, lat: -38.2738114106744 },
    { lng: 146.628286552166, lat: -38.2737656637394 },
    { lng: 146.628090553665, lat: -38.2736821430582 },
    { lng: 146.628023707496, lat: -38.27359291943 },
    { lng: 146.627938005608, lat: -38.2734578618075 },
    { lng: 146.627753612546, lat: -38.2734075763261 },
    { lng: 146.627612801962, lat: -38.2734247803733 },
    { lng: 146.627454599665, lat: -38.2735552894331 },
    { lng: 146.62735444505, lat: -38.2735782139099 },
    { lng: 146.627287652571, lat: -38.2735587736728 },
    { lng: 146.627239736588, lat: -38.27351640973 },
    { lng: 146.627239715283, lat: -38.2734569267768 },
    { lng: 146.627487896572, lat: -38.2732886012447 },
    { lng: 146.627728841421, lat: -38.27317180343 },
    { lng: 146.627901574252, lat: -38.2731363069658 },
    { lng: 146.628068554007, lat: -38.2731946335972 },
    { lng: 146.628221057604, lat: -38.273375420031 },
    { lng: 146.628434469515, lat: -38.2734074055986 },
    { lng: 146.628572398117, lat: -38.2734165007809 },
    { lng: 146.628656624947, lat: -38.2734736966421 },
    { lng: 146.628643600547, lat: -38.2735733011687 },
    { lng: 146.628646537903, lat: -38.2736625666102 },
    { lng: 146.628706091967, lat: -38.2737083317163 },
    { lng: 146.628780121591, lat: -38.2737094530705 },
    { lng: 146.628942702114, lat: -38.2736166755681 },
    { lng: 146.628952810576, lat: -38.2735365966415 },
    { lng: 146.628874391845, lat: -38.2734312992565 },
    { lng: 146.628804684514, lat: -38.2733958382248 },
    { lng: 146.628778505024, lat: -38.2733432227495 },
    { lng: 146.628813308698, lat: -38.2732402107841 },
    { lng: 146.628817664747, lat: -38.2731921178279 },
    { lng: 146.628797304468, lat: -38.2731452321767 },
    { lng: 146.628727596605, lat: -38.273125792416 },
    { lng: 146.628491009461, lat: -38.2731864827752 },
    { lng: 146.628435810476, lat: -38.2731293034527 },
    { lng: 146.628453125082, lat: -38.2728683270209 },
    { lng: 146.628393603668, lat: -38.2728362996837 },
    { lng: 146.628280347913, lat: -38.2728409072565 },
    { lng: 146.628235314394, lat: -38.2727642702253 },
    { lng: 146.628291898522, lat: -38.2727081790411 },
    { lng: 146.628374650794, lat: -38.2726795089376 },
    { lng: 146.62848934817, lat: -38.2726977916861 },
    { lng: 146.628598203993, lat: -38.2726691811062 },
    { lng: 146.628696874499, lat: -38.2725512522417 },
    { lng: 146.628705564359, lat: -38.2724928986354 },
    { lng: 146.628772322927, lat: -38.2724528309341 },
    { lng: 146.628828939577, lat: -38.2724619757007 },
    { lng: 146.628872533103, lat: -38.2725145833137 },
    { lng: 146.628942250473, lat: -38.2726416241522 },
    { lng: 146.629216657242, lat: -38.2727125139751 },
    { lng: 146.629393757293, lat: -38.2726826962715 },
    { lng: 146.629534535417, lat: -38.2725979763318 },
    { lng: 146.629591151605, lat: -38.2725349910366 },
    { lng: 146.629605584524, lat: -38.2723827986926 },
    { lng: 146.62948352833, lat: -38.2721550841514 },
    { lng: 146.629484935621, lat: -38.2720452132954 },
    { lng: 146.629502283101, lat: -38.271851770132 },
    { lng: 146.629577687941, lat: -38.2717018469054 },
    { lng: 146.629640068949, lat: -38.2716091285713 },
    { lng: 146.629730060162, lat: -38.271536980681 },
    { lng: 146.629841786625, lat: -38.2714327966944 },
    { lng: 146.62986928713, lat: -38.2712623037162 },
    { lng: 146.629795190708, lat: -38.2710997670097 },
    { lng: 146.629740004387, lat: -38.2710666159957 },
    { lng: 146.629652895029, lat: -38.2710643636362 },
    { lng: 146.629522248151, lat: -38.2711055886972 },
    { lng: 146.629446732943, lat: -38.2710380898567 },
    { lng: 146.629475718486, lat: -38.2709099019387 },
    { lng: 146.629493086611, lat: -38.2707988921541 },
    { lng: 146.629423336209, lat: -38.2706489781106 },
    { lng: 146.629259193804, lat: -38.2704098297382 },
    { lng: 146.628973095422, lat: -38.2701191902147 },
    { lng: 146.628843811624, lat: -38.2699452626818 },
    { lng: 146.628646240697, lat: -38.2696294622923 },
    { lng: 146.628531511449, lat: -38.2694806868111 },
    { lng: 146.628376103193, lat: -38.2692953178438 },
    { lng: 146.628309266878, lat: -38.2691980622262 },
    { lng: 146.628220672696, lat: -38.2691054118541 },
    { lng: 146.628040625175, lat: -38.2690173262207 },
    { lng: 146.627979663068, lat: -38.2689898542924 },
    { lng: 146.62797817819, lat: -38.2689429508632 },
    { lng: 146.627987142612, lat: -38.2689399172696 },
    { lng: 146.628049304543, lat: -38.2689188711257 },
    { lng: 146.628116084877, lat: -38.2689222879553 },
    { lng: 146.628208969545, lat: -38.2688948326263 },
    { lng: 146.628265564027, lat: -38.2688490433589 },
    { lng: 146.628274253482, lat: -38.2687940988094 },
    { lng: 146.628332323337, lat: -38.2687620108682 },
    { lng: 146.628380194582, lat: -38.2687574470982 },
    { lng: 146.628384243337, lat: -38.2687446974036 },
    { lng: 146.628388906585, lat: -38.2687299843899 },
    { lng: 146.628301754479, lat: -38.2686201382616 },
    { lng: 146.62826980976, lat: -38.2685869199877 },
    { lng: 146.628256730917, lat: -38.2685274111364 },
    { lng: 146.628281349243, lat: -38.2684210002043 },
    { lng: 146.628275915232, lat: -38.2684155687641 },
    { lng: 146.6282595694, lat: -38.2683992570138 },
    { lng: 146.628173933939, lat: -38.2683935277586 },
    { lng: 146.628131792551, lat: -38.2683489365821 },
    { lng: 146.628230418446, lat: -38.2681348982517 },
    { lng: 146.628300027493, lat: -38.2679860978385 },
    { lng: 146.628327615123, lat: -38.267978048012 },
    { lng: 146.628390051136, lat: -38.2680261322408 },
    { lng: 146.628480756686, lat: -38.268043660093 },
    { lng: 146.628549737463, lat: -38.26805698793 },
    { lng: 146.628553653885, lat: -38.2680577855898 },
    { lng: 146.628667350042, lat: -38.2680809656032 },
    { lng: 146.629020108879, lat: -38.2681289737325 },
    { lng: 146.62928575846, lat: -38.2680888113311 },
    { lng: 146.629374286876, lat: -38.2680349983927 },
    { lng: 146.629407628037, lat: -38.2679491477658 },
    { lng: 146.629242067313, lat: -38.2677832535823 },
    { lng: 146.629210562607, lat: -38.2677171409049 },
    { lng: 146.62920717451, lat: -38.2677100430937 },
    { lng: 146.629214897381, lat: -38.267675200563 },
    { lng: 146.629227459134, lat: -38.2676184810556 },
    { lng: 146.629182425348, lat: -38.2675303617457 },
    { lng: 146.629160611846, lat: -38.2674319494271 },
    { lng: 146.62914894109, lat: -38.2672877209356 },
    { lng: 146.629122782488, lat: -38.2672087644019 },
    { lng: 146.629122739019, lat: -38.2671355368219 },
    { lng: 146.629145937892, lat: -38.2670920499942 },
    { lng: 146.629218505415, lat: -38.2669970120775 },
    { lng: 146.629246026471, lat: -38.266916907772 },
    { lng: 146.629241615852, lat: -38.2668093323134 },
    { lng: 146.629135564648, lat: -38.2665804521911 },
    { lng: 146.629164549715, lat: -38.2664820391008 },
    { lng: 146.629203688459, lat: -38.2663687000988 },
    { lng: 146.629215270958, lat: -38.2662897270908 },
    { lng: 146.629180400892, lat: -38.266224531878 },
    { lng: 146.629128106598, lat: -38.2661558689069 },
    { lng: 146.629052547886, lat: -38.2659532972144 },
    { lng: 146.629062679484, lat: -38.2658823387017 },
    { lng: 146.629133772559, lat: -38.2658331333373 },
    { lng: 146.629339835527, lat: -38.2656945994135 },
    { lng: 146.629400775397, lat: -38.265604142711 },
    { lng: 146.629496551812, lat: -38.2655549274416 },
    { lng: 146.629569119367, lat: -38.2655400267405 },
    { lng: 146.629663520852, lat: -38.2655960594491 },
    { lng: 146.62981593728, lat: -38.2656257655714 },
    { lng: 146.629968320539, lat: -38.2655776993777 },
    { lng: 146.630286154216, lat: -38.265423101064 },
    { lng: 146.630528505708, lat: -38.2653120230401 },
    { lng: 146.630824581733, lat: -38.2651837578093 },
    { lng: 146.630895674864, lat: -38.2651196507429 },
    { lng: 146.631000119604, lat: -38.2649742672035 },
    { lng: 146.631052337299, lat: -38.2648723692812 },
    { lng: 146.631188770007, lat: -38.2648139741906 },
    { lng: 146.63128018002, lat: -38.2647750529118 },
    { lng: 146.631396273479, lat: -38.2646903330692 },
    { lng: 146.631528361563, lat: -38.2646559333275 },
    { lng: 146.631635798515, lat: -38.2647154416817 },
    { lng: 146.631720015473, lat: -38.2647405677044 },
    { lng: 146.631782439991, lat: -38.264782889812 },
    { lng: 146.631911634998, lat: -38.2647783002743 },
    { lng: 146.63198998804, lat: -38.2647141592712 },
    { lng: 146.63196671211, lat: -38.2645962887364 },
    { lng: 146.631962290194, lat: -38.2644646663796 },
    { lng: 146.631952092849, lat: -38.2643765473365 },
    { lng: 146.631975303484, lat: -38.2643204726871 },
    { lng: 146.63206380891, lat: -38.2642872803827 },
    { lng: 146.632204597483, lat: -38.2642506108264 },
    { lng: 146.6322684416, lat: -38.2641681703776 },
    { lng: 146.632166725172, lat: -38.2639599032495 },
    { lng: 146.632156494465, lat: -38.2638695223892 },
    { lng: 146.632198591737, lat: -38.2638122571804 },
    { lng: 146.632379949465, lat: -38.2636474343939 },
    { lng: 146.632488739167, lat: -38.2634688315574 },
    { lng: 146.632494502504, lat: -38.2634150702475 },
    { lng: 146.632459633164, lat: -38.2633475622343 },
    { lng: 146.632328985768, lat: -38.2633052240728 },
    { lng: 146.632262161226, lat: -38.2632091323741 },
    { lng: 146.632179319873, lat: -38.2630271885999 },
    { lng: 146.632142987069, lat: -38.262933348686 },
    { lng: 146.632169078948, lat: -38.2628703896558 },
    { lng: 146.632318546947, lat: -38.2627478709349 },
    { lng: 146.63232434381, lat: -38.2627100963368 },
    { lng: 146.632301035287, lat: -38.2625979732724 },
    { lng: 146.632314070426, lat: -38.2624709059538 },
    { lng: 146.632389452484, lat: -38.2623209920038 },
    { lng: 146.632631783435, lat: -38.262101148169 },
    { lng: 146.632789885797, lat: -38.2619271701348 },
    { lng: 146.632811632608, lat: -38.2618687924023 },
    { lng: 146.632773793089, lat: -38.2616582219251 },
    { lng: 146.632690941469, lat: -38.2614007229133 },
    { lng: 146.632686496982, lat: -38.2612313431184 },
    { lng: 146.632708244256, lat: -38.2611890129293 },
    { lng: 146.632798201539, lat: -38.2611248890642 },
    { lng: 146.632811237072, lat: -38.2610814027432 },
    { lng: 146.632764729113, lat: -38.2609658202825 },
    { lng: 146.632616602591, lat: -38.2608056370102 },
    { lng: 146.63252514933, lat: -38.260762151889 },
    { lng: 146.632498990457, lat: -38.2607072500069 },
    { lng: 146.632514940627, lat: -38.2606625909139 },
    { lng: 146.632607825418, lat: -38.260623652124 },
    { lng: 146.63272392974, lat: -38.2605835487567 },
    { lng: 146.632808068989, lat: -38.2605400639099 },
    { lng: 146.632837065401, lat: -38.260477112199 },
    { lng: 146.632883506899, lat: -38.2604656457563 },
    { lng: 146.632992440381, lat: -38.2605537231978 },
    { lng: 146.633072266647, lat: -38.2605582698109 },
    { lng: 146.633118686654, lat: -38.2605079000328 },
    { lng: 146.633060541164, lat: -38.2603488560046 },
    { lng: 146.632989360335, lat: -38.2602035059782 },
    { lng: 146.632992209504, lat: -38.2601073712458 },
    { lng: 146.633138761977, lat: -38.2600375443789 },
    { lng: 146.633192441873, lat: -38.2599757226822 },
    { lng: 146.633185171068, lat: -38.2599287693774 },
    { lng: 146.633099492454, lat: -38.2598681715617 },
    { lng: 146.633082034953, lat: -38.2597846093471 },
    { lng: 146.63315313864, lat: -38.2597227799029 },
    { lng: 146.633205344622, lat: -38.2596575599528 },
    { lng: 146.633298251397, lat: -38.2596678017888 },
    { lng: 146.633328775509, lat: -38.2597891312541 },
    { lng: 146.633368002093, lat: -38.2598451967924 },
    { lng: 146.633563988968, lat: -38.2599126608308 },
    { lng: 146.633707670845, lat: -38.2598714193277 },
    { lng: 146.633742475455, lat: -38.2598015920618 },
    { lng: 146.633681446455, lat: -38.259674577014 },
    { lng: 146.633351876124, lat: -38.2595167226641 },
    { lng: 146.632978777933, lat: -38.2593531658196 },
    { lng: 146.632967117634, lat: -38.2592948299022 },
    { lng: 146.633102076451, lat: -38.2591986361195 },
    { lng: 146.633123801935, lat: -38.2591162195884 },
    { lng: 146.633189097656, lat: -38.2590681269358 },
    { lng: 146.633284886468, lat: -38.259075004411 },
    { lng: 146.633374898645, lat: -38.2590944001706 },
    { lng: 146.633422771353, lat: -38.2590612167528 },
    { lng: 146.633400969153, lat: -38.2589845208449 },
    { lng: 146.63333272543, lat: -38.258954787798 },
    { lng: 146.633242745431, lat: -38.258970836428 },
    { lng: 146.633036605108, lat: -38.2589056579602 },
    { lng: 146.632969801949, lat: -38.2588484868594 },
    { lng: 146.632805770332, lat: -38.2588153543193 },
    { lng: 146.632753487129, lat: -38.2587684430495 },
    { lng: 146.632657644173, lat: -38.2586563097478 },
    { lng: 146.632567630814, lat: -38.2586208568872 },
    { lng: 146.632384722441, lat: -38.2585671204403 },
    { lng: 146.632348390129, lat: -38.2584858773454 },
    { lng: 146.632377397234, lat: -38.2584263256781 },
    { lng: 146.632631320626, lat: -38.2582809422811 },
    { lng: 146.632734346647, lat: -38.2581675780684 },
    { lng: 146.632793834975, lat: -38.2581412281944 },
    { lng: 146.63287074679, lat: -38.2581297856665 },
    { lng: 146.632888159462, lat: -38.2580931430735 },
    { lng: 146.63288954624, lat: -38.2580038931917 },
    { lng: 146.63293597717, lat: -38.2579546701445 },
    { lng: 146.633094134921, lat: -38.257861917601 },
    { lng: 146.633247980635, lat: -38.2578389759433 },
    { lng: 146.633346695503, lat: -38.2578389341706 },
    { lng: 146.633452625558, lat: -38.2577931439375 },
    { lng: 146.633496119144, lat: -38.2577221693089 },
    { lng: 146.633273962644, lat: -38.2575150581381 },
    { lng: 146.633273896714, lat: -38.2574269639593 },
    { lng: 146.633343560318, lat: -38.257375411906 },
    { lng: 146.633372138197, lat: -38.2573246499452 },
    { lng: 146.63340737089, lat: -38.2572620901843 },
    { lng: 146.633588750118, lat: -38.2571681902657 },
    { lng: 146.634003669702, lat: -38.2568064304342 },
    { lng: 146.634118256961, lat: -38.25666446293 },
    { lng: 146.634196565921, lat: -38.2565488632901 },
    { lng: 146.634295224872, lat: -38.2564755928069 },
    { lng: 146.634325683833, lat: -38.2564000201655 },
    { lng: 146.634312560955, lat: -38.2562809940055 },
    { lng: 146.634393762735, lat: -38.2561585430076 },
    { lng: 146.634446013265, lat: -38.2561619601202 },
    { lng: 146.634567969622, lat: -38.256222574001 },
    { lng: 146.634701499557, lat: -38.2562259562728 },
    { lng: 146.634766794551, lat: -38.2561801420391 },
    { lng: 146.634839318367, lat: -38.2560805471516 },
    { lng: 146.634829109769, lat: -38.2560004599753 },
    { lng: 146.634781161034, lat: -38.2558986215519 },
    { lng: 146.634789829034, lat: -38.2558299162304 },
    { lng: 146.634823170529, lat: -38.2557647039846 },
    { lng: 146.634811521303, lat: -38.2556685688716 },
    { lng: 146.634757796827, lat: -38.2555964641053 },
    { lng: 146.634631450486, lat: -38.2554946682457 },
    { lng: 146.634589320778, lat: -38.2554145717926 },
    { lng: 146.634602344725, lat: -38.2553550637845 },
    { lng: 146.63488376845, lat: -38.2550665563577 },
    { lng: 146.634996947845, lat: -38.255011586742 },
    { lng: 146.635025954795, lat: -38.2549692324022 },
    { lng: 146.634917054462, lat: -38.2548605416469 },
    { lng: 146.634903953544, lat: -38.2547930176206 },
    { lng: 146.634973584398, lat: -38.2547140023635 },
    { lng: 146.635176732439, lat: -38.2546372896133 },
    { lng: 146.635244932507, lat: -38.2545891707337 },
    { lng: 146.635300053265, lat: -38.2545147619462 },
    { lng: 146.635376920994, lat: -38.254451820355 },
    { lng: 146.635588747943, lat: -38.2543098266942 },
    { lng: 146.635690332878, lat: -38.2542926396233 },
    { lng: 146.635799222414, lat: -38.2543383790657 },
    { lng: 146.635896462042, lat: -38.254324592306 },
    { lng: 146.635958821008, lat: -38.2542410531376 },
    { lng: 146.635992964749, lat: -38.254168693233 },
    { lng: 146.636030299526, lat: -38.2541101700234 },
    { lng: 146.636100984741, lat: -38.2540457999461 },
    { lng: 146.63608008472, lat: -38.2539623809259 },
    { lng: 146.63603616171, lat: -38.253900738933 },
    { lng: 146.636120048186, lat: -38.2538591910457 },
    { lng: 146.636267745042, lat: -38.2538128320587 },
    { lng: 146.636357306513, lat: -38.2537494898009 },
    { lng: 146.636480595254, lat: -38.2537409902663 },
    { lng: 146.636695875905, lat: -38.253814480819 },
    { lng: 146.636844969475, lat: -38.253756638557 },
    { lng: 146.636920958133, lat: -38.253619006884 },
    { lng: 146.637055762557, lat: -38.2535943822527 },
    { lng: 146.637127934157, lat: -38.2535345762905 },
    { lng: 146.637059150632, lat: -38.2534501787663 },
    { lng: 146.636915314718, lat: -38.253210487623 },
    { lng: 146.636897230808, lat: -38.2531110035926 },
    { lng: 146.636936104961, lat: -38.2530639228078 },
    { lng: 146.637145039442, lat: -38.2530573090722 },
    { lng: 146.637324635523, lat: -38.2530050599968 },
    { lng: 146.637488733787, lat: -38.2530192637972 },
    { lng: 146.637587062519, lat: -38.2529661976573 },
    { lng: 146.637558001535, lat: -38.252747781922 },
    { lng: 146.637553930519, lat: -38.25257384741 },
    { lng: 146.637580991151, lat: -38.2524982144069 },
    { lng: 146.637736354752, lat: -38.2525090091482 },
    { lng: 146.638000310477, lat: -38.2524838492419 },
    { lng: 146.638281118585, lat: -38.2523797065576 },
    { lng: 146.638485543152, lat: -38.2523467863936 },
    { lng: 146.638660068928, lat: -38.2524044159056 },
    { lng: 146.638805126072, lat: -38.2523946580498 },
    { lng: 146.638923475509, lat: -38.2522980384303 },
    { lng: 146.638999419256, lat: -38.2521558173569 },
    { lng: 146.639084460246, lat: -38.2520696692212 },
    { lng: 146.63934788846, lat: -38.2519667167814 },
    { lng: 146.63956330134, lat: -38.2518468069146 },
    { lng: 146.639606212484, lat: -38.2517539284197 },
    { lng: 146.639616552521, lat: -38.251566213605 },
    { lng: 146.639611228318, lat: -38.2514220542753 },
    { lng: 146.63943668029, lat: -38.2513609821113 },
    { lng: 146.639463013959, lat: -38.2511766340257 },
    { lng: 146.639576962488, lat: -38.25107198203 },
    { lng: 146.639598797566, lat: -38.2510364935696 },
    { lng: 146.639664523237, lat: -38.2509297170404 },
    { lng: 146.639663225458, lat: -38.2507386537252 },
    { lng: 146.639635207649, lat: -38.2506712227131 },
    { lng: 146.639552048002, lat: -38.2506086462746 },
    { lng: 146.639547406199, lat: -38.2505651863694 },
    { lng: 146.639764667531, lat: -38.250503628658 },
    { lng: 146.639874700454, lat: -38.2504653959873 },
    { lng: 146.639986119346, lat: -38.2504191728031 },
    { lng: 146.640041536456, lat: -38.2504589778383 },
    { lng: 146.640069675077, lat: -38.2505412331135 },
    { lng: 146.640173141073, lat: -38.2506060199069 },
    { lng: 146.640265595794, lat: -38.2505450074228 },
    { lng: 146.640442233669, lat: -38.2504813003383 },
    { lng: 146.640651486864, lat: -38.2505261967633 },
    { lng: 146.64073380055, lat: -38.2505538470232 },
    { lng: 146.640803693607, lat: -38.2505027034319 },
    { lng: 146.640764985225, lat: -38.2503586788804 },
    { lng: 146.640714549564, lat: -38.2501952226025 },
    { lng: 146.640622072738, lat: -38.2500376758066 },
    { lng: 146.640583375125, lat: -38.249895946636 },
    { lng: 146.640700260869, lat: -38.2497970412265 },
    { lng: 146.640882564048, lat: -38.2497173195752 },
    { lng: 146.640944285222, lat: -38.2496175213509 },
    { lng: 146.640907225644, lat: -38.2495043858838 },
    { lng: 146.641026301378, lat: -38.2495130217282 },
    { lng: 146.641286341069, lat: -38.2497717197589 },
    { lng: 146.641394515175, lat: -38.2498868180104 },
    { lng: 146.641533555264, lat: -38.2498473271493 },
    { lng: 146.64158503532, lat: -38.2497372781312 },
    { lng: 146.641483471471, lat: -38.2495225417139 },
    { lng: 146.641552507814, lat: -38.2494330198969 },
    { lng: 146.641766072907, lat: -38.2494698760509 },
    { lng: 146.641963346766, lat: -38.2494587491086 },
    { lng: 146.642155231255, lat: -38.2495071487232 },
    { lng: 146.64220245413, lat: -38.2496225357818 },
    { lng: 146.642275417357, lat: -38.2496817300643 },
    { lng: 146.642443046579, lat: -38.2497908623726 },
    { lng: 146.642620101834, lat: -38.249794685913 },
    { lng: 146.642631003463, lat: -38.2496905267212 },
    { lng: 146.642587860732, lat: -38.2495339398064 },
    { lng: 146.642432167208, lat: -38.2494704882304 },
    { lng: 146.642422707449, lat: -38.2494492887986 },
    { lng: 146.64240121275, lat: -38.2494008309573 },
    { lng: 146.642340954877, lat: -38.2492866330753 },
    { lng: 146.642241471114, lat: -38.2491680406411 },
    { lng: 146.642209493929, lat: -38.2489473298194 },
    { lng: 146.642303312542, lat: -38.2488713828137 },
    { lng: 146.642554135559, lat: -38.2488406036884 },
    { lng: 146.642688037651, lat: -38.2486855211895 },
    { lng: 146.642729904488, lat: -38.2484370067701 },
    { lng: 146.642702800522, lat: -38.2482952608793 },
    { lng: 146.642489421712, lat: -38.2482824006319 },
    { lng: 146.6424553111, lat: -38.2481761419907 },
    { lng: 146.642629220835, lat: -38.2480772190204 },
    { lng: 146.642641782502, lat: -38.2480700792378 },
    { lng: 146.642785508448, lat: -38.2480809158464 },
    { lng: 146.642926605812, lat: -38.2481295017807 },
    { lng: 146.643082025543, lat: -38.2481494433336 },
    { lng: 146.643131744749, lat: -38.2482076012081 },
    { lng: 146.643158651226, lat: -38.24832076891 },
    { lng: 146.643293994509, lat: -38.2483820289109 },
    { lng: 146.64345921522, lat: -38.248351572621 },
    { lng: 146.643601280414, lat: -38.2483280623808 },
    { lng: 146.643615205458, lat: -38.2484573391203 },
    { lng: 146.643525996366, lat: -38.248568722708 },
    { lng: 146.643403147962, lat: -38.2486424766042 },
    { lng: 146.643259895486, lat: -38.2487025902851 },
    { lng: 146.643221593397, lat: -38.2488297490336 },
    { lng: 146.643298736128, lat: -38.2488648971723 },
    { lng: 146.643481314267, lat: -38.2488263996571 },
    { lng: 146.643635049754, lat: -38.2488119760363 },
    { lng: 146.643907465369, lat: -38.248753622948 },
    { lng: 146.644006773417, lat: -38.2486353462279 },
    { lng: 146.643946646541, lat: -38.2485429169165 },
    { lng: 146.643770768552, lat: -38.2485013178598 },
    { lng: 146.643838560436, lat: -38.2484392337621 },
    { lng: 146.644206664847, lat: -38.2483701627988 },
    { lng: 146.64449241242, lat: -38.2483494738149 },
    { lng: 146.644555123251, lat: -38.2483949831833 },
    { lng: 146.644573448517, lat: -38.2485287992249 },
    { lng: 146.64452646844, lat: -38.2486640509605 },
    { lng: 146.644528569197, lat: -38.2487578570613 },
    { lng: 146.644630384808, lat: -38.2487929276879 },
    { lng: 146.644821785299, lat: -38.24877267197 },
    { lng: 146.645061232984, lat: -38.2487704792687 },
    { lng: 146.645227563666, lat: -38.2486919812577 },
    { lng: 146.645297931079, lat: -38.2485840912179 },
    { lng: 146.64542301175, lat: -38.2486270670308 },
    { lng: 146.645608493663, lat: -38.2485885203818 },
    { lng: 146.645786804133, lat: -38.2485614393984 },
    { lng: 146.64602463457, lat: -38.2485386666241 },
    { lng: 146.646112337867, lat: -38.2485402987205 },
    { lng: 146.646227804521, lat: -38.24854240712 },
    { lng: 146.646256492831, lat: -38.2484896220649 },
    { lng: 146.646213263519, lat: -38.248322733272 },
    { lng: 146.646162355547, lat: -38.2480952386949 },
    { lng: 146.646280802495, lat: -38.2480146343695 },
    { lng: 146.646231754171, lat: -38.2478443283202 },
    { lng: 146.646255085174, lat: -38.2476496861106 },
    { lng: 146.646376414609, lat: -38.2475667773605 },
    { lng: 146.646451325564, lat: -38.2474852106884 },
    { lng: 146.646442601949, lat: -38.2474604760788 },
    { lng: 146.646403210809, lat: -38.2473489820639 },
    { lng: 146.646402485348, lat: -38.2473469504067 },
    { lng: 146.646307840787, lat: -38.2472958312395 },
    { lng: 146.646227804928, lat: -38.2472664382772 },
    { lng: 146.646205673475, lat: -38.2472104397545 },
    { lng: 146.646267668468, lat: -38.2471552669684 },
    { lng: 146.646419447037, lat: -38.2470664839974 },
    { lng: 146.646430204948, lat: -38.2469428512532 },
    { lng: 146.646489912984, lat: -38.2467618098677 },
    { lng: 146.646679300284, lat: -38.2466591470299 },
    { lng: 146.646838029936, lat: -38.246739702261 },
    { lng: 146.646964717125, lat: -38.2468055432113 },
    { lng: 146.647197971724, lat: -38.2467507935681 },
    { lng: 146.647234415406, lat: -38.2465617877055 },
    { lng: 146.647365865563, lat: -38.2464742629344 },
    { lng: 146.647528104237, lat: -38.2464335401948 },
    { lng: 146.647667595256, lat: -38.2464627036499 },
    { lng: 146.647789618413, lat: -38.2464793209188 },
    { lng: 146.647910838078, lat: -38.2463815292206 },
    { lng: 146.648011829253, lat: -38.2462998612979 },
    { lng: 146.648471937124, lat: -38.2463585108807 },
    { lng: 146.648809186218, lat: -38.2460343121226 },
    { lng: 146.648813542519, lat: -38.2459771575572 },
    { lng: 146.648761654575, lat: -38.2458183097258 },
    { lng: 146.648696567427, lat: -38.2456377787713 },
    { lng: 146.648621855757, lat: -38.245536271007 },
    { lng: 146.648408500344, lat: -38.2455280257369 },
    { lng: 146.648216560441, lat: -38.2454716291433 },
    { lng: 146.648172538937, lat: -38.2453974071683 },
    { lng: 146.648238561486, lat: -38.2452941566838 },
    { lng: 146.648345491588, lat: -38.245228468567 },
    { lng: 146.648343655129, lat: -38.2451735509392 },
    { lng: 146.648266324864, lat: -38.245109767294 },
    { lng: 146.648258701946, lat: -38.2450560386513 },
    { lng: 146.649390348878, lat: -38.2451955572922 },
    { lng: 146.649387412414, lat: -38.2451615315459 },
    { lng: 146.649357195487, lat: -38.2450850996037 },
    { lng: 146.649322214648, lat: -38.2449858882221 },
    { lng: 146.649320773878, lat: -38.2449858799797 },
    { lng: 146.649293273787, lat: -38.2448991535594 },
    { lng: 146.649344413499, lat: -38.2448299716426 },
    { lng: 146.649393901849, lat: -38.2447482367837 },
    { lng: 146.649396552923, lat: -38.2446405836877 },
    { lng: 146.649408422306, lat: -38.2445649172374 },
    { lng: 146.649470440099, lat: -38.2445402593891 },
    { lng: 146.649517387546, lat: -38.2445730178947 },
    { lng: 146.649593640527, lat: -38.2446214930019 },
    { lng: 146.64968093635, lat: -38.2446355088947 },
    { lng: 146.649825629604, lat: -38.2446969987749 },
    { lng: 146.649929382196, lat: -38.2447417939985 },
    { lng: 146.65002371827, lat: -38.2447420063299 },
    { lng: 146.650136413133, lat: -38.2447076656687 },
    { lng: 146.650182481355, lat: -38.2445939186577 },
    { lng: 146.650160437319, lat: -38.2444853985656 },
    { lng: 146.650076430303, lat: -38.2444049469168 },
    { lng: 146.650041735889, lat: -38.2443217065606 },
    { lng: 146.65006025973, lat: -38.244210518217 },
    { lng: 146.65016723515, lat: -38.2441819578192 },
    { lng: 146.65028145887, lat: -38.2441556758356 },
    { lng: 146.650386762505, lat: -38.2441145788257 },
    { lng: 146.650447525461, lat: -38.2440109466411 },
    { lng: 146.650578986504, lat: -38.2439718811061 },
    { lng: 146.65066655812, lat: -38.2440018943236 },
    { lng: 146.650782960388, lat: -38.2441117988242 },
    { lng: 146.650869541382, lat: -38.2441716127231 },
    { lng: 146.650980244911, lat: -38.2441052714348 },
    { lng: 146.650944935374, lat: -38.24398428172 },
    { lng: 146.650879100299, lat: -38.243859000226 },
    { lng: 146.650874677815, lat: -38.2436736330137 },
    { lng: 146.650896226912, lat: -38.243566932193 },
    { lng: 146.650874964436, lat: -38.2435087917415 },
    { lng: 146.650788162997, lat: -38.2435268213396 },
    { lng: 146.650723031847, lat: -38.2436293386335 },
    { lng: 146.650568262088, lat: -38.2436617578262 },
    { lng: 146.650490415301, lat: -38.2436053100568 },
    { lng: 146.650500578833, lat: -38.2435184138697 },
    { lng: 146.650507025181, lat: -38.2434632225701 },
    { lng: 146.650538341561, lat: -38.2433323832668 },
    { lng: 146.650599380859, lat: -38.2432459301376 },
    { lng: 146.650708511627, lat: -38.2432643406413 },
    { lng: 146.650821097128, lat: -38.2432254527654 },
    { lng: 146.650851753505, lat: -38.2431438531369 },
    { lng: 146.650779791901, lat: -38.2430919442327 },
    { lng: 146.650669110061, lat: -38.2430678213446 },
    { lng: 146.650543985574, lat: -38.2430484492717 },
    { lng: 146.650376235329, lat: -38.242996343591 },
    { lng: 146.650308365039, lat: -38.2429271792796 },
    { lng: 146.650311214549, lat: -38.2428321492747 },
    { lng: 146.650386068515, lat: -38.2427924631943 },
    { lng: 146.650534613161, lat: -38.2427326232353 },
    { lng: 146.650616959111, lat: -38.2426185023425 },
    { lng: 146.650639586493, lat: -38.2425191709704 },
    { lng: 146.650645460193, lat: -38.2424934333063 },
    { lng: 146.650630323753, lat: -38.2423630769095 },
    { lng: 146.650577908597, lat: -38.2422617310522 },
    { lng: 146.650543402261, lat: -38.2421911303491 },
    { lng: 146.650569263049, lat: -38.2421513158574 },
    { lng: 146.650606277545, lat: -38.242130031891 },
    { lng: 146.650680208789, lat: -38.2421235466297 },
    { lng: 146.650764369643, lat: -38.2421215658282 },
    { lng: 146.650850412259, lat: -38.2421482221529 },
    { lng: 146.650898954618, lat: -38.2421877812556 },
    { lng: 146.650900054629, lat: -38.2422564530716 },
    { lng: 146.650906797462, lat: -38.2423147633239 },
    { lng: 146.650967033902, lat: -38.2423599582766 },
    { lng: 146.651058059383, lat: -38.2423350099801 },
    { lng: 146.651168894899, lat: -38.242275527365 },
    { lng: 146.651262372483, lat: -38.2422219428063 },
    { lng: 146.651303205336, lat: -38.2421437165982 },
    { lng: 146.651326536561, lat: -38.2420599159702 },
    { lng: 146.651331287757, lat: -38.241992299293 },
    { lng: 146.651284945548, lat: -38.2419092194402 },
    { lng: 146.651174065436, lat: -38.2418736729427 },
    { lng: 146.651073711626, lat: -38.2418609476505 },
    { lng: 146.651022539612, lat: -38.2418373858726 },
    { lng: 146.65099258741, lat: -38.2417804868462 },
    { lng: 146.650982950848, lat: -38.2417210296083 },
    { lng: 146.651022803833, lat: -38.2416725798724 },
    { lng: 146.651096371775, lat: -38.2416443512034 },
    { lng: 146.651165561673, lat: -38.2416139209879 },
    { lng: 146.651237436082, lat: -38.2415708181327 },
    { lng: 146.651295010309, lat: -38.2415393502856 },
    { lng: 146.651369216496, lat: -38.2414607926561 },
    { lng: 146.651457986448, lat: -38.2413843610658 },
    { lng: 146.651515043568, lat: -38.2413219713455 },
    { lng: 146.651564729729, lat: -38.2412504783136 },
    { lng: 146.651589589802, lat: -38.2411723804245 },
    { lng: 146.651651773034, lat: -38.2411580494331 },
    { lng: 146.651700305096, lat: -38.2411976173825 },
    { lng: 146.651749859766, lat: -38.2413013156177 },
    { lng: 146.651801944633, lat: -38.2413809102245 },
    { lng: 146.651872190889, lat: -38.2414168401942 },
    { lng: 146.651954086404, lat: -38.241365678564 },
    { lng: 146.651996347547, lat: -38.2412851332856 },
    { lng: 146.652023495905, lat: -38.2411669322607 },
    { lng: 146.652043867687, lat: -38.2410797221888 },
    { lng: 146.652110891165, lat: -38.2410046661844 },
    { lng: 146.652179453552, lat: -38.2409352812992 },
    { lng: 146.652235784697, lat: -38.2409186894303 },
    { lng: 146.652326799028, lat: -38.2408914635695 },
    { lng: 146.652451351452, lat: -38.2408741907977 },
    { lng: 146.652543861611, lat: -38.2408503661148 },
    { lng: 146.652601523698, lat: -38.2408257932174 },
    { lng: 146.652609179844, lat: -38.2407604611133 },
    { lng: 146.652626626548, lat: -38.2406709737099 },
    { lng: 146.652641421488, lat: -38.2405975586923 },
    { lng: 146.652697455533, lat: -38.2405615190668 },
    { lng: 146.652763147273, lat: -38.2405860498566 },
    { lng: 146.652815264562, lat: -38.240667972588 },
    { lng: 146.652845900235, lat: -38.2407672364263 },
    { lng: 146.652887865273, lat: -38.2408515298121 },
    { lng: 146.652949806134, lat: -38.2409127563732 },
    { lng: 146.653051786496, lat: -38.2409369296705 },
    { lng: 146.653166813461, lat: -38.2409575086672 },
    { lng: 146.65328114753, lat: -38.2409380613483 },
    { lng: 146.653339745512, lat: -38.2408802598007 },
    { lng: 146.653401179844, lat: -38.2408201228343 },
    { lng: 146.653443596157, lat: -38.240749870979 },
    { lng: 146.653517197111, lat: -38.2407228070789 },
    { lng: 146.653576586075, lat: -38.2407153516065 },
    { lng: 146.653614689441, lat: -38.240828266017 },
    { lng: 146.653584076756, lat: -38.2409109966041 },
    { lng: 146.65354766651, lat: -38.2409937777755 },
    { lng: 146.65354296949, lat: -38.2410636570597 },
    { lng: 146.653601732011, lat: -38.2411065816052 },
    { lng: 146.653670295018, lat: -38.2411288183794 },
    { lng: 146.653746866417, lat: -38.2411062670725 },
    { lng: 146.653798455535, lat: -38.2410657218114 },
    { lng: 146.653819565477, lat: -38.2410243012976 },
    { lng: 146.653841355768, lat: -38.2409347884314 },
    { lng: 146.653850101141, lat: -38.2408465579756 },
    { lng: 146.653860660735, lat: -38.2407800634041 },
    { lng: 146.653876765072, lat: -38.2406986244007 },
    { lng: 146.653909027592, lat: -38.2406284736661 },
    { lng: 146.653983025292, lat: -38.2406265866158 },
    { lng: 146.654067494301, lat: -38.2406440625805 },
    { lng: 146.654111956295, lat: -38.240701999062 },
    { lng: 146.654141721617, lat: -38.2408378974135 },
    { lng: 146.65417610856, lat: -38.2409005163596 },
    { lng: 146.654259806572, lat: -38.2409615287609 },
    { lng: 146.654368091466, lat: -38.2410153766653 },
    { lng: 146.654434244512, lat: -38.241067380235 },
    { lng: 146.65449896886, lat: -38.2411216782622 },
    { lng: 146.654564385934, lat: -38.2411278909396 },
    { lng: 146.654589663899, lat: -38.2410750133752 },
    { lng: 146.654591104634, lat: -38.2409833827903 },
    { lng: 146.654573735679, lat: -38.2408965887073 },
    { lng: 146.654562307078, lat: -38.2408165361123 },
    { lng: 146.654614853945, lat: -38.240743904281 },
    { lng: 146.654720464526, lat: -38.2407222372001 },
    { lng: 146.654793087484, lat: -38.2407272689122 },
    { lng: 146.65485629356, lat: -38.24077586381 },
    { lng: 146.654890964929, lat: -38.2408568013729 },
    { lng: 146.654895573991, lat: -38.2409620390242 },
    { lng: 146.65490169028, lat: -38.2410730491397 },
    { lng: 146.654925120092, lat: -38.2411758141739 },
    { lng: 146.654956249811, lat: -38.241307147389 },
    { lng: 146.655015540427, lat: -38.2413832569674 },
    { lng: 146.65510676315, lat: -38.2413686106824 },
    { lng: 146.655222186457, lat: -38.2413274200829 },
    { lng: 146.655286140166, lat: -38.2412420713389 },
    { lng: 146.655299702822, lat: -38.2411824097354 },
    { lng: 146.655282873144, lat: -38.2411287661795 },
    { lng: 146.655257441406, lat: -38.2410809202075 },
    { lng: 146.655256407414, lat: -38.2410168215725 },
    { lng: 146.65528331273, lat: -38.2409742032264 },
    { lng: 146.655323661029, lat: -38.240956649754 },
    { lng: 146.655390265999, lat: -38.2409467978908 },
    { lng: 146.655490145759, lat: -38.2409298072117 },
    { lng: 146.655565375406, lat: -38.2409141501281 },
    { lng: 146.655631737754, lat: -38.2408894663412 },
    { lng: 146.655684977817, lat: -38.2408603280168 },
    { lng: 146.655710530953, lat: -38.2408245771735 },
    { lng: 146.655732783495, lat: -38.2407636908218 },
    { lng: 146.655777564515, lat: -38.2407506189966 },
    { lng: 146.655857557475, lat: -38.2407601303173 },
    { lng: 146.655926691983, lat: -38.2407274141602 },
    { lng: 146.65599878644, lat: -38.2406980719685 },
    { lng: 146.656037615927, lat: -38.240674755861 },
    { lng: 146.656082177009, lat: -38.2406479897238 },
    { lng: 146.656135350897, lat: -38.2406142617357 },
    { lng: 146.65614021353, lat: -38.2405546932567 },
    { lng: 146.65617249748, lat: -38.2404879940465 },
    { lng: 146.656243161773, lat: -38.2404598083302 },
    { lng: 146.656315893515, lat: -38.2404682234203 },
    { lng: 146.656364634727, lat: -38.2405203883221 },
    { lng: 146.656400241762, lat: -38.2405692629775 },
    { lng: 146.656450126826, lat: -38.2406031101496 },
    { lng: 146.656489529508, lat: -38.2406153070709 },
    { lng: 146.656585041555, lat: -38.2405983240637 },
    { lng: 146.65669689001, lat: -38.2406041037054 },
    { lng: 146.656769522549, lat: -38.2406079628561 },
    { lng: 146.656804965001, lat: -38.2405549488655 },
    { lng: 146.656837360519, lat: -38.2404939527845 },
    { lng: 146.656907441443, lat: -38.2404291481622 },
    { lng: 146.656919475162, lat: -38.2403649232606 },
    { lng: 146.656880711115, lat: -38.2403011722734 },
    { lng: 146.656835875135, lat: -38.2402203720637 },
    { lng: 146.656784856605, lat: -38.2401166796457 },
    { lng: 146.65681884669, lat: -38.2400648469015 },
    { lng: 146.656800762673, lat: -38.2400226790793 },
    { lng: 146.656715149635, lat: -38.2400235029075 },
    { lng: 146.656616986571, lat: -38.2400577072383 },
    { lng: 146.656548994769, lat: -38.2400721232482 },
    { lng: 146.656510923603, lat: -38.240049590334 },
    { lng: 146.656479837898, lat: -38.2400132859379 },
    { lng: 146.65645415276, lat: -38.2399494165705 },
    { lng: 146.656409547789, lat: -38.2398834571628 },
    { lng: 146.656372191602, lat: -38.2398174712344 },
    { lng: 146.656382697024, lat: -38.2397486805806 },
    { lng: 146.656384446362, lat: -38.2397372567865 },
    { lng: 146.656397183791, lat: -38.2396546373664 },
    { lng: 146.656346396767, lat: -38.239565846071 },
    { lng: 146.656267010123, lat: -38.2395037109742 },
    { lng: 146.656179999647, lat: -38.239417547451 },
    { lng: 146.656145405567, lat: -38.2393423734364 },
    { lng: 146.656177723062, lat: -38.2392768044119 },
    { lng: 146.656173927985, lat: -38.239220711649 },
    { lng: 146.656081220006, lat: -38.2392319582486 },
    { lng: 146.656000138844, lat: -38.2392441975704 },
    { lng: 146.655925547715, lat: -38.2392094836971 },
    { lng: 146.655859196435, lat: -38.2391448918666 },
    { lng: 146.655769501616, lat: -38.2390725233616 },
    { lng: 146.65576677427, lat: -38.2389924189864 },
    { lng: 146.655824435812, lat: -38.2389677769262 },
    { lng: 146.655904284752, lat: -38.238969290591 },
    { lng: 146.656009697453, lat: -38.2389361919105 },
    { lng: 146.65606733814, lat: -38.2389092633927 },
    { lng: 146.65614785845, lat: -38.2388626575551 },
    { lng: 146.656189008772, lat: -38.2388038983542 },
    { lng: 146.656192353493, lat: -38.238740878358 },
    { lng: 146.656108676127, lat: -38.2386822121391 },
    { lng: 146.655832751964, lat: -38.238671204298 },
    { lng: 146.655788498733, lat: -38.2386269870749 },
    { lng: 146.655776685222, lat: -38.2385240861199 },
    { lng: 146.655798410212, lat: -38.238432320341 },
    { lng: 146.655871659135, lat: -38.238383479151 },
    { lng: 146.655987466959, lat: -38.2383651783988 },
    { lng: 146.656068812425, lat: -38.2383689517323 },
    { lng: 146.656155117475, lat: -38.2384104070049 },
    { lng: 146.656229103825, lat: -38.2384073807932 },
    { lng: 146.656263160497, lat: -38.2383601376078 },
    { lng: 146.656261916919, lat: -38.238282277847 },
    { lng: 146.656243447844, lat: -38.238217236319 },
    { lng: 146.656198128501, lat: -38.2381054783839 },
    { lng: 146.656132864839, lat: -38.2380191614124 },
    { lng: 146.656155403967, lat: -38.2379754107285 },
    { lng: 146.656250872938, lat: -38.2379561580978 },
    { lng: 146.656381420613, lat: -38.2379514320968 },
    { lng: 146.656446101313, lat: -38.2379118564338 },
    { lng: 146.65649598556, lat: -38.237855245824 },
    { lng: 146.656510912493, lat: -38.2377898729855 },
    { lng: 146.656470179539, lat: -38.2376941207297 },
    { lng: 146.656401330805, lat: -38.2376547397546 },
    { lng: 146.656294235226, lat: -38.2376741196371 },
    { lng: 146.6561799451, lat: -38.2376970100724 },
    { lng: 146.656147429075, lat: -38.2376606990593 },
    { lng: 146.656140972242, lat: -38.2376195590039 },
    { lng: 146.656186874853, lat: -38.237587046069 },
    { lng: 146.656257263493, lat: -38.237540534089 },
    { lng: 146.656327334545, lat: -38.2374757469211 },
    { lng: 146.656433483798, lat: -38.2373968159633 },
    { lng: 146.656482620747, lat: -38.2372933027931 },
    { lng: 146.656440578815, lat: -38.237205566262 },
    { lng: 146.656398107615, lat: -38.2371831261211 },
    { lng: 146.656321218176, lat: -38.2371850390722 },
    { lng: 146.656224692672, lat: -38.2372283293819 },
    { lng: 146.656128200878, lat: -38.2372762599757 },
    { lng: 146.656023139756, lat: -38.2373310940754 },
    { lng: 146.655938770275, lat: -38.23731933019 },
    { lng: 146.655869524714, lat: -38.2372547807351 },
    { lng: 146.655841970036, lat: -38.2371669344314 },
    { lng: 146.655833775558, lat: -38.2371086315466 },
    { lng: 146.655892976587, lat: -38.2370885469472 },
    { lng: 146.656000953572, lat: -38.2370348441845 },
    { lng: 146.656052190674, lat: -38.2369713742018 },
    { lng: 146.656085301053, lat: -38.236864579965 },
    { lng: 146.656126539787, lat: -38.2368103422959 },
    { lng: 146.656198117099, lat: -38.2367501185675 },
    { lng: 146.656241072267, lat: -38.2367130592657 },
    { lng: 146.656280803531, lat: -38.2366565682092 },
    { lng: 146.65628697492, lat: -38.2365877855633 },
    { lng: 146.656321383244, lat: -38.2364718629444 },
    { lng: 146.656366582356, lat: -38.2363947081427 },
    { lng: 146.656394236218, lat: -38.2363085611154 },
    { lng: 146.656420316904, lat: -38.2362155712772 },
    { lng: 146.656490518883, lat: -38.2361587997681 },
    { lng: 146.65651710655, lat: -38.2360812372358 },
    { lng: 146.656525190748, lat: -38.2360576923816 },
    { lng: 146.656567254777, lat: -38.235966860737 },
    { lng: 146.656575835336, lat: -38.2358683287074 },
    { lng: 146.656499538471, lat: -38.2358164275952 },
    { lng: 146.656406028421, lat: -38.2357784410152 },
    { lng: 146.656290505822, lat: -38.2357234797068 },
    { lng: 146.65622295519, lat: -38.2356737810558 },
    { lng: 146.656163708949, lat: -38.2356011399533 },
    { lng: 146.656180077464, lat: -38.2355357238653 },
    { lng: 146.656268385218, lat: -38.2355222427533 },
    { lng: 146.656353678502, lat: -38.2355007889327 },
    { lng: 146.656428698885, lat: -38.2354713962617 },
    { lng: 146.65652834822, lat: -38.235440625787 },
    { lng: 146.656567981329, lat: -38.2353784319703 },
    { lng: 146.656560644012, lat: -38.235283512307 },
    { lng: 146.656515709513, lat: -38.2351969560356 },
    { lng: 146.656443900787, lat: -38.2351530027878 },
    { lng: 146.656316103499, lat: -38.2351474022494 },
    { lng: 146.656247440821, lat: -38.2351206437285 },
    { lng: 146.656213318726, lat: -38.2350740288639 },
    { lng: 146.656161531367, lat: -38.2350127527696 },
    { lng: 146.656081274475, lat: -38.2349860802828 },
    { lng: 146.655981384111, lat: -38.2350007761226 },
    { lng: 146.655888962106, lat: -38.2350303478266 },
    { lng: 146.655791809616, lat: -38.2350347341265 },
    { lng: 146.655746016545, lat: -38.2349859610918 },
    { lng: 146.655743167631, lat: -38.2348989893067 },
    { lng: 146.65577437494, lat: -38.2348540583583 },
    { lng: 146.655764584579, lat: -38.2347866190605 },
    { lng: 146.655782239593, lat: -38.2347097107531 },
    { lng: 146.655785485184, lat: -38.2346409970356 },
    { lng: 146.655747952585, lat: -38.2345635530063 },
    { lng: 146.655664649641, lat: -38.2345277511868 },
    { lng: 146.655576892411, lat: -38.2344862713144 },
    { lng: 146.655544254466, lat: -38.2344407801106 },
    { lng: 146.655567948708, lat: -38.2343798773786 },
    { lng: 146.65561960547, lat: -38.2343438962387 },
    { lng: 146.655725051198, lat: -38.2343119107662 },
    { lng: 146.655824853571, lat: -38.2342903122886 },
    { lng: 146.655921775463, lat: -38.2342721994145 },
    { lng: 146.656015726347, lat: -38.2342472006654 },
    { lng: 146.656078382301, lat: -38.2341733443836 },
    { lng: 146.656049342088, lat: -38.2340808730369 },
    { lng: 146.656062586187, lat: -38.2340029188033 },
    { lng: 146.656100480734, lat: -38.2339224072391 },
    { lng: 146.656180505493, lat: -38.2338437401773 },
    { lng: 146.65626290659, lat: -38.2338234585699 },
    { lng: 146.65634945504, lat: -38.2337905381037 },
    { lng: 146.656408359765, lat: -38.2337533156135 },
    { lng: 146.656452535946, lat: -38.2337025123927 },
    { lng: 146.656437179482, lat: -38.2336500081018 },
    { lng: 146.656365514981, lat: -38.2336164066237 },
    { lng: 146.656315289204, lat: -38.2335607923708 },
    { lng: 146.656307248346, lat: -38.2335128176827 },
    { lng: 146.656326772444, lat: -38.2334622167881 },
    { lng: 146.656354075335, lat: -38.2334508701942 },
    { lng: 146.656388768584, lat: -38.2334364192668 },
    { lng: 146.656470059261, lat: -38.2334378982781 },
    { lng: 146.65652545518, lat: -38.2334534029156 },
    { lng: 146.656589145381, lat: -38.2334436109876 },
    { lng: 146.65664952418, lat: -38.2334075195578 },
    { lng: 146.656704721925, lat: -38.2334092277551 },
    { lng: 146.656762339482, lat: -38.233382334217 },
    { lng: 146.656786078117, lat: -38.2333248488194 },
    { lng: 146.656809771962, lat: -38.2332639376846 },
    { lng: 146.656864397611, lat: -38.2332313397558 },
    { lng: 146.656964893871, lat: -38.233162796237 },
    { lng: 146.657019398661, lat: -38.2331221921737 },
    { lng: 146.657099104676, lat: -38.2331156983093 },
    { lng: 146.65717625849, lat: -38.2331309210074 },
    { lng: 146.657217663134, lat: -38.2330881578496 },
    { lng: 146.657216716908, lat: -38.2330298139486 },
    { lng: 146.657162079882, lat: -38.232970798218 },
    { lng: 146.657113251403, lat: -38.2329129298097 },
    { lng: 146.657015153558, lat: -38.2328577911632 },
    { lng: 146.656906285713, lat: -38.2327672989259 },
    { lng: 146.656854332971, lat: -38.2326945480223 },
    { lng: 146.656794844969, lat: -38.2326047120514 },
    { lng: 146.656751570934, lat: -38.2325319014164 },
    { lng: 146.656759271178, lat: -38.2324700115502 },
    { lng: 146.656761965596, lat: -38.2324513715613 },
    { lng: 146.656769743453, lat: -38.2323977622404 },
    { lng: 146.656743805368, lat: -38.232320199795 },
    { lng: 146.656689300199, lat: -38.2322692420084 },
    { lng: 146.656641548753, lat: -38.2321872597619 },
    { lng: 146.656615731728, lat: -38.2321165726412 },
    { lng: 146.656575351427, lat: -38.2320425725831 },
    { lng: 146.656533385434, lat: -38.2319582530672 },
    { lng: 146.656461445768, lat: -38.2319074479957 },
    { lng: 146.656392893943, lat: -38.2318864109208 },
    { lng: 146.656309360114, lat: -38.231924992278 },
    { lng: 146.656237937381, lat: -38.2319978627382 },
    { lng: 146.656156866925, lat: -38.2320100769476 },
    { lng: 146.656106948899, lat: -38.2319739524028 },
    { lng: 146.656099909355, lat: -38.2318961683058 },
    { lng: 146.656116321273, lat: -38.231834203963 },
    { lng: 146.656168889842, lat: -38.2317638402088 },
    { lng: 146.656263522503, lat: -38.2316931036493 },
    { lng: 146.656323715265, lat: -38.2316443897426 },
    { lng: 146.656302209691, lat: -38.2315701926924 },
    { lng: 146.656247529165, lat: -38.2315089166153 },
    { lng: 146.656165238494, lat: -38.2314456426961 },
    { lng: 146.656093902582, lat: -38.2314326378723 },
    { lng: 146.656043676577, lat: -38.231467462659 },
    { lng: 146.656034513757, lat: -38.231530541121 },
    { lng: 146.655977038743, lat: -38.2315665897482 },
    { lng: 146.655840979508, lat: -38.2315885615837 },
    { lng: 146.655789940027, lat: -38.2315730410117 },
    { lng: 146.655726426272, lat: -38.2315049899587 },
    { lng: 146.655697814552, lat: -38.2314400161156 },
    { lng: 146.65568351464, lat: -38.2313611531336 },
    { lng: 146.655695141601, lat: -38.2312729059265 },
    { lng: 146.655731639718, lat: -38.2311958281389 },
    { lng: 146.655771218529, lat: -38.2311302079132 },
    { lng: 146.655829463448, lat: -38.2310506135486 },
    { lng: 146.655835821395, lat: -38.2309956272493 },
    { lng: 146.655822137411, lat: -38.2309568169758 },
    { lng: 146.655846380502, lat: -38.2309302366848 },
    { lng: 146.655860152967, lat: -38.23088433663 },
    { lng: 146.655850626943, lat: -38.2308329036226 },
    { lng: 146.655806329787, lat: -38.2307864335823 },
    { lng: 146.655730385614, lat: -38.2307562762422 },
    { lng: 146.655643595793, lat: -38.2307743221302 },
    { lng: 146.655564340808, lat: -38.2308094521911 },
    { lng: 146.655516171545, lat: -38.2308808779644 },
    { lng: 146.655465341514, lat: -38.2309695332231 },
    { lng: 146.655386272951, lat: -38.2310172767361 },
    { lng: 146.655308568442, lat: -38.2310580938294 },
    { lng: 146.655230017477, lat: -38.2310474605854 },
    { lng: 146.655177526548, lat: -38.2310319308338 },
    { lng: 146.65513729847, lat: -38.230965936818 },
    { lng: 146.655121876738, lat: -38.2309099897299 },
    { lng: 146.655101768427, lat: -38.2308323766745 },
    { lng: 146.655125638871, lat: -38.2307829226764 },
    { lng: 146.655152368538, lat: -38.2307299598616 },
    { lng: 146.655134285401, lat: -38.2306877907047 },
    { lng: 146.655080319397, lat: -38.2306723297943 },
    { lng: 146.655004749397, lat: -38.2306650368251 },
    { lng: 146.65496046318, lat: -38.2306185681305 },
    { lng: 146.654944997164, lat: -38.2305592033788 },
    { lng: 146.654980813345, lat: -38.2305290544164 },
    { lng: 146.655047175974, lat: -38.2305055167039 },
    { lng: 146.655142974784, lat: -38.2305056876123 },
    { lng: 146.655280507929, lat: -38.2304848540428 },
    { lng: 146.655325036011, lat: -38.2304569568036 },
    { lng: 146.655318425284, lat: -38.2304066631155 },
    { lng: 146.655255339839, lat: -38.2303637796298 },
    { lng: 146.65515605448, lat: -38.2303258532663 },
    { lng: 146.655041852009, lat: -38.2302640316333 },
    { lng: 146.654975830349, lat: -38.2302188719033 },
    { lng: 146.654898093152, lat: -38.2301681607125 },
    { lng: 146.654801326394, lat: -38.2301073097382 },
    { lng: 146.654728351592, lat: -38.2300805599274 },
    { lng: 146.654660482564, lat: -38.2301006710569 },
    { lng: 146.654585605259, lat: -38.2301380541318 },
    { lng: 146.654557225101, lat: -38.2301784287325 },
    { lng: 146.654514666411, lat: -38.2302406576861 },
    { lng: 146.654458257846, lat: -38.230252676821 },
    { lng: 146.654382116208, lat: -38.2302099304354 },
    { lng: 146.654327269694, lat: -38.2301383595868 },
    { lng: 146.654287318362, lat: -38.2299990711121 },
    { lng: 146.654280288794, lat: -38.2299236156975 },
    { lng: 146.654258861575, lat: -38.229854001257 },
    { lng: 146.654234133114, lat: -38.2297592259117 },
    { lng: 146.654241635344, lat: -38.2296836010157 },
    { lng: 146.654245177145, lat: -38.2296343438397 },
    { lng: 146.654295358935, lat: -38.2295948867307 },
    { lng: 146.654313355037, lat: -38.2295409121875 },
    { lng: 146.654312816085, lat: -38.2295074813491 },
    { lng: 146.654312309864, lat: -38.2294756916104 },
    { lng: 146.654302509403, lat: -38.2294070800128 },
    { lng: 146.654292785261, lat: -38.2293442127877 },
    { lng: 146.654311155429, lat: -38.2293131292061 },
    { lng: 146.654362062701, lat: -38.2293206597934 },
    { lng: 146.654420780763, lat: -38.2293624287746 },
    { lng: 146.654470973541, lat: -38.2294145603259 },
    { lng: 146.65452129873, lat: -38.2294770519812 },
    { lng: 146.654570204468, lat: -38.22953950091 },
    { lng: 146.654615546803, lat: -38.2295619410961 },
    { lng: 146.654705845933, lat: -38.2295816520809 },
    { lng: 146.654791569103, lat: -38.2295876617813 },
    { lng: 146.654853741198, lat: -38.2295744786872 },
    { lng: 146.654905132527, lat: -38.2295213029319 },
    { lng: 146.654908312385, lat: -38.2294468337185 },
    { lng: 146.65489569536, lat: -38.2293840017033 },
    { lng: 146.654908047583, lat: -38.2293403970241 },
    { lng: 146.654934964494, lat: -38.2292988999647 },
    { lng: 146.654970539553, lat: -38.2292550398676 },
    { lng: 146.654986829772, lat: -38.2291850506083 },
    { lng: 146.654971122448, lat: -38.2291096466287 },
    { lng: 146.655008301537, lat: -38.2290772625713 },
    { lng: 146.655070814461, lat: -38.2290835011864 },
    { lng: 146.655134923048, lat: -38.2290988608288 },
    { lng: 146.655219545762, lat: -38.2291266308903 },
    { lng: 146.655302255251, lat: -38.2291269865893 },
    { lng: 146.655368342609, lat: -38.2290851169963 },
    { lng: 146.655398031758, lat: -38.229035561446 },
    { lng: 146.655391520547, lat: -38.2289921614085 },
    { lng: 146.655362655651, lat: -38.2289111393764 },
    { lng: 146.655344011089, lat: -38.2288346296993 },
    { lng: 146.655339699548, lat: -38.228747700209 },
    { lng: 146.65533396852, lat: -38.2286607289208 },
    { lng: 146.65529745953, lat: -38.228647383757 },
    { lng: 146.65518248753, lat: -38.2287160381763 },
    { lng: 146.655133393841, lat: -38.22873257925 },
    { lng: 146.655076634255, lat: -38.228721674072 },
    { lng: 146.655000557812, lat: -38.2286846815975 },
    { lng: 146.654940783455, lat: -38.2286669586901 },
    { lng: 146.654876973333, lat: -38.2286698906573 },
    { lng: 146.654810818671, lat: -38.2287071470965 },
    { lng: 146.654757568239, lat: -38.2287351800067 },
    { lng: 146.65473099226, lat: -38.2287068326201 },
    { lng: 146.654727009519, lat: -38.2286392998851 },
    { lng: 146.654746314846, lat: -38.2285749975988 },
    { lng: 146.654765762639, lat: -38.2285209967374 },
    { lng: 146.654772362439, lat: -38.2284966019266 },
    { lng: 146.654789028151, lat: -38.2284349432032 },
    { lng: 146.654834853749, lat: -38.228395536555 },
    { lng: 146.654913735064, lat: -38.2283375411361 },
    { lng: 146.654982352529, lat: -38.2282727463252 },
    { lng: 146.65502309701, lat: -38.2281887486625 },
    { lng: 146.655033436668, lat: -38.228109656569 },
    { lng: 146.655034965573, lat: -38.228024963308 },
    { lng: 146.65501215227, lat: -38.2279587987956 },
    { lng: 146.654987731632, lat: -38.2278834457983 },
    { lng: 146.654939914879, lat: -38.2277980814342 },
    { lng: 146.65494604151, lat: -38.2277270377324 },
    { lng: 146.655012415547, lat: -38.2277046476369 },
    { lng: 146.655099876918, lat: -38.2277277938595 },
    { lng: 146.655191947534, lat: -38.2277692573019 },
    { lng: 146.655249894614, lat: -38.2278533897512 },
    { lng: 146.65529519307, lat: -38.2279628360852 },
    { lng: 146.655339160193, lat: -38.2279898663478 },
    { lng: 146.655425917025, lat: -38.227969534422 },
    { lng: 146.655501102486, lat: -38.2279527722165 },
    { lng: 146.65557165596, lat: -38.2279177011164 },
    { lng: 146.655613995064, lat: -38.227842866646 },
    { lng: 146.655591906574, lat: -38.227642794161 },
    { lng: 146.655570523265, lat: -38.2275765957009 },
    { lng: 146.655567816745, lat: -38.2274976140386 },
    { lng: 146.655594019414, lat: -38.2274114920583 },
    { lng: 146.655674528087, lat: -38.2273649042569 },
    { lng: 146.655769886683, lat: -38.2273387662782 },
    { lng: 146.655859537419, lat: -38.2273184078283 },
    { lng: 146.655905670752, lat: -38.2272996411366 },
    { lng: 146.655919464782, lat: -38.2272548542666 },
    { lng: 146.655918475316, lat: -38.2271930501853 },
    { lng: 146.655916780783, lat: -38.2271690375916 },
    { lng: 146.655914701798, lat: -38.2271392965551 },
    { lng: 146.655860108527, lat: -38.2270825931864 },
    { lng: 146.655798333454, lat: -38.2270305481028 },
    { lng: 146.655800203107, lat: -38.2269664235488 },
    { lng: 146.655832895262, lat: -38.2269249091384 },
    { lng: 146.655866984506, lat: -38.2268776066905 },
    { lng: 146.655930035476, lat: -38.2268289265601 },
    { lng: 146.655990326736, lat: -38.2267882640825 },
    { lng: 146.65607257423, lat: -38.2267576378865 },
    { lng: 146.656161036064, lat: -38.2267556490004 },
    { lng: 146.656228784746, lat: -38.2268167638865 },
    { lng: 146.656278867803, lat: -38.2268632156955 },
    { lng: 146.656335803948, lat: -38.2268844068599 },
    { lng: 146.656414431805, lat: -38.2269007864301 },
    { lng: 146.656489726587, lat: -38.2268897107519 },
    { lng: 146.656542702694, lat: -38.226845706739 },
    { lng: 146.656518580087, lat: -38.2267886799628 },
    { lng: 146.656452084912, lat: -38.2267149679013 },
    { lng: 146.656393872661, lat: -38.2266582901109 },
    { lng: 146.656377725019, lat: -38.2266437377895 },
    { lng: 146.656362544762, lat: -38.2266292884115 },
    { lng: 146.656335155437, lat: -38.2266147534428 },
    { lng: 146.656307831201, lat: -38.2266081397307 },
    { lng: 146.656251752568, lat: -38.2264863687339 },
    { lng: 146.656231634112, lat: -38.2264213440567 },
    { lng: 146.656248673448, lat: -38.2263284390606 },
    { lng: 146.656255988463, lat: -38.2262573789057 },
    { lng: 146.656218830388, lat: -38.2262120227413 },
    { lng: 146.656127100451, lat: -38.2261959922464 },
    { lng: 146.655962122634, lat: -38.2262185676209 },
    { lng: 146.65587947958, lat: -38.2262218567973 },
    { lng: 146.655809564164, lat: -38.2262078331781 },
    { lng: 146.655784473315, lat: -38.2261875598537 },
    { lng: 146.655808530526, lat: -38.226156348384 },
    { lng: 146.65587260546, lat: -38.2260925982325 },
    { lng: 146.655913634946, lat: -38.2260383000415 },
    { lng: 146.655921279451, lat: -38.2260271232182 },
    { lng: 146.655978513462, lat: -38.2259436700414 },
    { lng: 146.655989425041, lat: -38.2258336542923 },
    { lng: 146.655868226566, lat: -38.225795047166 },
    { lng: 146.655673427507, lat: -38.2257768060544 },
    { lng: 146.655557817717, lat: -38.2257266986412 },
    { lng: 146.65551026523, lat: -38.2256688813048 },
    { lng: 146.655472128524, lat: -38.2256475886127 },
    { lng: 146.655292035455, lat: -38.2256394800736 },
    { lng: 146.655241974933, lat: -38.2256023272644 },
    { lng: 146.655206049374, lat: -38.2255466766045 },
    { lng: 146.655135890748, lat: -38.2255200298544 },
    { lng: 146.655077140471, lat: -38.2254841430914 },
    { lng: 146.655032468799, lat: -38.2254251274166 },
    { lng: 146.65500458434, lat: -38.2252606689331 },
    { lng: 146.655009126964, lat: -38.225120928663 },
    { lng: 146.655025813601, lat: -38.2250840991117 },
    { lng: 146.655081012414, lat: -38.2250868611851 },
    { lng: 146.655154425486, lat: -38.2251317916027 },
    { lng: 146.655154448316, lat: -38.2251329310068 },
    { lng: 146.655295116452, lat: -38.22520217207 },
    { lng: 146.655382401065, lat: -38.2252136980928 },
    { lng: 146.655444617189, lat: -38.2252049602563 },
    { lng: 146.655472325947, lat: -38.2251382269543 },
    { lng: 146.655472402617, lat: -38.2250672598058 },
    { lng: 146.655507690559, lat: -38.2250164389174 },
    { lng: 146.655560534577, lat: -38.2249734713974 },
    { lng: 146.655572711571, lat: -38.2249275120165 },
    { lng: 146.655506535826, lat: -38.2248825212974 },
    { lng: 146.655463779436, lat: -38.2248487325622 },
    { lng: 146.655449996041, lat: -38.224811103759 },
    { lng: 146.655501596805, lat: -38.2247784215837 },
    { lng: 146.655571215852, lat: -38.2247764240201 },
    { lng: 146.655673240583, lat: -38.2248004034142 },
    { lng: 146.655760812056, lat: -38.2248256306654 },
    { lng: 146.65583216926, lat: -38.2248396813713 },
    { lng: 146.655878214519, lat: -38.2248184913624 },
    { lng: 146.655870020098, lat: -38.2247716807817 },
    { lng: 146.655806055507, lat: -38.2246900222384 },
    { lng: 146.655754641423, lat: -38.2245842141311 },
    { lng: 146.655711103088, lat: -38.2245091577053 },
    { lng: 146.655676518879, lat: -38.2244466333251 },
    { lng: 146.655609430532, lat: -38.2244302697511 },
    { lng: 146.655549336755, lat: -38.2243989478961 },
    { lng: 146.655532694047, lat: -38.224363671995 },
    { lng: 146.655574142244, lat: -38.2243322822982 },
    { lng: 146.655658160094, lat: -38.2243243597049 },
    { lng: 146.655743564283, lat: -38.224314202563 },
    { lng: 146.655839968458, lat: -38.224271795816 },
    { lng: 146.655937494041, lat: -38.2242133842135 },
    { lng: 146.655993802535, lat: -38.2241989591838 },
    { lng: 146.65602492181, lat: -38.2242329506938 },
    { lng: 146.65603478878, lat: -38.2242911760469 },
    { lng: 146.656092187034, lat: -38.2243328425731 },
    { lng: 146.65617610626, lat: -38.2243215378568 },
    { lng: 146.656253975029, lat: -38.2242953916796 },
    { lng: 146.656340523003, lat: -38.2242691605535 },
    { lng: 146.656384324828, lat: -38.224207953333 },
    { lng: 146.656334912595, lat: -38.2241307379473 },
    { lng: 146.656232195117, lat: -38.2240724707656 },
    { lng: 146.656152511468, lat: -38.2240035785436 },
    { lng: 146.65613154469, lat: -38.2238974139312 },
    { lng: 146.656213384586, lat: -38.2238529065979 },
    { lng: 146.65632602475, lat: -38.2238229107976 },
    { lng: 146.656420944028, lat: -38.2237805392607 },
    { lng: 146.656464845172, lat: -38.2237262320041 },
    { lng: 146.656553889743, lat: -38.2236037886152 },
    { lng: 146.656609418241, lat: -38.2235493216732 },
    { lng: 146.656626325331, lat: -38.2235443829261 },
    { lng: 146.656890853231, lat: -38.2234734589011 },
    { lng: 146.658611759314, lat: -38.2236943309203 },
    { lng: 146.658611366345, lat: -38.2236840887629 },
    { lng: 146.658609835275, lat: -38.2236441403637 },
    { lng: 146.658608409846, lat: -38.2236071132373 },
    { lng: 146.658608414798, lat: -38.2236070789326 },
    { lng: 146.658605411698, lat: -38.2235976438767 },
    { lng: 146.658586046287, lat: -38.2235369869034 },
    { lng: 146.658582339687, lat: -38.2235253811846 },
    { lng: 146.658576220472, lat: -38.223505426963 },
    { lng: 146.658538949853, lat: -38.2233839280511 },
    { lng: 146.658485918994, lat: -38.2232689659977 },
    { lng: 146.658444841666, lat: -38.2231721679315 },
    { lng: 146.658452511442, lat: -38.2231194507396 },
    { lng: 146.658512351832, lat: -38.2230626027377 },
    { lng: 146.658615894819, lat: -38.2230155637318 },
    { lng: 146.658706854417, lat: -38.2229678110612 },
    { lng: 146.658720736214, lat: -38.2229605098304 },
    { lng: 146.658739183125, lat: -38.2229500122908 },
    { lng: 146.658825500424, lat: -38.2229008313463 },
    { lng: 146.658914203934, lat: -38.2228356470443 },
    { lng: 146.659026755708, lat: -38.2227301166663 },
    { lng: 146.659089412185, lat: -38.22268952087 },
    { lng: 146.659104119329, lat: -38.2226799837642 },
    { lng: 146.659176543493, lat: -38.2226733803359 },
    { lng: 146.659178688453, lat: -38.2226786070667 },
    { lng: 146.659188742335, lat: -38.2227029597296 },
    { lng: 146.659185771495, lat: -38.2227739686177 },
    { lng: 146.659237010455, lat: -38.2227974107933 },
    { lng: 146.659364995566, lat: -38.2228130178215 },
    { lng: 146.659502953347, lat: -38.2228170462189 },
    { lng: 146.65957207489, lat: -38.2227910199435 },
    { lng: 146.659558453682, lat: -38.2227119188358 },
    { lng: 146.65955820981, lat: -38.2227104959637 },
    { lng: 146.659557330347, lat: -38.2227053770685 },
    { lng: 146.659485675946, lat: -38.2226020561163 },
    { lng: 146.659419807588, lat: -38.2224986870811 },
    { lng: 146.659332698663, lat: -38.2223486788714 },
    { lng: 146.659356219022, lat: -38.2222156118495 },
    { lng: 146.659356228561, lat: -38.2222155783826 },
    { lng: 146.659469527585, lat: -38.2221478083896 },
    { lng: 146.659553611881, lat: -38.2221445175837 },
    { lng: 146.659593849847, lat: -38.222168207316 },
    { lng: 146.65966223682, lat: -38.2222084470964 },
    { lng: 146.659806465182, lat: -38.2223108291562 },
    { lng: 146.65992278351, lat: -38.222324327441 },
    { lng: 146.660035781835, lat: -38.22231493724 },
    { lng: 146.660099239934, lat: -38.2222946790524 },
    { lng: 146.660121629995, lat: -38.2222532329723 },
    { lng: 146.660132090517, lat: -38.2222430162717 },
    { lng: 146.660185711491, lat: -38.2221906100182 },
    { lng: 146.660325415517, lat: -38.2221374030889 },
    { lng: 146.660336856101, lat: -38.2221164000507 },
    { lng: 146.660343621263, lat: -38.2221039817669 },
    { lng: 146.660307771613, lat: -38.2220517578146 },
    { lng: 146.660163979819, lat: -38.2219801355687 },
    { lng: 146.660159767146, lat: -38.2219780366197 },
    { lng: 146.660155115289, lat: -38.2219753409677 },
    { lng: 146.660032089819, lat: -38.2219040193785 },
    { lng: 146.659932957789, lat: -38.2218068131106 },
    { lng: 146.659914272234, lat: -38.2217406186112 },
    { lng: 146.659979619452, lat: -38.2216700138055 },
    { lng: 146.659979641749, lat: -38.2216699891727 },
    { lng: 146.660125347933, lat: -38.2217014157892 },
    { lng: 146.660332544252, lat: -38.2217607369174 },
    { lng: 146.660536307533, lat: -38.2217925669495 },
    { lng: 146.660613907914, lat: -38.2217538485962 },
    { lng: 146.660643580535, lat: -38.2216857561484 },
    { lng: 146.660650004203, lat: -38.2216710171529 },
    { lng: 146.660727092481, lat: -38.2216059489435 },
    { lng: 146.660852833493, lat: -38.2215803760331 },
    { lng: 146.660917117394, lat: -38.2216036370498 },
    { lng: 146.660929898886, lat: -38.2216641834443 },
    { lng: 146.660910385254, lat: -38.2217777177436 },
    { lng: 146.660912475193, lat: -38.2218841379661 },
    { lng: 146.660932554321, lat: -38.2219231418412 },
    { lng: 146.660933573317, lat: -38.2219251162439 },
    { lng: 146.660947157717, lat: -38.2219276231393 },
    { lng: 146.660974339117, lat: -38.2219326385538 },
    { lng: 146.660977858999, lat: -38.2219319684359 },
    { lng: 146.661116030153, lat: -38.2219056970921 },
    { lng: 146.661244290504, lat: -38.2218617769905 },
    { lng: 146.661318033787, lat: -38.2218738022699 },
    { lng: 146.661322884499, lat: -38.2218745923021 },
    { lng: 146.66132481788, lat: -38.221878664172 },
    { lng: 146.661347128968, lat: -38.2219257792701 },
    { lng: 146.661342476438, lat: -38.2219853637654 },
    { lng: 146.661362408442, lat: -38.2220389228798 },
    { lng: 146.661409488147, lat: -38.2220726879048 },
    { lng: 146.661471879632, lat: -38.2220719189253 },
    { lng: 146.661516275639, lat: -38.2220427668493 },
    { lng: 146.661540673506, lat: -38.2219913993467 },
    { lng: 146.661559462365, lat: -38.2219518396365 },
    { lng: 146.661573389722, lat: -38.2219197780246 },
    { lng: 146.661596971567, lat: -38.2218654972109 },
    { lng: 146.661638068487, lat: -38.2218157746352 },
    { lng: 146.6617088205, lat: -38.2217977522436 },
    { lng: 146.661780309212, lat: -38.2218186476567 },
    { lng: 146.661819112895, lat: -38.2218731297694 },
    { lng: 146.661832790092, lat: -38.2218999559342 },
    { lng: 146.661841040681, lat: -38.2219161218384 },
    { lng: 146.661849290013, lat: -38.2219322823534 },
    { lng: 146.661851336462, lat: -38.2219335146185 },
    { lng: 146.661912441558, lat: -38.2219704036378 },
    { lng: 146.661990090556, lat: -38.2220083675521 },
    { lng: 146.662064698046, lat: -38.22203950886 },
    { lng: 146.662097394732, lat: -38.2220792036745 },
    { lng: 146.662138006497, lat: -38.222151943155 },
    { lng: 146.662179587324, lat: -38.2222006612615 },
    { lng: 146.662242407449, lat: -38.2222228119314 },
    { lng: 146.662307803104, lat: -38.2222277357777 },
    { lng: 146.662375804472, lat: -38.2222079246402 },
    { lng: 146.662385705155, lat: -38.2222050375939 },
    { lng: 146.662469756369, lat: -38.2221994279295 },
    { lng: 146.662618200897, lat: -38.2222216636283 },
    { lng: 146.66262800225, lat: -38.2222199131465 },
    { lng: 146.662707851096, lat: -38.2222056588003 },
    { lng: 146.662742426248, lat: -38.222193795969 },
    { lng: 146.662725682277, lat: -38.2221527886324 },
    { lng: 146.662616803448, lat: -38.2220762707498 },
    { lng: 146.662564982488, lat: -38.2220231115734 },
    { lng: 146.662567897876, lat: -38.2219509815912 },
    { lng: 146.662574245934, lat: -38.2219342361824 },
    { lng: 146.662584458877, lat: -38.2219072827433 },
    { lng: 146.662596894417, lat: -38.2219039093682 },
    { lng: 146.662655182761, lat: -38.2218880988395 },
    { lng: 146.662662497765, lat: -38.2218884106861 },
    { lng: 146.662717640708, lat: -38.221890751064 },
    { lng: 146.662780299191, lat: -38.2219397506402 },
    { lng: 146.662785411668, lat: -38.221943748046 },
    { lng: 146.662829140145, lat: -38.2219793768551 },
    { lng: 146.662853238513, lat: -38.2219990065429 },
    { lng: 146.662954498559, lat: -38.2220561260273 },
    { lng: 146.663072764999, lat: -38.2220947673947 },
    { lng: 146.663206345056, lat: -38.2220977004477 },
    { lng: 146.66327630479, lat: -38.2221140373516 },
    { lng: 146.663301890299, lat: -38.2221606339578 },
    { lng: 146.66329862795, lat: -38.2222167585938 },
    { lng: 146.663304138516, lat: -38.2222750777777 },
    { lng: 146.663338524538, lat: -38.2223273282472 },
    { lng: 146.663424764966, lat: -38.2223594489842 },
    { lng: 146.66346173644, lat: -38.222356312094 },
    { lng: 146.663550802813, lat: -38.2223487522262 },
    { lng: 146.663867536884, lat: -38.2222968376079 },
    { lng: 146.664142603218, lat: -38.2222636882045 },
    { lng: 146.664280081535, lat: -38.2222460080881 },
    { lng: 146.664321138968, lat: -38.2222672316333 },
    { lng: 146.664316251512, lat: -38.2222885420017 },
    { lng: 146.664303752837, lat: -38.2223430191655 },
    { lng: 146.664286219439, lat: -38.2224107721145 },
    { lng: 146.664319991193, lat: -38.2224588223753 },
    { lng: 146.66433248544, lat: -38.2224765963653 },
    { lng: 146.664341284642, lat: -38.2224757488197 },
    { lng: 146.664441110145, lat: -38.2224661073238 },
    { lng: 146.66455954708, lat: -38.222438336559 },
    { lng: 146.664697778545, lat: -38.2223839880133 },
    { lng: 146.664701205179, lat: -38.2223370211767 },
    { lng: 146.664695343507, lat: -38.2223329269722 },
    { lng: 146.664651210046, lat: -38.2223021083645 },
    { lng: 146.664467817881, lat: -38.2222001929581 },
    { lng: 146.664373822723, lat: -38.2221429880365 },
    { lng: 146.664342383918, lat: -38.2220935793843 },
    { lng: 146.66431093594, lat: -38.2220441672458 },
    { lng: 146.664306029356, lat: -38.2220360658699 },
    { lng: 146.664234475491, lat: -38.2219180190533 },
    { lng: 146.664218736795, lat: -38.22185525865 },
    { lng: 146.664249995478, lat: -38.2218216710948 },
    { lng: 146.664329570537, lat: -38.221810399832 },
    { lng: 146.664561527426, lat: -38.2217995537066 },
    { lng: 146.664809862569, lat: -38.2217669643058 },
    { lng: 146.664830707451, lat: -38.2217642278808 },
    { lng: 146.664841641032, lat: -38.2217596172475 },
    { lng: 146.664925704101, lat: -38.2217241278803 },
    { lng: 146.664945394531, lat: -38.2216910601428 },
    { lng: 146.664956581328, lat: -38.221672270213 },
    { lng: 146.664951698942, lat: -38.2216676318387 },
    { lng: 146.664913724756, lat: -38.2216315513841 },
    { lng: 146.664822545436, lat: -38.2215697229326 },
    { lng: 146.664773849364, lat: -38.2215279620194 },
    { lng: 146.66478183542, lat: -38.2214923979398 },
    { lng: 146.664825009641, lat: -38.221473527975 },
    { lng: 146.664899431735, lat: -38.2214943702673 },
    { lng: 146.665064849059, lat: -38.2215690428196 },
    { lng: 146.66522740127, lat: -38.2216448998142 },
    { lng: 146.665339743441, lat: -38.2216755679034 },
    { lng: 146.665418899754, lat: -38.2216544406277 },
    { lng: 146.665420550541, lat: -38.2216539997952 },
    { lng: 146.665435873564, lat: -38.2216217598756 },
    { lng: 146.665402344435, lat: -38.2215397599673 },
    { lng: 146.665363561272, lat: -38.2214860562137 },
    { lng: 146.665344166116, lat: -38.221459196723 },
    { lng: 146.665315632502, lat: -38.2214449687566 },
    { lng: 146.665229864538, lat: -38.2214022132444 },
    { lng: 146.665177857455, lat: -38.2213765191553 },
    { lng: 146.665125849405, lat: -38.2213508313642 },
    { lng: 146.665121954926, lat: -38.2213474812586 },
    { lng: 146.665066823446, lat: -38.221300008848 },
    { lng: 146.665038414033, lat: -38.2212269919879 },
    { lng: 146.665036247537, lat: -38.2212214286881 },
    { lng: 146.665059497341, lat: -38.2211489981615 },
    { lng: 146.665096831451, lat: -38.2210769507888 },
    { lng: 146.66510842506, lat: -38.2210545720818 },
    { lng: 146.665116644411, lat: -38.2210452480827 },
    { lng: 146.665199262992, lat: -38.2209515598163 },
    { lng: 146.66521636744, lat: -38.220910300984 },
    { lng: 146.665228340808, lat: -38.2208814111464 },
    { lng: 146.665200142465, lat: -38.2208485573598 },
    { lng: 146.66516582335, lat: -38.2208008977696 },
    { lng: 146.665165839837, lat: -38.2208008687112 },
    { lng: 146.665185222885, lat: -38.2207536969282 },
    { lng: 146.665226762563, lat: -38.2207257225761 },
    { lng: 146.665374998491, lat: -38.220664383237 },
    { lng: 146.665589806516, lat: -38.2205942368223 },
    { lng: 146.665606721439, lat: -38.22056885286 },
    { lng: 146.665640538511, lat: -38.2205180769945 },
    { lng: 146.665616872628, lat: -38.2204210533788 },
    { lng: 146.665622619882, lat: -38.2203443027138 },
    { lng: 146.665622643218, lat: -38.2203442735607 },
    { lng: 146.665660889411, lat: -38.2202980458853 },
    { lng: 146.665734490374, lat: -38.2202776887979 },
    { lng: 146.665825361858, lat: -38.2203240800813 },
    { lng: 146.665916232193, lat: -38.2203704659066 },
    { lng: 146.665997885325, lat: -38.2204644760703 },
    { lng: 146.666094156684, lat: -38.2205640360981 },
    { lng: 146.666245049928, lat: -38.2206377399533 },
    { lng: 146.666329138904, lat: -38.2206332523533 },
    { lng: 146.666408569584, lat: -38.2205407127212 },
    { lng: 146.666420286383, lat: -38.2205212819376 },
    { lng: 146.666476021669, lat: -38.2204288469616 },
    { lng: 146.666548357765, lat: -38.2203443958777 },
    { lng: 146.666653220908, lat: -38.2203098360653 },
    { lng: 146.666663670737, lat: -38.2203063932865 },
    { lng: 146.666750819505, lat: -38.2203110421688 },
    { lng: 146.666878699339, lat: -38.2202464881789 },
    { lng: 146.666952584901, lat: -38.2201912238667 },
    { lng: 146.666967348403, lat: -38.2201801758715 },
    { lng: 146.666950441482, lat: -38.2201311400853 },
    { lng: 146.666858570184, lat: -38.2200941109786 },
    { lng: 146.666856809364, lat: -38.2200934001145 },
    { lng: 146.666854422535, lat: -38.2200912923904 },
    { lng: 146.666799235332, lat: -38.2200426043002 },
    { lng: 146.666799236876, lat: -38.2200425709431 },
    { lng: 146.666803667091, lat: -38.2199727073524 },
    { lng: 146.666842189651, lat: -38.2199390232261 },
    { lng: 146.666905605236, lat: -38.21991763666 },
    { lng: 146.667011204657, lat: -38.2199014622854 },
    { lng: 146.667151707603, lat: -38.2198150419041 },
    { lng: 146.667176425281, lat: -38.21981820424 },
    { lng: 146.667187304176, lat: -38.2198546692158 },
    { lng: 146.667183431594, lat: -38.2199531839766 },
    { lng: 146.667194178985, lat: -38.2199648453502 },
    { lng: 146.667231424824, lat: -38.2200052472141 },
    { lng: 146.667239785292, lat: -38.220014281911 },
    { lng: 146.667300505321, lat: -38.2199932936713 },
    { lng: 146.667311835356, lat: -38.219989377377 },
    { lng: 146.667343186264, lat: -38.2198974410731 },
    { lng: 146.667363969088, lat: -38.2198364881847 },
    { lng: 146.667368864001, lat: -38.2198086158057 },
    { lng: 146.66738614, lat: -38.2197102879079 },
    { lng: 146.667417324625, lat: -38.2196721307803 },
    { lng: 146.667490464009, lat: -38.2197118860865 },
    { lng: 146.66749357709, lat: -38.2197135783269 },
    { lng: 146.66756882351, lat: -38.2197767296066 },
    { lng: 146.667656542249, lat: -38.2198100008775 },
    { lng: 146.66772754745, lat: -38.2197324562196 },
    { lng: 146.667698221416, lat: -38.2196423729342 },
    { lng: 146.667675550408, lat: -38.219595502637 },
    { lng: 146.667674009834, lat: -38.2195923155862 },
    { lng: 146.667676324052, lat: -38.2195818449969 },
    { lng: 146.667690015011, lat: -38.2195199899821 },
    { lng: 146.667643342462, lat: -38.2194347269438 },
    { lng: 146.667555407841, lat: -38.2193900163642 },
    { lng: 146.667439863454, lat: -38.2194165879764 },
    { lng: 146.66732772966, lat: -38.2193962224191 },
    { lng: 146.667312512348, lat: -38.2193186657257 },
    { lng: 146.667311604018, lat: -38.2193140175746 },
    { lng: 146.667323449479, lat: -38.2192873357253 },
    { lng: 146.667356054533, lat: -38.2192138879135 },
    { lng: 146.667440469422, lat: -38.219153327822 },
    { lng: 146.667443076736, lat: -38.2191497302672 },
    { lng: 146.667508274157, lat: -38.2190597830639 },
    { lng: 146.667508295309, lat: -38.2190597584455 },
    { lng: 146.667481212769, lat: -38.2190108741408 },
    { lng: 146.667402947726, lat: -38.2190152595679 },
    { lng: 146.667288889199, lat: -38.2190441260006 },
    { lng: 146.667235308494, lat: -38.2190493622027 },
    { lng: 146.667215622637, lat: -38.2190072500147 },
    { lng: 146.667233515532, lat: -38.2189589821245 },
    { lng: 146.667280749256, lat: -38.2189263496193 },
    { lng: 146.667419359807, lat: -38.2188914569954 },
    { lng: 146.667439797497, lat: -38.2188782393715 },
    { lng: 146.667516797965, lat: -38.2188284302059 },
    { lng: 146.667622331482, lat: -38.2188023680578 },
    { lng: 146.667633749595, lat: -38.218799546988 },
    { lng: 146.667641790528, lat: -38.2187971275749 },
    { lng: 146.667728938923, lat: -38.2187708770592 },
    { lng: 146.667786045007, lat: -38.2187232761671 },
    { lng: 146.667776761287, lat: -38.2186203671464 },
    { lng: 146.667760325406, lat: -38.2185220987558 },
    { lng: 146.667765913895, lat: -38.2184910651665 },
    { lng: 146.667779939685, lat: -38.2184131367158 },
    { lng: 146.667816701827, lat: -38.2183634384412 },
    { lng: 146.667924402556, lat: -38.2183060293199 },
    { lng: 146.66806291483, lat: -38.2182677018937 },
    { lng: 146.668126802895, lat: -38.2182692071061 },
    { lng: 146.66816836123, lat: -38.2183167811598 },
    { lng: 146.668139695319, lat: -38.218409881649 },
    { lng: 146.668118091563, lat: -38.2184914133708 },
    { lng: 146.668118896637, lat: -38.2184947015839 },
    { lng: 146.668135460373, lat: -38.2185621505698 },
    { lng: 146.668207796403, lat: -38.2186253570355 },
    { lng: 146.668271365557, lat: -38.2186108473809 },
    { lng: 146.66834724856, lat: -38.2185595584119 },
    { lng: 146.668457561484, lat: -38.2184883963684 },
    { lng: 146.668554505533, lat: -38.2183888521105 },
    { lng: 146.668555590843, lat: -38.2183864540423 },
    { lng: 146.668560169919, lat: -38.2183763097909 },
    { lng: 146.668588022158, lat: -38.218314664221 },
    { lng: 146.668594589006, lat: -38.2183001299927 },
    { lng: 146.668603499495, lat: -38.2182359038093 },
    { lng: 146.668463402468, lat: -38.2181208477868 },
    { lng: 146.668199865122, lat: -38.2180004617815 },
    { lng: 146.66812904646, lat: -38.2179406561959 },
    { lng: 146.668105832487, lat: -38.2178676851981 },
    { lng: 146.668112459112, lat: -38.2177611361118 },
    { lng: 146.668107266521, lat: -38.2175723505881 },
    { lng: 146.668117863792, lat: -38.2174463127063 },
    { lng: 146.668117866477, lat: -38.2174462793334 },
    { lng: 146.668121148753, lat: -38.2173924569682 },
    { lng: 146.668156533626, lat: -38.2173473662247 },
    { lng: 146.668253754072, lat: -38.2173461662001 },
    { lng: 146.668414035833, lat: -38.2173887632156 },
    { lng: 146.668418544727, lat: -38.2173899577179 },
    { lng: 146.668429379499, lat: -38.2173906530039 },
    { lng: 146.668508612463, lat: -38.2173957298817 },
    { lng: 146.668577010799, lat: -38.2173319108407 },
    { lng: 146.668587909641, lat: -38.2173058329542 },
    { lng: 146.668592831473, lat: -38.217294054103 },
    { lng: 146.668624123614, lat: -38.2172191761406 },
    { lng: 146.668624136773, lat: -38.2172191516325 },
    { lng: 146.668663360592, lat: -38.2171488733253 },
    { lng: 146.668692126513, lat: -38.2171248259721 },
    { lng: 146.668716172241, lat: -38.2171047225627 },
    { lng: 146.668730604516, lat: -38.2171011118916 },
    { lng: 146.668788364444, lat: -38.2170866696642 },
    { lng: 146.668793008503, lat: -38.2170877289399 },
    { lng: 146.668859787961, lat: -38.2171029416377 },
    { lng: 146.668947465229, lat: -38.2171350773367 },
    { lng: 146.669031080132, lat: -38.2171809683918 },
    { lng: 146.669067786917, lat: -38.2171928678581 },
    { lng: 146.669071955577, lat: -38.2171942202074 },
    { lng: 146.669082405835, lat: -38.2171929791667 },
    { lng: 146.669147240299, lat: -38.2171852610361 },
    { lng: 146.669237802585, lat: -38.2171768121339 },
    { lng: 146.669245711626, lat: -38.2171760729376 },
    { lng: 146.669250375524, lat: -38.2171773049071 },
    { lng: 146.669349273496, lat: -38.2172033918451 },
    { lng: 146.669438124575, lat: -38.2172553863139 },
    { lng: 146.669443194755, lat: -38.2172583524247 },
    { lng: 146.669540542929, lat: -38.2173090841182 },
    { lng: 146.66959127256, lat: -38.2173355238267 },
    { lng: 146.669707161666, lat: -38.2174004981081 },
    { lng: 146.669795305077, lat: -38.2174399038669 },
    { lng: 146.669799374519, lat: -38.2174417233649 },
    { lng: 146.66980224593, lat: -38.2174426152279 },
    { lng: 146.66987677052, lat: -38.2174659216951 },
    { lng: 146.669905271475, lat: -38.2175136840016 },
    { lng: 146.66991603872, lat: -38.2175318841029 },
    { lng: 146.669950295515, lat: -38.2175898188546 },
    { lng: 146.669962295554, lat: -38.2175968479005 },
    { lng: 146.670038382779, lat: -38.217641371168 },
    { lng: 146.670194637625, lat: -38.2176932799225 },
    { lng: 146.67031824473, lat: -38.2177077979679 },
    { lng: 146.670416222158, lat: -38.2176722209915 },
    { lng: 146.670466755941, lat: -38.2176601556562 },
    { lng: 146.670544471077, lat: -38.2176750646634 },
    { lng: 146.670545405794, lat: -38.2176752436281 },
    { lng: 146.670547550633, lat: -38.2176766879162 },
    { lng: 146.670607170808, lat: -38.2177168078439 },
    { lng: 146.670674326348, lat: -38.2177377435961 },
    { lng: 146.670735420049, lat: -38.2177461496918 },
    { lng: 146.670880718827, lat: -38.2176825537554 },
    { lng: 146.670934031341, lat: -38.2175903293166 },
    { lng: 146.670918163686, lat: -38.2174906748961 },
    { lng: 146.670917481186, lat: -38.2174863822228 },
    { lng: 146.670905349191, lat: -38.2174749142237 },
    { lng: 146.670779991671, lat: -38.2173563996917 },
    { lng: 146.670658596096, lat: -38.2172342893618 },
    { lng: 146.670563115751, lat: -38.2171015283301 },
    { lng: 146.670565184029, lat: -38.2170928062463 },
    { lng: 146.670584544775, lat: -38.2170108410398 },
    { lng: 146.670584560077, lat: -38.217010810195 },
    { lng: 146.670642422285, lat: -38.2169276766982 },
    { lng: 146.670762457792, lat: -38.2169080853011 },
    { lng: 146.670771114679, lat: -38.2169066734362 },
    { lng: 146.670781993794, lat: -38.2169061299653 },
    { lng: 146.670953769715, lat: -38.2168975281761 },
    { lng: 146.67115443159, lat: -38.2169202482704 },
    { lng: 146.671353949446, lat: -38.2169578552725 },
    { lng: 146.671458604405, lat: -38.2169980906464 },
    { lng: 146.671469438736, lat: -38.2170022561975 },
    { lng: 146.671540730122, lat: -38.2170860920793 },
    { lng: 146.671542281498, lat: -38.2170889897259 },
    { lng: 146.671601010287, lat: -38.2171986492912 },
    { lng: 146.671676612501, lat: -38.2172801513197 },
    { lng: 146.671779275743, lat: -38.2173152766879 },
    { lng: 146.671783225182, lat: -38.2173166283778 },
    { lng: 146.671861402242, lat: -38.2173076607712 },
    { lng: 146.671939567747, lat: -38.2172986878681 },
    { lng: 146.671997703317, lat: -38.2172292743877 },
    { lng: 146.672011959534, lat: -38.21719308943 },
    { lng: 146.67202533004, lat: -38.2171591322264 },
    { lng: 146.6720347513, lat: -38.217146042131 },
    { lng: 146.672053583536, lat: -38.2171198620803 },
    { lng: 146.672064297566, lat: -38.2171168268997 },
    { lng: 146.672112741191, lat: -38.2171030833932 },
    { lng: 146.672210442443, lat: -38.217128208835 },
    { lng: 146.67230680769, lat: -38.2171590810762 },
    { lng: 146.67241276612, lat: -38.2171611982879 },
    { lng: 146.67246647884, lat: -38.2170884293996 },
    { lng: 146.672507027811, lat: -38.2170428450001 },
    { lng: 146.672507629496, lat: -38.2170421717445 },
    { lng: 146.672539529801, lat: -38.2170316999563 },
    { lng: 146.672634481472, lat: -38.2170005390891 },
    { lng: 146.672715254624, lat: -38.2169755057909 },
    { lng: 146.672836815669, lat: -38.2169602833996 },
    { lng: 146.672886821598, lat: -38.2169610613612 },
    { lng: 146.672923771569, lat: -38.2169624762179 },
    { lng: 146.673052921801, lat: -38.2169793742471 },
    { lng: 146.673155453222, lat: -38.2170001136054 },
    { lng: 146.673221298752, lat: -38.2170333913738 },
    { lng: 146.673312181524, lat: -38.2170791760076 },
    { lng: 146.673345026977, lat: -38.2171268610709 },
    { lng: 146.673338327902, lat: -38.2172276706144 },
    { lng: 146.673337238745, lat: -38.2172293759376 },
    { lng: 146.673290470463, lat: -38.2173026723817 },
    { lng: 146.673290454982, lat: -38.2173026951206 },
    { lng: 146.673239701802, lat: -38.2173777181019 },
    { lng: 146.673198806217, lat: -38.2174365914363 },
    { lng: 146.673195966215, lat: -38.2174754004156 },
    { lng: 146.673195371822, lat: -38.2174835768429 },
    { lng: 146.673234245714, lat: -38.2175426269404 },
    { lng: 146.673239075504, lat: -38.217547199952 },
    { lng: 146.673302280808, lat: -38.2176070081796 },
    { lng: 146.673369755158, lat: -38.2176439635546 },
    { lng: 146.673448240132, lat: -38.2176842709498 },
    { lng: 146.673450351763, lat: -38.2176853498542 },
    { lng: 146.673454554212, lat: -38.2176848311731 },
    { lng: 146.673512612374, lat: -38.2176776921071 },
    { lng: 146.673573078934, lat: -38.2176540480932 },
    { lng: 146.673661067571, lat: -38.2176277797734 },
    { lng: 146.673732337499, lat: -38.2176280173664 },
    { lng: 146.67373650602, lat: -38.2176280262129 },
    { lng: 146.673737363992, lat: -38.2176286044459 },
    { lng: 146.673785037503, lat: -38.2176605978335 },
    { lng: 146.673797599909, lat: -38.2177085208902 },
    { lng: 146.673787432557, lat: -38.2177819440447 },
    { lng: 146.673816152612, lat: -38.2178399311158 },
    { lng: 146.673861978148, lat: -38.2178828725534 },
    { lng: 146.673931942602, lat: -38.2178991798041 },
    { lng: 146.673984786503, lat: -38.2178813345623 },
    { lng: 146.673988141657, lat: -38.2178802041476 },
    { lng: 146.674002606645, lat: -38.217862490427 },
    { lng: 146.674050489527, lat: -38.2178038613507 },
    { lng: 146.674114400174, lat: -38.21773441177 },
    { lng: 146.674161325835, lat: -38.2176868967641 },
    { lng: 146.674256267091, lat: -38.2176445032984 },
    { lng: 146.674351460935, lat: -38.2176147243223 },
    { lng: 146.674455212755, lat: -38.2175790999438 },
    { lng: 146.674563530228, lat: -38.2175537191956 },
    { lng: 146.674657250362, lat: -38.2175504552273 },
    { lng: 146.674663564151, lat: -38.2175502342512 },
    { lng: 146.674663948613, lat: -38.2175503190119 },
    { lng: 146.674669955517, lat: -38.2175516997132 },
    { lng: 146.67471310746, lat: -38.217561844829 },
    { lng: 146.674714581556, lat: -38.2175621928649 },
    { lng: 146.674720994957, lat: -38.2175930169995 },
    { lng: 146.674738066582, lat: -38.2176488770848 },
    { lng: 146.674784900535, lat: -38.2176689105597 },
    { lng: 146.674845863247, lat: -38.2176704501126 },
    { lng: 146.674897270448, lat: -38.2176286036679 },
    { lng: 146.674941210006, lat: -38.2175765672918 },
    { lng: 146.674963237369, lat: -38.2175167777186 },
    { lng: 146.674944184937, lat: -38.2174334529806 },
    { lng: 146.674937734794, lat: -38.2174003192788 },
    { lng: 146.674969066528, lat: -38.2173713263069 },
    { lng: 146.675024408475, lat: -38.2173809227791 },
    { lng: 146.675096028584, lat: -38.2174075188662 },
    { lng: 146.675188538996, lat: -38.2174636361448 },
    { lng: 146.675292763953, lat: -38.2175241432968 },
    { lng: 146.675388838261, lat: -38.2175336506476 },
    { lng: 146.675395954795, lat: -38.2175343473859 },
    { lng: 146.675402995433, lat: -38.2175324611976 },
    { lng: 146.675498452618, lat: -38.217506731111 },
    { lng: 146.675590929473, lat: -38.2174269670559 },
    { lng: 146.675594020806, lat: -38.2174243023046 },
    { lng: 146.675672450616, lat: -38.2172813707776 },
    { lng: 146.675822567512, lat: -38.2172417433604 },
    { lng: 146.675965557094, lat: -38.2172090783414 },
    { lng: 146.676045197441, lat: -38.2170547270558 },
    { lng: 146.676143632558, lat: -38.2169687919222 },
    { lng: 146.676332165494, lat: -38.2168920787289 },
    { lng: 146.676453187123, lat: -38.216816047417 },
    { lng: 146.676469752716, lat: -38.2168056543043 },
    { lng: 146.676476357831, lat: -38.2166991372653 },
    { lng: 146.676476364118, lat: -38.2166991119508 },
    { lng: 146.676370631676, lat: -38.2166351670173 },
    { lng: 146.676253404486, lat: -38.2165554282138 },
    { lng: 146.676250005698, lat: -38.2165531157928 },
    { lng: 146.676246112529, lat: -38.2165417518885 },
    { lng: 146.676203835625, lat: -38.2164185783948 },
    { lng: 146.676223246423, lat: -38.2162993320336 },
    { lng: 146.67622325363, lat: -38.2162992967956 },
    { lng: 146.676315191548, lat: -38.2162139067242 },
    { lng: 146.676324387984, lat: -38.216205363892 },
    { lng: 146.676331449521, lat: -38.2162046251917 },
    { lng: 146.676473525957, lat: -38.2161897780817 },
    { lng: 146.676586407581, lat: -38.2162490565722 },
    { lng: 146.676699553538, lat: -38.2163197573872 },
    { lng: 146.676880635522, lat: -38.216306072381 },
    { lng: 146.677006332677, lat: -38.2162263337661 },
    { lng: 146.677016749521, lat: -38.2162197209139 },
    { lng: 146.677058286556, lat: -38.2161702506171 },
    { lng: 146.67706069499, lat: -38.216167386292 },
    { lng: 146.677174111824, lat: -38.2160323121052 },
    { lng: 146.677325707177, lat: -38.2159216882516 },
    { lng: 146.677507988043, lat: -38.2158880002927 },
    { lng: 146.677517965245, lat: -38.2158861470275 },
    { lng: 146.677523233914, lat: -38.2158833159035 },
    { lng: 146.677644311062, lat: -38.2158181770795 },
    { lng: 146.677627503008, lat: -38.2156950066559 },
    { lng: 146.677627315802, lat: -38.215693629873 },
    { lng: 146.677621750574, lat: -38.2156864562435 },
    { lng: 146.677502278656, lat: -38.2155325972423 },
    { lng: 146.677372555123, lat: -38.2152091134303 },
    { lng: 146.677396892564, lat: -38.2151325628588 },
    { lng: 146.677398494438, lat: -38.2151275383984 },
    { lng: 146.677429811022, lat: -38.2150973889906 },
    { lng: 146.677433209767, lat: -38.2150963263436 },
    { lng: 146.677506250218, lat: -38.2150735298677 },
    { lng: 146.677552771436, lat: -38.2150054192463 },
    { lng: 146.677554473758, lat: -38.2149435943788 },
    { lng: 146.677663924334, lat: -38.214903308246 },
    { lng: 146.677761328749, lat: -38.2149135510985 },
    { lng: 146.677954357395, lat: -38.2149169167492 },
    { lng: 146.67803998053, lat: -38.2148448879784 },
    { lng: 146.678031972739, lat: -38.2147327897105 },
    { lng: 146.67795107859, lat: -38.2146777022764 },
    { lng: 146.677995629013, lat: -38.2145833087093 },
    { lng: 146.677995639885, lat: -38.2145832842319 },
    { lng: 146.677960439441, lat: -38.2144909989821 },
    { lng: 146.67799439705, lat: -38.2143749906638 },
    { lng: 146.678081274644, lat: -38.2142926426804 },
    { lng: 146.678151114559, lat: -38.2142288168231 },
    { lng: 146.678162256758, lat: -38.2142214126284 },
    { lng: 146.678205541444, lat: -38.2141926407919 },
    { lng: 146.678263313627, lat: -38.2141416702002 },
    { lng: 146.678298250885, lat: -38.2140868006768 },
    { lng: 146.678304674367, lat: -38.2140177113235 },
    { lng: 146.67830568585, lat: -38.2140067117078 },
    { lng: 146.678305565112, lat: -38.2139999290718 },
    { lng: 146.678304409721, lat: -38.2139380060547 },
    { lng: 146.678289177363, lat: -38.2138963128556 },
    { lng: 146.678288526536, lat: -38.2138945280744 },
    { lng: 146.67828853692, lat: -38.2138927953617 },
    { lng: 146.678288547827, lat: -38.2138910860669 },
    { lng: 146.678329258935, lat: -38.2138516617511 },
    { lng: 146.67836996988, lat: -38.2138122320165 },
    { lng: 146.678376856039, lat: -38.2138117812399 },
    { lng: 146.67849040913, lat: -38.2138043686475 },
    { lng: 146.67862975664, lat: -38.2137622177908 },
    { lng: 146.67867127251, lat: -38.2136626062556 },
    { lng: 146.678677904315, lat: -38.2136466893157 },
    { lng: 146.678799838773, lat: -38.2136034029452 },
    { lng: 146.678855036696, lat: -38.2135771301632 },
    { lng: 146.678873441448, lat: -38.2135046422455 },
    { lng: 146.678893085896, lat: -38.2134272826341 },
    { lng: 146.6790701679, lat: -38.2133851782656 },
    { lng: 146.679144997515, lat: -38.2133371322357 },
    { lng: 146.679173322288, lat: -38.2133189510318 },
    { lng: 146.67920116459, lat: -38.2132010992983 },
    { lng: 146.679201177766, lat: -38.2132010756896 },
    { lng: 146.679234624435, lat: -38.2131633495719 },
    { lng: 146.679421635661, lat: -38.2132105495758 },
    { lng: 146.679765573341, lat: -38.2131572468671 },
    { lng: 146.679844255836, lat: -38.2129994110315 },
    { lng: 146.679947128221, lat: -38.212881116238 },
    { lng: 146.67996351808, lat: -38.2128622631195 },
    { lng: 146.680215956489, lat: -38.2128189221095 },
    { lng: 146.680320512353, lat: -38.2128009700588 },
    { lng: 146.680932079309, lat: -38.2124298599382 },
    { lng: 146.680917322169, lat: -38.2121235753234 },
    { lng: 146.68091685549, lat: -38.2121139486236 },
    { lng: 146.680934479517, lat: -38.2120189783901 },
    { lng: 146.680934488085, lat: -38.2120189530436 },
    { lng: 146.680951593325, lat: -38.2119776429692 },
    { lng: 146.680966695573, lat: -38.2119411269458 },
    { lng: 146.680969511975, lat: -38.21193436014 },
    { lng: 146.680970964061, lat: -38.2119308711623 },
    { lng: 146.681161341205, lat: -38.2118571803749 },
    { lng: 146.681177114785, lat: -38.2118510691133 },
    { lng: 146.681184485346, lat: -38.2118449235804 },
    { lng: 146.681271571598, lat: -38.2117722322256 },
    { lng: 146.68127160526, lat: -38.2117722056282 },
    { lng: 146.681050064637, lat: -38.2116002093044 },
    { lng: 146.680848764826, lat: -38.2114488493729 },
    { lng: 146.68078966688, lat: -38.2112896787933 },
    { lng: 146.680949404282, lat: -38.2112120733612 },
    { lng: 146.681055875409, lat: -38.2112526563035 },
    { lng: 146.681060977535, lat: -38.2112545979858 },
    { lng: 146.681066557282, lat: -38.2112801231966 },
    { lng: 146.681094020876, lat: -38.2114057028884 },
    { lng: 146.681289061855, lat: -38.2114417106446 },
    { lng: 146.681294100322, lat: -38.2114426441141 },
    { lng: 146.681528003968, lat: -38.2112941581772 },
    { lng: 146.681583664366, lat: -38.2110561921202 },
    { lng: 146.681532000129, lat: -38.210814600789 },
    { lng: 146.681648570502, lat: -38.2105847016999 },
    { lng: 146.681738674544, lat: -38.2105138689219 },
    { lng: 146.6818822483, lat: -38.2105301013822 },
    { lng: 146.681986912889, lat: -38.2104283899693 },
    { lng: 146.682078730331, lat: -38.2102362556372 },
    { lng: 146.682226742014, lat: -38.2102124081468 },
    { lng: 146.682366093871, lat: -38.210165689277 },
    { lng: 146.68229702531, lat: -38.2100452272333 },
    { lng: 146.682293857256, lat: -38.2100397015878 },
    { lng: 146.682131584731, lat: -38.2099616252967 },
    { lng: 146.681954804365, lat: -38.2100525620598 },
    { lng: 146.681934068978, lat: -38.2100632303611 },
    { lng: 146.681684468438, lat: -38.210109800745 },
    { lng: 146.68152384556, lat: -38.2099390111492 },
    { lng: 146.681451950304, lat: -38.2096653872908 },
    { lng: 146.681452964365, lat: -38.2096476401842 },
    { lng: 146.681454776799, lat: -38.2096159772159 },
    { lng: 146.681462521457, lat: -38.2094811357908 },
    { lng: 146.681584532821, lat: -38.2094114620904 },
    { lng: 146.681665999251, lat: -38.2093062973405 },
    { lng: 146.681744429028, lat: -38.2092651773759 },
    { lng: 146.681885152533, lat: -38.2092608082189 },
    { lng: 146.681992643463, lat: -38.2091831493617 },
    { lng: 146.68199266591, lat: -38.2091831319303 },
    { lng: 146.681919490561, lat: -38.2090819527793 },
    { lng: 146.681916523429, lat: -38.2090778496624 },
    { lng: 146.681907339295, lat: -38.2090651523293 },
    { lng: 146.681892951405, lat: -38.209061113829 },
    { lng: 146.681739192734, lat: -38.2090179599126 },
    { lng: 146.68164790989, lat: -38.2089754756243 },
    { lng: 146.681658914505, lat: -38.2086035512755 },
    { lng: 146.68168684646, lat: -38.2084376303357 },
    { lng: 146.68168685028, lat: -38.2084375969464 },
    { lng: 146.681676866485, lat: -38.2083643427089 },
    { lng: 146.681478228674, lat: -38.2083274280246 },
    { lng: 146.681308366977, lat: -38.208387880357 },
    { lng: 146.68125160734, lat: -38.2084713502169 },
    { lng: 146.681239429693, lat: -38.2084710613275 },
    { lng: 146.681045664462, lat: -38.2084664536522 },
    { lng: 146.680892247697, lat: -38.2083071672752 },
    { lng: 146.680994206958, lat: -38.2081276167117 },
    { lng: 146.680994217849, lat: -38.2081275931346 },
    { lng: 146.680894536351, lat: -38.207942064002 },
    { lng: 146.680688484497, lat: -38.2079886689882 },
    { lng: 146.680479395523, lat: -38.2080662229622 },
    { lng: 146.68035329211, lat: -38.2080397032801 },
    { lng: 146.680245106728, lat: -38.2077762883742 },
    { lng: 146.680213745906, lat: -38.2075404814248 },
    { lng: 146.680016945198, lat: -38.2073284820331 },
    { lng: 146.679956048641, lat: -38.2073196283609 },
    { lng: 146.679476086326, lat: -38.2072498696753 },
    { lng: 146.679446286945, lat: -38.2072368753124 },
    { lng: 146.679057326507, lat: -38.2070672606921 },
    { lng: 146.678744333359, lat: -38.2069374829859 },
    { lng: 146.678609571448, lat: -38.2068766223954 },
    { lng: 146.678614445316, lat: -38.2066477510555 },
    { lng: 146.678645345902, lat: -38.2065932409541 },
    { lng: 146.678921769147, lat: -38.2061056471137 },
    { lng: 146.679318577331, lat: -38.2056367531725 },
    { lng: 146.679542822532, lat: -38.2053717744444 },
    { lng: 146.679542844798, lat: -38.2053717489071 },
    { lng: 146.679640822392, lat: -38.2050159642455 },
    { lng: 146.679568706547, lat: -38.2048464396597 },
    { lng: 146.679460026546, lat: -38.2046142953628 },
    { lng: 146.679456121053, lat: -38.2046059490317 },
    { lng: 146.679519999521, lat: -38.204521535603 },
    { lng: 146.679583874623, lat: -38.2044371311913 },
    { lng: 146.679588517176, lat: -38.2044310020814 },
    { lng: 146.679824721144, lat: -38.2041560958124 },
    { lng: 146.679854673178, lat: -38.2041212370965 },
    { lng: 146.679771579253, lat: -38.2036804443932 },
    { lng: 146.679697090705, lat: -38.2032786043879 },
    { lng: 146.679812948134, lat: -38.2030754038225 },
    { lng: 146.679852912956, lat: -38.2030053062866 },
    { lng: 146.679804364503, lat: -38.2026698800035 },
    { lng: 146.680039792364, lat: -38.2024756203502 },
    { lng: 146.680151101838, lat: -38.2023203849529 },
    { lng: 146.680156194103, lat: -38.2023132873041 },
    { lng: 146.680249498797, lat: -38.2021005408928 },
    { lng: 146.680249517455, lat: -38.2021005072967 },
    { lng: 146.680613430798, lat: -38.2021548474065 },
    { lng: 146.680711356378, lat: -38.2018345036201 },
    { lng: 146.68067579019, lat: -38.2015288820012 },
    { lng: 146.680694720359, lat: -38.2015188174162 },
    { lng: 146.681075155674, lat: -38.2013165255792 },
    { lng: 146.681357239634, lat: -38.2012823807576 },
    { lng: 146.68137401459, lat: -38.2012803503927 },
    { lng: 146.681408863086, lat: -38.2012854381077 },
    { lng: 146.681856837534, lat: -38.2013508316979 },
    { lng: 146.68230839861, lat: -38.2011317356461 },
    { lng: 146.682313964511, lat: -38.2011318301099 },
    { lng: 146.683055310492, lat: -38.2011465261719 },
    { lng: 146.683662939552, lat: -38.2010291752363 },
    { lng: 146.683808391853, lat: -38.2010010833024 },
    { lng: 146.683845219936, lat: -38.2009556329124 },
    { lng: 146.68407891556, lat: -38.2006672368594 },
    { lng: 146.684139240945, lat: -38.2002885024085 },
    { lng: 146.684139249309, lat: -38.2002884680545 },
    { lng: 146.684260639393, lat: -38.199839994704 },
    { lng: 146.684291995658, lat: -38.1993312323546 },
    { lng: 146.684292238322, lat: -38.1993272880814 },
    { lng: 146.684645227839, lat: -38.1990862615529 },
    { lng: 146.684818751848, lat: -38.1989924858561 },
    { lng: 146.684854315878, lat: -38.1989732631487 },
    { lng: 146.685004685722, lat: -38.1989316304417 },
    { lng: 146.685121176372, lat: -38.1988392771543 },
    { lng: 146.685134694914, lat: -38.1988316669283 },
    { lng: 146.685274108924, lat: -38.1987531638562 },
    { lng: 146.68538849805, lat: -38.198669505281 },
    { lng: 146.685402170649, lat: -38.1986595025708 },
    { lng: 146.685425831769, lat: -38.1986276016242 },
    { lng: 146.685738286484, lat: -38.1982063509586 },
    { lng: 146.685873913166, lat: -38.1979706177032 },
    { lng: 146.685900504061, lat: -38.1979243960571 },
    { lng: 146.685900514929, lat: -38.1979243715787 },
    { lng: 146.685899843465, lat: -38.1979185660348 },
    { lng: 146.68586303798, lat: -38.1976056391836 },
    { lng: 146.685693572288, lat: -38.1973405577343 },
    { lng: 146.68556862341, lat: -38.1971686280517 },
    { lng: 146.685575402679, lat: -38.1969327357918 },
    { lng: 146.685575410122, lat: -38.1969327113613 },
    { lng: 146.685505933449, lat: -38.1967735986042 },
    { lng: 146.685524208848, lat: -38.1965878906835 },
    { lng: 146.68552421609, lat: -38.196587857246 },
    { lng: 146.68568770555, lat: -38.1964626778447 },
    { lng: 146.686157694781, lat: -38.1961916716577 },
    { lng: 146.686236817688, lat: -38.1962110358704 },
    { lng: 146.686262822002, lat: -38.1962174017664 },
    { lng: 146.686432353845, lat: -38.1964835709299 },
    { lng: 146.68652200963, lat: -38.1965278719169 },
    { lng: 146.68669076626, lat: -38.1963293721929 },
    { lng: 146.686686532516, lat: -38.1963124740223 },
    { lng: 146.686635634514, lat: -38.1961093575862 },
    { lng: 146.686539174615, lat: -38.1959724489176 },
    { lng: 146.686539185441, lat: -38.1959724226378 },
    { lng: 146.686562450457, lat: -38.1959205476738 },
    { lng: 146.686580656198, lat: -38.1959213337458 },
    { lng: 146.686831099491, lat: -38.1959321104013 },
    { lng: 146.686945734554, lat: -38.1958798540223 },
    { lng: 146.68685683352, lat: -38.195754287746 },
    { lng: 146.686804109694, lat: -38.1956258017552 },
    { lng: 146.686849365756, lat: -38.1954728182323 },
    { lng: 146.686849375462, lat: -38.1954727928691 },
    { lng: 146.686768194552, lat: -38.1953104431709 },
    { lng: 146.686743772226, lat: -38.1952101359959 },
    { lng: 146.686743775043, lat: -38.1952101089271 },
    { lng: 146.686858769225, lat: -38.1951166429601 },
    { lng: 146.687183587591, lat: -38.1950586077128 },
    { lng: 146.687558509984, lat: -38.1949028528201 },
    { lng: 146.687602489592, lat: -38.1948845764429 },
    { lng: 146.687644542309, lat: -38.1948630591465 },
    { lng: 146.688053258949, lat: -38.194653886297 },
    { lng: 146.688157736638, lat: -38.1945238700876 },
    { lng: 146.688164171473, lat: -38.1945158630918 },
    { lng: 146.688174108563, lat: -38.1944356491809 },
    { lng: 146.688202400415, lat: -38.1942073126861 },
    { lng: 146.688202406514, lat: -38.1942072792644 },
    { lng: 146.688286470097, lat: -38.1938773943723 },
    { lng: 146.688565990793, lat: -38.1936438308978 },
    { lng: 146.688691698666, lat: -38.1936033106931 },
    { lng: 146.688820542511, lat: -38.1935617751862 },
    { lng: 146.688933292291, lat: -38.1934923533202 },
    { lng: 146.689221634842, lat: -38.1934818472836 },
    { lng: 146.689442834008, lat: -38.1933495607984 },
    { lng: 146.689497184901, lat: -38.1933170569572 },
    { lng: 146.689609703523, lat: -38.1930210105869 },
    { lng: 146.689550007162, lat: -38.1929029286929 },
    { lng: 146.689297657198, lat: -38.1928480579325 },
    { lng: 146.689285951839, lat: -38.1928455108141 },
    { lng: 146.689283279114, lat: -38.1928441328019 },
    { lng: 146.689197734543, lat: -38.1928000240121 },
    { lng: 146.68922249001, lat: -38.1927061101614 },
    { lng: 146.689231974872, lat: -38.1926701217785 },
    { lng: 146.689238537269, lat: -38.1925600125524 },
    { lng: 146.689242006883, lat: -38.1925016856278 },
    { lng: 146.689314650399, lat: -38.1924665290346 },
    { lng: 146.689410978216, lat: -38.1924199072018 },
    { lng: 146.68944800275, lat: -38.1924009914146 },
    { lng: 146.689665682948, lat: -38.1922897547191 },
    { lng: 146.689665715701, lat: -38.1922897389435 },
    { lng: 146.68953501367, lat: -38.1921202063157 },
    { lng: 146.68927993554, lat: -38.1919630318761 },
    { lng: 146.689255405375, lat: -38.1918592991652 },
    { lng: 146.689454549217, lat: -38.1917576160627 },
    { lng: 146.689498622246, lat: -38.1917451183371 },
    { lng: 146.689546200998, lat: -38.1917351005496 },
    { lng: 146.689554627519, lat: -38.1916765834181 },
    { lng: 146.689592709184, lat: -38.1916392591719 },
    { lng: 146.689632232071, lat: -38.1916268818974 },
    { lng: 146.689670335636, lat: -38.1916149488567 },
    { lng: 146.689764704647, lat: -38.1916178298929 },
    { lng: 146.689804752028, lat: -38.1915976093527 },
    { lng: 146.689833498693, lat: -38.1915387210097 },
    { lng: 146.68984925554, lat: -38.1914453997342 },
    { lng: 146.689859484056, lat: -38.1913848373146 },
    { lng: 146.689859491457, lat: -38.1913848110824 },
    { lng: 146.689958601779, lat: -38.1911517784325 },
    { lng: 146.690018100909, lat: -38.191102589805 },
    { lng: 146.690040903907, lat: -38.1910741956076 },
    { lng: 146.690040926165, lat: -38.1910741700681 },
    { lng: 146.690090899109, lat: -38.1910119677162 },
    { lng: 146.690189646338, lat: -38.19101590338 },
    { lng: 146.69028379554, lat: -38.1910611567847 },
    { lng: 146.690595171699, lat: -38.1912924713442 },
    { lng: 146.690849745714, lat: -38.1913603202166 },
    { lng: 146.690854254706, lat: -38.1913615210869 },
    { lng: 146.690857016364, lat: -38.191362387861 },
    { lng: 146.690995407348, lat: -38.1914057672142 },
    { lng: 146.691277623021, lat: -38.1913278321552 },
    { lng: 146.69132077626, lat: -38.1912513794403 },
    { lng: 146.691361245367, lat: -38.1909819283518 },
    { lng: 146.691438168208, lat: -38.1907665981151 },
    { lng: 146.691462135522, lat: -38.1907225083659 },
    { lng: 146.691554319783, lat: -38.1905529088229 },
    { lng: 146.691554342101, lat: -38.1905528859853 },
    { lng: 146.691984021455, lat: -38.190240127612 },
    { lng: 146.692599075247, lat: -38.1898086913937 },
    { lng: 146.693205395109, lat: -38.1895113519589 },
    { lng: 146.693260042566, lat: -38.1894845516781 },
    { lng: 146.693302008332, lat: -38.1894639389937 },
    { lng: 146.693624761508, lat: -38.1891666994096 },
    { lng: 146.693565028674, lat: -38.1887750478756 },
    { lng: 146.693438129129, lat: -38.1885837051046 },
    { lng: 146.693687514058, lat: -38.1884731111192 },
    { lng: 146.693818842491, lat: -38.1884475501412 },
    { lng: 146.69382083374, lat: -38.1884471608804 },
    { lng: 146.693832351393, lat: -38.1884379117755 },
    { lng: 146.693850676291, lat: -38.1884231980879 },
    { lng: 146.693935861034, lat: -38.1883547908039 },
    { lng: 146.694073977229, lat: -38.1882664156299 },
    { lng: 146.694306379787, lat: -38.1881282489994 },
    { lng: 146.694412007016, lat: -38.1880198612042 },
    { lng: 146.694433698434, lat: -38.1880108790546 },
    { lng: 146.694437405807, lat: -38.1880093421426 },
    { lng: 146.694813397529, lat: -38.1878745503917 },
    { lng: 146.694818369283, lat: -38.1878719307335 },
    { lng: 146.69487962424, lat: -38.1878396489169 },
    { lng: 146.695070791714, lat: -38.1877296952867 },
    { lng: 146.695166331701, lat: -38.1876086103908 },
    { lng: 146.695172395222, lat: -38.1875957410594 },
    { lng: 146.695172415011, lat: -38.1875957074447 },
    { lng: 146.695228261648, lat: -38.1870259183911 },
    { lng: 146.694781596454, lat: -38.1864289289251 },
    { lng: 146.694403460183, lat: -38.185844562741 },
    { lng: 146.694266510653, lat: -38.1855492219903 },
    { lng: 146.694309046923, lat: -38.1854592572238 },
    { lng: 146.694344599209, lat: -38.1854578720841 },
    { lng: 146.694612955313, lat: -38.1854473568561 },
    { lng: 146.694664699527, lat: -38.1854567776042 },
    { lng: 146.694844164334, lat: -38.1855005682838 },
    { lng: 146.69486078502, lat: -38.1855046216113 },
    { lng: 146.694885908837, lat: -38.185499360855 },
    { lng: 146.695017017903, lat: -38.1854718626097 },
    { lng: 146.695051636604, lat: -38.1853283576995 },
    { lng: 146.695051646305, lat: -38.1853283323355 },
    { lng: 146.694975437831, lat: -38.1851553568159 },
    { lng: 146.694888214603, lat: -38.1850111460176 },
    { lng: 146.694896270483, lat: -38.1849126331641 },
    { lng: 146.694999791532, lat: -38.1848711680773 },
    { lng: 146.695268840974, lat: -38.1849065019228 },
    { lng: 146.695478049681, lat: -38.184924309807 },
    { lng: 146.695552673913, lat: -38.1848708961301 },
    { lng: 146.695570867602, lat: -38.1848578571165 },
    { lng: 146.695610707512, lat: -38.1847841087907 },
    { lng: 146.695640629605, lat: -38.1847287079697 },
    { lng: 146.695802989755, lat: -38.1847265656499 },
    { lng: 146.696069091103, lat: -38.1846920900201 },
    { lng: 146.696265595287, lat: -38.1845910672075 },
    { lng: 146.696383690748, lat: -38.1844853284059 },
    { lng: 146.696405405247, lat: -38.1844183732177 },
    { lng: 146.696406813207, lat: -38.1844140466862 },
    { lng: 146.696409276672, lat: -38.184359247228 },
    { lng: 146.696417615055, lat: -38.1841735388322 },
    { lng: 146.696378754508, lat: -38.183848992391 },
    { lng: 146.696378756018, lat: -38.1838489581322 },
    { lng: 146.69641208171, lat: -38.1836388796366 },
    { lng: 146.696440066364, lat: -38.1834624290794 },
    { lng: 146.696422693059, lat: -38.1831249736295 },
    { lng: 146.696446302431, lat: -38.1830208286101 },
    { lng: 146.696540596334, lat: -38.1826048946469 },
    { lng: 146.696711739058, lat: -38.1821996858944 },
    { lng: 146.696874874385, lat: -38.1820303920879 },
    { lng: 146.69720951594, lat: -38.1818118906441 },
    { lng: 146.69747775133, lat: -38.1816068476198 },
    { lng: 146.697637862557, lat: -38.181566951353 },
    { lng: 146.698156912612, lat: -38.1816034794264 },
    { lng: 146.698226026289, lat: -38.1816083410066 },
    { lng: 146.698254637441, lat: -38.1816090129845 },
    { lng: 146.698474142187, lat: -38.1816141639431 },
    { lng: 146.698807881564, lat: -38.1815570768019 },
    { lng: 146.6991115812, lat: -38.1813836183439 },
    { lng: 146.699333891032, lat: -38.1813018573764 },
    { lng: 146.699415159326, lat: -38.1812719705743 },
    { lng: 146.699528661185, lat: -38.1811548297956 },
    { lng: 146.699539360375, lat: -38.1811255238471 },
    { lng: 146.699561371243, lat: -38.1810925779527 },
    { lng: 146.699577563492, lat: -38.1810683356398 },
    { lng: 146.699806461474, lat: -38.1809233511761 },
    { lng: 146.700518811053, lat: -38.1805337883125 },
    { lng: 146.700717845236, lat: -38.1803491596518 },
    { lng: 146.70095280512, lat: -38.180215496662 },
    { lng: 146.701399350359, lat: -38.1800092186696 },
    { lng: 146.701720803038, lat: -38.1797851500179 },
    { lng: 146.701888795161, lat: -38.1795734410235 },
    { lng: 146.702071911688, lat: -38.1793563510986 },
    { lng: 146.702222733529, lat: -38.1792339677243 },
    { lng: 146.702412549289, lat: -38.179135002771 },
    { lng: 146.702489417396, lat: -38.1790924259869 },
    { lng: 146.702611604622, lat: -38.1790129767966 },
    { lng: 146.7027730412, lat: -38.1789193493661 },
    { lng: 146.703002182071, lat: -38.1787065688783 },
    { lng: 146.703045819368, lat: -38.1783326794122 },
    { lng: 146.703008132465, lat: -38.1778796463648 },
    { lng: 146.703101412946, lat: -38.1777988713082 },
    { lng: 146.70319352735, lat: -38.1777190812104 },
    { lng: 146.703216901825, lat: -38.1776819022489 },
    { lng: 146.703247503945, lat: -38.177637837618 },
    { lng: 146.703446064505, lat: -38.1774350105918 },
    { lng: 146.703637685335, lat: -38.177292992889 },
    { lng: 146.703756088817, lat: -38.1771763731138 },
    { lng: 146.704001235435, lat: -38.1768810999443 },
    { lng: 146.704103535548, lat: -38.1767097989716 },
    { lng: 146.70435463201, lat: -38.1763615038847 },
    { lng: 146.704898438818, lat: -38.1758762716296 },
    { lng: 146.705238734753, lat: -38.1751013527813 },
    { lng: 146.70583797063, lat: -38.174531300582 },
    { lng: 146.705879253493, lat: -38.1744920314394 },
    { lng: 146.705945265042, lat: -38.1744148589567 },
    { lng: 146.705953767614, lat: -38.1744049313341 },
    { lng: 146.706696267851, lat: -38.1735969642001 },
    { lng: 146.707274263186, lat: -38.1730905501512 },
    { lng: 146.707430495873, lat: -38.1729285400287 },
    { lng: 146.707893585038, lat: -38.1724483424149 },
    { lng: 146.708249841902, lat: -38.1721619425766 },
    { lng: 146.708262205502, lat: -38.1720092493947 },
    { lng: 146.7082768357, lat: -38.1718286913928 },
    { lng: 146.70815008265, lat: -38.1714673573653 },
    { lng: 146.70789632445, lat: -38.1709926566856 },
    { lng: 146.707895884461, lat: -38.1709898689254 },
    { lng: 146.70786192672, lat: -38.1707722518063 },
    { lng: 146.708353901809, lat: -38.1694779396656 },
    { lng: 146.708958154417, lat: -38.168442581163 },
    { lng: 146.708842807589, lat: -38.167903288789 },
    { lng: 146.708817012768, lat: -38.1677833800296 },
    { lng: 146.708828991645, lat: -38.1675949780653 },
    { lng: 146.721224199238, lat: -38.1663061393578 },
    { lng: 146.722980525251, lat: -38.1661233982372 },
    { lng: 146.72339552156, lat: -38.1641627788259 },
    { lng: 146.724729656951, lat: -38.1593013579614 },
    { lng: 146.729804528593, lat: -38.1591627990684 },
    { lng: 146.73123625632, lat: -38.1591279910757 },
    { lng: 146.731465176972, lat: -38.1591168818751 },
    { lng: 146.735971964973, lat: -38.1590184433109 },
    { lng: 146.735977839541, lat: -38.1590177036874 },
    { lng: 146.738381041839, lat: -38.1587177476144 },
    { lng: 146.740754523268, lat: -38.1584214629534 },
    { lng: 146.74550423539, lat: -38.1578283838383 },
    { lng: 146.750603460985, lat: -38.1571914367578 },
    { lng: 146.750848210551, lat: -38.1571619333176 }
  ],
  [
    { lng: 146.628996403948, lat: -38.2676508664292 },
    { lng: 146.62920717451, lat: -38.2677100430937 },
    { lng: 146.629210562607, lat: -38.2677171409049 },
    { lng: 146.629242045012, lat: -38.2677832791094 },
    { lng: 146.629407628037, lat: -38.2679491477658 },
    { lng: 146.629374275609, lat: -38.2680350057522 },
    { lng: 146.62928575846, lat: -38.2680888113311 },
    { lng: 146.629020131004, lat: -38.2681289400993 },
    { lng: 146.628667350042, lat: -38.2680809656032 },
    { lng: 146.628553653885, lat: -38.2680577855898 },
    { lng: 146.628549737463, lat: -38.26805698793 },
    { lng: 146.628480756686, lat: -38.268043660093 },
    { lng: 146.62839005096, lat: -38.2680261241347 },
    { lng: 146.628327626548, lat: -38.267978047858 },
    { lng: 146.628300027493, lat: -38.2679860978385 },
    { lng: 146.628230418446, lat: -38.2681348982517 },
    { lng: 146.628131792551, lat: -38.2683489365821 },
    { lng: 146.628133662995, lat: -38.268350925854 },
    { lng: 146.628173923258, lat: -38.2683935621382 },
    { lng: 146.628249910577, lat: -38.268398618768 },
    { lng: 146.628259568433, lat: -38.2683992651353 },
    { lng: 146.628275915232, lat: -38.2684155687641 },
    { lng: 146.628281349243, lat: -38.2684210002043 },
    { lng: 146.628256720059, lat: -38.26852743741 },
    { lng: 146.628269799079, lat: -38.2685869543673 },
    { lng: 146.628301754479, lat: -38.2686201382616 },
    { lng: 146.628388906585, lat: -38.2687299843899 },
    { lng: 146.628384243337, lat: -38.2687446974036 },
    { lng: 146.628380194582, lat: -38.2687574470982 },
    { lng: 146.628332323337, lat: -38.2687620108682 },
    { lng: 146.6282742432, lat: -38.268794098948 },
    { lng: 146.628265564027, lat: -38.2688490433589 },
    { lng: 146.628208969545, lat: -38.2688948326263 },
    { lng: 146.628204668176, lat: -38.268896098785 },
    { lng: 146.628116084877, lat: -38.2689222879553 },
    { lng: 146.628049304543, lat: -38.2689188711257 },
    { lng: 146.627987142612, lat: -38.2689399172696 },
    { lng: 146.62797817819, lat: -38.2689429508632 },
    { lng: 146.627979640766, lat: -38.2689898798193 },
    { lng: 146.628040625175, lat: -38.2690173262207 },
    { lng: 146.628220672696, lat: -38.2691054118541 },
    { lng: 146.628309255981, lat: -38.2691980866984 },
    { lng: 146.628376092511, lat: -38.2692953522234 },
    { lng: 146.628531489323, lat: -38.2694807204441 },
    { lng: 146.628646240697, lat: -38.2696294622923 },
    { lng: 146.628843812171, lat: -38.2699452879006 },
    { lng: 146.628893014745, lat: -38.270011503305 },
    { lng: 146.628973083578, lat: -38.2701192237091 },
    { lng: 146.629259193804, lat: -38.2704098297382 },
    { lng: 146.629423336209, lat: -38.2706489781106 },
    { lng: 146.629482328682, lat: -38.2707757553631 },
    { lng: 146.629493086611, lat: -38.2707988921541 },
    { lng: 146.62947571831, lat: -38.2709098938326 },
    { lng: 146.629446732943, lat: -38.2710380898567 },
    { lng: 146.629450737359, lat: -38.2710416692897 },
    { lng: 146.629522248151, lat: -38.2711055886972 },
    { lng: 146.629611655722, lat: -38.2710773693362 },
    { lng: 146.629652895029, lat: -38.2710643636362 },
    { lng: 146.62974000344, lat: -38.2710666250178 },
    { lng: 146.6297451076, lat: -38.2710696940834 },
    { lng: 146.629795180026, lat: -38.2710998013894 },
    { lng: 146.629868803315, lat: -38.2712612327284 },
    { lng: 146.629869286934, lat: -38.2712622947095 },
    { lng: 146.629861872494, lat: -38.2713082893278 },
    { lng: 146.629841786801, lat: -38.2714328048004 },
    { lng: 146.629730060162, lat: -38.271536980681 },
    { lng: 146.629640068949, lat: -38.2716091285713 },
    { lng: 146.629629860504, lat: -38.2716243011487 },
    { lng: 146.629577687941, lat: -38.2717018469054 },
    { lng: 146.6295022724, lat: -38.271851803611 },
    { lng: 146.629484935621, lat: -38.2720452132954 },
    { lng: 146.629484001478, lat: -38.2721182962084 },
    { lng: 146.62948352833, lat: -38.2721550841514 },
    { lng: 146.629605573099, lat: -38.2723827988467 },
    { lng: 146.629603108625, lat: -38.2724088772233 },
    { lng: 146.629591130445, lat: -38.2725350165484 },
    { lng: 146.629574498549, lat: -38.2725535299752 },
    { lng: 146.629534535417, lat: -38.2725979763318 },
    { lng: 146.629393757293, lat: -38.2726826962715 },
    { lng: 146.629216657242, lat: -38.2727125139751 },
    { lng: 146.628942250473, lat: -38.2726416241522 },
    { lng: 146.628934221268, lat: -38.272627003994 },
    { lng: 146.628872533103, lat: -38.2725145833137 },
    { lng: 146.628835738427, lat: -38.2724701960089 },
    { lng: 146.628828939577, lat: -38.2724619757007 },
    { lng: 146.628772322927, lat: -38.2724528309341 },
    { lng: 146.628750070395, lat: -38.2724661838313 },
    { lng: 146.628705564359, lat: -38.2724928986354 },
    { lng: 146.628696874499, lat: -38.2725512522417 },
    { lng: 146.628598203817, lat: -38.2726691730002 },
    { lng: 146.62848934817, lat: -38.2726977916861 },
    { lng: 146.628374650794, lat: -38.2726795089376 },
    { lng: 146.628291908804, lat: -38.2727081789024 },
    { lng: 146.628291402947, lat: -38.2727086722283 },
    { lng: 146.628235314394, lat: -38.2727642702253 },
    { lng: 146.628280337192, lat: -38.2728409398347 },
    { lng: 146.628393581541, lat: -38.2728363333166 },
    { lng: 146.628453114419, lat: -38.2728683623011 },
    { lng: 146.628437967224, lat: -38.2730967137117 },
    { lng: 146.628435810476, lat: -38.2731293034527 },
    { lng: 146.62849099744, lat: -38.2731865081635 },
    { lng: 146.628711801394, lat: -38.2731298470499 },
    { lng: 146.628727596605, lat: -38.273125792416 },
    { lng: 146.628797293043, lat: -38.2731452323309 },
    { lng: 146.628799779448, lat: -38.2731509521565 },
    { lng: 146.628817654065, lat: -38.2731921522075 },
    { lng: 146.628813308698, lat: -38.2732402107841 },
    { lng: 146.628778505024, lat: -38.2733432227495 },
    { lng: 146.62880466351, lat: -38.2733958709418 },
    { lng: 146.628874369737, lat: -38.2734313337903 },
    { lng: 146.628949192131, lat: -38.2735317443748 },
    { lng: 146.628952810576, lat: -38.2735365966415 },
    { lng: 146.628951897535, lat: -38.2735438137266 },
    { lng: 146.628942679636, lat: -38.2736166929891 },
    { lng: 146.628780122558, lat: -38.273709444949 },
    { lng: 146.628706091967, lat: -38.2737083317163 },
    { lng: 146.628688140084, lat: -38.2736945364037 },
    { lng: 146.628646537903, lat: -38.2736625666102 },
    { lng: 146.628643600547, lat: -38.2735733011687 },
    { lng: 146.628656635445, lat: -38.2734737064108 },
    { lng: 146.628572397941, lat: -38.2734164926749 },
    { lng: 146.628434469515, lat: -38.2734074055986 },
    { lng: 146.628221068854, lat: -38.2733754117709 },
    { lng: 146.628068554007, lat: -38.2731946335972 },
    { lng: 146.627901574252, lat: -38.2731363069658 },
    { lng: 146.627892278711, lat: -38.2731382188357 },
    { lng: 146.627728841421, lat: -38.27317180343 },
    { lng: 146.627487907997, lat: -38.2732886010907 },
    { lng: 146.627239715283, lat: -38.2734569267768 },
    { lng: 146.627239725905, lat: -38.2735164441095 },
    { lng: 146.627287652571, lat: -38.2735587736728 },
    { lng: 146.62735444505, lat: -38.2735782139099 },
    { lng: 146.627454599665, lat: -38.2735552894331 },
    { lng: 146.627507333438, lat: -38.2735117861422 },
    { lng: 146.627612801962, lat: -38.2734247803733 },
    { lng: 146.627753612722, lat: -38.2734075844321 },
    { lng: 146.627777251692, lat: -38.2734140281784 },
    { lng: 146.627937994749, lat: -38.273457888081 },
    { lng: 146.628023707496, lat: -38.27359291943 },
    { lng: 146.628090532504, lat: -38.2736821685696 },
    { lng: 146.628286552166, lat: -38.2737656637394 },
    { lng: 146.628306879964, lat: -38.2738114282339 },
    { lng: 146.62815306703, lat: -38.2739362157281 },
    { lng: 146.628064516642, lat: -38.2739316427888 },
    { lng: 146.627910605445, lat: -38.2738859314249 },
    { lng: 146.627822033212, lat: -38.2738584839015 },
    { lng: 146.627729116194, lat: -38.2738447476278 },
    { lng: 146.6276407532, lat: -38.2738375321181 },
    { lng: 146.627633625269, lat: -38.273836954299 },
    { lng: 146.627626530067, lat: -38.273840888814 },
    { lng: 146.627498996333, lat: -38.2739117373175 },
    { lng: 146.627368898878, lat: -38.2740174169809 },
    { lng: 146.62725327786, lat: -38.2741196292195 },
    { lng: 146.627244378812, lat: -38.2741222816663 },
    { lng: 146.627144587418, lat: -38.2741519805787 },
    { lng: 146.627132564058, lat: -38.2740707892206 },
    { lng: 146.627098782753, lat: -38.2740778689819 },
    { lng: 146.627038315709, lat: -38.2740905337195 },
    { lng: 146.626890608162, lat: -38.274159629886 },
    { lng: 146.626720911219, lat: -38.27419221898 },
    { lng: 146.626594851093, lat: -38.2741746840229 },
    { lng: 146.626527728792, lat: -38.2741653508747 },
    { lng: 146.626522812442, lat: -38.2741678919611 },
    { lng: 146.626411899449, lat: -38.2742252158263 },
    { lng: 146.626405310395, lat: -38.2743629248021 },
    { lng: 146.626404011634, lat: -38.274390006323 },
    { lng: 146.626451169236, lat: -38.274530622128 },
    { lng: 146.626550301505, lat: -38.2746184269537 },
    { lng: 146.626643152129, lat: -38.2746296469253 },
    { lng: 146.626679573496, lat: -38.2746340492596 },
    { lng: 146.626820768551, lat: -38.2747148590662 },
    { lng: 146.626834431021, lat: -38.2748338170683 },
    { lng: 146.626801287859, lat: -38.2748776245786 },
    { lng: 146.626740710519, lat: -38.2749576696001 },
    { lng: 146.626663370417, lat: -38.2750334897896 },
    { lng: 146.626654031424, lat: -38.2750426357329 },
    { lng: 146.626605147375, lat: -38.2751389066165 },
    { lng: 146.626541259005, lat: -38.2751333913041 },
    { lng: 146.62653562695, lat: -38.2751341302537 },
    { lng: 146.626433920812, lat: -38.2751474321314 },
    { lng: 146.626277291605, lat: -38.2751742326318 },
    { lng: 146.626183098985, lat: -38.2752100176841 },
    { lng: 146.626166214246, lat: -38.2753164983489 },
    { lng: 146.626175475959, lat: -38.2754286117914 },
    { lng: 146.626236679977, lat: -38.275476475743 },
    { lng: 146.626323700847, lat: -38.2754613384186 },
    { lng: 146.626420897045, lat: -38.275449591132 },
    { lng: 146.626470452219, lat: -38.2754917756223 },
    { lng: 146.626467657937, lat: -38.2755383896653 },
    { lng: 146.626465094572, lat: -38.2755810518098 },
    { lng: 146.626523593033, lat: -38.2756666894131 },
    { lng: 146.626539905935, lat: -38.2757375793737 },
    { lng: 146.626528531538, lat: -38.2757483149354 },
    { lng: 146.626470562541, lat: -38.2758030039329 },
    { lng: 146.626376038675, lat: -38.2757689614122 },
    { lng: 146.626244797807, lat: -38.2757244885992 },
    { lng: 146.626242256881, lat: -38.2757236308957 },
    { lng: 146.626227066055, lat: -38.2757296059461 },
    { lng: 146.626116240478, lat: -38.2757732016742 },
    { lng: 146.626006604429, lat: -38.2759131548992 },
    { lng: 146.62598405362, lat: -38.2760846509725 },
    { lng: 146.6259828221, lat: -38.2760940102208 },
    { lng: 146.625980699041, lat: -38.2761085870593 },
    { lng: 146.625965981287, lat: -38.2762096607939 },
    { lng: 146.625881919187, lat: -38.2762373872687 },
    { lng: 146.625839888123, lat: -38.2762512509343 },
    { lng: 146.625715411513, lat: -38.2763237310522 },
    { lng: 146.625732362734, lat: -38.2763832823745 },
    { lng: 146.625734607187, lat: -38.2763911866646 },
    { lng: 146.625861195219, lat: -38.2764182076562 },
    { lng: 146.625951142143, lat: -38.2764374179967 },
    { lng: 146.626094307559, lat: -38.2764400534401 },
    { lng: 146.626164542423, lat: -38.2764413536622 },
    { lng: 146.626222710242, lat: -38.276485298896 },
    { lng: 146.626250507034, lat: -38.2765063108746 },
    { lng: 146.626247162526, lat: -38.276579435908 },
    { lng: 146.626243972581, lat: -38.2766493569448 },
    { lng: 146.626234688825, lat: -38.2767302523103 },
    { lng: 146.626216142922, lat: -38.2768920409345 },
    { lng: 146.626188830192, lat: -38.2770439355468 },
    { lng: 146.626175168083, lat: -38.277119883373 },
    { lng: 146.626099344858, lat: -38.2772191546141 },
    { lng: 146.626081426018, lat: -38.2772426057035 },
    { lng: 146.626092338334, lat: -38.2773924872772 },
    { lng: 146.625925919407, lat: -38.2774959567741 },
    { lng: 146.625759323722, lat: -38.2775253502335 },
    { lng: 146.625747521454, lat: -38.2775274326152 },
    { lng: 146.625625244924, lat: -38.2774579961694 },
    { lng: 146.62560585235, lat: -38.2773516527235 },
    { lng: 146.62546343512, lat: -38.2773223273275 },
    { lng: 146.62537180518, lat: -38.2772813907902 },
    { lng: 146.625284376818, lat: -38.2772626236117 },
    { lng: 146.625277325468, lat: -38.2772611103539 },
    { lng: 146.625272837889, lat: -38.2772630906371 },
    { lng: 146.62518608059, lat: -38.2773014261968 },
    { lng: 146.625066236175, lat: -38.2774299550313 },
    { lng: 146.625008816184, lat: -38.2774804438226 },
    { lng: 146.624998300387, lat: -38.2774896838354 },
    { lng: 146.624993416183, lat: -38.2775101349312 },
    { lng: 146.624984077524, lat: -38.2775492438908 },
    { lng: 146.62500113803, lat: -38.2776827278132 },
    { lng: 146.6250022055, lat: -38.277691065981 },
    { lng: 146.624970976294, lat: -38.2778341810885 },
    { lng: 146.624898914819, lat: -38.2779397001948 },
    { lng: 146.624964530485, lat: -38.2779990129691 },
    { lng: 146.625090853444, lat: -38.2780043421239 },
    { lng: 146.625207003135, lat: -38.2779919317895 },
    { lng: 146.625256250539, lat: -38.2779866706396 },
    { lng: 146.625265280485, lat: -38.2779859743135 },
    { lng: 146.625395554389, lat: -38.2779759440571 },
    { lng: 146.625416167852, lat: -38.2780964985666 },
    { lng: 146.625416475672, lat: -38.2780983097992 },
    { lng: 146.625340377563, lat: -38.2782656062673 },
    { lng: 146.625302185611, lat: -38.2784682720611 },
    { lng: 146.625242433981, lat: -38.2785639559645 },
    { lng: 146.625178347976, lat: -38.2786665938863 },
    { lng: 146.625078809294, lat: -38.2787950880767 },
    { lng: 146.625110081517, lat: -38.2789563338179 },
    { lng: 146.624964189272, lat: -38.2790963709488 },
    { lng: 146.624807725539, lat: -38.2791643630805 },
    { lng: 146.624798969036, lat: -38.2791861392625 },
    { lng: 146.624781897526, lat: -38.2792285460066 },
    { lng: 146.624841626813, lat: -38.2792706978153 },
    { lng: 146.624914425193, lat: -38.2793116683035 },
    { lng: 146.624878795878, lat: -38.2794468088571 },
    { lng: 146.624727336431, lat: -38.2796418079176 },
    { lng: 146.624639524165, lat: -38.279787386779 },
    { lng: 146.624492003446, lat: -38.279898829813 },
    { lng: 146.62434146841, lat: -38.2799920081063 },
    { lng: 146.624300613631, lat: -38.2800696123542 },
    { lng: 146.624255195051, lat: -38.2801558791437 },
    { lng: 146.624122645048, lat: -38.2803599638317 },
    { lng: 146.623921290216, lat: -38.2804802645971 },
    { lng: 146.62390119284, lat: -38.2804922667535 },
    { lng: 146.623879027634, lat: -38.2804966009724 },
    { lng: 146.623663328847, lat: -38.2805387700654 },
    { lng: 146.623629305439, lat: -38.2807025137568 },
    { lng: 146.623656530784, lat: -38.2807238060576 },
    { lng: 146.623725996004, lat: -38.280854833903 },
    { lng: 146.623796165336, lat: -38.2809908172556 },
    { lng: 146.623866421924, lat: -38.2811508893328 },
    { lng: 146.623866972014, lat: -38.2813007857348 },
    { lng: 146.623864827237, lat: -38.2813635003201 },
    { lng: 146.623856499928, lat: -38.2816074577405 },
    { lng: 146.623847964159, lat: -38.2816578380444 },
    { lng: 146.623826304721, lat: -38.2816899419846 },
    { lng: 146.623769853381, lat: -38.2817312437286 },
    { lng: 146.623709044624, lat: -38.2817806105855 },
    { lng: 146.623654286533, lat: -38.2818894447501 },
    { lng: 146.623606910504, lat: -38.2820314383185 },
    { lng: 146.623628205471, lat: -38.2822934339677 },
    { lng: 146.62363534501, lat: -38.2823058689957 },
    { lng: 146.623679334177, lat: -38.2823825808481 },
    { lng: 146.623824820138, lat: -38.2824692139255 },
    { lng: 146.623905372758, lat: -38.2825166268649 },
    { lng: 146.624019002993, lat: -38.2825835045581 },
    { lng: 146.624088170573, lat: -38.2826242108077 },
    { lng: 146.624376480618, lat: -38.2828604180338 },
    { lng: 146.624542591956, lat: -38.2830271113075 },
    { lng: 146.624635641175, lat: -38.2830635075776 },
    { lng: 146.624653570491, lat: -38.2830821570448 },
    { lng: 146.624728898517, lat: -38.283160553273 },
    { lng: 146.624720516648, lat: -38.283246419995 },
    { lng: 146.624672887125, lat: -38.2833255036812 },
    { lng: 146.624668982355, lat: -38.2834422002844 },
    { lng: 146.624725994818, lat: -38.2835439030546 },
    { lng: 146.624806658449, lat: -38.2837737085802 },
    { lng: 146.624811640623, lat: -38.2839396456363 },
    { lng: 146.624983944838, lat: -38.2842173321233 },
    { lng: 146.624958611464, lat: -38.284426763906 },
    { lng: 146.624818329205, lat: -38.2845746971085 },
    { lng: 146.62469588853, lat: -38.2848301896864 },
    { lng: 146.624721352717, lat: -38.2850406416078 },
    { lng: 146.624555759035, lat: -38.2854175324254 },
    { lng: 146.624562942066, lat: -38.2857905547353 },
    { lng: 146.624552129066, lat: -38.2860022468289 },
    { lng: 146.624600650256, lat: -38.2861658042668 },
    { lng: 146.624605611057, lat: -38.2863374190311 },
    { lng: 146.62469940762, lat: -38.2865660692341 },
    { lng: 146.625019453045, lat: -38.2871351697604 },
    { lng: 146.625318047447, lat: -38.2877343182903 },
    { lng: 146.625244611979, lat: -38.2881270426448 },
    { lng: 146.625057358937, lat: -38.2883549189902 },
    { lng: 146.624810496829, lat: -38.2887434121189 },
    { lng: 146.624777904238, lat: -38.2889586068798 },
    { lng: 146.624824962081, lat: -38.2891175574216 },
    { lng: 146.624797792264, lat: -38.2892308872524 },
    { lng: 146.624682379905, lat: -38.2894302802847 },
    { lng: 146.624635046713, lat: -38.2895871563202 },
    { lng: 146.624486293454, lat: -38.2897946068004 },
    { lng: 146.624374314059, lat: -38.2901404382353 },
    { lng: 146.624212262466, lat: -38.2902987334099 },
    { lng: 146.624108268008, lat: -38.290442010359 },
    { lng: 146.624008454463, lat: -38.2905417658859 },
    { lng: 146.623794162585, lat: -38.2906289844107 },
    { lng: 146.623636313248, lat: -38.2908149644024 },
    { lng: 146.623616414184, lat: -38.2909237302048 },
    { lng: 146.623499285487, lat: -38.29105559002 },
    { lng: 146.623234197021, lat: -38.2912232616954 },
    { lng: 146.623037439738, lat: -38.2914136610996 },
    { lng: 146.622895606412, lat: -38.2915341059993 },
    { lng: 146.622858216659, lat: -38.2916349082248 },
    { lng: 146.6228213557, lat: -38.2918707148372 },
    { lng: 146.622749053189, lat: -38.2919521108141 },
    { lng: 146.62267831189, lat: -38.2920587098224 },
    { lng: 146.622638613139, lat: -38.2923196847921 },
    { lng: 146.622618548935, lat: -38.2923906687106 },
    { lng: 146.622490002675, lat: -38.2925763676811 },
    { lng: 146.622311814499, lat: -38.2926900467954 },
    { lng: 146.622113517513, lat: -38.2928552528056 },
    { lng: 146.621971716075, lat: -38.2929837638017 },
    { lng: 146.621836801287, lat: -38.2930172383949 },
    { lng: 146.621776124639, lat: -38.2930070970001 },
    { lng: 146.621694174924, lat: -38.2930119586733 },
    { lng: 146.621655630704, lat: -38.2930142455911 },
    { lng: 146.621552384669, lat: -38.2929675630046 },
    { lng: 146.621415886265, lat: -38.2929632628741 },
    { lng: 146.621032810867, lat: -38.2934287482468 },
    { lng: 146.620996478271, lat: -38.2935501189284 },
    { lng: 146.620978305771, lat: -38.2936108102007 },
    { lng: 146.621018323945, lat: -38.2938338496246 },
    { lng: 146.621002550168, lat: -38.2938899581393 },
    { lng: 146.620994464778, lat: -38.2938975307902 },
    { lng: 146.620938903818, lat: -38.2939495857542 },
    { lng: 146.620843214687, lat: -38.2939898500651 },
    { lng: 146.620728441388, lat: -38.2939706833824 },
    { lng: 146.62058739859, lat: -38.2939160700117 },
    { lng: 146.620481358596, lat: -38.2939048671916 },
    { lng: 146.620160587584, lat: -38.2939353483955 },
    { lng: 146.6200603669, lat: -38.293924120303 },
    { lng: 146.61999212337, lat: -38.29392542031 },
    { lng: 146.619804979495, lat: -38.2939624291779 },
    { lng: 146.619725119864, lat: -38.2939580432337 },
    { lng: 146.619556688303, lat: -38.2938943443757 },
    { lng: 146.619547722454, lat: -38.2938909518996 },
    { lng: 146.619462054954, lat: -38.293891139258 },
    { lng: 146.619266243518, lat: -38.2939453611988 },
    { lng: 146.619154692589, lat: -38.294015409566 },
    { lng: 146.619066538943, lat: -38.2941228748046 },
    { lng: 146.619057827426, lat: -38.2941334918213 },
    { lng: 146.618988252466, lat: -38.2941610907957 },
    { lng: 146.618900009455, lat: -38.2942493890857 },
    { lng: 146.618875656018, lat: -38.2943142521194 },
    { lng: 146.618853975361, lat: -38.2943719683483 },
    { lng: 146.618863137989, lat: -38.2944966799474 },
    { lng: 146.618834317772, lat: -38.2945562306338 },
    { lng: 146.618764721207, lat: -38.2945792476691 },
    { lng: 146.618668845427, lat: -38.2945692016573 },
    { lng: 146.618531004501, lat: -38.294595789904 },
    { lng: 146.618305834257, lat: -38.2946840962944 },
    { lng: 146.618293217505, lat: -38.29468904292 },
    { lng: 146.618236743409, lat: -38.2947385727254 },
    { lng: 146.618144122645, lat: -38.2948198153894 },
    { lng: 146.617991013804, lat: -38.2950375767192 },
    { lng: 146.617917281219, lat: -38.2950921637767 },
    { lng: 146.617859233995, lat: -38.2951351404372 },
    { lng: 146.617817621223, lat: -38.2952690917799 },
    { lng: 146.617739311463, lat: -38.2952944375675 },
    { lng: 146.617653687505, lat: -38.2953072143103 },
    { lng: 146.617616035254, lat: -38.2953393688187 },
    { lng: 146.61733124527, lat: -38.2952782116428 },
    { lng: 146.615460211544, lat: -38.2950283287144 },
    { lng: 146.613755155625, lat: -38.2954902186217 },
    { lng: 146.612970184697, lat: -38.2946413236491 },
    { lng: 146.61288826853, lat: -38.2945604887044 },
    { lng: 146.612812819484, lat: -38.2944860377619 },
    { lng: 146.605289215537, lat: -38.2967199137021 },
    { lng: 146.605619875357, lat: -38.2951062572022 },
    { lng: 146.60623590834, lat: -38.2920996958808 },
    { lng: 146.60633023306, lat: -38.2916393114336 },
    { lng: 146.603834278117, lat: -38.2913150789395 },
    { lng: 146.601095311156, lat: -38.2909592088003 },
    { lng: 146.587491930461, lat: -38.2893653476929 },
    { lng: 146.586961718827, lat: -38.2883743329408 },
    { lng: 146.58614494701, lat: -38.2875690353511 },
    { lng: 146.584075153626, lat: -38.2869572563282 },
    { lng: 146.57234784523, lat: -38.2852971211075 },
    { lng: 146.573593968617, lat: -38.2791091890816 },
    { lng: 146.574053494158, lat: -38.2768266069183 },
    { lng: 146.574168839475, lat: -38.2762536727281 },
    { lng: 146.578873518452, lat: -38.2768440672803 },
    { lng: 146.585676875468, lat: -38.2776975258974 },
    { lng: 146.585568590733, lat: -38.2777740938709 },
    { lng: 146.584708127347, lat: -38.2783823800529 },
    { lng: 146.583459055264, lat: -38.2803625226188 },
    { lng: 146.584189388454, lat: -38.2809092678217 },
    { lng: 146.58506120465, lat: -38.2814218256582 },
    { lng: 146.58513272707, lat: -38.2815289778118 },
    { lng: 146.587259005027, lat: -38.2820521608761 },
    { lng: 146.587821897314, lat: -38.2821906693831 },
    { lng: 146.590916780126, lat: -38.2826453669543 },
    { lng: 146.590671645249, lat: -38.283921940446 },
    { lng: 146.591797274592, lat: -38.2840661077276 },
    { lng: 146.592472752156, lat: -38.2820777122799 },
    { lng: 146.595512040835, lat: -38.2827867310816 },
    { lng: 146.602203736718, lat: -38.283594425876 },
    { lng: 146.602437113266, lat: -38.2836225618459 },
    { lng: 146.604337802815, lat: -38.2794520366754 },
    { lng: 146.606570857836, lat: -38.277376642382 },
    { lng: 146.606361429345, lat: -38.2772925690574 },
    { lng: 146.601771701555, lat: -38.2767279823045 },
    { lng: 146.601636621013, lat: -38.2767113311054 },
    { lng: 146.603510537249, lat: -38.2675315263833 },
    { lng: 146.603631174474, lat: -38.2675467328965 },
    { lng: 146.605712264347, lat: -38.2678089491575 },
    { lng: 146.6102515016, lat: -38.2683807701211 },
    { lng: 146.610869965699, lat: -38.2653368088843 },
    { lng: 146.61972875044, lat: -38.2664632122508 },
    { lng: 146.622225288118, lat: -38.2667828972582 },
    { lng: 146.622679994716, lat: -38.2668356821897 },
    { lng: 146.625589297545, lat: -38.2672112027599 },
    { lng: 146.628996403948, lat: -38.2676508664292 }
  ]
]
