const options = [
  {
    title: 'Bores',
    link: '/',
    adminOnly: false
  },
  {
    title: 'Timesheets',
    link: '/timesheets',
    adminOnly: true
  },
  {
    title: 'Equipment',
    link: '/equipment',
    adminOnly: true
  },
  {
    title: 'Calendar',
    link: '/calendar',
    adminOnly: true
  },
  {
    title: 'Enquiries',
    link: '/enquiries',
    adminOnly: true
  }, {
    title: 'Maps',
    link: '/delwp',
    adminOnly: false
  },
  {
    title: '温泉',
    link: '/hot-springs',
    adminOnly: true
  }
]

export default options
