import React from 'react'
import { withData } from '../context'
import { Button, Icon } from 'antd'
import moment from 'moment'

const NavButtons = ({ setSelectedDate, dates, index, selectedJob }) => {
  const disabledPrevious = !selectedJob || index === 0 || index === -1
  const disabledNext = !selectedJob || index === -1 || (dates && index === dates.length - 1)

  return (
    <Button.Group style={{ width: '100%' }}>
      <Button
        disabled={disabledPrevious}
        style={{ width: '50%' }}
        onClick={() => setSelectedDate(moment(dates[index - 1]))}>
        <Icon type="left" />
        {/* Previous */}
      </Button>
      <Button
        disabled={disabledNext}
        style={{ width: '50%' }}
        onClick={() => setSelectedDate(moment(dates[index + 1]))}>
        {/* Next */}
        <Icon type="right" />
      </Button>
    </Button.Group>
  )
}

export default withData(NavButtons)
