import SRO from './SRO.js'
import DDO from './DDO.js'
import PAO from './PAO'
import WAO from './WAO.js'
import PropertyOutlines from './PropertyOutlines'
import Propertys from './propertys'

const overlayOptions = {
  // editable: true,
  // draggable: true,
  geodesic: true,
  strokeOpacity: 1,
  strokeWeight: 1,
  fillOpacity: 0.2
}
const red = {
  strokeColor: '#e74c3c',
  fillColor: '#e74c3c'
}
const yellow = {
  strokeColor: '#f1c40f',
  fillColor: '#f1c40f'
}
const blue = {
  strokeColor: '#3498db',
  fillColor: '#3498db'
}
const green = {
  strokeColor: '#1abc9c',
  fillColor: '#1abc9c'
}
const clear = {
  strokeColor: '#fff',
  fillColor: '#fff0'
}

const _SRO = SRO.map(path => ({
  ...overlayOptions,
  ...yellow,
  label: 'another thing',
  path
}))
const _PAO = PAO.map(path => ({
  ...overlayOptions,
  ...blue,
  label: 'another thing',
  path
}))
const _Property = PropertyOutlines.map(path => ({
  ...overlayOptions,
  ...green,
  label: 'another thing',
  type: 'property',
  path
}))

const t_overlays = [
  {
    ...overlayOptions,
    ...clear,
    label: 'Aquifer',
    path: WAO
  },
  ..._SRO,
  ..._PAO,
  ..._Property,
  {
    ...overlayOptions,
    ...red,
    label: 'pipeline',
    path: DDO
  }
]

const t_heatmap = [
  { lat: -38.19791878016367, lng: 146.53235371650393, weight: 50 },
  { lat: -38.20256182072197, lng: 146.53293410256435, weight: 40 },
  { lat: -38.19986384013803, lng: 146.53396407082607, weight: 30 },
  { lat: -38.214971243637486, lng: 146.5353373618417, weight: 45 },
  { lat: -38.1944675790409, lng: 146.5075282187753, weight: 52 }
]

export { t_overlays, Propertys, t_heatmap }
