import React from 'react'

import { ReportProvider } from './components/context'

import Select from './components/Select'
import Prestarts from './components/Prestarts'
import Workshops from './components/Workshops'
import Photos from './components/Photos'
import LastPrestart from './components/LastPrestart'

import './index.css'

export default () => {
  return (
    <ReportProvider>
      <div style={{ display: 'grid', gridGap: 10 }}>
        <Select />
        <Prestarts />
        <Workshops />
        <LastPrestart />
        <Photos />
      </div>
    </ReportProvider>
  )
}
