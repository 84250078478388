import { Client } from 'fulcrum-app'
import moment from 'moment'
const fulcrum = new Client(process.env.REACT_APP_FULCRUM_KEY)

const _localSave = (id, data) => {
  let recent = []
  data.sort((a, b) => moment(a.updated_at).isBefore(moment(b.updated_at)))

  for (let i = 0; i < 100; i++) data[i] && recent.push(data[i])
  try {
    window.localStorage.setItem(id, JSON.stringify(recent))
  } catch (error) {
    console.error(error)
  }
}

const fil_sor = (raw, user) => {
  if (raw && user) {
    let filtered = raw.filter((p) => {
      if (p.form_values['c713']) {
        if (user.role !== 'admin') {
          if (p.form_values['6f71'] === user.username) {
            return p
          }
        } else return p
      }
      return null
    })

    filtered.sort((a, b) => {
      a = a.form_values['c713']
      b = b.form_values['c713']
      return a < b ? -1 : 1
    })
    return filtered
  }
}

export default {
  getLocalData: (id) =>
    new Promise((res, rej) => {
      let local = window.localStorage.getItem(id)
      res(JSON.parse(local))
    }),
  getAllData: (form_id, project_id, user, props) =>
    new Promise((resolve, reject) => {
      // Add sort by (asd || desc) for child feilds
      let options = project_id ? { form_id, project_id, sort: 'created_by:asc' } : { form_id }
      fulcrum.records
        .all({ ...options, ...props })
        .then((page) => {
          // Save to local storage for offline support
          let data = user ? fil_sor(page.objects, user) : page.objects
          _localSave(form_id, data)
          resolve(data)
        })
        .catch(reject)
    }),
  getPhoto: (id) => new Promise((resolve, reject) => fulcrum.photos.find(id).then(resolve).catch(reject)),
  updateRecord: (obj) =>
    new Promise((resolve, reject) => fulcrum.records.update(obj.id, obj).then(resolve).catch(reject)),
  choiceLists: (id) => new Promise((resolve, reject) => fulcrum.choiceLists.find(id).then(resolve).catch(reject)),
  getRecord: (id) => new Promise((resolve, reject) => fulcrum.records.find(id).then(resolve).catch(reject)),
  createRecord: (obj) => new Promise((resolve, reject) => fulcrum.records.create(obj).then(resolve).catch(reject)),
  deleteRecord: (obj) => new Promise((resolve, reject) => fulcrum.records.delete(obj.id).then(resolve).catch(reject)),
  getSet: (form_id) =>
    new Promise((resolve, reject) => {
      fulcrum.classificationSets
        .find(form_id)
        .then((resp) => {
          // Save to local storage for offline support
          _localSave(form_id, resp.items)
          resolve(resp.items)
        })
        .catch(reject)
    }),
}
