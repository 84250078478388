import React, { useState, useEffect } from 'react'
import { withData } from './context'
import Modal from 'antd/lib/modal'
import Input from 'antd/lib/input'
import Select from 'antd/lib/select'
import DatePicker from 'antd/lib/date-picker'
import RadioGroup from 'antd/lib/radio/group'
import Radio from 'antd/lib/radio'
import moment from 'moment'
import Button from 'antd/lib/button'

export default withData(({ selectedEvent, createNew, updateEvent, deleteEvent, setSelectedEvent, loading }) => {
  const [event, setEvent] = useState(null)

  useEffect(() => {
    if (selectedEvent) setEvent(selectedEvent)
    return () => setEvent(null)
  }, [selectedEvent])

  const updateValue = (v, l) => setEvent({ ...event, form_values: { ...event.form_values, [l]: v } })
  const updateSelection = (v, l) =>
    setEvent({ ...event, form_values: { ...event.form_values, [l]: { choice_values: [v] } } })
  const updateDate = v =>
    v &&
    setEvent({
      ...event,
      form_values: {
        ...event.form_values,
        '5bbd': v[0].format('YYYY-MM-DD'), // date Start
        '840e': v[0].format('HH:mm'), // hours Start
        '887d': v[1].format('YYYY-MM-DD'), // date Finish
        edcd: v[1].format('HH:mm') // hours Finish
      }
    })

  if (selectedEvent && event)
    return (
      <Modal
        visible={selectedEvent ? true : false}
        title={event.id ? 'Update event.' : 'Create a new event.'}
        okText={event.id ? 'Update' : 'Create'}
        onCancel={() => setSelectedEvent(null)}
        footer={[
          event.id && (
            <Button key='submit' ghost type='danger' loading={loading} onClick={() => deleteEvent(event)}>
              Delete
            </Button>
          ),
          <Button key='back' onClick={() => setSelectedEvent(null)}>
            Cancel
          </Button>,
          <Button
            key='update'
            type='primary'
            loading={loading}
            onClick={() => (event.id ? updateEvent(event) : createNew(event))}>
            {event.id ? 'Update' : 'Create'}
          </Button>
        ]}>
        <div>
          <h3 className='section-title enq-smaller'>Title</h3>
          <Input
            value={event.form_values['81f1']}
            onChange={v => updateValue(v.target.value, '81f1')}
            placeholder='John Smith'
            required
          />
        </div>
        <div>
          <h3 className='section-title enq-smaller'>Description</h3>
          <Input
            value={event.form_values['aff0']}
            onChange={v => updateValue(v.target.value, 'aff0')}
            placeholder='John Smith has leave'
          />
        </div>
        <div>
          <h3 className='section-title enq-smaller'>Colour</h3>
          <Select
            value={event.form_values['9077'] && event.form_values['9077'].choice_values[0]}
            onChange={v => updateSelection(v, '9077')}
            style={{ width: '100%' }}>
            <Select.Option value='Red'>Red - Leave</Select.Option>
            <Select.Option value='Blue'>Blue - Appointment</Select.Option>
            <Select.Option value='Green'>Green - Training</Select.Option>
            <Select.Option value='Yellow'>Yellow - Meetings</Select.Option>
            <Select.Option value='Purple'>Purple - Public Holiday</Select.Option>
            <Select.Option value='Orange'>Orange</Select.Option>
          </Select>
        </div>
        <div>
          <h3 className='section-title enq-smaller'>All Day?</h3>
          <RadioGroup onChange={v => updateValue(v.target.value, '839d')} value={event.form_values['839d']}>
            <Radio value='true'>Yes</Radio>
            <Radio value='false'>No</Radio>
          </RadioGroup>
        </div>

        <div>
          <h3 className='section-title enq-smaller'>Date</h3>
          <DatePicker.RangePicker
            showTime={{ format: 'HH:mm', defaultValue: [moment('00:00', 'HH:mm'), moment('00:01', 'HH:mm')] }}
            format='Do MMM YYYY HH:mm'
            value={
              event.form_values['5bbd']
                ? [
                    moment(`${event.form_values['5bbd']} ${event.form_values['840e']}`, 'YYYY-MM-DD HH:mm'),
                    moment(`${event.form_values['887d']} ${event.form_values['edcd']}`, 'YYYY-MM-DD HH:mm')
                  ]
                : [moment(), moment()]
            }
            onChange={updateDate}
            placeholder={['Start date', 'End date']}
            style={{ width: '100%' }}
          />
        </div>
      </Modal>
    )
  return null
})
