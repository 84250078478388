import React from 'react'
import './App.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// sudo npm install --unsafe-perm node-sass -g
//Layout
import Nav from './components/layout/Nav'
import './components/layout/SharedStyle.css'
import 'antd/dist/antd.css'

//Pages
import Bores from './components/pages/Bores'
import Timesheets from './components/pages/Timesheets'
import Equipment from './components/pages/Equipment'
import Calendar from './components/pages/Calendar'
import Enquiries from './components/pages/Enquiries'
import DELWP from './components/pages/DELWP'
import Testing from './components/pages/Testing'

import HotSprings from './components/pages/HotSprings'

import Users from './components/pages/Users'

import Login from './components/pages/Auth/Login'
import PasswordReset from './components/pages/Auth/PasswordReset'
import NotFound from './components/pages/NotFound'

//Authentication & Context
import { AuthProvider } from './authentication/Auth'
import PrivateRoute from './authentication/PrivateRoute'
import { FulcrumProvider } from './Fulcrum'

const App = () => (
  <AuthProvider>
    <FulcrumProvider>
      <Router>
        <div id='App'>
          <Nav />
          <Switch>
            <PrivateRoute exact path='/' adminOnly={false} component={Bores} />
            <PrivateRoute path='/timesheets' adminOnly={true} component={Timesheets} />
            <PrivateRoute path='/calendar' adminOnly={true} component={Calendar} />
            <PrivateRoute path='/equipment' adminOnly={true} component={Equipment} />
            <PrivateRoute path='/enquiries' adminOnly={true} component={Enquiries} />
            <PrivateRoute path='/delwp' adminOnly={false} component={DELWP} />
            <PrivateRoute path='/testing' adminOnly={true} dev={true} component={Testing} />
            <PrivateRoute path='/hot-springs' adminOnly={true} dev={true} component={HotSprings} />
            <PrivateRoute path='/users' component={Users} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/password-reset' component={PasswordReset} />
            <Route exact component={NotFound} />
          </Switch>
        </div>
      </Router>
    </FulcrumProvider>
  </AuthProvider>
)

export default App
