import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import moment from 'moment'
import Select from 'react-select'

const ActionBar = ({ loading, data, setTimesheet, timesheet, setEmployee, employee }) => {
  const [timesheets, setTimesheets] = useState([])

  useEffect(() => {
    data &&
      employee &&
      setTimesheets(
        employee.timesheets.sort((a, b) => new Date(b.form_values['b4cf']) - new Date(a.form_values['b4cf']))
      )

    return () => {
      setTimesheets([])
    }
  }, [data, employee])

  return (
    <Row gutter={8}>
      <Col xs={0} sm={0} md={14} lg={14} xl={14}>
        <Select
          options={data && data.sort((a, b) => a.name > b.name)}
          classNamePrefix='job_select'
          onChange={r => r && setEmployee(r)}
          placeholder='Select employee'
          isLoading={loading}
          getOptionLabel={r => r.name}
          getOptionValue={r => r.name}
          theme={theme => ({
            ...theme,
            borderWidth: 1,
            colors: {
              ...theme.colors,
              primary: '#40a9ff'
            }
          })}
        />
      </Col>
      <Col xs={0} sm={0} md={10} lg={10} xl={10}>
        <Select
          options={timesheets}
          classNamePrefix='job_select'
          onChange={r => r && setTimesheet(r)}
          placeholder='Select timesheet'
          isLoading={loading}
          value={timesheet}
          getOptionLabel={r => moment(r.form_values['b4cf']).format('Do MMMM YYYY')}
          getOptionValue={r => r.id}
          isDisabled={!employee}
          theme={theme => ({
            ...theme,
            borderWidth: 1,
            colors: {
              ...theme.colors,
              primary: '#40a9ff'
            }
          })}
        />
      </Col>
    </Row>
  )
}

export default ActionBar
