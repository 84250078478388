import React, { useState, useContext, useEffect } from 'react'
import { FulcrumContext } from '../../../Fulcrum'
import GoogleMapReact from 'google-map-react'

export default () => {
  const { data } = useContext(FulcrumContext)

  useEffect(() => {
    // fulcrum.getAllData('01029d95-7ca3-4e8e-85ec-80e098a61749').then(console.log)
  }, [])

  const [center] = useState({
    lat: -38.2379611,
    lng: 146.3782018,
  })

  let colors = [
    {
      type: 'Completed',
      color: 'green',
    },
    {
      type: 'In Progress',
      color: 'yellow',
    },
    {
      type: 'Awaiting Invoice',
      color: 'pink',
    },
    {
      type: 'Upcoming',
      color: 'blue',
    },
    {
      type: 'Enquiry',
      color: 'black',
    },
  ]
  const color = (status) => {
    let index = colors.findIndex((i) => i.type === status)
    return index !== -1 ? colors[index].color : colors[4].color
  }

  const Marker = ({ job }) => <div className={`marker ${color(job.status)}`} />

  return (
    <div id='bore-map' style={{ height: '90vh' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBZsOKeC_xTGwdascGnram_6zNjcZH5F_U' }}
        defaultCenter={{ lat: -38.2379611, lng: 146.3782018 }}
        defaultZoom={11}
        center={center}
        options={{
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
          panControl: false,
          zoomControl: false,
          rotateControl: false,
          fullscreenControl: false,
          //   styles: styles
        }}>
        {data &&
          data.map(
            (job) =>
              job.status !== 'Enquiry' &&
              job.latitude && <Marker lat={job.latitude} lng={job.longitude} job={job} key={job.id} />
          )}
      </GoogleMapReact>
    </div>
  )
}
