import React, { useEffect, useState } from 'react'
import { withData } from './context'
import Table from 'antd/lib/table'
import moment from 'moment'

export default withData(({ prestarts, itemSelected, loading, plant }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    if (prestarts && plant) {
      let uniqueArray = []
      let on = (r, l, f) =>
        r.form_values[f] && r.form_values[f].choice_values[r.form_values[f].choice_values.length - l]
      prestarts.map((r) => {
        if (r.form_values['07dc'] && r.form_values['c5c8']) {
          let servicePlantIndex = plant.findIndex((p) => on(p, 1, 'e2b4') === on(r, 1, '07dc'))
          if (servicePlantIndex >= 0) {
            //Waiting for values then ill add logic
            // let kms = plant[servicePlantIndex].form_values['7054']
            // let date = plant[servicePlantIndex].form_values['e8f3']
            // console.log({ kms, date })
          }

          let index = uniqueArray.findIndex((i) => i.on === on(r, 1, '07dc'))
          if (on(r, 2, '07dc')) {
            if (index === -1) {
              uniqueArray.push({
                on: on(r, 1, '07dc'),
                category: on(r, 2, '07dc'),
                date: r.form_values['c5c8'],
                status: r.status,
                completedBy: r.form_values['947b'].choice_values[0],
                count: 1,
              })
            } else {
              uniqueArray[index].count = uniqueArray[index].count + 1
              if (moment(uniqueArray[index].date).isBefore(moment(r.form_values['c5c8']))) {
                uniqueArray[index].date = r.form_values['c5c8']
                uniqueArray[index].status = r.status
                uniqueArray[index].completedBy = r.form_values['947b'].choice_values[0]
              }
            }
          }
        }
        return null
      })
      uniqueArray.sort((a, b) => new Date(a.date) - new Date(b.date))
      setData(uniqueArray)
    }
  }, [prestarts, plant])

  if (itemSelected) return null

  return (
    <>
      <h3 className='section-title'>Last Prestart</h3>
      <p className='section-description'>
        This is a list that conveys how many prestarts have been done for a specific item of plant and when the most
        recent one was submitted.
      </p>
      <Table
        columns={[
          {
            title: '',
            dataIndex: 'status',
            render: (v) => <div className={`badge ${v.split(' ').join('-')}`} />,
            width: 20,
          },
          { title: 'On', dataIndex: 'on' },
          { title: 'Service Due', dataIndex: 'servicee_due' },
          { title: 'Category', dataIndex: 'category' },
          { title: 'Date', dataIndex: 'date', render: (v) => v && moment(v).format('Do MMM YYYY') },
          { title: 'Completed By', dataIndex: 'completedBy' },
          { title: 'Count', dataIndex: 'count' },
        ]}
        dataSource={data}
        size='small'
        pagination={false}
        loading={loading}
        rowKey='on'
      />
    </>
  )
})
