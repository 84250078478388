export default [
  { lng: 146.429398361072, lat: -38.1386269227947 },
  { lng: 146.435006036172, lat: -38.1389525192022 },
  { lng: 146.435215611767, lat: -38.139030562408 },
  { lng: 146.43784789717, lat: -38.1400107855957 },
  { lng: 146.444068297832, lat: -38.1391346738354 },
  { lng: 146.447410172075, lat: -38.1414675398983 },
  { lng: 146.447729895823, lat: -38.1416907301815 },
  { lng: 146.448732476264, lat: -38.1423906017767 },
  { lng: 146.450068710187, lat: -38.143323388585 },
  { lng: 146.451237785861, lat: -38.1434884089974 },
  { lng: 146.455979627846, lat: -38.1441577382391 },
  { lng: 146.461767846676, lat: -38.1449747669543 },
  { lng: 146.461970016896, lat: -38.1450033039555 },
  { lng: 146.462001256841, lat: -38.1450142016021 },
  { lng: 146.472381777049, lat: -38.1486355261021 },
  { lng: 146.473295624904, lat: -38.148954329262 },
  { lng: 146.474253811286, lat: -38.1492885998429 },
  { lng: 146.476314443383, lat: -38.1500074673955 },
  { lng: 146.477800669613, lat: -38.1505259486035 },
  { lng: 146.477889601544, lat: -38.1505569743393 },
  { lng: 146.477910403209, lat: -38.150558999669 },
  { lng: 146.478043115843, lat: -38.1505719268169 },
  { lng: 146.481260674791, lat: -38.1508853253463 },
  { lng: 146.481923109375, lat: -38.1509498479077 },
  { lng: 146.484028068415, lat: -38.1511548762044 },
  { lng: 146.484384453569, lat: -38.1511895886896 },
  { lng: 146.485954277469, lat: -38.1513424932733 },
  { lng: 146.493364748266, lat: -38.1520642902028 },
  { lng: 146.493818081523, lat: -38.152108445878 },
  { lng: 146.499035428561, lat: -38.1526166276607 },
  { lng: 146.504931645803, lat: -38.1531909327948 },
  { lng: 146.505630068994, lat: -38.1532589612558 },
  { lng: 146.505714336149, lat: -38.1532671689742 },
  { lng: 146.505783822551, lat: -38.1532739368032 },
  { lng: 146.505839212993, lat: -38.1532793318961 },
  { lng: 146.505902009275, lat: -38.1532854489316 },
  { lng: 146.506246017661, lat: -38.1533189560114 },
  { lng: 146.511419246959, lat: -38.15440262393 },
  { lng: 146.515457861487, lat: -38.155248618066 },
  { lng: 146.515679726607, lat: -38.1552950941114 },
  { lng: 146.519950331752, lat: -38.1561896844247 },
  { lng: 146.520195860802, lat: -38.1562411164435 },
  { lng: 146.521426590361, lat: -38.1564989248075 },
  { lng: 146.521448864589, lat: -38.1565035395981 },
  { lng: 146.52343148482, lat: -38.1569143467877 },
  { lng: 146.533524117451, lat: -38.1590055820422 },
  { lng: 146.533632958107, lat: -38.1590281337454 },
  { lng: 146.533753291428, lat: -38.1590530681801 },
  { lng: 146.533795077885, lat: -38.1590617256151 },
  { lng: 146.540180399801, lat: -38.1608332799116 },
  { lng: 146.54044356572, lat: -38.1609062933738 },
  { lng: 146.541616926701, lat: -38.1612274494281 },
  { lng: 146.544871393089, lat: -38.1621182164079 },
  { lng: 146.545014148037, lat: -38.1621572886962 },
  { lng: 146.545312408207, lat: -38.1622389237025 },
  { lng: 146.548916642505, lat: -38.1631096108998 },
  { lng: 146.559794777249, lat: -38.1657374806178 },
  { lng: 146.561611487335, lat: -38.1662004376399 },
  { lng: 146.562367345447, lat: -38.1663930552729 },
  { lng: 146.563048983687, lat: -38.1665667593512 },
  { lng: 146.563576059459, lat: -38.1667010750188 },
  { lng: 146.565369113755, lat: -38.1667980727848 },
  { lng: 146.566250837173, lat: -38.1668457705765 },
  { lng: 146.566357745568, lat: -38.1668515535566 },
  { lng: 146.566867094552, lat: -38.1668791078002 },
  { lng: 146.57203217488, lat: -38.1672779732274 },
  { lng: 146.574728368623, lat: -38.167486182142 },
  { lng: 146.58094943221, lat: -38.1679665941244 },
  { lng: 146.581189621745, lat: -38.167985141931 },
  { lng: 146.581199666099, lat: -38.1679859183974 },
  { lng: 146.584374522548, lat: -38.1682253167046 },
  { lng: 146.589168181704, lat: -38.1685867806623 },
  { lng: 146.593385772945, lat: -38.1689048055655 },
  { lng: 146.598607181516, lat: -38.1692985237231 },
  { lng: 146.603839634931, lat: -38.1696930750418 },
  { lng: 146.617191738718, lat: -38.1706750312356 },
  { lng: 146.617399951545, lat: -38.1706903437714 },
  { lng: 146.617495190467, lat: -38.1706973480667 },
  { lng: 146.617617386936, lat: -38.1707063349999 },
  { lng: 146.621924444052, lat: -38.1710230887542 },
  { lng: 146.628482413808, lat: -38.17150538272 },
  { lng: 146.636016018147, lat: -38.1720594281945 },
  { lng: 146.636237434601, lat: -38.1720757121465 },
  { lng: 146.645233448515, lat: -38.1727373070659 },
  { lng: 146.645416268751, lat: -38.1727511392769 },
  { lng: 146.653664582462, lat: -38.1733752076563 },
  { lng: 146.653906002558, lat: -38.17339347434 },
  { lng: 146.654210038407, lat: -38.1735375189029 },
  { lng: 146.655223772476, lat: -38.1740178019436 },
  { lng: 146.655795661356, lat: -38.1742887491404 },
  { lng: 146.656972674438, lat: -38.1748463911247 },
  { lng: 146.656988687141, lat: -38.1748539781431 },
  { lng: 146.658537751817, lat: -38.1749577968505 },
  { lng: 146.658837525953, lat: -38.1749778883047 },
  { lng: 146.660101239656, lat: -38.1750625828502 },
  { lng: 146.661214150247, lat: -38.1751371712382 },
  { lng: 146.664557434593, lat: -38.1753612409728 },
  { lng: 146.666414034068, lat: -38.1754750812663 },
  { lng: 146.684370656474, lat: -38.1765761123215 },
  { lng: 146.684738492012, lat: -38.1766038176486 },
  { lng: 146.690606656457, lat: -38.1770458206521 },
  { lng: 146.691558380216, lat: -38.1771077870685 },
  { lng: 146.69181620885, lat: -38.1771245739554 },
  { lng: 146.693324457143, lat: -38.1772227740378 },
  { lng: 146.693673212509, lat: -38.1772454808522 },
  { lng: 146.702633781706, lat: -38.1778288916196 },
  { lng: 146.703036433, lat: -38.1778551406216 },
  { lng: 146.70300812022, lat: -38.1778796600526 },
  { lng: 146.703045809793, lat: -38.1783327101812 },
  { lng: 146.703002159944, lat: -38.1787065998245 },
  { lng: 146.702773040079, lat: -38.1789193502829 },
  { lng: 146.702611590544, lat: -38.1790130103321 },
  { lng: 146.70248941976, lat: -38.1790924295575 },
  { lng: 146.702412539632, lat: -38.1791350299371 },
  { lng: 146.702222720571, lat: -38.1792340003429 },
  { lng: 146.702071899789, lat: -38.1793563800982 },
  { lng: 146.701888790109, lat: -38.1795734699266 },
  { lng: 146.701876839118, lat: -38.1795885301841 },
  { lng: 146.701851220623, lat: -38.179586860889 },
  { lng: 146.691924403481, lat: -38.1789401533108 },
  { lng: 146.69163654601, lat: -38.1789214002007 },
  { lng: 146.690389813256, lat: -38.1788401800656 },
  { lng: 146.684359514677, lat: -38.1783859327892 },
  { lng: 146.684173878107, lat: -38.1783719491257 },
  { lng: 146.677466252491, lat: -38.1779606659669 },
  { lng: 146.666448556245, lat: -38.1772851074182 },
  { lng: 146.666019007538, lat: -38.1772587693117 },
  { lng: 146.664372326478, lat: -38.1771578007124 },
  { lng: 146.660841296657, lat: -38.1769211481827 },
  { lng: 146.658678043438, lat: -38.1767761638601 },
  { lng: 146.658462531807, lat: -38.176761720043 },
  { lng: 146.656267780048, lat: -38.176614624662 },
  { lng: 146.654283343872, lat: -38.1756744398522 },
  { lng: 146.65369866236, lat: -38.1753974298294 },
  { lng: 146.65317395287, lat: -38.1751488336192 },
  { lng: 146.651469173724, lat: -38.175019833831 },
  { lng: 146.6502991777, lat: -38.1749313013214 },
  { lng: 146.645045591425, lat: -38.174533763674 },
  { lng: 146.645018883075, lat: -38.1745317431243 },
  { lng: 146.635870574821, lat: -38.1738589723446 },
  { lng: 146.635644622836, lat: -38.1738423545549 },
  { lng: 146.628284970445, lat: -38.1733011230692 },
  { lng: 146.621548540356, lat: -38.1728057216389 },
  { lng: 146.617242462131, lat: -38.1724890510982 },
  { lng: 146.617118255507, lat: -38.1724799168239 },
  { lng: 146.617022137602, lat: -38.1724728483125 },
  { lng: 146.603625179262, lat: -38.1714876293012 },
  { lng: 146.59866752057, lat: -38.1711137941365 },
  { lng: 146.593082085869, lat: -38.1706926205732 },
  { lng: 146.588789221152, lat: -38.1703689149638 },
  { lng: 146.583951313544, lat: -38.1700041103408 },
  { lng: 146.580979875574, lat: -38.1697800472971 },
  { lng: 146.580818989784, lat: -38.1697676221101 },
  { lng: 146.57837498654, lat: -38.1695788754294 },
  { lng: 146.577059171617, lat: -38.1694772562933 },
  { lng: 146.574711156832, lat: -38.1692959222543 },
  { lng: 146.572419123747, lat: -38.1691189112182 },
  { lng: 146.570115091936, lat: -38.1689409732597 },
  { lng: 146.568112855433, lat: -38.1687863430289 },
  { lng: 146.567229950115, lat: -38.1687181569407 },
  { lng: 146.566661273744, lat: -38.1686742393043 },
  { lng: 146.565876825782, lat: -38.1686356728878 },
  { lng: 146.565647394408, lat: -38.1686243933693 },
  { lng: 146.565228661276, lat: -38.1686038059993 },
  { lng: 146.564995456744, lat: -38.1685923408704 },
  { lng: 146.564457846201, lat: -38.1685659102245 },
  { lng: 146.564166951478, lat: -38.1685516079249 },
  { lng: 146.563858652339, lat: -38.1685364512913 },
  { lng: 146.563223521201, lat: -38.1685052253918 },
  { lng: 146.561113275983, lat: -38.1679674732213 },
  { lng: 146.559110203452, lat: -38.1674570306759 },
  { lng: 146.548559999518, lat: -38.1649084418593 },
  { lng: 146.545119926547, lat: -38.1640774309034 },
  { lng: 146.544618406681, lat: -38.1639562799171 },
  { lng: 146.540216959022, lat: -38.1627518759412 },
  { lng: 146.540107508512, lat: -38.1627219256082 },
  { lng: 146.539667042746, lat: -38.1626013977845 },
  { lng: 146.539148164304, lat: -38.1624574376894 },
  { lng: 146.533359393674, lat: -38.1608513806268 },
  { lng: 146.533239166514, lat: -38.1608180239355 },
  { lng: 146.533131695156, lat: -38.16078820702 },
  { lng: 146.533125827425, lat: -38.1607865787954 },
  { lng: 146.524897741999, lat: -38.1591383470915 },
  { lng: 146.520814781315, lat: -38.1583204582469 },
  { lng: 146.519837348332, lat: -38.1581157343976 },
  { lng: 146.519593578218, lat: -38.1580646758827 },
  { lng: 146.515318699469, lat: -38.1571693000475 },
  { lng: 146.515095891826, lat: -38.1571226333439 },
  { lng: 146.511985400124, lat: -38.1564711379965 },
  { lng: 146.511631979074, lat: -38.1584015809947 },
  { lng: 146.510928464698, lat: -38.1622442872208 },
  { lng: 146.514179835162, lat: -38.163169485198 },
  { lng: 146.515780958598, lat: -38.1636250948876 },
  { lng: 146.51839404756, lat: -38.1643686647569 },
  { lng: 146.518639684349, lat: -38.1644385631689 },
  { lng: 146.521612182107, lat: -38.1652844057295 },
  { lng: 146.521617700253, lat: -38.1652860668537 },
  { lng: 146.522990574727, lat: -38.1656994546641 },
  { lng: 146.524599606224, lat: -38.1661839521475 },
  { lng: 146.525834338585, lat: -38.1666542749201 },
  { lng: 146.526175199813, lat: -38.1667839802004 },
  { lng: 146.526281010693, lat: -38.1668244170192 },
  { lng: 146.527921116038, lat: -38.1674491500659 },
  { lng: 146.528039569418, lat: -38.1674942696606 },
  { lng: 146.52889208573, lat: -38.1678190020025 },
  { lng: 146.52899910222, lat: -38.1678597653345 },
  { lng: 146.529617102481, lat: -38.168095168155 },
  { lng: 146.536330343427, lat: -38.1691201846395 },
  { lng: 146.541732542913, lat: -38.1699450239823 },
  { lng: 146.545583670828, lat: -38.1705330371664 },
  { lng: 146.545604930874, lat: -38.1705362829472 },
  { lng: 146.549686293553, lat: -38.171174073431 },
  { lng: 146.551939615431, lat: -38.1715261968713 },
  { lng: 146.553536396963, lat: -38.1717757238009 },
  { lng: 146.558699383709, lat: -38.1725825390949 },
  { lng: 146.563997135617, lat: -38.1734104127432 },
  { lng: 146.564109466013, lat: -38.1734279671731 },
  { lng: 146.564225793771, lat: -38.1734461453744 },
  { lng: 146.564623856092, lat: -38.173508350371 },
  { lng: 146.565328474373, lat: -38.1736184602167 },
  { lng: 146.567004729465, lat: -38.1738804061208 },
  { lng: 146.567244881198, lat: -38.1739179343026 },
  { lng: 146.567331679632, lat: -38.1739314983179 },
  { lng: 146.56899570529, lat: -38.1741870561985 },
  { lng: 146.570144682441, lat: -38.1743635143988 },
  { lng: 146.571262597737, lat: -38.1745352020739 },
  { lng: 146.573512760506, lat: -38.174880777684 },
  { lng: 146.574313952097, lat: -38.1750038239789 },
  { lng: 146.574621682161, lat: -38.1752428841806 },
  { lng: 146.574817904004, lat: -38.1753953189296 },
  { lng: 146.575831250502, lat: -38.1761825371026 },
  { lng: 146.576122406752, lat: -38.176408720696 },
  { lng: 146.576776466357, lat: -38.1766595649224 },
  { lng: 146.577290697725, lat: -38.1768567833403 },
  { lng: 146.578022585282, lat: -38.1771374746985 },
  { lng: 146.582067024209, lat: -38.1786885921505 },
  { lng: 146.583186530204, lat: -38.1791179428318 },
  { lng: 146.586878841113, lat: -38.1793518984085 },
  { lng: 146.588694870727, lat: -38.1794669657404 },
  { lng: 146.588931129111, lat: -38.1794819356059 },
  { lng: 146.590297136007, lat: -38.1795684902735 },
  { lng: 146.590828627966, lat: -38.1796021673075 },
  { lng: 146.590900649487, lat: -38.1796067302682 },
  { lng: 146.59255957045, lat: -38.1797118444132 },
  { lng: 146.594141970174, lat: -38.1798121090414 },
  { lng: 146.595538980402, lat: -38.179900627276 },
  { lng: 146.595891246069, lat: -38.1799229475656 },
  { lng: 146.595932589086, lat: -38.1799255682721 },
  { lng: 146.600062806963, lat: -38.1801872694352 },
  { lng: 146.6007096395, lat: -38.1802860637128 },
  { lng: 146.601497800849, lat: -38.1808323589481 },
  { lng: 146.601988567672, lat: -38.1831848532403 },
  { lng: 146.602061080405, lat: -38.1835324407568 },
  { lng: 146.602173836313, lat: -38.1840729352926 },
  { lng: 146.602248197217, lat: -38.1844293861052 },
  { lng: 146.602256316841, lat: -38.1844683111031 },
  { lng: 146.6023614074, lat: -38.1844733180991 },
  { lng: 146.608959156036, lat: -38.1847876825728 },
  { lng: 146.611783569192, lat: -38.1849222579185 },
  { lng: 146.613356438523, lat: -38.1849972008764 },
  { lng: 146.614260269448, lat: -38.18504026606 },
  { lng: 146.614342177098, lat: -38.1850441692167 },
  { lng: 146.614462800655, lat: -38.185049916407 },
  { lng: 146.614474005666, lat: -38.1850504502498 },
  { lng: 146.614578065405, lat: -38.1850555811516 },
  { lng: 146.620518483527, lat: -38.1853484860105 },
  { lng: 146.639290391459, lat: -38.1869937195984 },
  { lng: 146.639653665785, lat: -38.1868766330187 },
  { lng: 146.640630652577, lat: -38.1870869741115 },
  { lng: 146.643926399623, lat: -38.1873557441399 },
  { lng: 146.644057615249, lat: -38.1873664449016 },
  { lng: 146.651093136465, lat: -38.187950874958 },
  { lng: 146.652097713173, lat: -38.188034324105 },
  { lng: 146.656052620171, lat: -38.1877734760472 },
  { lng: 146.656146547657, lat: -38.1877773975654 },
  { lng: 146.656379532009, lat: -38.1877871262253 },
  { lng: 146.658901147058, lat: -38.1878924082338 },
  { lng: 146.667114952434, lat: -38.1882450479437 },
  { lng: 146.667370811741, lat: -38.1882560330536 },
  { lng: 146.669770301029, lat: -38.1883590491699 },
  { lng: 146.674489106237, lat: -38.1885616403782 },
  { lng: 146.680524242512, lat: -38.1888207443379 },
  { lng: 146.681344122007, lat: -38.1889073743866 },
  { lng: 146.681603274631, lat: -38.1889347570475 },
  { lng: 146.692212538653, lat: -38.1900557343834 },
  { lng: 146.69224241671, lat: -38.1900588911893 },
  { lng: 146.691984000502, lat: -38.1902401603414 },
  { lng: 146.691722208536, lat: -38.1904307086493 },
  { lng: 146.691554319803, lat: -38.1905529097236 },
  { lng: 146.691438170531, lat: -38.1907665998845 },
  { lng: 146.691407396095, lat: -38.1908527376491 },
  { lng: 146.691361239702, lat: -38.1909819302332 },
  { lng: 146.69135437778, lat: -38.1910276298064 },
  { lng: 146.691320779704, lat: -38.191251380293 },
  { lng: 146.691277609954, lat: -38.191327860269 },
  { lng: 146.69099541036, lat: -38.1914057996074 },
  { lng: 146.690854250121, lat: -38.1913615202502 },
  { lng: 146.690595159794, lat: -38.1912925003425 },
  { lng: 146.690283800186, lat: -38.1910611603235 },
  { lng: 146.690189640078, lat: -38.1910159295963 },
  { lng: 146.690090880437, lat: -38.1910120004133 },
  { lng: 146.690040919986, lat: -38.1910741998872 },
  { lng: 146.690018100436, lat: -38.1911026195442 },
  { lng: 146.689958599537, lat: -38.1911517802659 },
  { lng: 146.689859479551, lat: -38.1913848400807 },
  { lng: 146.689833499814, lat: -38.1915387200931 },
  { lng: 146.689804740163, lat: -38.1915976401524 },
  { lng: 146.689764710353, lat: -38.1916178298129 },
  { lng: 146.689641033561, lat: -38.1916047349405 },
  { lng: 146.68031040247, lat: -38.1906167766117 },
  { lng: 146.679883454158, lat: -38.1905984460403 },
  { lng: 146.679610754853, lat: -38.1905867379878 },
  { lng: 146.666991645132, lat: -38.1900449522386 },
  { lng: 146.666735767214, lat: -38.1900339656078 },
  { lng: 146.658778596293, lat: -38.189692333707 },
  { lng: 146.65608750153, lat: -38.1895799892363 },
  { lng: 146.656004733099, lat: -38.1895854467936 },
  { lng: 146.655763006257, lat: -38.1896013873418 },
  { lng: 146.652073051415, lat: -38.1898447140218 },
  { lng: 146.650177326339, lat: -38.1896872411485 },
  { lng: 146.643820730064, lat: -38.1891592139547 },
  { lng: 146.643553752506, lat: -38.189137440292 },
  { lng: 146.640210068483, lat: -38.1888647315465 },
  { lng: 146.639800053992, lat: -38.1887765186593 },
  { lng: 146.639611140117, lat: -38.1888373874269 },
  { lng: 146.620311717399, lat: -38.1871442376337 },
  { lng: 146.614335065485, lat: -38.18684957697 },
  { lng: 146.614199381492, lat: -38.1868432067983 },
  { lng: 146.614086022037, lat: -38.1868378846606 },
  { lng: 146.61396454388, lat: -38.1868321818333 },
  { lng: 146.612267768785, lat: -38.1867525166974 },
  { lng: 146.608846298421, lat: -38.1865918782638 },
  { lng: 146.608452209862, lat: -38.1865733759823 },
  { lng: 146.602580167503, lat: -38.1862976810332 },
  { lng: 146.601981119175, lat: -38.1862590115546 },
  { lng: 146.60142769789, lat: -38.186223289407 },
  { lng: 146.600502620088, lat: -38.1858917879691 },
  { lng: 146.600085301456, lat: -38.1851895186164 },
  { lng: 146.600006947021, lat: -38.184807452807 },
  { lng: 146.599933943189, lat: -38.1844514765506 },
  { lng: 146.599823384322, lat: -38.183912380035 },
  { lng: 146.599752433201, lat: -38.1835664167399 },
  { lng: 146.599422431469, lat: -38.1819572920728 },
  { lng: 146.595553235524, lat: -38.1817055222203 },
  { lng: 146.593388005239, lat: -38.1815646296431 },
  { lng: 146.591399012795, lat: -38.1814352063319 },
  { lng: 146.591133634531, lat: -38.1814179378307 },
  { lng: 146.590200014462, lat: -38.1813571868821 },
  { lng: 146.587903984571, lat: -38.1812077839087 },
  { lng: 146.587644319709, lat: -38.1811908884124 },
  { lng: 146.586503458589, lat: -38.1811166519721 },
  { lng: 146.582511954745, lat: -38.1808569243618 },
  { lng: 146.58165170646, lat: -38.1805278829553 },
  { lng: 146.577589216471, lat: -38.1789740004244 },
  { lng: 146.577364302345, lat: -38.1788879724201 },
  { lng: 146.574692098958, lat: -38.1778658669119 },
  { lng: 146.573722502098, lat: -38.1769416453502 },
  { lng: 146.573286680316, lat: -38.1765262175607 },
  { lng: 146.573181248879, lat: -38.1764257209669 },
  { lng: 146.568758776465, lat: -38.1758595356187 },
  { lng: 146.567427303362, lat: -38.1756828460597 },
  { lng: 146.566742519845, lat: -38.1755930057371 },
  { lng: 146.563859306467, lat: -38.1752149411913 },
  { lng: 146.563742969701, lat: -38.1751989800274 },
  { lng: 146.56363124811, lat: -38.17518228408 },
  { lng: 146.56349617786, lat: -38.175161238145 },
  { lng: 146.563260985616, lat: -38.1751245896284 },
  { lng: 146.56302590243, lat: -38.1750879599642 },
  { lng: 146.562801902386, lat: -38.1750530566248 },
  { lng: 146.562600250435, lat: -38.1750216356491 },
  { lng: 146.562331408558, lat: -38.1749797461445 },
  { lng: 146.562129814258, lat: -38.1749483335499 },
  { lng: 146.561927572058, lat: -38.1749168208954 },
  { lng: 146.561703472324, lat: -38.1748819024015 },
  { lng: 146.561524153065, lat: -38.1748539609719 },
  { lng: 146.561300155632, lat: -38.1748190584269 },
  { lng: 146.561076190449, lat: -38.1747841595554 },
  { lng: 146.56085220834, lat: -38.1747492595881 },
  { lng: 146.560627987123, lat: -38.1747143217576 },
  { lng: 146.560404068429, lat: -38.174679431854 },
  { lng: 146.560180156827, lat: -38.1746445432468 },
  { lng: 146.558335363503, lat: -38.1743570912726 },
  { lng: 146.553167180842, lat: -38.1735517969379 },
  { lng: 146.550510298577, lat: -38.173137807573 },
  { lng: 146.549317408832, lat: -38.1729519345985 },
  { lng: 146.54606523892, lat: -38.1724451888962 },
  { lng: 146.545161323233, lat: -38.1723043426889 },
  { lng: 146.543039914839, lat: -38.1719804288048 },
  { lng: 146.539216046835, lat: -38.1713965694048 },
  { lng: 146.528892125562, lat: -38.1698202267098 },
  { lng: 146.527279070964, lat: -38.169221077032 },
  { lng: 146.527123697081, lat: -38.1691633647625 },
  { lng: 146.526091642144, lat: -38.1687810798949 },
  { lng: 146.526079860292, lat: -38.1687741798303 },
  { lng: 146.526020430484, lat: -38.1687478200266 },
  { lng: 146.525853769935, lat: -38.1686940636772 },
  { lng: 146.525772950354, lat: -38.1686679951517 },
  { lng: 146.52575799011, lat: -38.1686631696631 },
  { lng: 146.525700510122, lat: -38.168649589602 },
  { lng: 146.52565610009, lat: -38.168640139961 },
  { lng: 146.525560250321, lat: -38.1686204096821 },
  { lng: 146.525557979808, lat: -38.1686199302625 },
  { lng: 146.525394819755, lat: -38.1685762802121 },
  { lng: 146.525374629736, lat: -38.1685684404239 },
  { lng: 146.525355189912, lat: -38.1685594397036 },
  { lng: 146.525336379779, lat: -38.1685494696804 },
  { lng: 146.525303109534, lat: -38.1685292304347 },
  { lng: 146.525301880154, lat: -38.1685284297085 },
  { lng: 146.525282540391, lat: -38.1685155301187 },
  { lng: 146.525230179471, lat: -38.168479479781 },
  { lng: 146.525212529616, lat: -38.1684678598028 },
  { lng: 146.52520461422, lat: -38.168462919906 },
  { lng: 146.525105020069, lat: -38.168400770356 },
  { lng: 146.524922091835, lat: -38.1682821207683 },
  { lng: 146.524519258922, lat: -38.1681374628299 },
  { lng: 146.524357219958, lat: -38.1680779401546 },
  { lng: 146.524217977446, lat: -38.1680290091947 },
  { lng: 146.523695087393, lat: -38.1678407807261 },
  { lng: 146.523537545714, lat: -38.1677933397826 },
  { lng: 146.522664836371, lat: -38.167530534962 },
  { lng: 146.521877874931, lat: -38.167293551743 },
  { lng: 146.521029977616, lat: -38.1670382198776 },
  { lng: 146.520804132773, lat: -38.1669702101557 },
  { lng: 146.51868851152, lat: -38.1663682519203 },
  { lng: 146.514802540487, lat: -38.1652625763078 },
  { lng: 146.513831479573, lat: -38.164986280298 },
  { lng: 146.511218226109, lat: -38.16424273128 },
  { lng: 146.510882895642, lat: -38.1641473200101 },
  { lng: 146.510594967013, lat: -38.1640653951766 },
  { lng: 146.510574515609, lat: -38.1641770976564 },
  { lng: 146.510472250617, lat: -38.1647356559428 },
  { lng: 146.510436929778, lat: -38.1649285759405 },
  { lng: 146.510417756132, lat: -38.1650332996461 },
  { lng: 146.510336529422, lat: -38.1654769547928 },
  { lng: 146.510281873477, lat: -38.1657754760685 },
  { lng: 146.509622849499, lat: -38.1664878724393 },
  { lng: 146.50949599019, lat: -38.1666250049617 },
  { lng: 146.503662378632, lat: -38.1729310553577 },
  { lng: 146.502376580644, lat: -38.1743209851755 },
  { lng: 146.501440412487, lat: -38.1753444521041 },
  { lng: 146.501362392491, lat: -38.1754297463529 },
  { lng: 146.499934970299, lat: -38.1769902766449 },
  { lng: 146.499916522277, lat: -38.1770104452752 },
  { lng: 146.499883618273, lat: -38.1770464172704 },
  { lng: 146.499259287246, lat: -38.1774864565831 },
  { lng: 146.498924824923, lat: -38.1777221918624 },
  { lng: 146.496666770505, lat: -38.1806213662653 },
  { lng: 146.495994491178, lat: -38.1814845219377 },
  { lng: 146.495798172676, lat: -38.1815949116922 },
  { lng: 146.495535108182, lat: -38.1817428338808 },
  { lng: 146.495256133442, lat: -38.1818997037423 },
  { lng: 146.494248250544, lat: -38.1824664416451 },
  { lng: 146.492740228462, lat: -38.1833144100675 },
  { lng: 146.490120056583, lat: -38.1867755830173 },
  { lng: 146.489617218513, lat: -38.1874398177407 },
  { lng: 146.489394985831, lat: -38.1877333814097 },
  { lng: 146.488208339159, lat: -38.1879654909056 },
  { lng: 146.487798191707, lat: -38.1886500342983 },
  { lng: 146.487661023454, lat: -38.1888789711181 },
  { lng: 146.487542255166, lat: -38.1890771970489 },
  { lng: 146.48736826479, lat: -38.1893675893501 },
  { lng: 146.486993059607, lat: -38.1899938130699 },
  { lng: 146.486378848563, lat: -38.1910189423794 },
  { lng: 146.486138336662, lat: -38.191420357621 },
  { lng: 146.485809339276, lat: -38.1919694607014 },
  { lng: 146.485446182437, lat: -38.1925755739253 },
  { lng: 146.484985045078, lat: -38.1933452200821 },
  { lng: 146.484718764428, lat: -38.1937896478206 },
  { lng: 146.484551306598, lat: -38.1940691360324 },
  { lng: 146.484120911005, lat: -38.1947874743711 },
  { lng: 146.483602544276, lat: -38.1956526358881 },
  { lng: 146.483203820198, lat: -38.1963181128868 },
  { lng: 146.482830379478, lat: -38.1969413914172 },
  { lng: 146.482439510533, lat: -38.197593756298 },
  { lng: 146.482072232791, lat: -38.1982067493156 },
  { lng: 146.481670730389, lat: -38.1988768633876 },
  { lng: 146.481293667337, lat: -38.1995061871031 },
  { lng: 146.480899349482, lat: -38.2001643100351 },
  { lng: 146.480702956268, lat: -38.2004920934169 },
  { lng: 146.480337030665, lat: -38.2011028280042 },
  { lng: 146.480155712197, lat: -38.2014054528437 },
  { lng: 146.479026821356, lat: -38.2015730833615 },
  { lng: 146.478877625471, lat: -38.2015952369462 },
  { lng: 146.478772388704, lat: -38.2016108637927 },
  { lng: 146.477172592924, lat: -38.2018484183818 },
  { lng: 146.475426437953, lat: -38.2021077058118 },
  { lng: 146.474614505478, lat: -38.2029885610033 },
  { lng: 146.473027541102, lat: -38.2032335475976 },
  { lng: 146.465730083188, lat: -38.2043600907198 },
  { lng: 146.464677788556, lat: -38.2045225387058 },
  { lng: 146.4644943446, lat: -38.2054161952294 },
  { lng: 146.463933604815, lat: -38.2081478788555 },
  { lng: 146.463691151626, lat: -38.2093290031045 },
  { lng: 146.463476503749, lat: -38.2103746757162 },
  { lng: 146.462918825395, lat: -38.2130914474015 },
  { lng: 146.462147942078, lat: -38.2168468585935 },
  { lng: 146.462041795472, lat: -38.2172581879595 },
  { lng: 146.461920076485, lat: -38.2177298558893 },
  { lng: 146.460957665503, lat: -38.2182395931409 },
  { lng: 146.460442655054, lat: -38.2207294852921 },
  { lng: 146.459874541011, lat: -38.2234761158086 },
  { lng: 146.458943401504, lat: -38.2279778471703 },
  { lng: 146.458835600528, lat: -38.2284990250262 },
  { lng: 146.459080240953, lat: -38.2290130246868 },
  { lng: 146.459168223374, lat: -38.2291978785945 },
  { lng: 146.459326954453, lat: -38.229531380801 },
  { lng: 146.459402911306, lat: -38.2296909673961 },
  { lng: 146.459494728478, lat: -38.2298838767021 },
  { lng: 146.459540832001, lat: -38.2299807438439 },
  { lng: 146.459491178473, lat: -38.2304003206935 },
  { lng: 146.458933387539, lat: -38.2330765941286 },
  { lng: 146.458119148303, lat: -38.2369833170291 },
  { lng: 146.4569053402, lat: -38.2377024792802 },
  { lng: 146.451580846577, lat: -38.2408571581024 },
  { lng: 146.451236612769, lat: -38.2410610808011 },
  { lng: 146.450773340539, lat: -38.2413361899843 },
  { lng: 146.450084911114, lat: -38.2417437786209 },
  { lng: 146.449211706806, lat: -38.2422607636103 },
  { lng: 146.448945760728, lat: -38.2424182185904 },
  { lng: 146.448808142482, lat: -38.2424996967158 },
  { lng: 146.448367527194, lat: -38.2427598320468 },
  { lng: 146.447918895868, lat: -38.2430253863002 },
  { lng: 146.447519231523, lat: -38.2432619563502 },
  { lng: 146.446250403546, lat: -38.2440130012595 },
  { lng: 146.445751262515, lat: -38.2443084538165 },
  { lng: 146.444713212442, lat: -38.2449228992 },
  { lng: 146.444181773377, lat: -38.2452374688706 },
  { lng: 146.443182930367, lat: -38.2458287056888 },
  { lng: 146.443033193565, lat: -38.2465735992019 },
  { lng: 146.442846379405, lat: -38.2475029479889 },
  { lng: 146.442487705067, lat: -38.2492872441166 },
  { lng: 146.442415666532, lat: -38.2496456124006 },
  { lng: 146.442209980544, lat: -38.2506688432545 },
  { lng: 146.438185879585, lat: -38.2501379970975 },
  { lng: 146.436839428302, lat: -38.2499603773904 },
  { lng: 146.435826588743, lat: -38.2503640849839 },
  { lng: 146.435550588597, lat: -38.250474095432 },
  { lng: 146.433972938482, lat: -38.2511029295602 },
  { lng: 146.428848628153, lat: -38.2543477413512 },
  { lng: 146.428606446014, lat: -38.2545010951608 },
  { lng: 146.428379415269, lat: -38.2546448555516 },
  { lng: 146.428314889629, lat: -38.254685714126 },
  { lng: 146.428039943485, lat: -38.2560268317183 },
  { lng: 146.427777143823, lat: -38.2573086933206 },
  { lng: 146.427341275367, lat: -38.2594347410296 },
  { lng: 146.426278459454, lat: -38.264618858988 },
  { lng: 146.424987083478, lat: -38.2709178393489 },
  { lng: 146.424944706626, lat: -38.2711245365807 },
  { lng: 146.424723543364, lat: -38.2722033146138 },
  { lng: 146.424086257661, lat: -38.2753118147521 },
  { lng: 146.423930301455, lat: -38.2760725301582 },
  { lng: 146.412116518474, lat: -38.2828752812941 },
  { lng: 146.411950711988, lat: -38.2829707572602 },
  { lng: 146.411876721318, lat: -38.2830133626082 },
  { lng: 146.411824066909, lat: -38.2830436834127 },
  { lng: 146.410503416421, lat: -38.2838041557381 },
  { lng: 146.410239384271, lat: -38.2839561940971 },
  { lng: 146.406356502466, lat: -38.286192080402 },
  { lng: 146.404392860454, lat: -38.2873228070478 },
  { lng: 146.402969079699, lat: -38.2944033227529 },
  { lng: 146.402581253146, lat: -38.2963319997182 },
  { lng: 146.402187077243, lat: -38.2982922526255 },
  { lng: 146.40174034977, lat: -38.300513840659 },
  { lng: 146.401238455536, lat: -38.3030097778197 },
  { lng: 146.397410716641, lat: -38.3054040617289 },
  { lng: 146.397318407266, lat: -38.3054618030944 },
  { lng: 146.397067485841, lat: -38.3056187560516 },
  { lng: 146.396349094272, lat: -38.3060681156882 },
  { lng: 146.394482008694, lat: -38.3072359942755 },
  { lng: 146.393654956498, lat: -38.3077533222678 },
  { lng: 146.392657842148, lat: -38.3080762047102 },
  { lng: 146.391772887913, lat: -38.3083627667938 },
  { lng: 146.389124837839, lat: -38.3092202522396 },
  { lng: 146.385804806517, lat: -38.308742194627 },
  { lng: 146.383638082529, lat: -38.3075551360807 },
  { lng: 146.382494132541, lat: -38.3074153520149 },
  { lng: 146.380635819481, lat: -38.3071882757785 },
  { lng: 146.38063003191, lat: -38.3071875860813 },
  { lng: 146.380415818574, lat: -38.3071620616212 },
  { lng: 146.380409001561, lat: -38.3071612224188 },
  { lng: 146.37648656428, lat: -38.3066780036252 },
  { lng: 146.374555820599, lat: -38.3059115363737 },
  { lng: 146.373007516644, lat: -38.3052968896556 },
  { lng: 146.372051009118, lat: -38.3041860075859 },
  { lng: 146.370936415235, lat: -38.3028915258273 },
  { lng: 146.368004168001, lat: -38.3025126068894 },
  { lng: 146.363506764479, lat: -38.3019314298859 },
  { lng: 146.363277638604, lat: -38.3019018210933 },
  { lng: 146.360213605713, lat: -38.3015058715961 },
  { lng: 146.357793628362, lat: -38.3011931510328 },
  { lng: 146.35722299098, lat: -38.3011194096591 },
  { lng: 146.353863135081, lat: -38.3006852329341 },
  { lng: 146.353627374495, lat: -38.30065476677 },
  { lng: 146.35303793379, lat: -38.3005785957398 },
  { lng: 146.352243444426, lat: -38.300475927955 },
  { lng: 146.35126445134, lat: -38.3003494177889 },
  { lng: 146.350609152261, lat: -38.3002647367125 },
  { lng: 146.347285260098, lat: -38.2978417439035 },
  { lng: 146.344479355449, lat: -38.2970730527103 },
  { lng: 146.344045314421, lat: -38.2969541453062 },
  { lng: 146.34143874863, lat: -38.29624006433 },
  { lng: 146.336707375695, lat: -38.2964751537449 },
  { lng: 146.335873717511, lat: -38.2965165772142 },
  { lng: 146.334182575557, lat: -38.2955480343227 },
  { lng: 146.333449195031, lat: -38.2951280148641 },
  { lng: 146.333115038935, lat: -38.2949366394433 },
  { lng: 146.328585172264, lat: -38.2923423124209 },
  { lng: 146.324618874761, lat: -38.288665189029 },
  { lng: 146.324380470967, lat: -38.2884441658756 },
  { lng: 146.320996434546, lat: -38.2853068524055 },
  { lng: 146.32082205632, lat: -38.2851451880943 },
  { lng: 146.320465994848, lat: -38.2848150864502 },
  { lng: 146.320298135314, lat: -38.2846464286071 },
  { lng: 146.320034032792, lat: -38.2844087166643 },
  { lng: 146.319960798389, lat: -38.2843428003857 },
  { lng: 146.319807754602, lat: -38.2841225998435 },
  { lng: 146.319768152718, lat: -38.2840756984028 },
  { lng: 146.31956707523, lat: -38.2838375516148 },
  { lng: 146.31908880241, lat: -38.2832064832725 },
  { lng: 146.316366735532, lat: -38.2796147862421 },
  { lng: 146.31605532481, lat: -38.2792041677343 },
  { lng: 146.315916127002, lat: -38.279020774296 },
  { lng: 146.315599970565, lat: -38.2786042369761 },
  { lng: 146.313975631585, lat: -38.2764641669123 },
  { lng: 146.313578957228, lat: -38.2739721744392 },
  { lng: 146.312946882384, lat: -38.2700013522156 },
  { lng: 146.312791998634, lat: -38.2690283420857 },
  { lng: 146.312727264927, lat: -38.2689546665944 },
  { lng: 146.307389444881, lat: -38.2628796407289 },
  { lng: 146.305287790463, lat: -38.260487727428 },
  { lng: 146.305018626561, lat: -38.2601813884252 },
  { lng: 146.304227157081, lat: -38.2592806097311 },
  { lng: 146.297233708566, lat: -38.2582052150871 },
  { lng: 146.295997859316, lat: -38.2568036932339 },
  { lng: 146.295842395919, lat: -38.2551698741208 },
  { lng: 146.295806284612, lat: -38.254793885027 },
  { lng: 146.295785264959, lat: -38.254575020215 },
  { lng: 146.295699384849, lat: -38.2536808359844 },
  { lng: 146.293620087896, lat: -38.2494186890902 },
  { lng: 146.293300659082, lat: -38.2477992781025 },
  { lng: 146.292162192235, lat: -38.2471978937849 },
  { lng: 146.290940542638, lat: -38.2468559470473 },
  { lng: 146.289154396061, lat: -38.2434553610111 },
  { lng: 146.287942526513, lat: -38.2408363831592 },
  { lng: 146.287075230718, lat: -38.240501603111 },
  { lng: 146.286846320566, lat: -38.2400457456646 },
  { lng: 146.286722754574, lat: -38.2397997179249 },
  { lng: 146.287094620459, lat: -38.2396398998296 },
  { lng: 146.288873300396, lat: -38.236358239594 },
  { lng: 146.288225119624, lat: -38.234997770442 },
  { lng: 146.288067929693, lat: -38.2346678299855 },
  { lng: 146.286256129884, lat: -38.2333428096065 },
  { lng: 146.285820750522, lat: -38.2321802695558 },
  { lng: 146.286045912586, lat: -38.2315456099116 },
  { lng: 146.286505750448, lat: -38.2302494798102 },
  { lng: 146.288780480389, lat: -38.2294377801958 },
  { lng: 146.2898905495, lat: -38.2294868595716 },
  { lng: 146.290620469482, lat: -38.2280934696644 },
  { lng: 146.290694799621, lat: -38.227968639574 },
  { lng: 146.291376861682, lat: -38.2271989388185 },
  { lng: 146.291383410072, lat: -38.2271915497677 },
  { lng: 146.290981619453, lat: -38.2271403401607 },
  { lng: 146.290764443198, lat: -38.2271126612337 },
  { lng: 146.291691730421, lat: -38.2263688388879 },
  { lng: 146.29221774144, lat: -38.2252118956326 },
  { lng: 146.292327576607, lat: -38.2249699830129 },
  { lng: 146.292337883508, lat: -38.2249472832372 },
  { lng: 146.292270338432, lat: -38.2239374826395 },
  { lng: 146.292185472244, lat: -38.2226687264404 },
  { lng: 146.292181640487, lat: -38.2226114317411 },
  { lng: 146.293495404226, lat: -38.2210079738766 },
  { lng: 146.29374531177, lat: -38.2207029596927 },
  { lng: 146.294074514084, lat: -38.2203699870257 },
  { lng: 146.294563052025, lat: -38.2198758507437 },
  { lng: 146.294495052538, lat: -38.2195672016752 },
  { lng: 146.294374907978, lat: -38.2190218647613 },
  { lng: 146.292687703734, lat: -38.218164116363 },
  { lng: 146.292267516728, lat: -38.2179504990699 },
  { lng: 146.292227263788, lat: -38.21673374643 },
  { lng: 146.29222112199, lat: -38.2165480844439 },
  { lng: 146.292205080691, lat: -38.2160632179941 },
  { lng: 146.291107320499, lat: -38.2156738522323 },
  { lng: 146.289995826508, lat: -38.2152796151783 },
  { lng: 146.289445093136, lat: -38.2150842739582 },
  { lng: 146.287268632938, lat: -38.2145471762358 },
  { lng: 146.287175538566, lat: -38.2145061508013 },
  { lng: 146.285517141276, lat: -38.2137753199262 },
  { lng: 146.284126650895, lat: -38.2131625521451 },
  { lng: 146.284020102226, lat: -38.2131155971179 },
  { lng: 146.282569663684, lat: -38.213004514274 },
  { lng: 146.281020177466, lat: -38.2128858457534 },
  { lng: 146.28058283303, lat: -38.2128523518132 },
  { lng: 146.280130471185, lat: -38.2121417384421 },
  { lng: 146.279613018697, lat: -38.2113288751008 },
  { lng: 146.278796719495, lat: -38.2100465529182 },
  { lng: 146.277868940905, lat: -38.209850094426 },
  { lng: 146.27681923506, lat: -38.2096278161278 },
  { lng: 146.275412250205, lat: -38.2093298842545 },
  { lng: 146.274858720926, lat: -38.2089432826025 },
  { lng: 146.274033405904, lat: -38.2083668583877 },
  { lng: 146.273538096674, lat: -38.2080209193318 },
  { lng: 146.273187678692, lat: -38.2077761770016 },
  { lng: 146.272991047231, lat: -38.2076388434156 },
  { lng: 146.27294773262, lat: -38.2076085909487 },
  { lng: 146.272782421755, lat: -38.2074931332043 },
  { lng: 146.272386337536, lat: -38.2072164959027 },
  { lng: 146.27170850529, lat: -38.2067430765697 },
  { lng: 146.268863064023, lat: -38.2047557356504 },
  { lng: 146.268377732496, lat: -38.2044167659096 },
  { lng: 146.267706145565, lat: -38.2039477087722 },
  { lng: 146.266032432842, lat: -38.2027795727541 },
  { lng: 146.265784443593, lat: -38.2026064935886 },
  { lng: 146.265503856851, lat: -38.2024106626699 },
  { lng: 146.26506440092, lat: -38.2021930607476 },
  { lng: 146.2648347141, lat: -38.2020793292797 },
  { lng: 146.264375575115, lat: -38.2018519805938 },
  { lng: 146.263426473459, lat: -38.2013820221525 },
  { lng: 146.263213160453, lat: -38.2012764000448 },
  { lng: 146.263199944074, lat: -38.2012685085648 },
  { lng: 146.262969740962, lat: -38.2013123700573 },
  { lng: 146.261767738512, lat: -38.2015375997652 },
  { lng: 146.26112872303, lat: -38.201657337796 },
  { lng: 146.260934526567, lat: -38.2016937260343 },
  { lng: 146.260747770878, lat: -38.2017287199559 },
  { lng: 146.26034014298, lat: -38.2018051013031 },
  { lng: 146.260151731938, lat: -38.2018404052871 },
  { lng: 146.258873792587, lat: -38.2020798642179 },
  { lng: 146.257214365407, lat: -38.2023908049081 },
  { lng: 146.255233087731, lat: -38.2027620538633 },
  { lng: 146.254348558996, lat: -38.202927796273 },
  { lng: 146.252995757125, lat: -38.2031812818 },
  { lng: 146.252977702517, lat: -38.2031797305558 },
  { lng: 146.251260968792, lat: -38.203032273301 },
  { lng: 146.251126923308, lat: -38.2030207588523 },
  { lng: 146.251021126448, lat: -38.2030060512739 },
  { lng: 146.245144023308, lat: -38.2021889826355 },
  { lng: 146.245124237489, lat: -38.2021996419629 },
  { lng: 146.243833020938, lat: -38.2028952476503 },
  { lng: 146.241825507846, lat: -38.2026702412476 },
  { lng: 146.239936006457, lat: -38.2024584618161 },
  { lng: 146.239706005302, lat: -38.20243268287 },
  { lng: 146.237326031547, lat: -38.2021659299386 },
  { lng: 146.231228275098, lat: -38.2014824797225 },
  { lng: 146.227689861388, lat: -38.2021195212664 },
  { lng: 146.227673361385, lat: -38.2020946330121 },
  { lng: 146.22726380375, lat: -38.2021715263693 },
  { lng: 146.226459179675, lat: -38.2017105097752 },
  { lng: 146.225110279792, lat: -38.2010608400877 },
  { lng: 146.223752409799, lat: -38.2004109102842 },
  { lng: 146.223737143322, lat: -38.2000653948898 },
  { lng: 146.226305950778, lat: -38.2004820813701 },
  { lng: 146.22648902362, lat: -38.2004641893553 },
  { lng: 146.228874385011, lat: -38.200016306965 },
  { lng: 146.228898771669, lat: -38.200053137429 },
  { lng: 146.231131717518, lat: -38.1996511551397 },
  { lng: 146.23767396763, lat: -38.2003844686079 },
  { lng: 146.238517589861, lat: -38.2004790298264 },
  { lng: 146.239397458514, lat: -38.2005776542782 },
  { lng: 146.239637174895, lat: -38.2006045231522 },
  { lng: 146.240934280886, lat: -38.2007499137918 },
  { lng: 146.242186342734, lat: -38.2008902569786 },
  { lng: 146.243276915622, lat: -38.2010124981919 },
  { lng: 146.243454104675, lat: -38.2009170407618 },
  { lng: 146.243593075323, lat: -38.2008421737611 },
  { lng: 146.243739285522, lat: -38.2007634057696 },
  { lng: 146.244624437576, lat: -38.2002865467179 },
  { lng: 146.244780446909, lat: -38.200308237371 },
  { lng: 146.245006074473, lat: -38.2003396059033 },
  { lng: 146.246410415611, lat: -38.2005348552074 },
  { lng: 146.246631753444, lat: -38.2005656280847 },
  { lng: 146.248024551492, lat: -38.2007592716148 },
  { lng: 146.251401650516, lat: -38.2012287973564 },
  { lng: 146.251449036068, lat: -38.2012353851884 },
  { lng: 146.251512918811, lat: -38.2012408712088 },
  { lng: 146.251630826643, lat: -38.2012509958605 },
  { lng: 146.252851305235, lat: -38.2013557962557 },
  { lng: 146.253395330341, lat: -38.2012538515871 },
  { lng: 146.253571649208, lat: -38.2012208117807 },
  { lng: 146.253671809487, lat: -38.2012020427046 },
  { lng: 146.257612816626, lat: -38.2004635452085 },
  { lng: 146.26113191442, lat: -38.1998041082539 },
  { lng: 146.261267015721, lat: -38.1997787908371 },
  { lng: 146.26176427454, lat: -38.1996856107276 },
  { lng: 146.262449220774, lat: -38.1995572602668 },
  { lng: 146.263596750183, lat: -38.199342227302 },
  { lng: 146.263602946947, lat: -38.1993410659744 },
  { lng: 146.263810333484, lat: -38.1994437837184 },
  { lng: 146.264247430004, lat: -38.1996602789934 },
  { lng: 146.265231823221, lat: -38.2001478497192 },
  { lng: 146.265797389898, lat: -38.200427976884 },
  { lng: 146.266877163017, lat: -38.2009627900986 },
  { lng: 146.26706106764, lat: -38.2010911745549 },
  { lng: 146.267184217509, lat: -38.2011771447854 },
  { lng: 146.26738095965, lat: -38.2013144922661 },
  { lng: 146.26774842412, lat: -38.2015710188224 },
  { lng: 146.267927301601, lat: -38.2016958943518 },
  { lng: 146.268548908737, lat: -38.2021298386187 },
  { lng: 146.271626598453, lat: -38.2042783805734 },
  { lng: 146.27199654309, lat: -38.2045366391459 },
  { lng: 146.272118389649, lat: -38.2046217000071 },
  { lng: 146.272461062628, lat: -38.2048610822192 },
  { lng: 146.276523502728, lat: -38.2076989718173 },
  { lng: 146.277071028819, lat: -38.207814918034 },
  { lng: 146.27728867038, lat: -38.2078610067118 },
  { lng: 146.28037824444, lat: -38.208515269718 },
  { lng: 146.281627301505, lat: -38.2104773769556 },
  { lng: 146.282058361651, lat: -38.2111545193525 },
  { lng: 146.283475515255, lat: -38.211263043189 },
  { lng: 146.284572118326, lat: -38.2113470200654 },
  { lng: 146.28471672063, lat: -38.2113580941084 },
  { lng: 146.286617673594, lat: -38.212195784967 },
  { lng: 146.288176198506, lat: -38.2128825784553 },
  { lng: 146.288632972938, lat: -38.2129953043607 },
  { lng: 146.28974400972, lat: -38.2132694949304 },
  { lng: 146.290257354985, lat: -38.2133961822756 },
  { lng: 146.291900115511, lat: -38.2139788506933 },
  { lng: 146.294077687666, lat: -38.2147512126827 },
  { lng: 146.294451357443, lat: -38.2148837496795 },
  { lng: 146.294476323317, lat: -38.2156366100462 },
  { lng: 146.294519920762, lat: -38.2169513753667 },
  { lng: 146.295415743275, lat: -38.2174068107797 },
  { lng: 146.296454270991, lat: -38.2179347992313 },
  { lng: 146.296498205685, lat: -38.2181341523903 },
  { lng: 146.296796909751, lat: -38.2194894999826 },
  { lng: 146.29698749575, lat: -38.2203542741841 },
  { lng: 146.296777511799, lat: -38.2205666651298 },
  { lng: 146.296618418917, lat: -38.2207275820966 },
  { lng: 146.295609162614, lat: -38.2217484093212 },
  { lng: 146.295587602354, lat: -38.2217747275931 },
  { lng: 146.294853733148, lat: -38.2226705090933 },
  { lng: 146.294502233423, lat: -38.223099561442 },
  { lng: 146.29456857995, lat: -38.2240910039699 },
  { lng: 146.29462390444, lat: -38.2249177202908 },
  { lng: 146.294616204159, lat: -38.2252746774298 },
  { lng: 146.294277615102, lat: -38.2260204999036 },
  { lng: 146.294162299923, lat: -38.2262745091204 },
  { lng: 146.293724214521, lat: -38.2272395000952 },
  { lng: 146.293673646835, lat: -38.2273508872341 },
  { lng: 146.292585152423, lat: -38.2282230387047 },
  { lng: 146.292240038987, lat: -38.2284995600995 },
  { lng: 146.291980918594, lat: -38.228974137807 },
  { lng: 146.291402369974, lat: -38.2300337488408 },
  { lng: 146.29121439845, lat: -38.2303780181014 },
  { lng: 146.290484310961, lat: -38.230625842184 },
  { lng: 146.289193973222, lat: -38.2310638389768 },
  { lng: 146.288656468385, lat: -38.2312462913487 },
  { lng: 146.288175215245, lat: -38.2314096497855 },
  { lng: 146.287769561772, lat: -38.2315473463289 },
  { lng: 146.28753753112, lat: -38.2319508473246 },
  { lng: 146.287448296521, lat: -38.2321060252276 },
  { lng: 146.287440361894, lat: -38.2321262045581 },
  { lng: 146.287416803175, lat: -38.2321861210079 },
  { lng: 146.287517609656, lat: -38.2324224403668 },
  { lng: 146.287626902679, lat: -38.2326786529429 },
  { lng: 146.288399725768, lat: -38.2332257362788 },
  { lng: 146.289294309542, lat: -38.2338590133769 },
  { lng: 146.289361526237, lat: -38.2339065972841 },
  { lng: 146.28990194553, lat: -38.2350094887647 },
  { lng: 146.290083149596, lat: -38.2353792928262 },
  { lng: 146.290169662561, lat: -38.2355558487801 },
  { lng: 146.290383855153, lat: -38.235992972831 },
  { lng: 146.290393433892, lat: -38.2360375092849 },
  { lng: 146.290413007183, lat: -38.2361285101794 },
  { lng: 146.290432863624, lat: -38.2362208305654 },
  { lng: 146.290441008533, lat: -38.2362586976381 },
  { lng: 146.290479897248, lat: -38.2364395030398 },
  { lng: 146.290508242818, lat: -38.2365712957958 },
  { lng: 146.289007204908, lat: -38.239240678317 },
  { lng: 146.289773849928, lat: -38.2395366400283 },
  { lng: 146.291284513444, lat: -38.2428012089032 },
  { lng: 146.292663087817, lat: -38.2454255668155 },
  { lng: 146.293196313163, lat: -38.2455748442124 },
  { lng: 146.295404992294, lat: -38.2467414951887 },
  { lng: 146.295841076438, lat: -38.2489512486913 },
  { lng: 146.297953306861, lat: -38.2532807745552 },
  { lng: 146.298121716589, lat: -38.2550344179874 },
  { lng: 146.298230584546, lat: -38.2561784160433 },
  { lng: 146.298571430015, lat: -38.2565650303389 },
  { lng: 146.302885260451, lat: -38.257244713214 },
  { lng: 146.303118864406, lat: -38.2572815187018 },
  { lng: 146.305556022833, lat: -38.2576655156012 },
  { lng: 146.306557234846, lat: -38.2588064779787 },
  { lng: 146.307234759351, lat: -38.2595785712933 },
  { lng: 146.307505445841, lat: -38.2598870412788 },
  { lng: 146.307914767204, lat: -38.2603534958624 },
  { lng: 146.315086027432, lat: -38.2685257279679 },
  { lng: 146.315056304319, lat: -38.2687337591746 },
  { lng: 146.315041828947, lat: -38.2688350687895 },
  { lng: 146.315138387208, lat: -38.2692932792519 },
  { lng: 146.315144414525, lat: -38.2693311338691 },
  { lng: 146.316184443456, lat: -38.275863812905 },
  { lng: 146.318017692474, lat: -38.2782790448146 },
  { lng: 146.318329476302, lat: -38.278690206721 },
  { lng: 146.318541584442, lat: -38.2789700601462 },
  { lng: 146.318858096468, lat: -38.2793876625497 },
  { lng: 146.320488368282, lat: -38.2815386248964 },
  { lng: 146.321111561336, lat: -38.2823608598965 },
  { lng: 146.321381218452, lat: -38.2827166427793 },
  { lng: 146.321501579425, lat: -38.2828754462512 },
  { lng: 146.321647011368, lat: -38.2830476911934 },
  { lng: 146.321765953427, lat: -38.2831885598 },
  { lng: 146.321846055216, lat: -38.2833037259636 },
  { lng: 146.322059510021, lat: -38.2834958948325 },
  { lng: 146.322237544584, lat: -38.2836748792957 },
  { lng: 146.330165024643, lat: -38.2910241924461 },
  { lng: 146.333077802102, lat: -38.2926973564045 },
  { lng: 146.334221173761, lat: -38.2933541340538 },
  { lng: 146.334516197541, lat: -38.2935236015667 },
  { lng: 146.336526138514, lat: -38.2946781549522 },
  { lng: 146.341753767471, lat: -38.2944183781117 },
  { lng: 146.345027572668, lat: -38.2953153237467 },
  { lng: 146.345461874777, lat: -38.2954343121211 },
  { lng: 146.348488329707, lat: -38.2962634902581 },
  { lng: 146.35166209383, lat: -38.298576951441 },
  { lng: 146.352178098761, lat: -38.2986435360547 },
  { lng: 146.354004302027, lat: -38.2988791885794 },
  { lng: 146.354874146442, lat: -38.2989914344441 },
  { lng: 146.35494907158, lat: -38.2990011019204 },
  { lng: 146.37230971051, lat: -38.3012413187456 },
  { lng: 146.372834432177, lat: -38.3018863083839 },
  { lng: 146.373291682015, lat: -38.3024171480726 },
  { lng: 146.3734584845, lat: -38.3026107951651 },
  { lng: 146.374012267378, lat: -38.3032537039915 },
  { lng: 146.374286660408, lat: -38.3035722584158 },
  { lng: 146.374566975817, lat: -38.3038976878844 },
  { lng: 146.3749355129, lat: -38.3040439923544 },
  { lng: 146.377194919914, lat: -38.3049409441093 },
  { lng: 146.380763352964, lat: -38.3053805474233 },
  { lng: 146.380772345221, lat: -38.3053816202956 },
  { lng: 146.380982033103, lat: -38.3054066507917 },
  { lng: 146.380993394344, lat: -38.3054080389017 },
  { lng: 146.382863917575, lat: -38.3056365659706 },
  { lng: 146.384510393497, lat: -38.3058377201104 },
  { lng: 146.386703649703, lat: -38.3070394066311 },
  { lng: 146.387081716083, lat: -38.3070938372035 },
  { lng: 146.388292104144, lat: -38.307268097436 },
  { lng: 146.388875962921, lat: -38.3073521557628 },
  { lng: 146.392488693064, lat: -38.3061823516844 },
  { lng: 146.392956698664, lat: -38.3058895579342 },
  { lng: 146.393126592822, lat: -38.305783268641 },
  { lng: 146.394026893912, lat: -38.3052200217071 },
  { lng: 146.394277529215, lat: -38.3050632189273 },
  { lng: 146.395908031095, lat: -38.3040431437784 },
  { lng: 146.399118744984, lat: -38.3020344538086 },
  { lng: 146.399682307881, lat: -38.2992322305715 },
  { lng: 146.400710130302, lat: -38.2941215597213 },
  { lng: 146.401064853692, lat: -38.2923577552659 },
  { lng: 146.401443367031, lat: -38.290475663173 },
  { lng: 146.401952266127, lat: -38.2879452479187 },
  { lng: 146.402282114038, lat: -38.2863051360763 },
  { lng: 146.403701690973, lat: -38.2854877132074 },
  { lng: 146.406873055451, lat: -38.2836615730341 },
  { lng: 146.408661072453, lat: -38.2826319953253 },
  { lng: 146.40892504549, lat: -38.282479994309 },
  { lng: 146.41183707153, lat: -38.2808031866309 },
  { lng: 146.412014443523, lat: -38.2807010520265 },
  { lng: 146.412337337721, lat: -38.2805151228876 },
  { lng: 146.416123637492, lat: -38.2783348911899 },
  { lng: 146.416605182877, lat: -38.2780576068751 },
  { lng: 146.417028229807, lat: -38.277814007854 },
  { lng: 146.420214305126, lat: -38.2759793977404 },
  { lng: 146.420505871296, lat: -38.2758115079316 },
  { lng: 146.42079708798, lat: -38.275643818797 },
  { lng: 146.421819136591, lat: -38.2750553006294 },
  { lng: 146.422297103672, lat: -38.2727250610501 },
  { lng: 146.422422499437, lat: -38.2721137185458 },
  { lng: 146.422552547416, lat: -38.2714796937765 },
  { lng: 146.423516163765, lat: -38.2667817510058 },
  { lng: 146.423587968077, lat: -38.2664316906734 },
  { lng: 146.423639039435, lat: -38.2661826933194 },
  { lng: 146.425507318873, lat: -38.2570742429262 },
  { lng: 146.426099405107, lat: -38.254187637166 },
  { lng: 146.426136118151, lat: -38.254008648851 },
  { lng: 146.426195772543, lat: -38.2537178114052 },
  { lng: 146.432725339639, lat: -38.2495816757882 },
  { lng: 146.434928978211, lat: -38.2487047403768 },
  { lng: 146.435521683505, lat: -38.2484688730263 },
  { lng: 146.436251783891, lat: -38.2481783301114 },
  { lng: 146.436417621294, lat: -38.2481123358393 },
  { lng: 146.43648384257, lat: -38.2480859833827 },
  { lng: 146.437577731023, lat: -38.2482302791256 },
  { lng: 146.439356255617, lat: -38.2484648853629 },
  { lng: 146.440313426041, lat: -38.2485911456514 },
  { lng: 146.440672849507, lat: -38.2468034279489 },
  { lng: 146.441069724486, lat: -38.2448294238226 },
  { lng: 146.441070545145, lat: -38.2448253400668 },
  { lng: 146.443576731586, lat: -38.243341909786 },
  { lng: 146.446996663243, lat: -38.2413176275946 },
  { lng: 146.447436992638, lat: -38.2410576734221 },
  { lng: 146.449864022242, lat: -38.2396198893219 },
  { lng: 146.450208256493, lat: -38.2394159649306 },
  { lng: 146.450365935423, lat: -38.2393225436285 },
  { lng: 146.451144340968, lat: -38.2388613622439 },
  { lng: 146.453447081078, lat: -38.237497054765 },
  { lng: 146.456013330253, lat: -38.2359766282782 },
  { lng: 146.456084545573, lat: -38.2356357181297 },
  { lng: 146.456365725283, lat: -38.2342897123269 },
  { lng: 146.456905209617, lat: -38.2317071766979 },
  { lng: 146.457138880635, lat: -38.2305885977952 },
  { lng: 146.457191838644, lat: -38.2303350838075 },
  { lng: 146.457215267067, lat: -38.2302229352531 },
  { lng: 146.457096218821, lat: -38.2299727756223 },
  { lng: 146.45708597162, lat: -38.2299512428105 },
  { lng: 146.456725293377, lat: -38.2291933387784 },
  { lng: 146.456482326417, lat: -38.2286827856675 },
  { lng: 146.457064515361, lat: -38.2258685311935 },
  { lng: 146.457577783083, lat: -38.2233874340074 },
  { lng: 146.458862253248, lat: -38.2171784098902 },
  { lng: 146.459315816424, lat: -38.2169381916628 },
  { lng: 146.459580667177, lat: -38.2167979206073 },
  { lng: 146.459868357193, lat: -38.2166455532314 },
  { lng: 146.459900058797, lat: -38.2165228901 },
  { lng: 146.460184953049, lat: -38.2151351842498 },
  { lng: 146.460742747597, lat: -38.212418185827 },
  { lng: 146.461444787821, lat: -38.2089985793337 },
  { lng: 146.461687178849, lat: -38.2078179029184 },
  { lng: 146.462205631685, lat: -38.2052925377427 },
  { lng: 146.462449894726, lat: -38.2041027403413 },
  { lng: 146.462507892163, lat: -38.2038202403828 },
  { lng: 146.462566809892, lat: -38.2035332497026 },
  { lng: 146.462626261289, lat: -38.2032436676678 },
  { lng: 146.462677383134, lat: -38.2029946560152 },
  { lng: 146.464081389645, lat: -38.2027779196063 },
  { lng: 146.470109988189, lat: -38.2018472901452 },
  { lng: 146.473295133055, lat: -38.2013556014332 },
  { lng: 146.473788718593, lat: -38.2008200981087 },
  { lng: 146.474112664518, lat: -38.2004686432561 },
  { lng: 146.476723103376, lat: -38.2000810585612 },
  { lng: 146.478588718495, lat: -38.1998040620985 },
  { lng: 146.480231398307, lat: -38.1970624631497 },
  { lng: 146.48075269796, lat: -38.1961924234473 },
  { lng: 146.48521993334, lat: -38.1887366978902 },
  { lng: 146.485521092793, lat: -38.1882340670517 },
  { lng: 146.486233014181, lat: -38.1870458862916 },
  { lng: 146.48633275548, lat: -38.1868794203182 },
  { lng: 146.4866070247, lat: -38.1864216696831 },
  { lng: 146.487922438051, lat: -38.1861643666499 },
  { lng: 146.491025296407, lat: -38.1820655297245 },
  { lng: 146.494291922052, lat: -38.180228807656 },
  { lng: 146.495254330316, lat: -38.178993178824 },
  { lng: 146.497154661566, lat: -38.1765533573398 },
  { lng: 146.497188789121, lat: -38.1765293050318 },
  { lng: 146.498177693751, lat: -38.1758323701298 },
  { lng: 146.498371795978, lat: -38.1756201731902 },
  { lng: 146.498385232162, lat: -38.1756054836385 },
  { lng: 146.500528977344, lat: -38.1732618941391 },
  { lng: 146.503619211529, lat: -38.1699215157523 },
  { lng: 146.503895072955, lat: -38.1696233237843 },
  { lng: 146.503985699218, lat: -38.1695253621842 },
  { lng: 146.504193867939, lat: -38.1693003423638 },
  { lng: 146.506321580561, lat: -38.1670003966431 },
  { lng: 146.506393983526, lat: -38.1669221341786 },
  { lng: 146.506466388568, lat: -38.1668438689367 },
  { lng: 146.506975567562, lat: -38.1662934741985 },
  { lng: 146.507061296977, lat: -38.1662008049372 },
  { lng: 146.507147028444, lat: -38.1661081346811 },
  { lng: 146.507902076878, lat: -38.1652919659735 },
  { lng: 146.507985691931, lat: -38.1652015829491 },
  { lng: 146.508065137202, lat: -38.1651157064002 },
  { lng: 146.508103456348, lat: -38.165074286221 },
  { lng: 146.508402436031, lat: -38.1634413681006 },
  { lng: 146.506161372671, lat: -38.1628034849875 },
  { lng: 146.501257834162, lat: -38.1601136109596 },
  { lng: 146.500496244545, lat: -38.1596958351091 },
  { lng: 146.49940470004, lat: -38.1590970597144 },
  { lng: 146.498366550778, lat: -38.1589539348618 },
  { lng: 146.497625194818, lat: -38.1588517270687 },
  { lng: 146.496703293693, lat: -38.1587246289646 },
  { lng: 146.495038837824, lat: -38.1584951583186 },
  { lng: 146.492463139532, lat: -38.1581400565569 },
  { lng: 146.492016183797, lat: -38.1580784368677 },
  { lng: 146.487703537676, lat: -38.1574838706771 },
  { lng: 146.484743521307, lat: -38.1568136983041 },
  { lng: 146.480200815402, lat: -38.1557851915951 },
  { lng: 146.478680766409, lat: -38.155441039864 },
  { lng: 146.475448057556, lat: -38.1539355312684 },
  { lng: 146.470295664204, lat: -38.1515360042219 },
  { lng: 146.469302123729, lat: -38.156336194966 },
  { lng: 146.469274248516, lat: -38.1564708741958 },
  { lng: 146.46926305105, lat: -38.1565198250215 },
  { lng: 146.468903731474, lat: -38.158090617601 },
  { lng: 146.468709833966, lat: -38.1589382592804 },
  { lng: 146.468320919771, lat: -38.1606384300624 },
  { lng: 146.467741015661, lat: -38.1631735328828 },
  { lng: 146.467669062121, lat: -38.1634880844354 },
  { lng: 146.467571871438, lat: -38.1634826156748 },
  { lng: 146.467069803739, lat: -38.1634543721069 },
  { lng: 146.46676695452, lat: -38.1649534651061 },
  { lng: 146.465768542873, lat: -38.1698955763388 },
  { lng: 146.465332375548, lat: -38.1720545866448 },
  { lng: 146.464216568234, lat: -38.1719104460624 },
  { lng: 146.4639958011, lat: -38.1718819273038 },
  { lng: 146.454640699406, lat: -38.1706734237505 },
  { lng: 146.45379852421, lat: -38.1705646306418 },
  { lng: 146.453534016943, lat: -38.1708112571943 },
  { lng: 146.452798116128, lat: -38.1714974076174 },
  { lng: 146.451319238107, lat: -38.172876309058 },
  { lng: 146.451262407521, lat: -38.1729292971375 },
  { lng: 146.451423056954, lat: -38.1731472247693 },
  { lng: 146.451584675469, lat: -38.1733664694175 },
  { lng: 146.45141215116, lat: -38.1739902838841 },
  { lng: 146.450420688466, lat: -38.1760645980719 },
  { lng: 146.451005569481, lat: -38.176245121684 },
  { lng: 146.451937940179, lat: -38.1774190649733 },
  { lng: 146.450669199944, lat: -38.1823604642442 },
  { lng: 146.448431085402, lat: -38.1820024183497 },
  { lng: 146.449505905591, lat: -38.1777502677688 },
  { lng: 146.449364610912, lat: -38.1780481237987 },
  { lng: 146.44640156251, lat: -38.1799485965777 },
  { lng: 146.444961519644, lat: -38.1785497348574 },
  { lng: 146.447443505407, lat: -38.1769578129494 },
  { lng: 146.449066929274, lat: -38.1735346162858 },
  { lng: 146.448810651283, lat: -38.1731868546193 },
  { lng: 146.44891948968, lat: -38.1728497337162 },
  { lng: 146.449028751527, lat: -38.1725113038713 },
  { lng: 146.449162178112, lat: -38.1720980311365 },
  { lng: 146.449841831185, lat: -38.1714643209357 },
  { lng: 146.452904334394, lat: -38.1686088398559 },
  { lng: 146.456299585532, lat: -38.1690612170443 },
  { lng: 146.460471968221, lat: -38.1696171384274 },
  { lng: 146.463433028357, lat: -38.1700116643191 },
  { lng: 146.464822806354, lat: -38.163133331593 },
  { lng: 146.4648386871, lat: -38.1630547350826 },
  { lng: 146.465014727047, lat: -38.162183469716 },
  { lng: 146.465144935946, lat: -38.1615390339729 },
  { lng: 146.465786796901, lat: -38.1615751163483 },
  { lng: 146.467024495204, lat: -38.1561653079634 },
  { lng: 146.468107113527, lat: -38.1508309096416 },
  { lng: 146.468142070462, lat: -38.1506586601243 },
  { lng: 146.460983840312, lat: -38.1497664900736 },
  { lng: 146.460747999437, lat: -38.1497372700847 },
  { lng: 146.456929882767, lat: -38.1492669291205 },
  { lng: 146.456826092711, lat: -38.149229247264 },
  { lng: 146.455030117593, lat: -38.1485772012662 },
  { lng: 146.450559220502, lat: -38.1469539976093 },
  { lng: 146.449150033697, lat: -38.1464423788133 },
  { lng: 146.449039854065, lat: -38.146410703587 },
  { lng: 146.448491606302, lat: -38.1462530896596 },
  { lng: 146.448203493995, lat: -38.1461702621275 },
  { lng: 146.447003614818, lat: -38.1458253125675 },
  { lng: 146.441926362468, lat: -38.1457402790752 },
  { lng: 146.440895546228, lat: -38.1457230150643 },
  { lng: 146.439739665952, lat: -38.1456422267978 },
  { lng: 146.435982828715, lat: -38.1453796520816 },
  { lng: 146.432770956756, lat: -38.1451551648612 },
  { lng: 146.430851446124, lat: -38.1450183567165 },
  { lng: 146.426760580124, lat: -38.1439851940455 },
  { lng: 146.426290119615, lat: -38.1438663780452 },
  { lng: 146.422833534858, lat: -38.1428884272686 },
  { lng: 146.418915312744, lat: -38.1420779609344 },
  { lng: 146.411746569498, lat: -38.1415931969511 },
  { lng: 146.409439438624, lat: -38.1409509837772 },
  { lng: 146.409298254643, lat: -38.1409116839573 },
  { lng: 146.399570115559, lat: -38.13974290695 },
  { lng: 146.399191554302, lat: -38.1396974247361 },
  { lng: 146.398289097408, lat: -38.1395115297918 },
  { lng: 146.395587239548, lat: -38.1389549796163 },
  { lng: 146.394865870222, lat: -38.1388063803336 },
  { lng: 146.395022469826, lat: -38.1387612603803 },
  { lng: 146.402175002612, lat: -38.1367001851974 },
  { lng: 146.402441741752, lat: -38.1367198788411 },
  { lng: 146.413954436523, lat: -38.137566126163 },
  { lng: 146.414387323134, lat: -38.1375979461074 },
  { lng: 146.424579565454, lat: -38.1383471323682 },
  { lng: 146.429398361072, lat: -38.1386269227947 }
]
