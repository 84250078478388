import React, { useCallback, useContext, useState } from 'react'
import { withRouter, Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import { auth } from '../../../authentication/base.js'
import { AuthContext } from '../../../authentication/Auth.js'
import { Button } from 'antd'
import './index.css'
import ReactGA from 'react-ga'
import logo from '../../layout/logo_full.png'

const Login = ({ history }) => {
  const [loading, setLoading] = useState(false)

  const handleLogin = useCallback(
    async event => {
      event.preventDefault()
      const { email, password } = event.target.elements
      try {
        setLoading(true)
        await auth.signInWithEmailAndPassword(email.value, password.value)
        history.push('/')
        ReactGA.event({
          category: 'Auth',
          action: 'Logged In'
        })
      } catch (error) {
        setLoading(false)
        alert(error)
      }
    },
    [history]
  )

  const { currentUser } = useContext(AuthContext)

  return currentUser ? (
    <Redirect to='/' />
  ) : (
    <div className='auth-form-container'>
      <form onSubmit={handleLogin} className='authForm'>
        <img src={logo} alt='My logo' className='auth-logo' style={{ margin: 'auto' }} />

        {/* <div className='auth-form-title'>Login</div> */}
        <input name='email' type='email' placeholder='Email' className='authInput' />
        <input name='password' type='password' placeholder='Password' className='authInput' />
        <Button
          type='primary'
          htmlType='submit'
          disabled={loading ? true : false}
          loading={loading ? true : false}
          size='large'>
          {loading ? 'Loading' : 'Log in'}
        </Button>
        <div className='authLink'>
          Forgot your password?{' '}
          <Link to='/password-reset' className='authLink-action'>
            Reset it.
          </Link>
        </div>
      </form>
    </div>
  )
}

export default withRouter(Login)
