import React from 'react'
import { withData } from './context'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { TopLoader } from '../../../layout/Loading'

import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'

const localizer = momentLocalizer(moment)

export default withData(({ data, startBlank, setSelectedEvent, loading }) => (
  <>
    <TopLoader loading={loading} />
    <Calendar
      localizer={localizer}
      events={data && data.length > 0 ? data : []}
      allDayAccessor={r => true}
      titleAccessor={r => r.form_values['81f1']}
      startAccessor={r => moment(r.form_values['5bbd'])}
      endAccessor={r => moment(r.form_values['887d'])}
      views={['month', 'week', 'day']}
      style={{ height: '85vh' }}
      eventPropGetter={e => ({
        className: e.form_values['9077'] && `event${e.form_values['9077'].choice_values[0]} eventAll`
      })}
      onSelectEvent={e => setSelectedEvent(e)}
      selectable
      onSelectSlot={e => startBlank(e)}
    />
  </>
))
