import React, { useState, useEffect } from 'react'
// import { FulcrumContext } from '../../../../Fulcrum'
import { t_overlays, Propertys, t_heatmap } from '../resources'

export const ReportContext = React.createContext()

export const Provider = ({ children }) => {
  const [propertys, setPropertys] = useState(Propertys)
  const [overlays, setOverlays] = useState(t_overlays)
  const [heatmap, setHeatmap] = useState(t_heatmap)
  const [mapStyle, setMapStyle] = useState('satelite')

  useEffect(() => {}, [])

  return (
    <ReportContext.Provider
      value={{ propertys, setPropertys, overlays, setOverlays, mapStyle, setMapStyle, heatmap, setHeatmap }}>
      {children}
    </ReportContext.Provider>
  )
}

export const withData = Component => props => (
  <ReportContext.Consumer>{contexts => <Component {...props} {...contexts} />}</ReportContext.Consumer>
)
