import React, { useEffect, useState } from 'react'
import { withData } from './context'
import Table from 'antd/lib/table'
import moment from 'moment'

import { storage } from '../../../../authentication/base'

export default withData(({ prestarts, itemSelected, loading }) => {
  const [forThis, setForThis] = useState([])
  const [image, setImage] = useState(null)

  useEffect(() => {
    if (itemSelected) {
      storage
        .ref(`/${itemSelected.value}.png`)
        .getDownloadURL()
        .then(setImage)
        .catch(console.error)
    }
    return () => setImage(null)
  }, [itemSelected])

  useEffect(() => {
    if (prestarts) {
      let _forThis = itemSelected
        ? prestarts.filter(
            p =>
              p.form_values['c5c8'] &&
              p.form_values['07dc'] &&
              p.form_values['07dc'].choice_values[p.form_values['07dc'].choice_values.length - 1] === itemSelected.value
          )
        : prestarts.filter(r => r.form_values['c5c8'])

      // _forThis.sort((a, b) => moment(a.form_values['c5c8']).isBefore(b.form_values['c5c8']))
      _forThis.sort((a, b) => new Date(b.form_values['c5c8']) - new Date(a.form_values['c5c8']))
      setForThis(_forThis)
    }
  }, [prestarts, itemSelected])

  return (
    <>
      <h3 className='section-title'>Prestarts</h3>
      <p className='section-description'>
        All the prestarts submitted for all items of plant, vehicles, and equipment.
      </p>
      <div style={{ display: 'flex' }}>
        <Table
          columns={[
            {
              title: '',
              dataIndex: 'status',
              render: v => <div className={`badge ${v.split(' ').join('-')}`} />,
              width: 20
            },
            {
              title: 'On',
              dataIndex: 'form_values[07dc]',
              render: v => v && v.choice_values[v.choice_values.length - 1],
              className: itemSelected && 'hide-column'
            },
            { title: 'Date', dataIndex: 'form_values[c5c8]', render: v => v && moment(v).format('Do MMM YYYY') },
            { title: 'Completed By', dataIndex: 'form_values[947b].choice_values[0]' },
            { title: 'Fix List', dataIndex: 'form_values[38e9]', render: v => v && v.split(',').join(' ') },
            {
              title: 'Link',
              dataIndex: 'id',
              render: v => (
                <a target='_blank' rel='noopener noreferrer' href={`https://web.fulcrumapp.com/records/${v}`}>
                  Open
                </a>
              )
            }
          ]}
          dataSource={forThis}
          size='small'
          pagination={{ pageSize: 5 }}
          loading={loading}
          style={{ flex: 1 }}
          rowKey='id'
        />
        {image && <img src={image} className='equipment_photo' alt='img' />}
      </div>
    </>
  )
})
