import React from 'react'

//Context
import { ReportProvider } from './components/context'

//parts
import Map from './components/Map'
import ActionBar from './components/ActionBar'
import JobDetails from './components/JobDetails'
import DrillersLog from './components/DrillersLog'
import Photos from './components/Photos'

import './index.css'

const DailyReport = () => (
  <ReportProvider>
    <div style={{ display: 'grid', gridGap: 10 }}>
      <ActionBar />
      <Map />
      <JobDetails />
      <DrillersLog />
      <Photos />
    </div>
  </ReportProvider>
)

export default DailyReport
