import React from 'react'
import { withData } from './context'
import Table from 'antd/lib/table'
import moment from 'moment'

export default withData(
  ({ selectedJob }) =>
    selectedJob && (
      <>
        <h3 className='section-title'>Details</h3>
        <Table
          pagination={false}
          bordered
          id='boresTableOne'
          className='boreTables tableResizer'
          columns={[
            {
              title: 'Job Title',
              dataIndex: 'form_values.b62d',
              key: 'title',
              width: 100
            },
            {
              title: 'Start Date',
              dataIndex: 'form_values.c769',
              render: v => v && moment(v).format('Do MMM YYYY'),
              key: 'start',
              width: 50
            },
            {
              title: 'Location',
              dataIndex: 'form_values["94d5"]',
              key: 'location',
              width: 100
            },
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
              width: 50
            }
          ]}
          dataSource={[selectedJob]}
          rowKey='id'
          size='middle'
        />
        <Table
          pagination={false}
          bordered
          id='boresTableOne'
          className='boreTables tableResizer'
          columns={[
            {
              title: 'Driller',
              dataIndex: 'form_values[3493].choice_values[0]',
              key: 'driller',
              width: 100
            },
            {
              title: 'Offsider',
              dataIndex: 'form_values[fb65]choice_values',
              render: v => v && v.map(i => i + ', '),
              key: 'Offsider',
              width: 100
            },
            {
              title: 'Rig',
              dataIndex: 'form_values["0a37"].choice_values["0"]',
              key: 'Rig',
              width: 40,
              className: 'hideThis'
            }
          ]}
          dataSource={[selectedJob]}
          rowKey='id'
          size='middle'
        />
      </>
    )
)
