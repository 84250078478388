import React, { useEffect, useState, useContext } from 'react'

import { FulcrumContext } from '../../../Fulcrum'

import ActionBar from './components/ActionBar'
import { Table } from 'antd'
import columns from './components/columns'
import layout from './components/timesheetLayout'
import { TopLoader } from '../../layout/Loading'

import './index.css'
const Timesheet = () => {
  const { fulcrum } = useContext(FulcrumContext)

  const [timesheets, setTimesheets] = useState(null)
  const [data, setData] = useState(null)
  const [employee, setEmployee] = useState(null)
  const [timesheet, setTimesheet] = useState(null)

  const [formatted, setFormatted] = useState(null)

  const [loading, setLoading] = useState(false)
  const form_id = '50038e62-4596-4da6-96e9-5650dc9964b7'

  useEffect(() => {
    setLoading(true)
    fulcrum
      .getLocalData(form_id)
      .then(setTimesheets)
      .then(() => {
        const d = new Date()
        const days = 60
        d.setDate(d.getDate() - days)
        fulcrum.getAllData(form_id, undefined, undefined, { newest_first: true, updated_since: d }).then(setTimesheets)
      })
      .then(() => setLoading(false))
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    let people = []
    timesheets &&
      timesheets.forEach((r) => {
        if (r.form_values['1734']) {
          let name = r.form_values['1734'].choice_values[0]
          let index = people.findIndex((i) => i.name === name)
          index !== -1 ? people[index].timesheets.push(r) : people.push({ name, timesheets: [r] })
        }
      })
    setData(people)
  }, [timesheets])

  useEffect(() => {
    if (employee) setTimesheet(employee.timesheets[0])
    return () => setTimesheet(null)
  }, [employee])

  useEffect(() => {
    timesheet && setFormatted(layout(timesheet))
  }, [timesheet])

  return (
    <div style={{ display: 'grid', gridGap: 10 }}>
      <TopLoader loading={loading} />
      <ActionBar
        loading={loading}
        data={data}
        setTimesheet={setTimesheet}
        timesheet={timesheet}
        employee={employee}
        setEmployee={setEmployee}
      />
      <Table
        pagination={false}
        bordered
        loading={loading}
        columns={columns(timesheet)}
        className='timesheet-table'
        dataSource={formatted}
        rowKey={() => Math.random()}
        rowClassName={(i) => ['Finish', 'Total', 'Personal Leave'].includes(i[0]) && 'timesheet-thick'}
        size='middle'
      />
    </div>
  )
}

export default Timesheet
