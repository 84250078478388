import React from 'react'

import { Provider } from './components/context'

// Parts
// import ActionBar from './components/ActionBar'
import Map from './components/Map'

import './index.css'

export default () => (
  <Provider>
    {/* <ActionBar /> */}
    <Map />
  </Provider>
)
