import React, { useState, useEffect } from 'react'
import { db } from '../../../authentication/base'

import { Table } from 'antd'
import columns from './columns'
import CreateAccount from './CreateAccount'
import './index.css'

const Users = () => {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    db.ref('users')
      .once('value')
      .then(resp => {
        let users = []
        Object.entries(resp.val()).forEach(user => {
          users.push({
            id: user[0],
            user: user[1]
          })
        })
        setData(users)
      })
      .then(() => setLoading(false))
  }, [])

  const deleteUser = id => {
    console.log(id)
  }

  return (
    <div>
      <CreateAccount />
      <Table
        bordered
        pagination={false}
        columns={columns(deleteUser)}
        loading={loading}
        dataSource={data}
        rowClassName={record => record.status}
        rowKey='id'
      />
    </div>
  )
}

export default Users
