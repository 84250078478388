export default [
  { lat: -38.16013554655013, lng: 146.37888844550787 },
  { lat: -38.161485285097406, lng: 146.40601094306646 },
  { lat: -38.148526763656186, lng: 146.4417165094727 },
  { lat: -38.14609678454842, lng: 146.4798253351563 },
  { lat: -38.15014670475797, lng: 146.5182774835938 },
  { lat: -38.139886470195314, lng: 146.5230840021485 },
  { lat: -38.127734326309735, lng: 146.55947621406256 },
  { lat: -38.10990752208157, lng: 146.58213551582037 },
  { lat: -38.15635614569376, lng: 146.63157399238287 },
  { lat: -38.15149662819402, lng: 146.66933949531256 },
  { lat: -38.207361527060826, lng: 146.68478901923834 },
  { lat: -38.22974943047507, lng: 146.6446202570313 },
  { lat: -38.25293926803004, lng: 146.5841954523438 },
  { lat: -38.25159122419086, lng: 146.55295308173834 },
  { lat: -38.254556887629825, lng: 146.49870808662115 },
  { lat: -38.28882425442783, lng: 146.48600514472662 },
  { lat: -38.30552966254657, lng: 146.46437581123052 },
  { lat: -38.31280339608209, lng: 146.43828328193365 },
  { lat: -38.334620219373896, lng: 146.4142506891602 },
  { lat: -38.34781486296212, lng: 146.3524525934571 },
  { lat: -38.333542999512815, lng: 146.3091939264649 },
  { lat: -38.286668437740005, lng: 146.35519917548834 },
  { lat: -38.26187194381915, lng: 146.3593190485352 },
  { lat: -38.24057254575544, lng: 146.34352620185552 },
  { lat: -38.217918237765424, lng: 146.32807667792974 },
  { lat: -38.19201896079788, lng: 146.33288319648443 },
  { lat: -38.16988936579321, lng: 146.35691578925787 }
]
