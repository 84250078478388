import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { AuthContext } from '../../../authentication/Auth.js'

import options from './options'
import './nav_style.css'
import Profile from './Profile'
import logo from './logo_white.png'

const Nav = () => {
  const { currentUser, user } = useContext(AuthContext)
  const [location, setLocation] = useState(null)

  useEffect(() => {
    setLocation(window.location.pathname)
  }, [])

  const menuItem = (item) => (
    <div key={item.link} className={`nav-option ${location === item.link ? 'nav-option-active' : undefined}`}>
      <Link to={item.link} key={item.link} onClick={() => setLocation(item.link)}>
        {item.title}
      </Link>
    </div>
  )

  if (!currentUser || !user) return <div />

  return (
    <div id='nav' className='no-print'>
      <div id='company-logo-container'>
        <img src={logo} alt='My logo' id='company-logo' />
      </div>

      {options.map((item) => {
        if (item.link === '/hot-springs') {
          if (['jim@infosync.solutions', 'ross@drilltec.com.au'].includes(currentUser.email)) {
            return menuItem(item)
          }
        } else if (item.link === '/delwp') {
          if (user.role === 'admin' || currentUser.email === 'ben.hall@eartheon.com.au') {
            return menuItem(item)
          }
        } else if (item.adminOnly) {
          if (user.role === 'admin') {
            return menuItem(item)
          } else return null
        } else {
          return menuItem(item)
        }
      })}
      <div style={{ flexGrow: 1 }} />

      <Profile />
    </div>
  )
}

export default Nav
