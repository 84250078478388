export default [
  [
    { lng: 146.498867865743, lat: -38.2109610590855 },
    { lng: 146.502647048779, lat: -38.2117496126995 },
    { lng: 146.508066573028, lat: -38.2136362219112 },
    { lng: 146.51359873723, lat: -38.2153272460444 },
    { lng: 146.521408071784, lat: -38.2178937981871 },
    { lng: 146.521415798462, lat: -38.2178963369242 },
    { lng: 146.521851291386, lat: -38.2180338410611 },
    { lng: 146.523785287154, lat: -38.2186444895586 },
    { lng: 146.525964266067, lat: -38.219170878314 },
    { lng: 146.52903866644, lat: -38.2196245985622 },
    { lng: 146.533949473639, lat: -38.219866670961 },
    { lng: 146.53513569192, lat: -38.2203039786902 },
    { lng: 146.538738159027, lat: -38.2207781014244 },
    { lng: 146.542289640009, lat: -38.2206091399087 },
    { lng: 146.542465579883, lat: -38.2205809797398 },
    { lng: 146.542549759447, lat: -38.2205782999409 },
    { lng: 146.54268221957, lat: -38.2205474500773 },
    { lng: 146.544394508266, lat: -38.2202997203492 },
    { lng: 146.545157516609, lat: -38.2201893288249 },
    { lng: 146.545132979591, lat: -38.2203175099522 },
    { lng: 146.545013760458, lat: -38.2209403196158 },
    { lng: 146.544870720192, lat: -38.2216874996339 },
    { lng: 146.544861289997, lat: -38.2217367796047 },
    { lng: 146.544526186477, lat: -38.2222797955832 },
    { lng: 146.54389807489, lat: -38.2223396453641 },
    { lng: 146.543627770132, lat: -38.2223654001558 },
    { lng: 146.543413089658, lat: -38.2222638897183 },
    { lng: 146.543220730126, lat: -38.2222232397312 },
    { lng: 146.54302915959, lat: -38.2222283699219 },
    { lng: 146.542604589759, lat: -38.2221947802384 },
    { lng: 146.542311469523, lat: -38.2222001398454 },
    { lng: 146.542195809998, lat: -38.2221739104241 },
    { lng: 146.54207195002, lat: -38.222111000078 },
    { lng: 146.542045299551, lat: -38.2220489501666 },
    { lng: 146.541935085294, lat: -38.2220391120894 },
    { lng: 146.5417743696, lat: -38.2220247603753 },
    { lng: 146.535913567973, lat: -38.2219114989736 },
    { lng: 146.533300825581, lat: -38.2216563719794 },
    { lng: 146.533345519258, lat: -38.221649155075 },
    { lng: 146.529919491882, lat: -38.2212991586263 },
    { lng: 146.521686409533, lat: -38.2193823070348 },
    { lng: 146.521658954443, lat: -38.2193731663328 },
    { lng: 146.52121970484, lat: -38.2192269192607 },
    { lng: 146.503408799204, lat: -38.2132968173054 },
    { lng: 146.501478804877, lat: -38.2125229091435 },
    { lng: 146.498683540275, lat: -38.2118707044284 },
    { lng: 146.498678009007, lat: -38.211869846966 },
    { lng: 146.49846281373, lat: -38.2118365172476 },
    { lng: 146.498457038269, lat: -38.2118361258932 },
    { lng: 146.495128714474, lat: -38.2116110120562 },
    { lng: 146.492590529545, lat: -38.2117804604045 },
    { lng: 146.489217027642, lat: -38.2125409198659 },
    { lng: 146.489216202694, lat: -38.2125449833884 },
    { lng: 146.487278739702, lat: -38.2132754992203 },
    { lng: 146.485424261146, lat: -38.214229096783 },
    { lng: 146.482164432831, lat: -38.2163543523287 },
    { lng: 146.480093947307, lat: -38.2178197103722 },
    { lng: 146.475405333605, lat: -38.2209268761033 },
    { lng: 146.47514628546, lat: -38.2210984525899 },
    { lng: 146.474227306058, lat: -38.2217074483859 },
    { lng: 146.470136581925, lat: -38.2243124262035 },
    { lng: 146.466059762277, lat: -38.2270515677939 },
    { lng: 146.46502132059, lat: -38.2277492466434 },
    { lng: 146.464866228763, lat: -38.227853397697 },
    { lng: 146.463396683978, lat: -38.2288406559052 },
    { lng: 146.462441652682, lat: -38.2294308107367 },
    { lng: 146.460452423893, lat: -38.2305809041508 },
    { lng: 146.460525893129, lat: -38.2305931613851 },
    { lng: 146.458354570323, lat: -38.231835887287 },
    { lng: 146.458079294486, lat: -38.2324043576496 },
    { lng: 146.457958015048, lat: -38.2325987908416 },
    { lng: 146.457601301284, lat: -38.233170667096 },
    { lng: 146.456554519619, lat: -38.2341640402833 },
    { lng: 146.457285730006, lat: -38.2332651996598 },
    { lng: 146.458107079715, lat: -38.2318760797808 },
    { lng: 146.458312580226, lat: -38.2308741199588 },
    { lng: 146.458447335993, lat: -38.2302170322348 },
    { lng: 146.458484999848, lat: -38.2300299900858 },
    { lng: 146.458519880237, lat: -38.2298633002064 },
    { lng: 146.458558739655, lat: -38.229848249909 },
    { lng: 146.458721879806, lat: -38.2297840499577 },
    { lng: 146.458884199486, lat: -38.2297185497473 },
    { lng: 146.459045680063, lat: -38.2296517900479 },
    { lng: 146.459206300364, lat: -38.2295837395861 },
    { lng: 146.459326954453, lat: -38.229531380801 },
    { lng: 146.459366040251, lat: -38.2295144202311 },
    { lng: 146.459524910537, lat: -38.2294438597841 },
    { lng: 146.459682859464, lat: -38.2293720399548 },
    { lng: 146.459839890436, lat: -38.2292989598046 },
    { lng: 146.459995980063, lat: -38.2292246502515 },
    { lng: 146.459837297789, lat: -38.2294964110319 },
    { lng: 146.459648510194, lat: -38.2298197301102 },
    { lng: 146.460767810463, lat: -38.2292561897438 },
    { lng: 146.461730330287, lat: -38.2287243702584 },
    { lng: 146.46266690057, lat: -38.2281455503205 },
    { lng: 146.464283526024, lat: -38.2270595108661 },
    { lng: 146.465311670297, lat: -38.2263688101934 },
    { lng: 146.465312241822, lat: -38.2263684834226 },
    { lng: 146.465318288827, lat: -38.2263643657109 },
    { lng: 146.465854315392, lat: -38.226004194683 },
    { lng: 146.472168564205, lat: -38.2217614672728 },
    { lng: 146.475348672743, lat: -38.2196246595809 },
    { lng: 146.475473700554, lat: -38.2195406500148 },
    { lng: 146.475479638527, lat: -38.2195368511291 },
    { lng: 146.475722750238, lat: -38.219381340422 },
    { lng: 146.475728639986, lat: -38.2193774132793 },
    { lng: 146.481698825385, lat: -38.2153971412326 },
    { lng: 146.484994039638, lat: -38.2132002499714 },
    { lng: 146.485000428454, lat: -38.2131972405745 },
    { lng: 146.487478179963, lat: -38.212030350345 },
    { lng: 146.487484889444, lat: -38.2120283783482 },
    { lng: 146.489437680176, lat: -38.2114545502804 },
    { lng: 146.489444316903, lat: -38.2114525997926 },
    { lng: 146.489663290197, lat: -38.2113882501793 },
    { lng: 146.489670028576, lat: -38.2113865894284 },
    { lng: 146.492797443069, lat: -38.2106156408085 },
    { lng: 146.495125182626, lat: -38.2106633393106 },
    { lng: 146.498647166229, lat: -38.2109175102057 },
    { lng: 146.498665488783, lat: -38.2109188322325 },
    { lng: 146.498867865743, lat: -38.2109610590855 }
  ],
  [
    { lng: 146.606425245528, lat: -38.2011699308743 },
    { lng: 146.604032861059, lat: -38.2021871720543 },
    { lng: 146.603532743595, lat: -38.202139982975 },
    { lng: 146.603332633651, lat: -38.2021211016552 },
    { lng: 146.603326427372, lat: -38.2021223463885 },
    { lng: 146.601142665813, lat: -38.2025600674482 },
    { lng: 146.599152974719, lat: -38.2027473911078 },
    { lng: 146.602985870434, lat: -38.2037526403736 },
    { lng: 146.60294906045, lat: -38.2039289301286 },
    { lng: 146.603155680978, lat: -38.2039560496155 },
    { lng: 146.603159709073, lat: -38.2039565790086 },
    { lng: 146.603760687243, lat: -38.2040354600612 },
    { lng: 146.600158836766, lat: -38.203860126267 },
    { lng: 146.598312761675, lat: -38.2034752880784 },
    { lng: 146.595679174499, lat: -38.2032950366338 },
    { lng: 146.592105714791, lat: -38.2033627817236 },
    { lng: 146.589706614618, lat: -38.2037270163535 },
    { lng: 146.589092231738, lat: -38.2039169819281 },
    { lng: 146.589011568443, lat: -38.2041161461492 },
    { lng: 146.588329943471, lat: -38.2065839499699 },
    { lng: 146.588801303435, lat: -38.2043278639239 },
    { lng: 146.588849704554, lat: -38.2040990858288 },
    { lng: 146.588621333441, lat: -38.204070006566 },
    { lng: 146.588618318638, lat: -38.2040705769337 },
    { lng: 146.588611652761, lat: -38.2040731612801 },
    { lng: 146.588211571653, lat: -38.2059998137031 },
    { lng: 146.588428920667, lat: -38.2048391558322 },
    { lng: 146.588533960551, lat: -38.2043330306569 },
    { lng: 146.5884839983, lat: -38.2041226310713 },
    { lng: 146.584533469342, lat: -38.2056532522034 },
    { lng: 146.57644808357, lat: -38.2102052305595 },
    { lng: 146.572323909028, lat: -38.212514316449 },
    { lng: 146.572317214878, lat: -38.2125181210288 },
    { lng: 146.57204720044, lat: -38.2126715846257 },
    { lng: 146.569039473938, lat: -38.2143810431175 },
    { lng: 146.569034897905, lat: -38.214383707284 },
    { lng: 146.568811109913, lat: -38.2145139536187 },
    { lng: 146.56861380065, lat: -38.2146287913292 },
    { lng: 146.566449337284, lat: -38.2158885444078 },
    { lng: 146.564221804238, lat: -38.2170738603778 },
    { lng: 146.560441467062, lat: -38.2186414984145 },
    { lng: 146.558459809284, lat: -38.2193825920849 },
    { lng: 146.558218895431, lat: -38.2194726883624 },
    { lng: 146.558210508403, lat: -38.2194760409912 },
    { lng: 146.556042016666, lat: -38.2203428330134 },
    { lng: 146.553379624508, lat: -38.2208069607342 },
    { lng: 146.553146468827, lat: -38.2208476063822 },
    { lng: 146.552904201495, lat: -38.220889840088 },
    { lng: 146.545401573976, lat: -38.2221977532689 },
    { lng: 146.545522119769, lat: -38.2219842302043 },
    { lng: 146.545653580275, lat: -38.2217604297256 },
    { lng: 146.546077779911, lat: -38.2210382596958 },
    { lng: 146.546431460173, lat: -38.2204361301543 },
    { lng: 146.546822089822, lat: -38.2197710998836 },
    { lng: 146.547040279726, lat: -38.2194718097258 },
    { lng: 146.547001205071, lat: -38.2201496106915 },
    { lng: 146.550879661976, lat: -38.2196871338861 },
    { lng: 146.553164896505, lat: -38.2191550564053 },
    { lng: 146.553404021585, lat: -38.2190993789143 },
    { lng: 146.553639981118, lat: -38.2190444399385 },
    { lng: 146.553937618507, lat: -38.2189751396964 },
    { lng: 146.555866600415, lat: -38.2186242767476 },
    { lng: 146.557911675898, lat: -38.2182952679184 },
    { lng: 146.558388864802, lat: -38.2182110779919 },
    { lng: 146.558397700013, lat: -38.2182095187554 },
    { lng: 146.558634973247, lat: -38.2181431252827 },
    { lng: 146.560651897354, lat: -38.2175787517392 },
    { lng: 146.563306900583, lat: -38.2164999912146 },
    { lng: 146.567520260558, lat: -38.2142295199468 },
    { lng: 146.567733792513, lat: -38.214114455195 },
    { lng: 146.567963798086, lat: -38.2139905123931 },
    { lng: 146.568489407716, lat: -38.2137072742206 },
    { lng: 146.571838104779, lat: -38.2116782552083 },
    { lng: 146.572331160479, lat: -38.2113501731667 },
    { lng: 146.572604287554, lat: -38.2111684340301 },
    { lng: 146.575304649936, lat: -38.2093715998653 },
    { lng: 146.585537702379, lat: -38.2033085416808 },
    { lng: 146.585541530826, lat: -38.2033062740954 },
    { lng: 146.585901383603, lat: -38.2031788526977 },
    { lng: 146.587430033257, lat: -38.2026375698706 },
    { lng: 146.589021925863, lat: -38.2021702312051 },
    { lng: 146.589028146327, lat: -38.2021684050022 },
    { lng: 146.589285179309, lat: -38.202037712006 },
    { lng: 146.589306830424, lat: -38.2019352395465 },
    { lng: 146.589471264272, lat: -38.2011569068161 },
    { lng: 146.58992981022, lat: -38.1989864296957 },
    { lng: 146.590256460037, lat: -38.1974402197519 },
    { lng: 146.590208006037, lat: -38.1979172060042 },
    { lng: 146.590002327448, lat: -38.198993094212 },
    { lng: 146.590001859647, lat: -38.1989959131892 },
    { lng: 146.589749779032, lat: -38.2005157410195 },
    { lng: 146.589451656921, lat: -38.2019521822356 },
    { lng: 146.592890949877, lat: -38.2017116834146 },
    { lng: 146.596998724041, lat: -38.2016889109375 },
    { lng: 146.601261070089, lat: -38.2015391073574 },
    { lng: 146.603439565385, lat: -38.200967457403 },
    { lng: 146.603584719603, lat: -38.2008858618043 },
    { lng: 146.603737697589, lat: -38.2007998698077 },
    { lng: 146.60383100262, lat: -38.2007473607489 },
    { lng: 146.603837644107, lat: -38.2007436181294 },
    { lng: 146.603916986618, lat: -38.2006990177188 },
    { lng: 146.605949764627, lat: -38.199776036884 },
    { lng: 146.607588633478, lat: -38.198523170549 },
    { lng: 146.608701326283, lat: -38.1971200258717 },
    { lng: 146.608703449544, lat: -38.1971173478068 },
    { lng: 146.609068500323, lat: -38.1969864098144 },
    { lng: 146.610072597671, lat: -38.1923335902004 },
    { lng: 146.609296356589, lat: -38.1896105456795 },
    { lng: 146.609295513568, lat: -38.1896075891259 },
    { lng: 146.609610818375, lat: -38.1864411777365 },
    { lng: 146.610039697904, lat: -38.1850918512909 },
    { lng: 146.610317347559, lat: -38.1842183173829 },
    { lng: 146.611196900468, lat: -38.1829191980678 },
    { lng: 146.61157001692, lat: -38.1824849779493 },
    { lng: 146.614565382503, lat: -38.1819787608704 },
    { lng: 146.614194087374, lat: -38.1824132689871 },
    { lng: 146.611695750024, lat: -38.1853197999874 },
    { lng: 146.610518384046, lat: -38.1879468254484 },
    { lng: 146.610719518459, lat: -38.1897686558995 },
    { lng: 146.610728037141, lat: -38.1898017348515 },
    { lng: 146.6115020586, lat: -38.1928076096235 },
    { lng: 146.611297315825, lat: -38.1946885582229 },
    { lng: 146.610337301558, lat: -38.1968930071675 },
    { lng: 146.610186108525, lat: -38.1971361274313 },
    { lng: 146.610082554544, lat: -38.1973026392234 },
    { lng: 146.609464649773, lat: -38.1982962301126 },
    { lng: 146.606964261756, lat: -38.2009407409436 },
    { lng: 146.606425245528, lat: -38.2011699308743 }
  ]
]
