import React from 'react'
import { withData } from '../context'
import Photo from './Photo'

export default withData(
  ({ photos }) =>
    photos &&
    photos.length > 0 && (
      <>
        <h3 className='section-title'>{photos.length} Photos</h3>
        <div id='bore-photo-container'>
          {photos.map((i, index) => (
            <Photo data={i} key={index} />
          ))}
        </div>
      </>
    )
)
