// import React from 'react'

// import { Icon } from 'antd'

export default deleteUser => {
  return [
    {
      title: 'Name',
      dataIndex: 'user.username',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'user.email',
      key: 'email'
    },
    {
      title: 'Role',
      dataIndex: 'user.role',
      key: 'role'
    },
    {
      title: 'Last Active',
      dataIndex: 'user.data.last_loaded_data',
      key: 'activity'
    }
    // ,{
    //   title: '',
    //   dataIndex: 'id',
    //   key: 'delete',
    //   render: v => <Icon type='delete' className='user-delete' onClick={() => deleteUser(v)} />
    // }
  ]
}
