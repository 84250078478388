import React, { useCallback, useContext, useState } from 'react'
import { withRouter, Redirect } from 'react-router'
import { Link } from 'react-router-dom'
import { auth } from '../../../authentication/base.js'
import { AuthContext } from '../../../authentication/Auth.js'
import { Button, message } from 'antd'
import './index.css'
import ReactGA from 'react-ga'
import logo from '../../layout/logo_full.png'

const PasswordReset = ({ history }) => {
  const [loading, setLoading] = useState(false)

  const handleLogin = useCallback(
    async event => {
      event.preventDefault()
      const { email } = event.target.elements
      try {
        setLoading(true)
        await auth.sendPasswordResetEmail(email.value)
        history.push('/')
        ReactGA.event({
          category: 'Auth',
          action: 'Password Reset'
        })
        message.success(`Password reset link sent to: ${email.value}`)
      } catch (error) {
        setLoading(false)
        alert(error)
      }
    },
    [history]
  )

  const { currentUser } = useContext(AuthContext)

  return currentUser ? (
    <Redirect to='/' />
  ) : (
    <div className='auth-form-container'>
      <form onSubmit={handleLogin} className='authForm'>
        <img src={logo} alt='My logo' className='auth-logo' style={{ margin: 'auto' }} />
        <input name='email' type='email' placeholder='Email' className='authInput' />
        <Button
          type='primary'
          htmlType='submit'
          disabled={loading ? true : false}
          loading={loading ? true : false}
          size='large'>
          {loading ? 'Loading' : 'Reset'}
        </Button>
        <div className='authLink'>
          Remember your password?
          <Link to='/login' className='authLink-action'>
            Log in.
          </Link>
        </div>
      </form>
    </div>
  )
}

export default withRouter(PasswordReset)
