import React, { useState, useEffect } from 'react'
import { withData } from '../context'
import { DatePicker } from 'antd'

import moment from 'moment'

const SelectDate = ({ loading, setSelectedDate, selectedDate, dates, selectedJob }) => {
  const [defaultValue, setDefaultValue] = useState(null)

  const disabledDate = current => dates.findIndex(date => date === moment(current).format('YYYY-MM-DD')) === -1

  useEffect(() => {
    let recent = moment(dates[dates.length - 1], 'YYYY-MM-DD')
    if (dates.length > 0) {
      setDefaultValue(recent)
      selectedDate === null && setSelectedDate(recent)
    }
    //eslint-disable-next-line
  }, [dates])

  const no_data = () => selectedJob && dates.length === 0
  return (
    <DatePicker
      format='Do MMM YYYY'
      disabledDate={disabledDate}
      onChange={setSelectedDate}
      placeholder={loading.dates ? 'Loading' : no_data() ? 'No data' : 'Select Date'}
      disabled={loading.dates || dates.length <= 0}
      value={loading.dates ? null : selectedDate ? selectedDate : defaultValue}
      renderExtraFooter={() => <div className='no-of-prestarts'>{`${dates.length} Entries`}</div>}
      showToday={false}
    />
  )
}

export default withData(SelectDate)
