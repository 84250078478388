import React from 'react';
import { Link } from 'react-router-dom';

import './index.css';
const NotFound = () => {
  return (
    <div>
      <h1 className='notFound404'>404</h1>
      <p className='notFoundSub'>This URL dosn't exist.</p>
      <p>
        <Link to='/' className='notFoundLink'>
          Click here to go back.
        </Link>
      </p>
    </div>
  );
};

export default NotFound;
