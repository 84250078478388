import React from 'react'

//Context
import { ReportProvider } from './components/context'

//parts
import Map from './components/Map'
import Select from './components/Select'
import Enquiries from './components/Enquiries'
import Enquiry from './components/Enquiry'

import './index.css'

const DailyReport = () => (
  <ReportProvider>
    <div style={{ display: 'grid', gridGap: 10 }}>
      <Map />
      <Select />
      <Enquiries />
      <Enquiry />
    </div>
  </ReportProvider>
)

export default DailyReport
