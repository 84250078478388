export default [
  {
    lat: -38.16337487708676,
    lng: 146.39811451972662,
    color: 'green',
    link: 'https://www.domain.com.au/200-brown-coal-mine-road-yallourn-north-vic-3825-2015025339'
  },
  {
    lat: -38.136106020038994,
    lng: 146.5563863092774,
    color: 'green',
    link: 'https://www.domain.com.au/635-traralgon-maffra-road-glengarry-vic-3854-2013302216'
  },
  {
    lat: -38.291968103072506,
    lng: 146.49249488193814,
    color: 'green',
    link: 'https://www.domain.com.au/100-sagars-road-hazelwood-north-vic-3840-2015903079'
  },
  {
    lat: -38.2994819846391,
    lng: 146.41532357276617,
    color: 'green',
    link: 'https://www.domain.com.au/lot-f-silcocks-road-churchill-vic-3842-2016004862'
  },
  {
    lat: -38.34282964593634,
    lng: 146.4106011838865,
    color: 'green',
    link: 'https://www.domain.com.au/lot-3-reidys-road-jeeralang-junction-vic-3840-2014535996'
  },
  {
    lat: -38.251341401512576,
    lng: 146.44387888121423,
    color: 'yellow',
    link: 'https://www.commercialrealestate.com.au/property/1-tp837394-firmins-lane-morwell-vic-3840-2014839844'
  },
  {
    lat: -38.232142910055664,
    lng: 146.39953072608648,
    color: 'yellow',
    link: 'https://www.commercialrealestate.com.au/property/73-83-church-street-morwell-vic-3840-2015271072'
  },
  {
    lat: -38.220927247525275,
    lng: 146.46812759417912,
    color: 'yellow',
    link: 'https://www.commercialrealestate.com.au/property/lot-18-princes-highway-traralgon-vic-3844-2013820825'
  },
  {
    lat: -38.220927247525275,
    lng: 146.46812759417912,
    color: 'yellow',
    link: 'https://www.commercialrealestate.com.au/property/lot-18-princes-highway-traralgon-vic-3844-2013820825'
  },
  {
    lat: -38.20273392508241,
    lng: 146.53558733210477,
    color: 'yellow',
    link: 'https://www.commercialrealestate.com.au/property/14-28-dunbar-road-traralgon-vic-3844-2015657128'
  },
  {
    lat: -38.18649819933408,
    lng: 146.57381417498502,
    color: 'yellow',
    link: 'https://www.commercialrealestate.com.au/property/lot-49-princes-highway-traralgon-vic-3844-2013135104'
  },
  {
    lat: -38.17110144899726,
    lng: 146.57797272742926,
    color: 'yellow',
    link:
      'https://www.sidespace.com.au/commercial-farming/for-sale/in-traralgon-east-vic/5c5a2a07c6e60/140-burnets-road'
  }
]
