export default [
  [
    { lat: -38.2490609988094, lng: 146.43851752267742 },
    { lat: -38.25061130103987, lng: 146.45188565240764 },
    { lat: -38.254588012064666, lng: 146.4511775492277 },
    { lat: -38.25251543135237, lng: 146.4352559565153 },
    { lat: -38.2505775991691, lng: 146.43562073694133 },
    { lat: -38.25084721369767, lng: 146.43802399621867 }
  ],

  [
    { lat: -38.24642105244653, lng: 146.42306631388112 },
    { lat: -38.24706142193886, lng: 146.42454689325734 },
    { lat: -38.247701785789616, lng: 146.4263922530596 },
    { lat: -38.24960599232949, lng: 146.42669266046926 },
    { lat: -38.25056650236275, lng: 146.42613476099416 },
    { lat: -38.2525043348419, lng: 146.4251691657488 },
    { lat: -38.25376811079471, lng: 146.4242894011919 },
    { lat: -38.25344795629859, lng: 146.42214363398 },
    { lat: -38.25243693284044, lng: 146.42289465250417 },
    { lat: -38.25161125324907, lng: 146.42021244348928 },
    { lat: -38.24886453730668, lng: 146.4208132583086 },
    { lat: -38.24904990177151, lng: 146.42244404138967 }
  ],
  [
    { lat: -38.222036548814636, lng: 146.46934907535356 },
    { lat: -38.21933929006953, lng: 146.46806161502641 },
    { lat: -38.21915384985718, lng: 146.4669780025844 },
    { lat: -38.219482584455356, lng: 146.46684925655168 },
    { lat: -38.221143092930916, lng: 146.4677182922725 },
    { lat: -38.22212926530625, lng: 146.46746080020708 },
    { lat: -38.2228119921969, lng: 146.46749298671526 },
    { lat: -38.22294685107184, lng: 146.46800797084612 }
  ],
  [
    { lat: -38.18720257744757, lng: 146.57575044320743 },
    { lat: -38.18710138363936, lng: 146.57524618791263 },
    { lat: -38.188071151852945, lng: 146.5750637976996 },
    { lat: -38.18852651682038, lng: 146.57244596170108 },
    { lat: -38.185170241527466, lng: 146.57306823419253 },
    { lat: -38.18544009852408, lng: 146.57490286515872 },
    { lat: -38.18637615723334, lng: 146.57473120378177 },
    { lat: -38.18648578494399, lng: 146.57587918924014 }
  ],
  [
    { lat: -38.20201166200462, lng: 146.53463116026808 },
    { lat: -38.20217185266795, lng: 146.53684130049635 },
    { lat: -38.20344493435547, lng: 146.5366696391194 },
    { lat: -38.20336062499828, lng: 146.53437366820265 }
  ],
  [
    { lat: -38.231073212390214, lng: 146.3988847770621 },
    { lat: -38.231519880723525, lng: 146.40052628897922 },
    { lat: -38.231730572381394, lng: 146.40080523871677 },
    { lat: -38.23200868443517, lng: 146.40086961173313 },
    { lat: -38.23248905729581, lng: 146.40056920432346 },
    { lat: -38.2329609994724, lng: 146.40030098342197 },
    { lat: -38.23248905729581, lng: 146.39834833525913 }
  ],
  [
    { lat: -38.169382454006254, lng: 146.5770270949228 },
    { lat: -38.17268041514835, lng: 146.57632972057894 },
    { lat: -38.1729840562422, lng: 146.57865787800387 },
    { lat: -38.16965236947624, lng: 146.57928015049532 }
  ]
]
