import moment from 'moment'

export default (timesheet) => {
  let at = timesheet.form_values
  let weekEnd = moment(timesheet.form_values['b4cf'])
  let choice = (v) => at[v] && (at[v].choice_values[0] || at[v].other_values[0])
  let sub = (num) => moment(weekEnd).subtract(num, 'days').format('D-MMM')

  const diff = (start, lunch, finish) =>
    moment
      .duration(moment(start, 'H:mm').diff(moment(finish, 'H:mm')))
      .subtract(parseFloat(lunch), 'hours')
      .asHours()
      .toFixed(2)

  const total = {
    0: 'Total',
    [sub(6)]: at['3989'] && diff(at['3989'], at['8146'], at['7919']),
    [sub(5)]: at['b272'] && diff(at['b272'], at['98bb'], at['b982']),
    [sub(4)]: at['c696'] && diff(at['c696'], at['c55b'], at['d21a']),
    [sub(3)]: at['731a'] && diff(at['731a'], at['0e0c'], at['7707']),
    [sub(2)]: at['7bcd'] && diff(at['7bcd'], at['3bef'], at['9fd1']),
    [sub(1)]: at['eedf'] && diff(at['eedf'], at['53b7'], at['e851']),
    [sub(0)]: at['1c83'] && diff(at['1c83'], at['f90d'], at['bf5c'])
  }
  return [
    {
      0: 'Job',
      [sub(6)]: choice('cc30'),
      [sub(5)]: choice('624b'),
      [sub(4)]: choice('847c'),
      [sub(3)]: choice('ba73'),
      [sub(2)]: choice('e783'),
      [sub(1)]: choice('a43d'),
      [sub(0)]: choice('db2e')
    },
    {
      0: 'Rig',
      [sub(6)]: choice('5bae'),
      [sub(5)]: choice('d661'),
      [sub(4)]: choice('431e'),
      [sub(3)]: choice('2403'),
      [sub(2)]: choice('3662'),
      [sub(1)]: choice('0625'),
      [sub(0)]: choice('4144')
    },
    {
      0: 'Start',
      [sub(6)]: at['7919'],
      [sub(5)]: at['b982'],
      [sub(4)]: at['d21a'],
      [sub(3)]: at['7707'],
      [sub(2)]: at['9fd1'],
      [sub(1)]: at['e851'],
      [sub(0)]: at['bf5c']
    },
    {
      0: 'Break',
      [sub(6)]: at['8146'],
      [sub(5)]: at['98bb'],
      [sub(4)]: at['c55b'],
      [sub(3)]: at['0e0c'],
      [sub(2)]: at['3bef'],
      [sub(1)]: at['53b7'],
      [sub(0)]: at['f90d']
    },
    {
      0: 'Finish',
      [sub(6)]: at['3989'],
      [sub(5)]: at['b272'],
      [sub(4)]: at['c696'],
      [sub(3)]: at['731a'],
      [sub(2)]: at['7bcd'],
      [sub(1)]: at['eedf'],
      [sub(0)]: at['1c83']
    },
    {
      0: 'Travel',
      [sub(6)]: at['35ce'],
      [sub(5)]: at['b833'],
      [sub(4)]: at['8d72'],
      [sub(3)]: at['1c53'],
      [sub(2)]: at['99c5'],
      [sub(1)]: at['455d'],
      [sub(0)]: at['faa8']
    },
    {
      0: 'Ord Hours',
      [sub(6)]: at['7d60'],
      [sub(5)]: at['0598'],
      [sub(4)]: at['c419'],
      [sub(3)]: at['a7f0'],
      [sub(2)]: at['83da'],
      [sub(1)]: at['bc5f'],
      [sub(0)]: at['a489']
    },
    {
      0: 'Time x .5',
      [sub(6)]: at['01ce'],
      [sub(5)]: at['20fb'],
      [sub(4)]: at['bf66'],
      [sub(3)]: at['3a1f'],
      [sub(2)]: at['25ac'],
      [sub(1)]: at['b5e7'],
      [sub(0)]: at['5d1b']
    },
    {
      0: 'time x 2',
      [sub(6)]: at['cbed'],
      [sub(5)]: at['3dc7'],
      [sub(4)]: at['c71c'],
      [sub(3)]: at['2a26'],
      [sub(2)]: at['c158'],
      [sub(1)]: at['c7fc'],
      [sub(0)]: at['fcb5']
    },
    {
      0: 'LAFHA',
      [sub(6)]: at['5df8'],
      [sub(5)]: at['480a'],
      [sub(4)]: at['0606'],
      [sub(3)]: at['b8b5'],
      [sub(2)]: at['1b4d'],
      [sub(1)]: at['ebb5'],
      [sub(0)]: at['abf4']
    },
    {
      0: 'Annual Leave',
      [sub(6)]: at['6dc8'],
      [sub(5)]: at['6331'],
      [sub(4)]: at['cd11'],
      [sub(3)]: at['d265'],
      [sub(2)]: at['96d7'],
      [sub(1)]: at['17be'],
      [sub(0)]: at['27ec']
    },
    {
      0: 'Personal Leave',
      [sub(6)]: at['5463'],
      [sub(5)]: at['8046'],
      [sub(4)]: at['7f23'],
      [sub(3)]: at['e106'],
      [sub(2)]: at['d0ec'],
      [sub(1)]: at['b4b1'],
      [sub(0)]: at['05f9']
    },
    total,
    {
      0: 'Comments',
      [sub(6)]: at['fe65'],
      [sub(5)]: at['a3a6'],
      [sub(4)]: at['dfaa'],
      [sub(3)]: at['24c5'],
      [sub(2)]: at['1bbc'],
      [sub(1)]: at['ac29'],
      [sub(0)]: at['7eb1']
    },
    {
      0: '',
      [sub(6)]: '',
      [sub(5)]: '',
      [sub(4)]: '',
      [sub(3)]: '',
      [sub(2)]: '',
      [sub(1)]: '',
      [sub(0)]: ''
    },
    {
      0: '',
      [sub(6)]: '',
      [sub(5)]: '',
      [sub(4)]: '',
      [sub(3)]: '',
      [sub(2)]: '',
      [sub(1)]: '',
      [sub(0)]: ''
    },
    {
      0: '',
      [sub(6)]: '',
      [sub(5)]: '',
      [sub(4)]: '',
      [sub(3)]: '',
      [sub(2)]: '',
      [sub(1)]: '',
      [sub(0)]: ''
    },
    {
      0: '',
      [sub(6)]: '',
      [sub(5)]: '',
      [sub(4)]: '',
      [sub(3)]: '',
      [sub(2)]: '',
      [sub(1)]: '',
      [sub(0)]: ''
    }
  ]
}
