import React, { useContext, useEffect, useState } from 'react'
import { withData } from '../context'
import { Row, Col } from 'antd'

//Context
import { ReportContext } from '../context'

//parts
import JobSelection from '../../../../layout/JobSelection'
import SelectDate from './SelectDate'
import NavButtons from './NavButtons'
import { TopLoader } from '../../../../layout/Loading'

export default withData(({ allDrillersLogs, selectedDate, selectedJob, loading, setLoading }) => {
  const { setSelectedJob } = useContext(ReportContext)
  const [dates, setDates] = useState([])
  const [index, setIndex] = useState(null)

  useEffect(() => {
    let allprestartDates = []
    allDrillersLogs.forEach(log => log.form_values['a709'] && allprestartDates.push(log.form_values['a709']))
    let unique = [...new Set(allprestartDates)]
    let sorted = unique.sort((a, b) => {
      a = new Date(a)
      b = new Date(b)
      return a > b ? 1 : a < b ? -1 : 0
    })
    setDates(sorted)

    selectedDate && setIndex(sorted.indexOf(selectedDate.format('YYYY-MM-DD')))
  }, [allDrillersLogs, selectedDate])

  return (
    // <Row gutter={8} style={{ position: 'sticky', background: '#fff', zIndex: 20, top: 0 }}>
    <>
      <TopLoader loading={loading.jobs} />
      <Row gutter={8}>
        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
          <JobSelection
            multi={false}
            selectJob={setSelectedJob}
            selectedJob={selectedJob}
            setLoading={setLoading}
            loading={loading}
          />
        </Col>
        <Col xs={24} sm={24} md={7} lg={7} xl={7}>
          <SelectDate dates={dates} />
        </Col>
        <Col xs={24} sm={24} md={7} lg={7} xl={7}>
          <NavButtons dates={dates} index={index} />
        </Col>
      </Row>
    </>
  )
})
