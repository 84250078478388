import React, { useState, useContext, useEffect } from 'react'
import { FulcrumContext } from '../../../../Fulcrum/index'
import GoogleMapReact from 'google-map-react'
import { withData } from './context'

export default withData(({ selectedJob, setSelectedJob }) => {
  const { data } = useContext(FulcrumContext)

  const [center, setCenter] = useState({
    lat: -38.2379611,
    lng: 146.3782018
  })
  useEffect(() => {
    selectedJob &&
      setCenter({
        lat: selectedJob.latitude,
        lng: selectedJob.longitude
      })
  }, [selectedJob])

  useEffect(() => {
    let middle = { lat: 0, lng: 0, count: 0 }
    if (data) {
      data.map(r => {
        if (r.latitude && r.longitude) {
          middle.lat += r.latitude
          middle.lng += r.longitude
          middle.count++
        }
        return null
      })
      middle.lat !== 0 && setCenter({ lat: middle.lat / middle.count, lng: middle.lng / middle.count })
    }
  }, [data])

  let colors = [
    {
      type: 'Completed',
      color: 'green'
    },
    {
      type: 'In Progress',
      color: 'yellow'
    },
    {
      type: 'Awaiting Invoice',
      color: 'pink'
    },
    {
      type: 'Upcoming',
      color: 'blue'
    },
    {
      type: 'Enquiry',
      color: 'black'
    }
  ]
  const color = status => {
    let index = colors.findIndex(i => i.type === status)
    return index !== -1 ? colors[index].color : colors[4].color
  }

  const Marker = ({ job }) => <div className={`marker ${color(job.status)}`} onClick={() => setSelectedJob(job)} />

  return (
    <div id='bore-map' style={{ height: selectedJob ? 150 : '80vh' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBZsOKeC_xTGwdascGnram_6zNjcZH5F_U' }}
        defaultCenter={{ lat: -38.2379611, lng: 146.3782018 }}
        defaultZoom={11}
        center={center}
        options={{
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
          panControl: false,
          zoomControl: false,
          rotateControl: false,
          fullscreenControl: false
          //   styles: styles
        }}>
        {!selectedJob &&
          data &&
          data.map(
            job =>
              job.status !== 'Enquiry' &&
              job.latitude && <Marker lat={job.latitude} lng={job.longitude} job={job} key={job.id} />
          )}
        {selectedJob && (
          <Marker lat={selectedJob.latitude} lng={selectedJob.longitude} job={selectedJob} key={selectedJob.id} />
        )}
      </GoogleMapReact>
    </div>
  )
})
