import React from 'react'
import Select from 'react-select'
import { withData } from './context'
import { TopLoader } from '../../../layout/Loading'

export default withData(({ items, loading, itemSelected, setItemSelected }) => {
  return (
    <>
      <TopLoader loading={loading} />
      <Select
        options={items}
        value={itemSelected}
        classNamePrefix='job_select'
        onChange={setItemSelected}
        placeholder='Select a vehicle or item of plant to filter the information on this page...'
        isLoading={loading}
        isClearable
        theme={theme => ({
          ...theme,
          borderWidth: 1,
          colors: {
            ...theme.colors,
            primary: '#40a9ff'
          }
        })}
      />
    </>
  )
})
