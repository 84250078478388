import React, { useEffect } from 'react'
import { withData } from './context'
import moment from 'moment'
import Input from 'antd/lib/input'
import Picker from 'antd/lib/date-picker'
import Select from 'antd/lib/select'
import Divider from 'antd/lib/divider'
import Button from 'antd/lib/button'
import Popconfirm from 'antd/lib/popconfirm'
import { isEqual } from 'lodash'

export default withData(
  ({ selectedJob, setSelectedJob, updateEnquiry, loading, deleteEnquiry, createNew, enquiry, setEnquiry }) => {
    useEffect(() => {
      if (selectedJob) setEnquiry(selectedJob)
      return () => setEnquiry(null)
      //eslint-disable-next-line
    }, [selectedJob])

    const updateStatus = v => setEnquiry({ ...enquiry, status: v })
    const updateValue = (v, l) => setEnquiry({ ...enquiry, form_values: { ...enquiry.form_values, [l]: v } })
    const updateSelection = (v, l) =>
      setEnquiry({ ...enquiry, form_values: { ...enquiry.form_values, [l]: { choice_values: [v] } } })

    if (selectedJob && enquiry)
      return (
        <div>
          <h4 className='map-guide'>
            Click on the map to set a new position for this enquiry. Make sure to save changes.
          </h4>
          <div className='enq-grid enq-grid-4'>
            <div>
              <h3 className='section-title enq-smaller'>Date</h3>
              <Picker value={moment(enquiry.form_values['ce91'])} format='Do MMM YYYY' />
            </div>
            <div>
              <h3 className='section-title enq-smaller'>Name</h3>
              <Input
                value={enquiry.form_values['6612']}
                onChange={v => updateValue(v.target.value, '6612')}
                placeholder='John Smith'
              />
            </div>
            <div>
              <h3 className='section-title enq-smaller'>Email</h3>
              <Input
                value={enquiry.form_values['b89a']}
                onChange={v => updateValue(v.target.value, 'b89a')}
                placeholder='John@gmail.com'
              />
            </div>
            <div>
              <h3 className='section-title enq-smaller'>Phone Number</h3>
              <Input
                value={enquiry.form_values['c0bc']}
                onChange={v => updateValue(v.target.value, 'c0bc')}
                placeholder='0412 345 678'
              />
            </div>
          </div>
          <div className='enq-grid enq-grid-4'>
            <div>
              <h3 className='section-title enq-smaller'>Status</h3>
              <Select value={enquiry.status} style={{ width: '100%' }} onChange={v => updateStatus(v)}>
                <Select.Option value='Enquiry'>
                  <div className='status-opt'>
                    <div className='badge Enquiry' /> Enquiry
                  </div>
                </Select.Option>

                <Select.Option value='In Progress'>
                  <div className='status-opt'>
                    <div className='badge InProgress' />
                    In Progress
                  </div>
                </Select.Option>
                <Select.Option value='Completed'>
                  <div className='status-opt'>
                    <div className='badge Completed' />
                    Completed
                  </div>
                </Select.Option>
                <Select.Option value='Dead'>
                  <div className='status-opt'>
                    <div className='badge Dead' /> Dead
                  </div>
                </Select.Option>
              </Select>
            </div>
            <div>
              <h3 className='section-title enq-smaller'>Bore Type</h3>
              <Select
                value={enquiry.form_values['c2eb'] && enquiry.form_values['c2eb'].choice_values[0]}
                style={{ width: '100%' }}
                onChange={v => updateSelection(v, 'c2eb')}>
                <Select.Option value='S&D'>S&D</Select.Option>
                <Select.Option value='Irrigation'>Irrigation</Select.Option>
                <Select.Option value='Coring'>Coring</Select.Option>
                <Select.Option value='Other'>Other</Select.Option>
              </Select>
            </div>
            <div>
              <h3 className='section-title enq-smaller'>Construction licence</h3>
              <Input
                value={enquiry.form_values['4aad']}
                onChange={v => updateValue(v.target.value, '4aad')}
                placeholder='Yes or No'
              />
            </div>
            <div>
              <h3 className='section-title enq-smaller'>Approx depth</h3>
              <Input
                value={enquiry.form_values['85fa']}
                onChange={v => updateValue(v.target.value, '85fa')}
                placeholder='Eight full length football fields'
              />
            </div>
          </div>
          <div>
            <h3 className='section-title enq-smaller'>Address</h3>
            <Input
              value={enquiry.form_values['ba02']}
              onChange={v => updateValue(v.target.value, 'ba02')}
              placeholder='1 main st, basic town'
            />
          </div>
          <div>
            <h3 className='section-title enq-smaller'>Additional Information</h3>
            <Input.TextArea
              value={enquiry.form_values['29fd']}
              onChange={v => updateValue(v.target.value, '29fd')}
              rows={5}
            />
          </div>

          <Divider />
          <div className='enq-grid enq-grid-1-3'>
            <div />
            {selectedJob.id ? (
              <Popconfirm
                title='Are you sure delete this enquiry? Deleting this will also delete the quality plan assosiated.'
                onConfirm={() => deleteEnquiry(enquiry)}
                okText='Yes'
                cancelText='No'>
                <Button type='danger' ghost disabled={loading}>
                  Delete
                </Button>
              </Popconfirm>
            ) : (
              <div />
            )}
            <Button onClick={() => setSelectedJob(null)} disabled={loading}>
              Cancel
            </Button>
            <Button
              type='primary'
              onClick={() => (selectedJob.id ? updateEnquiry(enquiry) : createNew(enquiry))}
              loading={loading}
              disabled={isEqual(enquiry, selectedJob)}>
              {selectedJob.id ? 'Update' : 'Create'}
            </Button>
          </div>
        </div>
      )
    return null
  }
)
