import React, { useState, useContext, useEffect } from 'react'
import { FulcrumContext } from '../../../../Fulcrum'
import functions from './functions'

export const ReportContext = React.createContext()

export const ReportProvider = ({ children }) => {
  const { fulcrum } = useContext(FulcrumContext)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedEvent, setSelectedEvent] = useState(null)

  const form_id = 'ff9b40a4-61e0-46fb-a592-48a8f90e2d4d'

  const { startBlank, createNew, updateEvent, deleteEvent } = functions({
    setSelectedEvent,
    form_id,
    setLoading,
    fulcrum,
    setData,
    data
  })

  useEffect(() => {
    setLoading(true)
    fulcrum
      .getAllData(form_id)
      .then(setData)
      .catch(console.error)
      .then(() => setLoading(false))
      .catch(console.error)
    //eslint-disable-next-line
  }, [])

  return (
    <ReportContext.Provider
      value={{
        loading,
        fulcrum,
        data,
        selectedEvent,
        setSelectedEvent,
        createNew,
        updateEvent,
        deleteEvent,
        startBlank
      }}>
      {children}
    </ReportContext.Provider>
  )
}

export const withData = Component => props => (
  <ReportContext.Consumer>{contexts => <Component {...props} {...contexts} />}</ReportContext.Consumer>
)
