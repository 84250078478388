import { get } from 'lodash'
import React, { useState, useContext, useEffect } from 'react'
import { FulcrumContext } from '../../../Fulcrum'
import Photo from '../Bores/components/Photos/Photo'

const DELWP = () => {
  const { fulcrum } = useContext(FulcrumContext)
  const [bores, setBores] = useState([])
  const [selected, setSelected] = useState(null)

  useEffect(() => {
    const get = async () => {
      let _bores = []
      let records = await fulcrum.getAllData(
        '1390d0f3-7363-470d-847f-7a4f393999d7',
        '1c1390d5-1a97-402f-983f-47f5daa044ca'
      )
      records.forEach((record) => {
        if (record.form_values['1647'] && record.form_values['8e1c']) {
          let boreIndex = _bores.findIndex((bore) => bore.number === record.form_values['1647'])
          if (boreIndex >= 0) {
            _bores[boreIndex].photos = [..._bores[boreIndex].photos, ...record.form_values['8e1c']]
          } else {
            _bores.push({ number: record.form_values['1647'], photos: record.form_values['8e1c'] })
          }
        }
      })
      setBores(_bores)
    }
    get()
    //eslint-disable-next-line
  }, [])

  return (
    <div style={{ display: 'grid', gridGap: 10 }}>
      <iframe
        src='https://web.fulcrumapp.com/shares/535a1e4052434af9?&popup_properties=status,bore_number,bollards_installed,date_installed,installed_by,before_photos,after_photos,number_of_bollards,locks_installed,comments&title=Bollards'
        width='100%'
        height={window.innerHeight / 2 - 60}
        scrolling='no'
        frameBorder='0'
        title='Bollards'
        id='bollards'
      />
      <iframe
        src='https://web.fulcrumapp.com/shares/7bf13a2ff1028c6a?&popup_properties=status,bore_number,tender_number,proposed_works,headworks,casing_material,water_depth,constructed,latest_measured_depth,comments,screen_1_from,screen_1_to,screen_2_from,screen_2_to,first_length_from,first_length_to,first_length_diameter,second_length_from,second_length_to,second_length_diameter,third_length_from,third_length_to,third_length_diameter,perf_1,perf_2,perf_3,perforated&title=DELWP%20BORES'
        width='100%'
        height={window.innerHeight / 2 - 60}
        scrolling='no'
        frameBorder='0'
        title='DELWP Bores'
        id='delwp-bores'
      />
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 5 }}>
        {bores.map((bore) => (
          <div
            key={bore.number}
            style={{
              fontWeight: bore === selected && 'bold',
              cursor: 'pointer',
              gridColumn: bore === selected && '1/4',
              fontSize: bore === selected && 20
            }}>
            <div
              onClick={() =>
                selected ? (selected === bore ? setSelected(null) : setSelected(bore)) : setSelected(bore)
              }>
              {bore.number}
            </div>
            {selected && selected === bore && (
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10 }}>
                {bore.photos.map((photo) => (
                  <Photo data={photo} fulcrum={fulcrum} />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default DELWP
