import React, { useState, useEffect } from 'react'
import { FiLoader } from 'react-icons/fi'
import randomInt from 'random-int'
import './index.css'

const Loading = () => (
  <div className='loader-container'>
    <FiLoader className='loader' />
  </div>
)

const TopLoader = ({ loading }) => {
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    setPercentage(randomInt(45, 89))
    if (!loading) {
      setPercentage(95)
      var load_timer = setTimeout(() => setPercentage(100), 500)
      var load_timer2 = setTimeout(() => setPercentage(false), 1000)
    }
    return () => {
      clearTimeout(load_timer)
      clearTimeout(load_timer2)
    }

    //eslint-disable-next-line
  }, [loading])

  return (
    <div
      className='top-loader'
      style={{
        width: `${percentage}%`,
        visibility: percentage === false && 'hidden',
        opacity: percentage === false && 0
      }}
    />
  )
}

export { Loading, TopLoader }
