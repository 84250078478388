import moment from 'moment'
import message from 'antd/lib/message'
export default ({ setSelectedEvent, form_id, setLoading, fulcrum, setData, data }) => ({
  startBlank: date => {
    setSelectedEvent({
      form_id,
      form_values: {
        '81f1': '', // title
        aff0: '', // description
        '839d': 'true', // allday?
        '5bbd': moment(date.start).format('YYYY-MM-DD'), // date Start
        '840e': '00:00', // hours Start
        '887d': moment(date.end).format('YYYY-MM-DD'), // date Finish
        edcd: '00:01', // hours Finish
        '9077': { choice_values: ['Red'] } //color
      }
    })
  },
  createNew: r => {
    setLoading(true)
    fulcrum
      .createRecord(r)
      .then(resp => {
        r.id = resp.id
        setSelectedEvent(null)
        setData([...data, r])
      })
      .then(() => {
        setLoading(false)
        message.success('Event created.')
      })
      .catch(() => message.error('Something went wrong.'))
  },

  updateEvent: r => {
    setLoading(true)
    fulcrum
      .updateRecord(r)
      .then(() => setSelectedEvent(null))
      .then(() => {
        let _data = [...data]
        let index = _data.findIndex(i => i.id === r.id)
        _data[index] = r
        setData(_data)
      })
      .then(() => {
        setLoading(false)
        message.success('Event updated')
      })
      .catch(() => message.error('Something went wrong.'))
  },
  deleteEvent: r => {
    setLoading(true)
    fulcrum
      .deleteRecord(r)
      .then(() => setSelectedEvent(null))
      .then(() => {
        let _data = [...data]
        let index = _data.findIndex(i => i.id === r.id)
        _data.splice(index, 1)
        setData(_data)
      })
      .then(() => {
        setLoading(false)
        message.success('Event deleted')
      })
      .catch(() => message.error('Something went wrong.'))
  }
})
