import React from 'react'
import { withData } from './context'
import Table from 'antd/lib/table'
import moment from 'moment'

const drilling = activities =>
  activities.map(i => i.form_values['a988'] || i.form_values['9f30']).filter(i => i !== undefined).length > 0 && true

const columns = [
  {
    title: 'Start',
    dataIndex: 'form_values[e418]', //9344 //e418 //2c02
    key: 'start',
    render: v => moment(v, 'H.mm').format('LT'),
    width: 50
  },
  {
    title: 'Finish',
    dataIndex: 'form_values[8881]',
    key: 'finish',
    render: v => moment(v, 'H.mm').format('LT'),
    width: 50
  },
  {
    title: 'Activity',
    dataIndex: 'form_values[0ad7]',
    render: v => v && (v.choice_values[0] || v.other_values[0]),
    key: 'activity',
    width: 50
  },
  {
    title: 'Description',
    dataIndex: 'form_values[99a3]',
    key: 'description',
    width: 100
  }
]

export default withData(
  ({ activities }) =>
    activities &&
    activities.length > 0 && (
      <>
        <h3 className='section-title'>Drillers Log</h3>
        <Table
          pagination={false}
          bordered
          id='drillersLog'
          columns={
            drilling(activities)
              ? [
                  ...columns,
                  {
                    title: 'Drilling',
                    children: [
                      {
                        title: 'From',
                        dataIndex: 'form_values[9f30]',
                        key: 'from',
                        width: 20
                      },
                      {
                        title: 'To',
                        dataIndex: 'form_values[a988]',
                        key: 'to',
                        width: 20
                      }
                    ]
                  }
                ]
              : columns
          }
          dataSource={activities}
          rowKey='id'
          size='middle'
        />
      </>
    )
)
