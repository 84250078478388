import React from 'react'
import { withData } from './context'
import Table from 'antd/lib/table'
import moment from 'moment'

export default withData(
  ({ selectedJob, data, loading, setSelectedJob }) =>
    !selectedJob && (
      <>
        <h3 className='section-title'>All</h3>
        <Table
          pagination={false}
          bordered
          loading={loading}
          id='boresTableOne'
          className='boreTables tableResizer'
          columns={[
            {
              title: 'Status',
              dataIndex: 'status',
              key: 'status',
              render: v => <div className={`badge ${v.split(' ').join('')}`} />,
              filters: ['Enquiry', 'In Progress', 'Dead', 'Complete'].map(i => ({ text: i, value: i })),
              onFilter: (v, r) => v === r.status
            },
            {
              title: 'Date',
              dataIndex: 'form_values[ce91]',
              key: 'date',
              render: date => date && moment(date).format('Do MMM YY')
            },
            {
              title: 'Name',
              dataIndex: 'form_values[6612]',
              key: 'name'
            },
            {
              title: 'Phone',
              dataIndex: 'form_values[c0bc]',
              key: 'phone'
            },
            {
              title: 'Email',
              dataIndex: 'form_values[b89a]',
              key: 'email',
              className: 'hideThis'
            },
            {
              title: 'Address',
              dataIndex: 'form_values[ba02]',
              key: 'address',
              className: 'hideThis'
            }
          ]}
          dataSource={data.sort((a, b) => moment(a.form_values['ce91']).isBefore(b.form_values['ce91']))}
          rowKey='id'
          size='middle'
          onRow={r => ({ onClick: () => setSelectedJob(r) })}
          rowClassName='enquiries'
        />
      </>
    )
)
