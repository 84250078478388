import React, { useEffect, useState } from 'react'
import logo from '../logo_full.png'
import moment from 'moment'
import './index.css'
const PrintHeader = ({ page }) => {
  const [pageName, setPageName] = useState(null)

  useEffect(() => {
    let url = window.location.pathname.replace('/', '').replace('-', ' ')
    if (url === '') {
      setPageName('Daily Report')
    } else {
      setPageName(url.charAt(0).toUpperCase() + url.substring(1))
    }
  }, [page])

  return (
    <div className='printHeader printShow '>
      <img src={logo} alt='My logo' className='print-logo' />
      <div className='print-info-container'>
        <span className='printSubHeader'>{pageName}</span>
        <span className='printDateHeader'>{moment().format('LLLL')}</span>
      </div>
    </div>
  )
}

export default PrintHeader
