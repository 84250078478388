import React, { useContext, useEffect } from 'react'
import { FulcrumContext } from '../../../Fulcrum'
import { AuthContext } from '../../../authentication/Auth'
import Select from 'react-select'

import ReactGA from 'react-ga'

const JobSelection = ({ multi, selectJob, selectedJob, setLoading, loading }) => {
  const { fulcrum, data, setData } = useContext(FulcrumContext)
  const { user } = useContext(AuthContext)

  const form_id = 'e328b1d1-cd10-46d5-87d8-ace3f02a3344'

  useEffect(() => {
    user &&
      fulcrum
        .getLocalData(form_id)
        .then(setData)
        .then(() =>
          fulcrum.getAllData(form_id, false, user).then((resp) => {
            const sorted = resp.sort((a, b) => (a.form_values['c713'] > b.form_values['c713'] ? -1 : 1))
            setData(sorted)
          }),
        )
        .catch(console.error)
        .then(() => setLoading({ jobs: false, dates: false }))
        .catch(console.error)
    //eslint-disable-next-line
  }, [user])

  useEffect(() => {
    if (user && user.role !== 'admin' && user.username) {
      let filtered = data.filter((p) => p.form_values['6f71'] === user.username && p)
      setData(filtered)
    }
    //eslint-disable-next-line
  }, [user])

  const select = (r) => {
    selectJob(r)
    if (r) {
      ReactGA.event({
        category: 'Selection',
        action: r.project_id,
      })
    }
  }

  const label = (r) => {
    let str = ''
    if (r.form_values['c713']) str += r.form_values['c713']
    if (r.form_values['c713'] && r.form_values['b62d']) str += ' | '
    if (r.form_values['b62d']) str += r.form_values['b62d']
    return str
  }

  return (
    <Select
      options={data}
      value={selectedJob}
      // defaultMenuIsOpen
      classNamePrefix='job_select'
      onChange={(r) => select(r)}
      placeholder='Select a Job or click a marker from the map below...'
      isLoading={loading.jobs}
      getOptionLabel={label}
      getOptionValue={(r) => r.id}
      isMulti={multi}
      isClearable
      theme={(theme) => ({
        ...theme,
        borderWidth: 1,
        colors: {
          ...theme.colors,
          primary: '#40a9ff',
        },
      })}
    />
  )
}

export default JobSelection
