import React, { useState, useEffect } from 'react'
import { default as Map } from 'google-map-react'
import { withData } from './context'

// import mapStyle from '../resources/mapStyles.json'
// https://console.cloud.google.com/google/maps-apis/apis/maps-backend.googleapis.com/credentials?folder=&organizationId=&project=drilltec-map-1574199857445

export default withData(({ overlays, propertys }) => {
  const [google, setGoogle] = useState(null)

  //eslint-disable-next-line
  const [tempPath, setTempPath] = useState([])

  const center = { lat: -38.19791878016367, lng: 146.53235371650393 }

  useEffect(() => {
    google && overlays.forEach(drawPolygon)
    //eslint-disable-next-line
  }, [google, overlays])

  useEffect(() => {
    google &&
      drawPolygon({
        geodesic: true,
        strokeOpacity: 1,
        strokeWeight: 1,
        fillOpacity: 0.2,
        editable: true,
        strokeColor: '#f1c40f',
        fillColor: '#f1c40f',
        path: tempPath
      })
    //eslint-disable-next-line
  }, [google, tempPath])

  const Marker = ({ property }) => (
    <a href={property.link} target='_blank' rel='noopener noreferrer'>
      <div className={`marker ${property.color}`} onClick={() => console.log(property)} />
    </a>
  )

  console.log(tempPath)

  const drawPolygon = shape => {
    let _poly = new google.maps.Polygon(shape)
    // google.maps.event.addListener(_poly, 'click', e => {
    // var infoWindow = new google.maps.InfoWindow()
    //   if (shape.type && shape.type === 'property') {
    //     infoWindow.setContent(shape.label)
    //     var latLng = e.latLng
    //     infoWindow.setPosition(latLng)
    //     infoWindow.open(google.map)
    //   }
    // })
    _poly.setMap(google.map)

    // google.maps.event.addListener(_poly.getPath(), 'insert_at', (index, obj) => console.log(index, obj))
  }

  return (
    <div id='bore-map' style={{ height: 'calc(100vh - 60px)' }}>
      <Map
        defaultCenter={center}
        defaultZoom={12}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={setGoogle}
        bootstrapURLKeys={{ key: 'AIzaSyBZsOKeC_xTGwdascGnram_6zNjcZH5F_U' }}
        // onClick={v => setTempPath([{ lat: v.lat, lng: v.lng }, ...tempPath])}
        options={map => ({
          // styles: mapStyle ,
          mapTypeId: map.MapTypeId.HYBRID
        })}>
        {propertys.map((m, index) => (
          <Marker lat={m.lat} lng={m.lng} key={index} property={m} />
        ))}
      </Map>
    </div>
  )
})
// new maps.visualization.HeatmapLayer({
//   data: heatmap.map(i => ({ location: new maps.LatLng(i.lat, i.lng), weight: i.weight })),
//   radius: 50
// }).setMap(map)
