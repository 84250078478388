import React, { useState } from 'react'
import { Modal, Button, Select, Input, message } from 'antd'
import { auth, db } from '../../../authentication/base'

const CreateAccount = () => {
  const [visible, setVisible] = useState(false)
  const [role, setRole] = useState('admin')
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [passwordOne, setPasswordOne] = useState('')
  const [passwordTwo, setPasswordTwo] = useState('')
  const [err, setErr] = useState(null)

  const create = () => {
    passwordOne === passwordTwo
      ? auth
          .createUserWithEmailAndPassword(email, passwordOne)
          .then(resp => {
            db.ref(`users/${resp.user.uid}`)
              .set({
                username,
                email,
                role
              })
              .then(() => message.info(`You have been signed in as:  ${username}`))
              .then(() => setVisible(false))
              .catch(setErr)
          })
          .catch(setErr)
      : setErr({ message: 'Passwords dont match. Please try again.' })
  }
  const cancel = () => {
    setVisible(false)
  }
  return (
    <div>
      <Button type='primary' onClick={() => setVisible(true)} style={{ marginBottom: 10 }}>
        Create New Account
      </Button>
      <Modal
        title='Create Account'
        visible={visible}
        onOk={create}
        onCancel={cancel}
        okText='Create'
        style={{ maxWidth: 450 }}>
        <div className='modal-grid'>
          <Select
            defaultValue='admin'
            onChange={event => setRole(event)}
            className='somePadding'
            placeholder='Select a role'>
            <Select.Option value='admin'>Admin</Select.Option>
            <Select.Option value='user' disabled>
              User
            </Select.Option>
            <Select.Option value='client'>Client</Select.Option>
          </Select>
          <Input value={username} onChange={event => setUsername(event.target.value)} type='text' placeholder='Name' />
          <Input
            value={email}
            onChange={event => setEmail(event.target.value)}
            type='text'
            placeholder='Email Address'
          />
          <Input
            value={passwordOne}
            onChange={event => setPasswordOne(event.target.value)}
            type='password'
            placeholder='Password'
          />
          <Input
            value={passwordTwo}
            onChange={event => setPasswordTwo(event.target.value)}
            type='password'
            placeholder='Confirm Password'
          />
          {err && <div className='modal-err'>{err.message}</div>}
        </div>
      </Modal>
    </div>
  )
}

export default CreateAccount
