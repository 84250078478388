import React, { useState, useEffect, useContext } from 'react'
import { FulcrumContext } from '../../../../Fulcrum'
export const ReportContext = React.createContext()

export const ReportProvider = ({ children }) => {
  const { fulcrum } = useContext(FulcrumContext)

  const [data, setData] = useState(null)
  const [items, setItems] = useState(null)
  const [prestarts, setPrestarts] = useState(null)
  const [workshops, setWorkshops] = useState(null)
  const [plant, setPlant] = useState(null)

  const [itemSelected, setItemSelected] = useState(null)

  const [loading, setLoading] = useState(false)
  const item_list_id = 'c8866457-c446-4766-91f2-e4617b019a36'
  const forms = [
    { id: '3ebdfb67-0b99-40d2-b937-b91084a12457', action: setWorkshops },
    { id: 'dfd98e4f-bfb0-46e9-bb82-7bd0784cbe80', action: setPrestarts },
    { id: '5f5bbe6c-fd67-4cf6-8853-d70200b1e7fc', action: setPlant },
  ]

  useEffect(() => {
    setLoading(true)
    fulcrum
      .getLocalData(item_list_id)
      .then(setData)
      .then(() => fulcrum.getSet(item_list_id).then(setData))
      .then(() =>
        forms.forEach((form) =>
          fulcrum
            .getLocalData(form.id)
            .then(form.action)
            .then(() => fulcrum.getAllData(form.id).then(form.action))
        )
      )
      .then(() => setLoading(false))
      .catch(console.error)
    return () => setLoading(false)
    //eslint-disable-next-line
  }, [])

  // BULK UPDATE ITEMS

  // useEffect(() => {
  //   if (!!workshops) {
  //     let newArray = []

  //     workshops.forEach((workshop) => {
  //       let new_key = workshop.form_values['10e2']
  //       let old_key = workshop.form_values['5b6b'] || workshop.form_values['d6df']
  //       if (!new_key) {
  //         workshop.form_values['10e2'] = old_key
  //         delete workshop.form_values['5b6b']
  //         delete workshop.form_values['d6df']
  //       }
  //       newArray.push(workshop)
  //     })
  //     newArray.length > 100 && start(newArray)
  //     console.log({ workshops, newArray })
  //   }
  // }, [workshops])

  // async function asyncForEach(array, callback) {
  //   for (let index = 0; index < array.length; index++) {
  //     await callback(array[index], index, array)
  //   }
  // }

  // const start = async (newArray) => {
  //   await asyncForEach(newArray, async (record) => {
  //     await fulcrum.updateRecord(record)
  //   })
  //   console.log('Done')
  // }

  useEffect(() => {
    if (data) {
      let _items = []
      const flatten = (array, category) => {
        const label = (label) => {
          let str = ''
          if (category) str += category
          if (category && label) str += ' | '
          if (label) str += label
          return str
        }

        array.forEach((i) =>
          i.child_classifications
            ? flatten(i.child_classifications, i.value)
            : _items.push({ label: label(i.label), value: i.value, category })
        )
      }
      flatten(data)
      setItems(_items)
    }
  }, [data])

  return (
    <ReportContext.Provider
      value={{ loading, items, fulcrum, itemSelected, setItemSelected, prestarts, workshops, plant }}>
      {children}
    </ReportContext.Provider>
  )
}

export const withData = (Component) => (props) => (
  <ReportContext.Consumer>{(contexts) => <Component {...props} {...contexts} />}</ReportContext.Consumer>
)
