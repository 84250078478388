import React, { useEffect, useState } from 'react'
import { auth } from './base.js'
import ReactGA from 'react-ga'
import { db } from './base'

export const AuthContext = React.createContext()

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(false)
  const [user, setUser] = useState(false)

  useEffect(() => {
    auth.onAuthStateChanged(setCurrentUser)
  }, [])

  useEffect(() => {
    if (currentUser) {
      db.ref('/users/' + currentUser.uid)
        .once('value')
        .then(resp => setUser(resp.val()))
        .catch(console.log)
    } else if (currentUser === null) setUser(null)

    //eslint-disable-next-line
  }, [currentUser])

  useEffect(() => {
    ReactGA.initialize('UA-128212954-1', {
      debug: false,
      titleCase: false,
      gaOptions: {
        userId: currentUser && currentUser.uid
      }
    })
  }, [currentUser])

  return <AuthContext.Provider value={{ currentUser, user }}>{children}</AuthContext.Provider>
}
