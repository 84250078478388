import React, { useEffect, useState } from 'react'
import { withData } from './context'
import Table from 'antd/lib/table'
import moment from 'moment'

export default withData(({ workshops, itemSelected, loading }) => {
  const [forThis, setForThis] = useState([])

  useEffect(() => {
    if (workshops) {
      let _forThis = itemSelected
        ? workshops.filter(
            (p) =>
              p.form_values['4bc0'] &&
              p.form_values['10e2'] &&
              p.form_values['10e2'].choice_values[p.form_values['10e2'].choice_values.length - 1] === itemSelected.value
          )
        : workshops

      _forThis.sort((a, b) => new Date(b.form_values['4bc0']) - new Date(a.form_values['4bc0']))

      setForThis(_forThis)
    }
  }, [workshops, itemSelected])

  return (
    <>
      <h3 className='section-title'>Workshops</h3>
      <p className='section-description'>This table identifies all maintenance and services sheets submitted.</p>
      <Table
        columns={[
          {
            title: 'On',
            dataIndex: 'form_values[10e2]',
            render: (v) => v && v.choice_values[v.choice_values.length - 1],
            className: itemSelected && 'hide-column'
          },
          { title: 'Date', dataIndex: 'form_values[4bc0]', render: (v) => v && moment(v).format('Do MMM YYYY') },
          { title: 'Status', dataIndex: 'status' },
          { title: 'Completed By', dataIndex: 'form_values[fdcd].choice_values[0]' },
          {
            title: 'Link',
            dataIndex: 'id',
            render: (v) => (
              <a target='_blank' rel='noopener noreferrer' href={`https://web.fulcrumapp.com/records/${v}`}>
                Open
              </a>
            )
          }
        ]}
        dataSource={forThis}
        size='small'
        pagination={{ pageSize: 5 }}
        loading={loading}
        rowKey='id'
      />
    </>
  )
})
