import React, { useState, useEffect, useContext } from 'react'
import { FulcrumContext } from '../../../../Fulcrum'
export const ReportContext = React.createContext()

export const ReportProvider = ({ children }) => {
  const { fulcrum } = useContext(FulcrumContext)

  const [loading, setLoading] = useState({ jobs: true, dates: false })
  const [selectedJob, setSelectedJob] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [allDrillersLogs, setAllDrillersLogs] = useState([])
  const [activities, setActivities] = useState(null)
  const [photos, setPhotos] = useState(null)

  useEffect(() => {
    if (selectedJob) {
      setAllDrillersLogs([])
      setSelectedDate(null)
      setLoading({ jobs: false, dates: true })
      fulcrum
        .getAllData('1390d0f3-7363-470d-847f-7a4f393999d7', selectedJob.project_id) //Drillers Logs
        .then(setAllDrillersLogs)
        .then(() => setLoading({ jobs: false, dates: false }))
    }

    return () => {
      setAllDrillersLogs([])
      setActivities(null)
      setPhotos(null)
    }
    //eslint-disable-next-line
  }, [selectedJob])

  useEffect(() => {
    if (allDrillersLogs && selectedDate) {
      let logs = allDrillersLogs.filter(r => r.form_values['a709'] === selectedDate.format('YYYY-MM-DD'))
      let _activites = []
      let _photos = []
      logs.forEach(log => {
        log.form_values['bbcf'] && log.form_values['bbcf'].forEach(entry => _activites.push(entry))
        log.form_values['8e1c'] && log.form_values['8e1c'].forEach(photo => _photos.push(photo))
      })

      _activites.sort((a, b) => parseFloat(a.form_values['8881']) > parseFloat(b.form_values['8881']))

      setPhotos(_photos)
      setActivities(_activites)
    }
  }, [allDrillersLogs, selectedDate])

  return (
    <ReportContext.Provider
      value={{
        loading,
        setLoading,
        selectedJob,
        setSelectedJob,
        allDrillersLogs,
        setAllDrillersLogs,
        selectedDate,
        setSelectedDate,
        fulcrum,
        activities,
        photos
      }}>
      {children}
    </ReportContext.Provider>
  )
}

export const withData = Component => props => (
  <ReportContext.Consumer>{contexts => <Component {...props} {...contexts} />}</ReportContext.Consumer>
)
