import React from 'react'

import { ReportProvider } from './components/context'
import Calendar from './components/Calendar'
import Modal from './components/Modal'

import './index.css'

export default () => (
  <div id='calendar'>
    <ReportProvider>
      <Calendar />
      <Modal />
    </ReportProvider>
  </div>
)
