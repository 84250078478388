import moment from 'moment'
import message from 'antd/lib/message'

export default ({ setSelectedJob, fulcrum, setLoading, setData, data }) => ({
  startBlank: () => {
    setSelectedJob({
      status: 'Enquiry',
      form_id: 'e328b1d1-cd10-46d5-87d8-ace3f02a3344',
      form_values: {
        '6612': '', //name
        ce91: moment().format('YYYY-MM-DD'), //date
        c0bc: '', //number
        b89a: '', //email
        c2eb: { choice_values: [] }, //bore type
        ba02: '', //address
        '4aad': '', //liscence
        '85fa': '', //depth
        '29fd': '' //information
      },
      latitude: -38.2379611,
      longitude: 146.3782018
    })
  },
  createNew: r => {
    setLoading(true)
    fulcrum
      .createRecord(r)
      .then(resp => {
        r.id = resp.id
        setSelectedJob(null)
        setData([...data, r])
      })
      .then(() => {
        setLoading(false)
        message.success('Enquiry created.')
      })
      .catch(() => message.error('Something went wrong.'))
  },
  updateEnquiry: r => {
    setLoading(true)
    fulcrum
      .updateRecord(r)
      .then(() => setSelectedJob(null))
      .then(() => {
        let _data = [...data]
        let index = _data.findIndex(i => i.id === r.id)
        _data[index] = r
        setData(_data)
      })
      .then(() => {
        setLoading(false)
        message.success('Enquiry updated')
      })
      .catch(() => message.error('Something went wrong.'))
  },
  deleteEnquiry: r => {
    setLoading(true)
    fulcrum
      .deleteRecord(r)
      .then(() => setSelectedJob(null))
      .then(() => {
        let _data = [...data]
        let index = _data.findIndex(i => i.id === r.id)
        _data.splice(index, 1)
        setData(_data)
      })
      .then(() => {
        setLoading(false)
        message.success('Enquiry deleted')
      })
      .catch(() => message.error('Something went wrong.'))
  }
})
