import React, { useContext } from 'react'
import { AuthContext } from '../../../../authentication/Auth'
import { auth } from '../../../../authentication/base'

import './index.css'

export default () => {
  const { currentUser } = useContext(AuthContext)

  const name = currentUser && currentUser.email.split('@')[0]
  const email = currentUser && currentUser.email.split('@')[1]
  const upper = name.replace(/^\w/, chr => chr.toUpperCase())

  return !currentUser ? (
    <div />
  ) : (
    <div className='profile-nav-container'>
      <div className='profile-details'>
        <div className='profile-nav-name'>{upper}</div>
        <div className='profile-email' onClick={() => auth.signOut()}>
          {email}
        </div>
      </div>
      {/* <div className='profile-img' /> */}
    </div>
  )
}
