import React, { useState } from 'react'

import fulcrum from './fulcrum'

export const FulcrumContext = React.createContext()

export const FulcrumProvider = ({ children }) => {
  const [data, setData] = useState([])
  return <FulcrumContext.Provider value={{ fulcrum, data, setData }}>{children}</FulcrumContext.Provider>
}
