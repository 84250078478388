import React, { useState, useContext, useEffect } from 'react'
import { FulcrumContext } from '../../../../Fulcrum'
import functions from './functions'
export const ReportContext = React.createContext()

export const ReportProvider = ({ children }) => {
  const { fulcrum } = useContext(FulcrumContext)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [selectedJob, setSelectedJob] = useState(null)
  const [enquiry, setEnquiry] = useState(null)

  const form_id = 'e328b1d1-cd10-46d5-87d8-ace3f02a3344'

  const { updateEnquiry, deleteEnquiry, createNew, startBlank } = functions({
    setSelectedJob,
    fulcrum,
    setLoading,
    setData,
    data
  })

  useEffect(() => {
    setLoading(true)
    fulcrum
      .getAllData(form_id)
      .then(resp =>
        setData(
          resp.filter(r => r.form_values['6612'] && ['Enquiry', 'In Progress', 'Dead', 'Complete'].includes(r.status))
        )
      )
      .catch(console.error)
      .then(() => setLoading(false))
      .catch(console.error)
    //eslint-disable-next-line
  }, [])

  return (
    <ReportContext.Provider
      value={{
        loading,
        selectedJob,
        setSelectedJob,
        fulcrum,
        updateEnquiry,
        deleteEnquiry,
        data,
        startBlank,
        createNew,
        enquiry,
        setEnquiry
      }}>
      {children}
    </ReportContext.Provider>
  )
}

export const withData = Component => props => (
  <ReportContext.Consumer>{contexts => <Component {...props} {...contexts} />}</ReportContext.Consumer>
)
