import React, { useState, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import { withData } from './context'
import { TopLoader } from '../../../layout/Loading'
export default withData(({ selectedJob, setSelectedJob, data, enquiry, setEnquiry, loading }) => {
  const [center, setCenter] = useState({
    lat: -38.2379611,
    lng: 146.3782018
  })

  useEffect(() => {
    enquiry &&
      setCenter({
        lat: enquiry.latitude,
        lng: enquiry.longitude
      })
  }, [enquiry])

  useEffect(() => {
    let middle = { lat: 0, lng: 0, count: 0 }
    if (data) {
      data.map(r => {
        if (r.latitude && r.longitude) {
          middle.lat += r.latitude
          middle.lng += r.longitude
          middle.count++
        }
        return null
      })
      middle.lat !== 0 && setCenter({ lat: middle.lat / middle.count, lng: middle.lng / middle.count })
    }
  }, [data])

  let colors = [
    {
      type: 'Completed',
      color: 'green'
    },
    {
      type: 'In Progress',
      color: 'yellow'
    },
    {
      type: 'Awaiting Invoice',
      color: 'pink'
    },
    {
      type: 'Enquiry',
      color: 'blue'
    },
    {
      type: 'Dead',
      color: 'black'
    }
  ]
  const color = status => {
    console.log(status)
    let index = colors.findIndex(i => i.type === status)
    return index !== -1 ? colors[index].color : colors[4].color
  }

  const Marker = ({ job }) => <div className={`marker ${color(job.status)}`} onClick={() => setSelectedJob(job)} />
  const updatePos = e => enquiry && setEnquiry({ ...enquiry, latitude: e.lat, longitude: e.lng })

  return (
    <>
      <TopLoader loading={loading} />
      {/*  <div id='bore-map' style={{ height: selectedJob ? 250 : '30vh' }}> */}
      <div id='bore-map' style={{ height: '30vh' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBZsOKeC_xTGwdascGnram_6zNjcZH5F_U' }}
          defaultCenter={{ lat: -38.2379611, lng: 146.3782018 }}
          defaultZoom={8}
          center={center}
          onClick={updatePos}
          options={{
            streetViewControl: false,
            scaleControl: false,
            mapTypeControl: false,
            panControl: false,
            zoomControl: false,
            rotateControl: false,
            fullscreenControl: false
            //   styles: styles
          }}>
          {!selectedJob &&
            data &&
            data.map(job => job.latitude && <Marker lat={job.latitude} lng={job.longitude} job={job} key={job.id} />)}
          {enquiry && <Marker lat={enquiry.latitude} lng={enquiry.longitude} job={enquiry} key={enquiry.id} />}
        </GoogleMapReact>
      </div>
    </>
  )
})
