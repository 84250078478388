import React, { useState, useEffect } from 'react'
import { withData } from '../context'
import moment from 'moment'
import Photo from './Photo'

export default withData(({ prestarts, itemSelected }) => {
  const [photos, setPhotos] = useState([])

  useEffect(() => {
    if (prestarts && itemSelected) {
      let _photos = []
      let _prestarts = prestarts.filter(
        p =>
          p.form_values['1168'] &&
          p.form_values['07dc'] &&
          p.form_values['07dc'].choice_values[p.form_values['07dc'].choice_values.length - 1] === itemSelected.value
      )

      _prestarts.sort((a, b) => moment(a.form_values['c5c8']).isBefore(b.form_values['c5c8']))
      _prestarts.forEach(prestart => prestart.form_values['1168'].forEach(photo => _photos.push(photo)))
      setPhotos(_photos)
    }
    return () => setPhotos([])
  }, [prestarts, itemSelected])

  if (photos.length === 0) return null
  return (
    <>
      <h3 className='bores-section-title'>{photos.length} Photos</h3>
      <div id='bore-photo-container'>
        {photos.map((i, index) => (
          <Photo data={i} key={index} />
        ))}
      </div>
    </>
  )
})
